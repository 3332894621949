import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Button,
    Typography,
    Dialog,
    DialogTitle,
} from '@material-ui/core';

import { Form, Field } from 'react-final-form';

import OnboardingContainerView from './OnboardingContainerView';
import {
    updateVerificationCode,
    verifyPhoneNumber,
    addPhoneNumber,
} from '../../../ducks/OnboardingDuck';
import { push } from 'connected-react-router';
import { store } from '../../..';

import Colors from '../../../constants/Colors';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class VerifyPhoneNumberScreen extends Component {
    state = {
        verificationCodeResent: false,
    };
    // _onNext = () => {
    //     if (!this._areInputsValid()) {
    //         return;
    //     }

    //     this.props.verifyPhoneNumber({
    //         phoneNumber: this.props.phoneNumber,
    //         verificationCode: this.props.verificationCode,
    //     });
    // };

    // _areInputsValid = () => {
    //     // only accept verification codes of length 4
    //     return (
    //         this.props.verificationCode !== null &&
    //         this.props.verificationCode !== undefined &&
    //         this.props.verificationCode.toString().length === 4
    //     );
    // };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography
                    variant="h4"
                    gutterBottom
                >{`We've sent a verification code to ${this.props.formattedPhoneNumber}`}</Typography>
                <Typography variant="h2" gutterBottom>
                    Please enter the 4-digit verification code.
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    Didn't get one?{' '}
                    <Button
                        onClick={() => {
                            this.props.addPhoneNumber({
                                phoneNumber: this.props.phoneNumber,
                            });
                            this.setState({ verificationCodeResent: true });
                            // store.dispatch(push('/verifyPhoneNumber'));
                        }}
                        style={{ color: Colors.pangeaBlue }}
                    >
                        Resend verification code
                    </Button>
                </Typography>
                <Typography variant="subtitle2">
                    Wrong number?{' '}
                    <Button
                        onClick={() => {
                            store.dispatch(push('/addPhoneNumber'));
                        }}
                        style={{ color: Colors.pangeaBlue }}
                    >
                        Re-enter your phone number
                    </Button>
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <Form
                onSubmit={values => {
                    console.log('onSubmit');
                    console.log(values);
                    this.props.verifyPhoneNumber({
                        phoneNumber: this.props.phoneNumber,
                        verificationCode: values.verificationCode,
                    });
                }}
                validate={values => {
                    const errors = {};

                    if (
                        !values.verificationCode ||
                        values.verificationCode.length !== 4
                    ) {
                        errors.verificationCode = 'Required';
                    }

                    return errors;
                }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        error={!!this.props.error}
                        loading={this.props.loading}
                    >
                        <Field name="verificationCode">
                            {({ input, meta }) => (
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    label="Code"
                                    type="text"
                                    name="verification code"
                                    placeholder="_ _ _ _"
                                    maxLength={4}
                                    fullWidth
                                    error={
                                        (meta.error && meta.touched) ||
                                        !!this.props.error
                                    }
                                    {...input}
                                    onChange={event => {
                                        let verificationCode = event.target.value.replace(
                                            /[^0-9]/g,
                                            ''
                                        );
                                        input.onChange(verificationCode);
                                    }}
                                />
                            )}
                        </Field>
                        <div style={{ height: 20 }} />

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Continue
                        </Button>
                    </form>
                )}
            </Form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <Dialog
                    onClose={() => {
                        this.setState({ verificationCodeResent: false });
                    }}
                    open={this.state.verificationCodeResent}
                >
                    <DialogTitle>
                        <Typography>Resent Verification Text</Typography>
                    </DialogTitle>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 20,
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                this.setState({
                                    verificationCodeResent: false,
                                });
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </Dialog>
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const {
        verificationCode,
        phoneNumber,
        formattedPhoneNumber,
        loading,
        error,
    } = onboarding;

    return {
        verificationCode: verificationCode ? verificationCode : '',
        phoneNumber: phoneNumber ? phoneNumber : '',
        formattedPhoneNumber: formattedPhoneNumber ? formattedPhoneNumber : '',
        loading,
        error,
    };
};

export default connect(mapStateToProps, {
    addPhoneNumber,
    updateVerificationCode,
    verifyPhoneNumber,
})(VerifyPhoneNumberScreen);
