import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import { Dialog, TextField, Button, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { saveStripeCardToken } from '../../ducks/SubscriptionsDuck';
import Card from 'react-credit-cards';
import Environment from '../../constants/Environment';
import Payment from 'payment';
const stripe = require('stripe-client')(Environment.STRIPEKEY);

// props:
/*
close
isVisible
onSubmit
*/

function clearNumber(value = '') {
    return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
    if (!value) {
        return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
        case 'amex':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 15)}`;
            break;
        case 'dinersclub':
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                10
            )} ${clearValue.slice(10, 14)}`;
            break;
        default:
            nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
                4,
                8
            )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
            break;
    }

    return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
        const issuer = Payment.fns.cardType(allValues.number);
        maxLength = issuer === 'amex' ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
    const clearValue = clearNumber(value);

    if (clearValue.length >= 3) {
        return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
}

export function formatFormData(data) {
    return Object.keys(data).map(d => `${d}: ${data[d]}`);
}

class AddCreditCardForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: '',
        };
    }

    handleInputFocus = ({ target }) => {
        this.setState({
            focused: target.name,
        });
    };

    _createStripeToken() {
        let exp_month = this.state.creditCardForm.values.expiry.split('/')[0];
        let exp_year = this.state.creditCardForm.values.expiry.split('/')[1];
        const information = {
            card: {
                number: this.state.creditCardForm.values.number,
                exp_month,
                exp_year,
                cvc: this.state.creditCardForm.values.cvc,
                name: this.state.creditCardForm.values.number.name,
            },
        };
        this._onPayment(information);
    }

    async _onPayment(information) {
        const card = await stripe.createToken(information);
        const token = card.id;
        this.props.saveStripeCardToken(this.state.user.id, token);
        // send token to backend for processing
    }

    render() {
        const required = value => (value ? undefined : 'Required');

        return (
            <Dialog
                onClose={() => {
                    console.log('onClose');
                    this.props.close();
                }}
                open={this.props.isVisible}
            >
                <Form onSubmit={this.props.onSubmit}>
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    flex: 1,
                                    padding: 20,
                                    minWidth: 500,
                                }}
                            >
                                <Typography variant="h4" gutterBottom>
                                    Add Payment Method
                                </Typography>
                                <Card
                                    cvc={values.cvc ? values.cvc : ''}
                                    expiry={values.expiry ? values.expiry : ''}
                                    focused={this.state.focused}
                                    name={
                                        values.cardName ? values.cardName : ''
                                    }
                                    number={
                                        values.cardNum ? values.cardNum : ''
                                    }
                                />
                                <Field name="cardNum" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            type="tel"
                                            variant="outlined"
                                            label="Card Number"
                                            fullWidth
                                            style={{
                                                marginBottom: 20,
                                                marginTop: 20,
                                            }}
                                            {...input}
                                            onFocus={this.handleInputFocus}
                                            onChange={event => {
                                                input.onChange(
                                                    formatCreditCardNumber(
                                                        event.target.value
                                                    )
                                                );
                                            }}
                                        />
                                    )}
                                </Field>
                                <Field name="cardName" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            variant="outlined"
                                            label="Name"
                                            fullWidth
                                            style={{ marginBottom: 20 }}
                                            {...input}
                                            onFocus={this.handleInputFocus}
                                        />
                                    )}
                                </Field>
                                <div
                                    style={{
                                        justifyContent: 'space-between',
                                        display: 'flex',
                                        alignItems: 'stretch',
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            marginRight: 20,
                                        }}
                                    >
                                        <Field
                                            name="expiry"
                                            validate={required}
                                        >
                                            {({ input, meta }) => (
                                                <TextField
                                                    error={
                                                        meta.error &&
                                                        meta.touched
                                                    }
                                                    variant="outlined"
                                                    label="Valid Thru"
                                                    style={{
                                                        marginBottom: 20,
                                                    }}
                                                    {...input}
                                                    fullWidth
                                                    onFocus={
                                                        this.handleInputFocus
                                                    }
                                                    onChange={event => {
                                                        input.onChange(
                                                            formatExpirationDate(
                                                                event.target
                                                                    .value
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flex: 1,
                                            marginLeft: 10,
                                        }}
                                    >
                                        <Field name="cvc" validate={required}>
                                            {({ input, meta }) => (
                                                <TextField
                                                    error={
                                                        meta.error &&
                                                        meta.touched
                                                    }
                                                    variant="outlined"
                                                    label="CVC"
                                                    style={{ marginBottom: 20 }}
                                                    {...input}
                                                    onFocus={
                                                        this.handleInputFocus
                                                    }
                                                    onChange={event => {
                                                        input.onChange(
                                                            formatCVC(
                                                                event.target
                                                                    .value
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </Field>
                                    </div>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting}
                                    style={{ marginBottom: 20 }}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )}
                </Form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ userSession, subscriptions }) => {
    const { user } = userSession;
    const {
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    } = subscriptions;
    return {
        user,
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    };
};

export default connect(mapStateToProps, {
    saveStripeCardToken,
})(AddCreditCardForm);
