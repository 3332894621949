import Colors from './Colors';

export default {
    subTitleText: {
        fontSize: 21,
        fontWeight: '400',
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
    titleText: {
        fontSize: 36,
        fontWeight: '400',
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
    bodyText: {
        fontSize: 14,
        fontWeight: '400',
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
    formTitle: {
        fontSize: 18,
        fontWeight: '400',
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
    formTitleBold: {
        fontSize: 18,
        fontWeight: '700',
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
};
