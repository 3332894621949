import React from 'react';

import { Button } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import Colors from '../../constants/Colors';

import PropTypes from 'prop-types';

const styles = {
    root: {
        borderRadius: 22,
        borderColor: Colors.pangeaBlue,
        color: Colors.pangeaBlue,
        backgroundColor: 'white',
        '&:hover': {
            borderColor: 'white',
            backgroundColor: Colors.pangeaBlue,
            borderWidth: 1,
            color: 'white',
        },
        '&:focus': {
            borderColor: 'white',
            backgroundColor: Colors.pangeaBlue,
            borderWidth: 1,
            color: 'white',
        },
    },
};

function WhiteReverseButton(props) {
    const { classes } = props;
    return (
        <Button variant="outlined" className={classes.root} {...props}>
            {props.children}
        </Button>
    );
}

WhiteReverseButton.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhiteReverseButton);
