import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    REVIEW_CREATE: 'review_create',
    REVIEW_CREATE_SUCCESS: 'review_create_success',
    REVIEW_CREATE_FAIL: 'review_create_fail',

    REVIEW_FETCH: 'review_fetch',
    REVIEW_FETCH_SUCCESS: 'review_fetch_success',
    REVIEW_FETCH_FAIL: 'review_fetch_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    review: null,
    myReviewsArray: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.REVIEW_CREATE:
            return { ...state, loading: false };
        case types.REVIEW_CREATE_SUCCESS:
            return { ...state, loading: false, reveiw: action.payload };
        case types.REVIEW_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.REVIEW_FETCH:
            return { ...state, loading: true };
        case types.REVIEW_FETCH_SUCCESS:
            return { ...state, loading: false, myReviewsArray: action.payload };
        case types.REVIEW_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export const fetchMyReviews = ({ user }) => {
    return dispatch => {
        dispatch({ type: types.REVIEW_FETCH });

        WS.socket.emit('review:getUsersReviews', { user });
        WS.socket.once('review:getUsersReviews', res => {
            if (res.success) {
                fetchMyReviewsSuccess(dispatch, res.data);
            } else {
                fetchMyReviewsFail(dispatch, res.message);
            }
        });
    };
};
const fetchMyReviewsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch My Reviews Success', {});
    dispatch({
        type: types.REVIEW_FETCH_SUCCESS,
        payload: res,
    });
};
const fetchMyReviewsFail = (dispatch, msg) => {
    dispatch({
        type: types.REVIEW_FETCH_SUCCESS,
        payload: msg,
    });
};
