import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Footer, CustomContainer } from '../common';

import { Grid, Container } from '@material-ui/core';

import {
    ProfileMainCard,
    ProfileSkillsAndRatingsCard,
    ProfileAdCard,
    ProfileTeamMembersCard,
    ProfilePostsCard,
    ProfileTestimonialsCard,
} from '../common';

import {
    saveClickedPost,
    saveClickedPangean,
    clearClickedPangean,
} from '../../ducks/MainFeedDuck';

import { push } from 'connected-react-router';
import { store } from '../..';

class DetailedCompanyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    _onPostItemPress(item) {
        this.props.saveClickedPost({
            post: item,
            owner_id: item.owner_id,
            responder_id: this.props.user.id,
        }); // doesn't matter who is owner and who is responder
        store.dispatch(push('/detailedPost'));
    }

    _onEmployeeItemPress(item) {
        if (item.id === this.props.user.id) {
            store.dispatch(push('/myProfile'));
        } else {
            this.props.saveClickedPangean({
                owner_id: item.id,
                responder_id: this.props.user.id,
                pangean: item,
            });
            store.dispatch(push('/detailedProfile'));
        }
    }

    async componentDidMount() {}

    render() {
        if (this.props.loading) {
            return <div />;
        } else {
            return (
                <div style={{ backgroundColor: '#f2f2f7' }}>
                    <Header hasSearch user={this.props.user} />
                    <CustomContainer>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <ProfileMainCard
                                    type={'detailed_company'}
                                    company={this.props.clickedCompany}
                                />
                                <ProfileSkillsAndRatingsCard
                                    type="detailed_company"
                                    company={this.props.clickedCompany}
                                />
                                <ProfilePostsCard
                                    type="detailed_company"
                                    posts={this.props.clickedCompanyActivePosts}
                                    company={this.props.clickedCompany}
                                    loading={
                                        this.props.loading ||
                                        this.props.loadingActivePosts
                                    }
                                    onPostItemPress={post =>
                                        this._onPostItemPress(post)
                                    }
                                />
                                <ProfileTestimonialsCard
                                    type="detailed_company"
                                    company={this.props.clickedCompany}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <ProfileAdCard />
                                <ProfileTeamMembersCard
                                    type="detailed_company"
                                    company={this.props.clickedCompany}
                                    onEmployeeItemPress={pangean =>
                                        this._onEmployeeItemPress(pangean)
                                    }
                                />
                            </Grid>
                        </Grid>
                    </CustomContainer>
                    <Footer light />
                </div>
            );
        }
    }
}

const mapStateToProps = ({ userSession, company }) => {
    const { user } = userSession;
    const {
        loading,
        loadingActivePosts,
        clickedCompany,
        clickedCompanyActivePosts,
    } = company;

    return {
        user,
        loading,
        clickedCompany,
        clickedCompanyActivePosts,
        loadingActivePosts,
    };
};

export default connect(mapStateToProps, {
    saveClickedPangean,
    saveClickedPost,
})(DetailedCompanyScreen);
