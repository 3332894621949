import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Footer, CustomContainer } from '../common';

import { Grid, Button, Typography, Card, CardContent } from '@material-ui/core';
import Colors from '../../constants/Colors';

const styles = {
    rootContainer: {
        backgroundColor: '#f2f2f7',
        paddingTop: 49,
        paddingBottom: 64,
    },

    thankYouText: {
        fontWeight: 'normal',
    },

    planText: {
        fontWeight: 'normal',
        paddingBottom: 38,
    },

    planCard: {
        paddingTop: 40,
        paddingBottom: 40,
        paddingLeft: 30,
        paddingRight: 30,
    },

    planCardContent: {
        margin: 0,
        padding: 0,
    },

    planCardHeaderText: {
        fontWeight: 'bold',
        paddingBottom: 20,
    },

    featuresList: {
        margin: 0,
        padding: 0,
        paddingLeft: 20,
        marginBottom: 34,
    },

    featureListItemAvailable: {
        padding: '0px 0px 10px 10px',
    },

    featureListItemUnavailable: {
        padding: '0px 0px 10px 10px',
    },

    planCardActionContainer: {
        display: 'flex',
        alignItems: 'stretch',
    },

    manageSubscriptionBtn: {
        flex: 1,
        marginRight: 16,
        borderRadius: 25,
        fontSize: 16,
        fontWeight: '400',
    },

    postProjectBtn: {
        flex: 1,
        borderRadius: 25,
        fontSize: 16,
        fontWeight: '400',
        borderColor: Colors.fontBlack,
    },
};

class CompletedPurchaseSubscriptionScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <Header hasSearch={true} />

                <div style={styles.rootContainer}>
                    <CustomContainer>
                        <Typography
                            variant="h6"
                            style={styles.thankYouText}
                            gutterBottom
                        >
                            Thank you for subscribing.
                        </Typography>

                        <Typography variant="h4" style={styles.planText}>
                            Here’s to being a Pro.
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <Card style={styles.planCard}>
                                    <CardContent style={styles.planCardContent}>
                                        <Typography
                                            variant="subtitle1"
                                            style={styles.planCardHeaderText}
                                        >
                                            With Pro, you can enjoy these
                                            benefits of:
                                        </Typography>

                                        <ul style={styles.featuresList}>
                                            {[
                                                {
                                                    name:
                                                        'Unlimited project postings',
                                                    available: true,
                                                },
                                                {
                                                    name:
                                                        'Engage up to 25 students monthly',
                                                    available: true,
                                                },
                                                {
                                                    name: '3 Employee Accounts',
                                                    available: true,
                                                },
                                                {
                                                    name: 'Customer Support',
                                                    available: true,
                                                },
                                            ].map(feature => {
                                                return (
                                                    <li
                                                        style={
                                                            feature.available
                                                                ? styles.featureListItemAvailable
                                                                : styles.featureListItemUnavailable
                                                        }
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {feature.name}
                                                        </Typography>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                        <div
                                            style={
                                                styles.planCardActionContainer
                                            }
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                style={
                                                    styles.manageSubscriptionBtn
                                                }
                                                disabled={this.props.loading}
                                            >
                                                <Typography variant="subtitle1">
                                                    Manage Subscription
                                                </Typography>
                                            </Button>

                                            <Button
                                                variant="outlined"
                                                onClick={() => {}}
                                                style={styles.postProjectBtn}
                                            >
                                                <Typography variant="subtitle1">
                                                    Post a project
                                                </Typography>
                                            </Button>
                                        </div>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CustomContainer>
                </div>

                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(
    mapStateToProps,
    {}
)(CompletedPurchaseSubscriptionScreen);
