import WS from '../api/WS';
import { push } from 'connected-react-router';

// import { NavigationActions, StackActions } from 'react-navigation';
// import { AsyncStorage } from 'react-native';

// import Sentry from 'sentry-expo';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import { Analytics, Event } from 'expo-analytics';
import Environment from '../constants/Environment';
import {
    types as userTypes,
    updateUser,
    uploadUserProfilePic,
} from './UserSessionDuck';
// import {createGeo} from './GeoDuck';
import { createProposedSchool } from './ProposedSchoolDuck';

import moment from 'moment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    UPDATE_PHONE_NUMBER: 'update_phone_number',
    ADD_PHONE_NUMBER: 'add_phone_number',
    UNIQUE_PHONE_NUMBER_SUCCESS: 'unique_phone_number_success',
    UNIQUE_PHONE_NUMBER_FAIL: 'unique_phone_number_fail',

    SEND_SMS_VERIFICATION: 'send_sms_verification',
    SEND_SMS_VERIFICATION_SUCCESS: 'send_sms_verification_success',
    SEND_SMS_VERIFICATION_FAIL: 'send_sms_verification_fail',

    UPDATE_VERIFICATION_CODE: 'update_verification_code',
    VERIFY_PHONE_NUMBER: 'verify_phone_number',
    VERIFY_PHONE_NUMBER_SUCCESS: 'verify_phone_number_success',
    VERIFY_PHONE_NUMBER_FAIL: 'verify_phone_number_fail',

    SEND_VERIFICATION_EMAIL: 'send_verification_email',
    SEND_VERIFICATION_EMAIL_SUCCESS: 'send_verification_email_success',
    SEND_VERIFICATION_EMAIL_FAIL: 'send_verification_email_fail',

    ADD_USER_TYPE: 'add_user_type',
    ADD_PROFILE_PHOTO: 'add_profile_photo',
    ADD_BIO: 'add_bio',

    ADD_USER_WEBSITE: 'add_user_website',

    CREATE_ACCOUNT: 'create_account',
    CREATE_ACCOUNT_SUCCESS: 'create_account_success',
    CREATE_ACCOUNT_FAIL: 'create_account_fail',

    // Student
    ADD_STUDENT_SCHOOL_INFO: 'add_student_school_info',
    ADD_STUDENT_SKILLS: 'add_student_skills',
    ADD_STUDENT_BASE_HOURLY_RATE: 'add_student_base_hourly_rate',
    ADD_STUDENT_PROFESSIONAL_PRESENCE: 'add_student_professional_presence',
    STUDENT_ADD_HOME_ADDRESS_INFO: 'student_add_home_address_info',

    // Business
    ADD_BUSINESS_COMPANY_INFO: 'add_business_company_info',
    ADD_BUSINESS_COMPANY_LOOKUP_INFO: 'add_business_company_lookup_info',

    // Individual
    ADD_INDIVIDUAL_PERSONAL_INFO: 'add_individual_personal_info',
    ADD_INDIVIDUAL_ACCOUNT_INFO: 'add_individual_account_info',
    ADD_INDIVIDUAL_ADDRESS_INFO: 'add_individual_address_info',

    // Proposed School
    ADD_NEW_SCHOOL_INFO: 'add_new_school_info',
    CREATE_ACCOUNT_AND_PROPOSED_SCHOOL: 'create_account_and_proposed_school',
    CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_SUCCESS:
        'create_account_and_proposed_success',
    CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_FAIL: 'create_account_and_proposed_fail',
    CREATE_PROPOSED_SCHOOL: 'create_proposed_school',
    CREATE_PROPOSED_SCHOOL_SUCCESS: 'create_proposed_school_success',
    CREATE_PROPOSED_SCHOOL_FAIL: 'create_proposed_school_fail',

    // Company Lookup
    LOOKUP_COMPANY: 'lookup_company',
    LOOKUP_COMPANY_SUCCESS: 'lookup_company_success',
    LOOKUP_COMPANY_FAIL: 'lookup_company_fail',
    RESET_LOOKUP_COMPANY: 'RESET_LOOKUP_COMPANY',

    REQUEST_TO_JOIN_COMPANY: 'requst_to_join_company',
    REQUEST_TO_JOIN_COMPANY_SUCCESS: 'requst_to_join_company_success',
    REQUEST_TO_JOIN_COMPANY_FAIL: 'requst_to_join_company_fail',

    // currently unused!
    UPDATE_COMPANY_LOGO_PIC: 'update_company_logo',
    UPDATE_COMPANY_LOGO_PIC_SUCCESS: 'update_company_logo_success',
    UPDATE_COMPANY_LOGO_PIC_FAIL: 'update_company_logo_fail',

    ADD_COMPANY_LOGO_INFO: 'add_company_logo_info',

    ADD_COMPANY_ABOUT_US: 'add_company_about_us',

    ADD_VERIFY_COMPANY_INFO_SCREEN: 'add_verify_company_info_screen',

    UPDATE_EXISTING_COMPANY: 'update_existing_company',

    UPDATE_ACTIVATING_COMPANY: 'update_activating_company',
    UPDATE_ACTIVATING_COMPANY_SUCCESS: 'update_activating_company_success',
    UPDATE_ACTIVATING_COMPANY_FAIL: 'update_activating_company_fail',

    CREATE_COMPANY: 'create_company',
    CREATE_COMPANY_SUCCESS: 'create_company_success',
    CREATE_COMPANY_FAIL: 'create_company_fail',

    CREATE_COMPANY_WITH_LOGO: 'CREATE_COMPANY_WITH_LOGO',
    CREATE_COMPANY_WITH_LOGO_SUCCESS: 'CREATE_COMPANY_WITH_LOGO_SUCCESS',
    CREATE_COMPANY_WITH_LOGO_FAIL: 'CREATE_COMPANY_WITH_LOGO_FAIL',

    COMPANY_UPDATE: 'company_update',
    COMPANY_UPDATE_SUCCESS: 'company_update_success',
    COMPANY_UPDATE_FAIL: 'company_update_fail',

    SAVE_ONBOARDING_COMPANY: 'save_onboarding_company',
    SAVE_ONBOARDING_COMPANY_SUCCESS: 'save_onboarding_company_success',
    SAVE_ONBOARDING_COMPANY_FAIL: 'save_onboarding_company_fail',

    UPDATE_NEW_PASSWORD: 'update_new_password',

    RESET_PASSWORD_ON_ACTIVATION: 'reset_password_on_activation',
    RESET_PASSWORD_ON_ACTIVATION_SUCCESS:
        'reset_password_on_activation_success',
    RESET_PASSWORD_ON_ACTIVATION_FAIL: 'reset_password_on_activation_fail',

    CREATE_USER_HOMETOWN_GEO: 'create_user_hometown_geo',
    CREATE_USER_HOMETOWN_GEO_SUCCESS: 'create_user_hometown_geo_success',
    CREATE_USER_HOMETOWN_GEO_FAIL: 'create_user_hometown_geo_fail',
    CREATE_INDIVIDUAL_ADDRESS_GEO: 'create_individual_address_geo',
    CREATE_INDIVIDUAL_ADDRESS_GEO_SUCCESS:
        'create_individual_address_geo_success',
    CREATE_INDIVIDUAL_ADDRESS_GEO_FAIL: 'create_individual_address_geo_fail',

    // --------------------------- Added For Web ----------------------------------------
    // All User Types
    ADD_PERSONAL_INFO: 'ADD_PERSONAL_INFO',
    ADD_ACCOUNT_INFO: 'ADD_ACCOUNT_INFO',

    FETCH_SCHOOLS: 'FETCH_SCHOOLS',
    FETCH_SCHOOLS_SUCCESS: 'FETCH_SCHOOLS_SUCCESS',
    FETCH_SCHOOLS_FAIL: 'FETCH_SCHOOLS_FAIL',

    UPDATE_STUDENT_PROFILE_INFO: 'UPDATE_STUDENT_PROFILE_INFO',
    UPDATE_STUDENT_PROFILE_INFO_SUCCESS: 'UPDATE_STUDENT_PROFILE_INFO_SUCCESS',
    UPDATE_STUDENT_PROFILE_INFO_FAIL: 'UPDATE_STUDENT_PROFILE_INFO_FAIL',

    // TODO: Delete
    SELECT_SCHOOL: 'SELECT_SCHOOL',
    CREATE_STUDENT_ACCOUNT: 'CREATE_STUDENT_ACCOUNT',
    CREATE_STUDENT_ACCOUNT_SUCCESS: 'CREATE_STUDENT_ACCOUNT_SUCCESS',
    CREATE_STUDENT_ACCOUNT_FAIL: 'CREATE_STUDENT_ACCOUNT_FAIL',
    CREATE_BUSINESS_ACCOUNT: 'CREATE_BUSINESS_ACCOUNT',
    CREATE_BUSINESS_ACCOUNT_SUCCESS: 'CREATE_BUSINESS_ACCOUNT_SUCCESS',
    CREATE_BUSINESS_ACCOUNT_FAIL: 'CREATE_BUSINESS_ACCOUNT_FAIL',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    error: '',
    loading: false,
    phoneNumber: null,
    verifiedPhoneNumber: null,
    formattedPhoneNumber: null,
    verificationCode: null,
    userType: 'student',
    profilePhotoInfo: null,
    bio: null,
    website_url: null,

    // Student
    studentPersonalInfo: null,
    studentAccountInfo: null,
    studentSchoolInfo: null,
    selectedSkills: null,
    proposedSkills: null,
    baseHourlyRate: null,
    studentHomeAddress: null,

    // Business
    businessCompanyInfo: null,
    businessPersonalInfo: null,
    businessAccountInfo: null,
    businessCompanyLookupInfo: null,

    // Individual
    individualPersonalInfo: null,
    individualAccountInfo: null,
    individualAddressInfo: null,

    // Activating Company
    verifyCompanyInfo: null,

    // Proposed School
    newSchoolInfo: null,
    school: null,

    // Company Lookup
    existingCompany: null,
    companyName: null,
    company: null,
    companyLogoInfo: null,
    companyAboutUs: null,

    newPassword: null,

    // --------------------------- Added For Web ----------------------------------------
    // All User Types
    personalInfo: null,
    accountInfo: null,

    selectedSchool: null,
    schools: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_PHONE_NUMBER:
            return {
                ...state,
                phoneNumber: action.payload.phoneNumber,
                formattedPhoneNumber: action.payload.formattedPhoneNumber,
            };
        case types.ADD_PHONE_NUMBER:
            return {
                ...state,
                loading: true,
            };
        case types.UNIQUE_PHONE_NUMBER_SUCCESS:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.UNIQUE_PHONE_NUMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SEND_SMS_VERIFICATION:
            return {
                ...state,
                loading: true,
            };
        case types.SEND_SMS_VERIFICATION_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case types.SEND_SMS_VERIFICATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_VERIFICATION_CODE:
            return {
                ...state,
                verificationCode: action.payload,
            };
        case types.VERIFY_PHONE_NUMBER:
            return {
                ...state,
                loading: true,
            };
        case types.VERIFY_PHONE_NUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                verifiedPhoneNumber: action.payload,
            };
        case types.VERIFY_PHONE_NUMBER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SEND_VERIFICATION_EMAIL:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.SEND_VERIFICATION_EMAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case types.SEND_VERIFICATION_EMAIL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ADD_USER_TYPE:
            return {
                ...state,
                userType: action.payload,
            };
        case types.ADD_PROFILE_PHOTO:
            return {
                ...state,
                loading: false,
                profilePhotoInfo: action.payload,
            };
        case types.ADD_BIO:
            return {
                ...state,
                loading: false,
                bio: action.payload,
            };
        case types.ADD_USER_WEBSITE:
            return {
                ...state,
                loading: false,
                website_url: action.payload,
            };

        case types.CREATE_ACCOUNT:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.CREATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case types.CREATE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // Student
        case types.ADD_STUDENT_SCHOOL_INFO:
            return {
                ...state,
                loading: false,
                studentSchoolInfo: action.payload,
            };
        case types.ADD_STUDENT_SKILLS:
            return {
                ...state,
                loading: false,
                selectedSkills: action.payload.selectedSkills,
                proposedSkills: action.payload.proposedSkills,
            };
        case types.ADD_STUDENT_BASE_HOURLY_RATE:
            return {
                ...state,
                loading: false,
                baseHourlyRate: action.payload,
            };
        case types.STUDENT_ADD_HOME_ADDRESS_INFO:
            return {
                ...state,
                loading: false,
                studentHomeAddress: action.payload,
            };

        // Business
        case types.ADD_BUSINESS_COMPANY_INFO:
            return {
                ...state,
                loading: false,
                businessCompanyInfo: action.payload,
            };
        case types.ADD_BUSINESS_COMPANY_LOOKUP_INFO:
            return {
                ...state,
                loading: false,
                businessCompanyLookupInfo: action.payload,
            };

        // Individual
        case types.ADD_INDIVIDUAL_PERSONAL_INFO:
            return {
                ...state,
                individualPersonalInfo: action.payload,
            };
        case types.ADD_INDIVIDUAL_ACCOUNT_INFO:
            return {
                ...state,
                individualAccountInfo: action.payload,
            };
        case types.ADD_INDIVIDUAL_ADDRESS_INFO:
            return {
                ...state,
                individualAddressInfo: action.payload,
            };

        // Proposed School
        case types.ADD_NEW_SCHOOL_INFO:
            return {
                ...state,
                newSchoolInfo: action.payload,
            };
        case types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
                error: '',
            };
        case types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.CREATE_PROPOSED_SCHOOL:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_PROPOSED_SCHOOL_SUCCESS:
            return {
                ...state,
                loading: false,
                school: action.payload,
                error: '',
            };
        case types.CREATE_PROPOSED_SCHOOL_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // Company Lookup
        case types.LOOKUP_COMPANY:
            return {
                ...state,
                loading: true,
            };
        case types.LOOKUP_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                companyName: action.payload.company_name,
                existingCompany: action.payload,
                error: '',
            };
        case types.LOOKUP_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                companyName: action.payload.company_name,
                existingCompany: action.payload,
                // error: action.payload.message,
            };
        case types.RESET_LOOKUP_COMPANY:
            return {
                ...state,
                businessCompanyLookupInfo: null,
            };

        case types.REQUEST_TO_JOIN_COMPANY:
            return {
                ...state,
                loading: true,
            };
        case types.REQUEST_TO_JOIN_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload,
                error: '',
            };
        case types.REQUEST_TO_JOIN_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.ADD_COMPANY_LOGO_INFO:
            return {
                ...state,
                loading: false,
                companyLogoInfo: action.payload,
            };

        case types.ADD_COMPANY_ABOUT_US:
            return {
                ...state,
                loading: false,
                companyAboutUs: action.payload,
            };

        case types.ADD_VERIFY_COMPANY_INFO_SCREEN:
            return {
                ...state,
                verifyCompanyInfo: action.payload,
            };
        case types.UPDATE_ACTIVATING_COMPANY:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_ACTIVATING_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
            };
        case types.UPDATE_ACTIVATING_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_EXISTING_COMPANY:
            return {
                ...state,
                existingCompany: action.payload,
            };

        case types.CREATE_COMPANY:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.CREATE_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload,
                error: '',
            };
        case types.CREATE_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CREATE_COMPANY_WITH_LOGO:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.CREATE_COMPANY_WITH_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload,
                error: '',
            };
        case types.CREATE_COMPANY_WITH_LOGO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.COMPANY_UPDATE:
            return {
                ...state,
                loading: true,
            };
        case types.COMPANY_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload,
                error: '',
            };
        case types.COMPANY_UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.SAVE_ONBOARDING_COMPANY:
            return {
                ...state,
                loading: true,
            };
        case types.SAVE_ONBOARDING_COMPANY_SUCCESS:
            return {
                ...state,
                loading: false,
                company: action.payload,
                error: '',
            };
        case types.SAVE_ONBOARDING_COMPANY_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_NEW_PASSWORD:
            return {
                ...state,
                newPassword: action.payload,
            };

        case types.RESET_PASSWORD_ON_ACTIVATION:
            return {
                ...state,
                loading: true,
            };
        case types.RESET_PASSWORD_ON_ACTIVATION_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.RESET_PASSWORD_ON_ACTIVATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CREATE_USER_HOMETOWN_GEO:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_USER_HOMETOWN_GEO_SUCCESS:
            return {
                ...state,
                loading: false,
                geo: action.payload,
            };
        case types.CREATE_USER_HOMETOWN_GEO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CREATE_INDIVIDUAL_ADDRESS_GEO:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_INDIVIDUAL_ADDRESS_GEO_SUCCESS:
            return {
                ...state,
                loading: false,
                geo: action.payload,
            };
        case types.CREATE_INDIVIDUAL_ADDRESS_GEO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // --------------------------- Added For Web ----------------------------------------
        // All User Types
        case types.ADD_PERSONAL_INFO:
            return {
                ...state,
                personalInfo: action.payload,
            };
        case types.ADD_ACCOUNT_INFO:
            return {
                ...state,
                accountInfo: action.payload,
            };
        case types.FETCH_SCHOOLS:
            return {
                ...state,
                loading: true,
            };
        case types.FETCH_SCHOOLS_SUCCESS:
            return {
                ...state,
                loading: false,
                schools: action.payload,
            };
        case types.FETCH_SCHOOLS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_STUDENT_PROFILE_INFO:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_STUDENT_PROFILE_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_STUDENT_PROFILE_INFO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        // TODO: Remove
        case types.SELECT_SCHOOL:
            return {
                ...state,
                selectedSchool: action.payload,
            };
        case types.CREATE_STUDENT_ACCOUNT:
            return {
                ...state,
                loading: true,
            };
        case types.CREATE_BUSINESS_ACCOUNT:
            return {
                ...state,
                loading: true,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const updatePhoneNumber = ({ phoneNumber, formattedPhoneNumber }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PHONE_NUMBER,
            payload: {
                phoneNumber,
                formattedPhoneNumber,
            },
        });
    };
};

export const addPhoneNumber = ({ phoneNumber }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_PHONE_NUMBER,
        });

        if (Environment.ENVIRONMENT !== 'Production') {
            uniquePhoneNumberSuccess(dispatch, phoneNumber);

            // WS.socket.emit('user:checkUniquePhoneNumber', {
            //     phone_number: phoneNumber,
            // });
            // WS.socket.once('user:checkUniquePhoneNumber', res => {
            //     if (res.success) {
            //         uniquePhoneNumberSuccess(dispatch, phoneNumber);
            //     } else {
            //         uniquePhoneNumberFail(dispatch, res.message);
            //     }
            // });
        } else {
            WS.socket.emit('user:checkUniquePhoneNumber', {
                phone_number: phoneNumber,
            });
            WS.socket.once('user:checkUniquePhoneNumber', res => {
                if (res.success) {
                    uniquePhoneNumberSuccess(dispatch, phoneNumber);
                } else {
                    uniquePhoneNumberFail(dispatch, res.message);
                }
            });
        }
    };
};

const uniquePhoneNumberSuccess = (dispatch, phoneNumber) => {
    // AmplitudeAnalytics.track('Unique Phone Number Success', {
    //     phoneNumber: phoneNumber,
    // });
    dispatch({
        type: types.UNIQUE_PHONE_NUMBER_SUCCESS,
        payload: phoneNumber,
    });

    if (Environment.ENVIRONMENT !== 'Production') {
        sendSMSVerificationSuccess(dispatch, {});

        // dispatch({
        //     type: types.SEND_SMS_VERIFICATION,
        // });
        // WS.socket.emit('user:sendTwilioVerification', {
        //     phone_number: phoneNumber,
        // });
        // WS.socket.once('user:sendTwilioVerification', res => {
        //     if (res.success) {
        //         sendSMSVerificationSuccess(dispatch, res);
        //     } else {
        //         sendSMSVerificationFail(dispatch, res.message);
        //     }
        // });
    } else {
        dispatch({
            type: types.SEND_SMS_VERIFICATION,
            payload: phoneNumber,
        });
        WS.socket.emit('user:sendTwilioVerification', {
            phone_number: phoneNumber,
        });
        WS.socket.once('user:sendTwilioVerification', res => {
            if (res.success) {
                sendSMSVerificationSuccess(dispatch, res);
            } else {
                sendSMSVerificationFail(dispatch, res.message);
            }
        });
    }
};

const uniquePhoneNumberFail = (dispatch, msg) => {
    dispatch({
        type: types.UNIQUE_PHONE_NUMBER_FAIL,
        payload: msg,
    });
};

export const sendSMSVerification = ({ phoneNumber }) => {
    return dispatch => {
        dispatch({
            type: types.SEND_SMS_VERIFICATION,
            payload: phoneNumber,
        });
        WS.socket.emit('user:sendTwilioVerification', {
            phone_number: phoneNumber,
        });
        WS.socket.once('user:sendTwilioVerification', res => {
            if (res.success) {
                sendSMSVerificationSuccess(dispatch, res);
            } else {
                sendSMSVerificationFail(dispatch, res.message);
            }
        });
    };
};
const sendSMSVerificationSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Send SMS Verification Success', res);
    dispatch({
        type: types.SEND_SMS_VERIFICATION_SUCCESS,
        payload: res,
    });

    dispatch(push('/verifyPhoneNumber'));

    // dispatch(push('/selectUserType'));
};

const sendSMSVerificationFail = (dispatch, msg) => {
    dispatch({
        type: types.SEND_SMS_VERIFICATION_FAIL,
        payload: msg,
    });
};

export const updateVerificationCode = ({ verificationCode }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_VERIFICATION_CODE,
            payload: verificationCode,
        });
    };
};

export const verifyPhoneNumber = ({ phoneNumber, verificationCode }) => {
    return dispatch => {
        dispatch({
            type: types.VERIFY_PHONE_NUMBER,
            payload: { phoneNumber, verificationCode },
        });

        if (Environment.ENVIRONMENT !== 'Production') {
            checkSMSVerificationSuccess(dispatch, {});

            // WS.socket.emit('user:checkTwilioVerification', {
            //     phone_number: phoneNumber,
            //     verification_code: verificationCode,
            // });
            // WS.socket.once('user:checkTwilioVerification', res => {
            //     if (res.success) {
            //         checkSMSVerificationSuccess(dispatch, res, phoneNumber);
            //     } else {
            //         checkSMSVerificationFail(dispatch, res.message);
            //     }
            // });
        } else {
            WS.socket.emit('user:checkTwilioVerification', {
                phone_number: phoneNumber,
                verification_code: verificationCode,
            });
            WS.socket.once('user:checkTwilioVerification', res => {
                if (res.success) {
                    checkSMSVerificationSuccess(dispatch, res);
                } else {
                    checkSMSVerificationFail(dispatch, res.message);
                }
            });
        }
    };
};

const checkSMSVerificationSuccess = (dispatch, res, phoneNumber) => {
    // AmplitudeAnalytics.track('Verify Phone Number Success', res);

    dispatch({
        type: types.VERIFY_PHONE_NUMBER_SUCCESS,
        payload: phoneNumber,
    });

    dispatch(push('/selectUserType'));
};

const checkSMSVerificationFail = (dispatch, msg) => {
    dispatch({
        type: types.VERIFY_PHONE_NUMBER_FAIL,
        payload: msg.message,
    });
};

export const verifyPhoneNumberLegacyUser = ({
    phoneNumber,
    verificationCode,
    user,
}) => {
    return dispatch => {
        dispatch({
            type: types.VERIFY_PHONE_NUMBER,
        });

        if (Environment.ENVIRONMENT !== 'Production') {
            checkSMSVerificationLegacyUserSuccess(
                dispatch,
                {},
                user,
                phoneNumber
            );

            // WS.socket.emit('user:checkTwilioVerification', {
            //     phone_number: phoneNumber,
            //     verification_code: verificationCode,
            // });
            // WS.socket.once('user:checkTwilioVerification', res => {
            //     if (res.success) {
            //         checkSMSVerificationLegacyUserSuccess(
            //             dispatch,
            //             res,
            //             user,
            //             phoneNumber
            //         );
            //     } else {
            //         checkSMSVerificationFail(dispatch, res);
            //     }
            // });
        } else {
            WS.socket.emit('user:checkTwilioVerification', {
                phone_number: phoneNumber,
                verification_code: verificationCode,
            });
            WS.socket.once('user:checkTwilioVerification', res => {
                if (res.success) {
                    checkSMSVerificationLegacyUserSuccess(
                        dispatch,
                        res,
                        user,
                        phoneNumber
                    );
                } else {
                    checkSMSVerificationFail(dispatch, res);
                }
            });
        }
    };
};

const checkSMSVerificationLegacyUserSuccess = (
    dispatch,
    res,
    user,
    phoneNumber
) => {
    // AmplitudeAnalytics.track('Verify Phone Number Legacy Success', res);
    dispatch({
        type: types.VERIFY_PHONE_NUMBER_SUCCESS,
        payload: phoneNumber,
    });

    dispatch(
        updateUser({
            user: {
                id: user.id,
                phone_number: phoneNumber,
            },
        })
    );

    // if (user.user_type === 'student') {
    //     const pushAction = StackActions.push({
    //         routeName: 'StudentSchoolInfo',
    //     });
    //     dispatch(pushAction);
    // } else if (user.user_type === 'business' && user.needs_activation) {
    //     const pushAction = StackActions.push({
    //         routeName: 'IndividualPersonalInfo',
    //     });
    //     dispatch(pushAction);
    // } else {
    //     const pushAction = StackActions.push({
    //         routeName: 'AreYouABusiness',
    //     });
    //     dispatch(pushAction);
    // }
};

export const addUserType = ({ userType }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_USER_TYPE,
            payload: userType,
        });
    };
};

// currently unused
export const sendVerificationEmail = userId => {
    // Sentry.captureMessage('sendVerificationEmail', {
    //     level: 'info',
    // });
    return dispatch => {
        dispatch({
            type: types.SEND_VERIFICATION_EMAIL,
        });
        WS.socket.emit('user:sendVerificationEmail', {
            user_id: userId,
        });
        WS.socket.once('user:sendVerificationEmail', res => {
            if (res.success) {
                sendVerificationEmailSuccess(dispatch, res);
            } else {
                sendVerificationEmailFail(dispatch, res);
            }
        });
    };
};
const sendVerificationEmailSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Send Verification Email Success', res);

    dispatch({
        type: types.SEND_VERIFICATION_EMAIL_SUCCESS,
        payload: res,
    });
};
const sendVerificationEmailFail = (dispatch, msg) => {
    // Sentry.captureMessage('sendVerificationEmailFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SEND_VERIFICATION_EMAIL_FAIL,
        payload: msg,
    });
};

export const addProfilePhotoInfo = ({ profilePhotoInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_PROFILE_PHOTO,
            payload: profilePhotoInfo,
        });
    };
};
export const addBioInfo = ({ bio }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_BIO,
            payload: bio,
        });
    };
};
export const addUserWebsite = ({ website_url }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_USER_WEBSITE,
            payload: website_url,
        });
    };
};

export const createAccount = ({ userInfo }) => {
    let lowerCaseEmail = userInfo.email.toLowerCase().trim();
    return dispatch => {
        // Sentry.captureMessage('createAccount', {
        //     level: 'info',
        // });
        dispatch({
            type: types.CREATE_ACCOUNT,
        });

        WS.socket.emit('user:createAccount', {
            phone_number: userInfo.phoneNumber,
            user_type: userInfo.userType,
            first_name: userInfo.firstName.trim(),
            last_name: userInfo.lastName.trim(),
            email: lowerCaseEmail,
            password: userInfo.password,
            is_with_proposed_school: false,
        });
        WS.socket.once('user:createAccount', res => {
            if (res.success) {
                localStorage.setItem('auth_token', res.data.token);
                // AsyncStorage.setItem('auth_token', res.data.token);
                createAccountSuccess(dispatch, res.data.user);
            } else {
                createAccountFail(dispatch, res.message);
            }
        });
    };
};
const createAccountSuccess = (dispatch, user) => {
    // Sentry.setUserContext({
    //     email: user.email,
    //     id: user.id,
    // });
    // Sentry.captureMessage('createAccountSuccess', {
    //     level: 'info',
    // });

    // AmplitudeAnalytics.identify(user.id, user);
    // AmplitudeAnalytics.track('Create Account Success', user);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: user.id,
    //     cid: user.id,
    // });
    // analytics
    //     .event(new Event('Auth', 'Create Account Success', user.email))
    //     .then(() => console.log('Create Account Success'))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.CREATE_ACCOUNT_SUCCESS,
    });

    dispatch({
        type: userTypes.LOGIN_USER_SUCCESS,
        payload: user,
    });

    if (user.user_type !== 'student') {
        let blacklisted_domains = [
            /* Default domains included */
            'aol.com',
            'att.net',
            'comcast.net',
            'facebook.com',
            'gmail.com',
            'gmx.com',
            'googlemail.com',
            'google.com',
            'hotmail.com',
            'hotmail.co.uk',
            'mac.com',
            'me.com',
            'mail.com',
            'msn.com',
            'live.com',
            'sbcglobal.net',
            'verizon.net',
            'yahoo.com',
            'yahoo.co.uk',

            /* Other global domains */
            'email.com',
            'fastmail.fm',
            'games.com' /* AOL */,
            'gmx.net',
            'hush.com',
            'hushmail.com',
            'icloud.com',
            'iname.com',
            'inbox.com',
            'lavabit.com',
            'love.com' /* AOL */,
            'outlook.com',
            'pobox.com',
            'protonmail.com',
            'rocketmail.com' /* Yahoo */,
            'safe-mail.net',
            'wow.com' /* AOL */,
            'ygm.com' /* AOL */,
            'ymail.com' /* Yahoo */,
            'zoho.com',
            'yandex.com',
        ];
        let domain = user.email.substring(user.email.indexOf('@') + 1);

        if (!blacklisted_domains.includes(domain)) {
            companyLookUpByDomain(dispatch, domain, user);
        } else {
            dispatch(push('/businessSelectCompany'));
        }
    }

    // we navigate students from new schools in createProposedSchoolSuccess
    // this may be redundant, only calls where is_with_proposed_school if false would be made here
    if (!user.is_with_proposed_school) {
        // we navigate the non student users in companyLookUpByDomain
        if (user.user_type === 'student') {
            dispatch(push('/studentAddProfileInfo'));
        }
    }
};

const createAccountFail = (dispatch, msg) => {
    if (msg === 'This domain is not connected to one of our current schools') {
        dispatch(push('/addNewSchool'));
    }

    dispatch({
        type: types.CREATE_ACCOUNT_FAIL,
        payload: msg
            ? msg
            : 'Error creating account. Please contact support@pangea.app for assistance.',
    });
};

export const addStudentAddressInfo = ({ studentHomeAddress }) => {
    return dispatch => {
        dispatch({
            type: types.STUDENT_ADD_HOME_ADDRESS_INFO,
            payload: studentHomeAddress,
        });
    };
};

const companyLookUpByDomain = (dispatch, domain, user) => {
    WS.socket.emit('company:findFromDomain', {
        domain,
    });
    WS.socket.once('company:findFromDomain', res => {
        if (res.success) {
            if (res.data.length > 0) {
                dispatch({
                    type: types.UPDATE_EXISTING_COMPANY,
                    payload: res.data[0],
                });

                // add company to newly created user
                dispatch(
                    updateUser({
                        user: {
                            id: user.id,
                            company_id: res.data[0].id,
                        },
                    })
                );
                dispatch(push('/businessAddProfileInfo'));
            } else {
                dispatch(push('/businessAddCompanyInfo'));
            }
        } else {
            console.log('error finding company domain');
            dispatch(push('/businessAddCompanyInfo'));
        }
    });
};

// Student
export const addNewSchoolInfo = ({ newSchoolInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_NEW_SCHOOL_INFO,
            payload: newSchoolInfo,
        });
    };
};
export const addStudentSchoolInfo = ({ studentSchoolInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_STUDENT_SCHOOL_INFO,
            payload: studentSchoolInfo,
        });
    };
};
export const addStudentSkills = ({ selectedSkills, proposedSkills }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_STUDENT_SKILLS,
            payload: {
                selectedSkills,
                proposedSkills,
            },
        });
    };
};
export const addStudentBaseHourlyRate = ({ baseHourlyRate }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_STUDENT_BASE_HOURLY_RATE,
            payload: baseHourlyRate,
        });
    };
};

// Business
export const addBusinessCompanyInfo = ({ businessCompanyInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_BUSINESS_COMPANY_INFO,
            payload: businessCompanyInfo,
        });
    };
};
export const addBusinessCompanyLookupInfo = ({ businessCompanyLookupInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_BUSINESS_COMPANY_LOOKUP_INFO,
            payload: businessCompanyLookupInfo,
        });
    };
};

// Individual
export const addIndividualPersonalInfo = ({ individualPersonalInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_INDIVIDUAL_PERSONAL_INFO,
            payload: individualPersonalInfo,
        });
    };
};
export const addIndividualAccountInfo = ({ individualAccountInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_INDIVIDUAL_ACCOUNT_INFO,
            payload: individualAccountInfo,
        });
    };
};
export const addIndividualAddressInfo = ({ individualAddressInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_INDIVIDUAL_ADDRESS_INFO,
            payload: individualAddressInfo,
        });
    };
};

// Proposed School
export const createAccountAndProposedSchool = ({ schoolInfo, userInfo }) => {
    let lowerCaseEmail = userInfo.email.toLowerCase().trim();
    return dispatch => {
        // Sentry.captureMessage('createAccount', {
        //     level: 'info',
        // });
        dispatch({
            type: types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL,
            payload: {
                phone_number: userInfo.phoneNumber,
                user_type: userInfo.userType,
                first_name: userInfo.firstName.trim(),
                last_name: userInfo.lastName.trim(),
                email: lowerCaseEmail,
                password: userInfo.password,
                is_with_proposed_school: true,
                geo_id: '11b76f18-8505-4094-9379-8b0558b6a008', // non student school geo_id
            },
        });

        WS.socket.emit('user:createAccount', {
            phone_number: userInfo.phoneNumber,
            user_type: userInfo.userType,
            first_name: userInfo.firstName.trim(),
            last_name: userInfo.lastName.trim(),
            email: lowerCaseEmail,
            password: userInfo.password,
            is_with_proposed_school: true,
            geo_id: '11b76f18-8505-4094-9379-8b0558b6a008', // non student school geo_id
        });
        WS.socket.once('user:createAccount', res => {
            if (res.success) {
                localStorage.setItem('auth_token', res.data.token);
                // AsyncStorage.setItem('auth_token', res.data.token);
                createAccountAndProposedSchoolSuccess(
                    dispatch,
                    res.data.user,
                    schoolInfo
                );
            } else {
                createAccountAndProposedFail(dispatch, res.message.message);
            }
        });
    };
};
const createAccountAndProposedSchoolSuccess = (dispatch, user, schoolInfo) => {
    // Sentry.setUserContext({
    //     email: user.email,
    //     id: user.id,
    // });
    // Sentry.captureMessage('createAccountSuccess', {
    //     level: 'info',
    // });

    // AmplitudeAnalytics.identify(user.id, user);
    // AmplitudeAnalytics.track('Create Account Proposed School Success', user);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: user.id,
    //     cid: user.id,
    // });
    // analytics
    //     .event(new Event('Auth', 'Create Account Success', user.email))
    //     .then(() => console.log('Create Account Success'))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_SUCCESS,
        payload: user,
    });

    dispatch({
        type: userTypes.LOGIN_USER_SUCCESS,
        payload: user,
    });

    dispatch(
        createProposedSchool({
            schoolInfo,
            user,
        })
    );
};
const createAccountAndProposedFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_ACCOUNT_AND_PROPOSED_SCHOOL_FAIL,
        payload: msg,
    });
};

export const lookupCompany = ({ companyName }) => {
    return dispatch => {
        dispatch({
            type: types.LOOKUP_COMPANY,
            payload: companyName,
        });

        WS.socket.emit('company:lookup', {
            companyName,
        });
        WS.socket.once('company:lookup', res => {
            if (res.success) {
                lookupCompanySuccess(dispatch, res.data);
            } else {
                lookupCompanyFail(dispatch, res.message);
            }
        });
    };
};

const lookupCompanySuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Lookup Company Success', data);
    dispatch({
        type: types.LOOKUP_COMPANY_SUCCESS,
        payload: data,
    });

    if (!data.message) {
        dispatch(push('/businessAddCompanyInfo'));
    } else if (data.message === 'Company already exists.') {
        console.log('Success: Company already exists.');

        dispatch(push('/businessCompanyExists'));
    } else {
        console.log('lookupCompanySuccess else case');
    }
};

const lookupCompanyFail = (dispatch, res) => {
    dispatch({
        type: types.LOOKUP_COMPANY_FAIL,
        payload: res.existingCompany,
    });

    if (res.message === 'Company already exists.') {
        console.log('Fail: Company already exists.');

        dispatch(push('/businessCompanyExists'));
    } else {
        console.log('lookupCompanyFail else case');
    }
};

export const resetLookupCompany = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_LOOKUP_COMPANY,
            payload: true,
        });
    };
};

export const requestToJoinCompany = ({ companyName, newUser }) => {
    return dispatch => {
        dispatch({
            type: types.REQUEST_TO_JOIN_COMPANY,
            payload: companyName,
        });

        WS.socket.emit('company:requestToJoin', {
            companyName,
            newUser,
        });
        WS.socket.once('company:requestToJoin', res => {
            if (res.success) {
                requestToJoinCompanySuccess(dispatch, res.data);
            } else {
                requestToJoinCompanyFail(dispatch, res.message);
            }
            dispatch(push('/businessAddProfileInfo'));
        });
    };
};

const requestToJoinCompanySuccess = (dispatch, data) => {
    dispatch({
        type: types.REQUEST_TO_JOIN_COMPANY_SUCCESS,
        payload: data,
    });
};

const requestToJoinCompanyFail = (dispatch, msg) => {
    dispatch({
        type: types.REQUEST_TO_JOIN_COMPANY_FAIL,
        payload: msg.message,
    });
};

export const uploadCompanyLogo = (company, file) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_COMPANY_LOGO_PIC,
            payload: company,
        });
        uploadCompanyLogoPic(dispatch, company, file);
    };
};

const uploadCompanyLogoPic = (dispatch, company, file) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;

    let filedata = new File([file], company.logo_pic, {
        type: file.type,
    });

    const formData = new FormData();
    formData.append('image', filedata);

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'file-path': 'Companies/' + company.id + '/logoPic/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            dispatch({
                type: types.UPDATE_COMPANY_LOGO_PIC_SUCCESS,
                payload: results.imageUrl,
            });

            dispatch(push('/businessAddProfileInfo'));
        })
        .catch(error => {
            dispatch({
                type: types.UPDATE_COMPANY_LOGO_PIC_FAIL,
                payload: error,
            });
        });
};

export const addCompanyLogoInfo = ({ companyLogoInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_COMPANY_LOGO_INFO,
            payload: companyLogoInfo,
        });
    };
};

export const addCompanyAboutUs = ({ aboutUs }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_COMPANY_ABOUT_US,
            payload: aboutUs,
        });
    };
};

export const addVerifyCompanyInfo = ({ verifyCompanyInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_VERIFY_COMPANY_INFO_SCREEN,
            payload: verifyCompanyInfo,
        });
    };
};

export const updateActivatingCompany = ({ company, user }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_ACTIVATING_COMPANY,
        });
        WS.socket.emit('company:updateActivating', {
            company,
        });
        WS.socket.once('company:updateActivating', res => {
            if (res.success) {
                updateActivatingCompanySuccess(dispatch, res.data, user);
            } else {
                updateActivatingCompanyFail(dispatch, res.messsage);
            }
        });
    };
};
const updateActivatingCompanySuccess = (dispatch, company, user) => {
    // AmplitudeAnalytics.track('Update Activating Company Success', company);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: company.owner_id,
    //     cid: company.owner_id,
    // });
    // analytics
    //     .event(
    //         new Event(
    //             'Company',
    //             'Update Activating Company Success',
    //             company.owner_id
    //         )
    //     )
    //     .then(() => console.log('Update Activating Company Success'))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.UPDATE_ACTIVATING_COMPANY_SUCCESS,
        payload: company,
    });
    // dispatch(
    //     NavigationActions.navigate({
    //         routeName: 'AddCompanyAboutUs',
    //         key: 'AddCompanyAboutUs',
    //     })
    // );
};
const updateActivatingCompanyFail = (dispatch, msg) => {
    // Sentry.captureMessage('Update Activating Company Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_ACTIVATING_COMPANY_FAIL,
        payload: msg.message,
    });
};

export const createCompany = companyInfo => {
    return dispatch => {
        // Sentry.captureMessage('createCompany', {
        //     level: 'info',
        // });
        dispatch({
            type: types.CREATE_COMPANY,
            payload: companyInfo,
        });

        WS.socket.emit('company:create', {
            company: companyInfo,
        });
        WS.socket.once('company:create', res => {
            if (res.success) {
                createCompanySuccess(dispatch, res.data);
            } else {
                createCompanyFail(dispatch, res.message);
            }
        });
    };
};
const createCompanySuccess = (dispatch, company) => {
    dispatch({
        type: types.CREATE_COMPANY_SUCCESS,
        payload: company,
    });

    dispatch(
        updateUser({
            user: {
                id: company.owner_id,
                company_id: company.id,
            },
        })
    );

    dispatch(push('/businessAddProfileInfo'));
};
const createCompanyFail = (dispatch, msg) => {
    // Sentry.captureMessage('createCompanyFail', {
    //     level: 'warning',
    // });

    dispatch({
        type: types.CREATE_COMPANY_FAIL,
        payload: msg.message,
    });
};

export const createCompanyWithLogo = (companyInfo, file) => {
    return dispatch => {
        dispatch({
            type: types.CREATE_COMPANY_WITH_LOGO,
            payload: companyInfo,
        });

        dispatch({
            type: types.LOOKUP_COMPANY,
            payload: companyInfo.company_name,
        });

        WS.socket.emit('company:lookup', {
            companyName: companyInfo.company_name,
        });
        WS.socket.once('company:lookup', res => {
            if (res.success) {
                dispatch({
                    type: types.LOOKUP_COMPANY_SUCCESS,
                    payload: res.data,
                });

                if (!res.data.message) {
                    console.log('new company name');
                    WS.socket.emit('company:create', {
                        company: companyInfo,
                    });
                    WS.socket.once('company:create', res => {
                        if (res.success) {
                            createCompanyWithLogoSuccess(
                                dispatch,
                                res.data,
                                file
                            );
                        } else {
                            createCompanyWithLogoFail(
                                dispatch,
                                res.message.msg
                            );
                        }
                    });
                } else if (res.data.message === 'Company already exists.') {
                    createCompanyWithLogoFail(dispatch, res.data.message);
                    console.log('Success: Company already exists.');
                } else {
                    createCompanyWithLogoFail(
                        dispatch,
                        'error creating company'
                    );
                    console.log('lookupCompanySuccess else case');
                }
            } else {
                console.log('res');
                console.log(res);

                dispatch({
                    type: types.LOOKUP_COMPANY_FAIL,
                    payload: res.message.existingCompany,
                });

                if (res.message.message === 'Company already exists.') {
                    createCompanyWithLogoFail(dispatch, res.message.message);
                    console.log('Fail: Company already exists.');
                } else {
                    createCompanyWithLogoFail(
                        dispatch,
                        'error creating company'
                    );
                    console.log('lookupCompanyFail else case');
                }
            }
        });
    };
};
const createCompanyWithLogoSuccess = (dispatch, company, file) => {
    dispatch({
        type: types.CREATE_COMPANY_WITH_LOGO_SUCCESS,
        payload: company,
    });

    dispatch(
        updateUser({
            user: {
                id: company.owner_id,
                company_id: company.id,
            },
        })
    );

    dispatch(uploadCompanyLogo(company, file));
};
const createCompanyWithLogoFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_COMPANY_WITH_LOGO_FAIL,
        payload: msg,
    });
};

export const companyUpdate = ({ company, user }) => {
    return dispatch => {
        dispatch({
            type: types.COMPANY_UPDATE,
        });
        WS.socket.emit('company:update', {
            company,
        });
        WS.socket.once('company:update', res => {
            if (res.success) {
                companyUpdateSuccess(dispatch, res.data, user);
            } else {
                companyUpdateFail(dispatch, res.messsage);
            }
        });
    };
};

const companyUpdateSuccess = (dispatch, company, user) => {
    // AmplitudeAnalytics.track('Company Update Success', company);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: company.owner_id,
    //     cid: company.owner_id,
    // });
    // analytics
    //     .event(new Event('Company', 'Company Update Success', company.owner_id))
    //     .then(() => console.log('Company Update Success'))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.COMPANY_UPDATE_SUCCESS,
        payload: company,
    });
};

const companyUpdateFail = (dispatch, msg) => {
    // Sentry.captureMessage('Company Update Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.COMPANY_UPDATE_FAIL,
        payload: msg,
    });
};

export const emailProfilePicUpload = ({ user_id }) => {
    return dispatch => {
        dispatch({
            type: types.COMPANY_UPDATE,
        });
        WS.socket.emit('user:requestProfilePicUpload', {
            user_id,
        });
        WS.socket.once('user:requestProfilePicUpload', res => {
            if (res.success) {
                console.log('success');
            } else {
                console.log('fail');
            }
        });
    };
};

export const saveOnboardingCompany = ({ company_id }) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_ONBOARDING_COMPANY,
        });

        WS.socket.emit('company:embed', {
            company_id,
        });
        WS.socket.once('company:embed', res => {
            if (res.success) {
                dispatch({
                    type: types.SAVE_ONBOARDING_COMPANY_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.SAVE_ONBOARDING_COMPANY_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const updateNewPassword = ({ newPassword }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_NEW_PASSWORD,
            payload: newPassword,
        });
    };
};

export const resetPasswordOnActivation = ({ newPassword, user_id }) => {
    return dispatch => {
        dispatch({
            type: types.RESET_PASSWORD_ON_ACTIVATION,
        });

        WS.socket.emit('user:resetPasswordOnActivation', {
            newPassword,
            user_id,
        });
        WS.socket.once('user:resetPasswordOnActivation', res => {
            if (res.success) {
                dispatch({
                    type: types.RESET_PASSWORD_ON_ACTIVATION_SUCCESS,
                });

                // dispatch(
                //     NavigationActions.navigate({
                //         routeName: 'AddPhoneNumber',
                //         key: 'AddPhoneNumber',
                //     })
                // );
            } else {
                dispatch({
                    type: types.RESET_PASSWORD_ON_ACTIVATION_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const createUserHometownGeo = ({ address, relatedTo }) => {
    return dispatch => {
        dispatch({
            type: types.CREATE_USER_HOMETOWN_GEO,
        });

        WS.socket.emit('geo:create', {
            address,
            related_to: relatedTo,
        });
        WS.socket.once('geo:create', res => {
            if (res.success) {
                createUserHometownGeoSuccess(dispatch, res.data);
            } else {
                createUserHometownGeoFail(dispatch, res.message);
            }
        });
    };
};

const createUserHometownGeoSuccess = (dispatch, res) => {
    dispatch({
        type: types.CREATE_USER_HOMETOWN_GEO_SUCCESS,
        payload: res,
    });

    dispatch(
        updateUser({
            user: {
                id: res.related_to.id,
                home_geo_id: res.id,
                // if user puts home address in during the summer,
                // set their adctive_geo to their home address
                active_geo:
                    moment(new Date()).format('M') > 5 &&
                    moment(new Date()).format('M') < 9
                        ? 'home'
                        : 'default',
            },
        })
    );
};

const createUserHometownGeoFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_USER_HOMETOWN_GEO_FAIL,
        payload: msg,
    });
};

export const createIndividualAddressGeo = ({
    address,
    relatedTo,
    nextScreen,
}) => {
    return dispatch => {
        dispatch({
            type: types.CREATE_INDIVIDUAL_ADDRESS_GEO,
        });

        WS.socket.emit('geo:create', {
            address,
            related_to: relatedTo,
        });
        WS.socket.once('geo:create', res => {
            if (res.success) {
                createIndividualAddressGeoSuccess(
                    dispatch,
                    res.data,
                    nextScreen
                );
            } else {
                createIndividualAddressGeoFail(dispatch, res.message);
            }
        });
    };
};

const createIndividualAddressGeoSuccess = (dispatch, res, nextScreen) => {
    dispatch({
        type: types.CREATE_INDIVIDUAL_ADDRESS_GEO_SUCCESS,
        res,
    });

    dispatch(
        updateUser({
            user: {
                id: res.related_to.id,
                geo_id: res.id,
            },
        })
    );

    // dispatch(
    //     NavigationActions.navigate({
    //         key: nextScreen,
    //         routeName: nextScreen,
    //     })
    // );
};

const createIndividualAddressGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('createIndividualAddressGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CREATE_INDIVIDUAL_ADDRESS_GEO_FAIL,
        payload: msg,
    });
};

// --------------------------- Added For Web ----------------------------------------
export const addPersonalInfo = ({ personalInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_PERSONAL_INFO,
            payload: personalInfo,
        });
    };
};

export const addAccountInfo = ({ accountInfo }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_ACCOUNT_INFO,
            payload: accountInfo,
        });
    };
};

export const selectSchool = ({ selectedSchool }) => {
    return dispatch => {
        dispatch({
            type: types.SELECT_SCHOOL,
            payload: selectedSchool,
        });
    };
};

export const fetchSchools = () => {
    return dispatch => {
        dispatch({
            type: types.FETCH_SCHOOLS,
        });

        WS.socket.emit('school:fetchAll', {});
        WS.socket.once('school:fetchAll', res => {
            console.log('school:fetchAll response');
            if (res.success) {
                fetchSchoolsSuccess(dispatch, res.data);
            } else {
                fetchSchoolFail(dispatch, res.message);
            }
        });
    };
};

const fetchSchoolsSuccess = (dispatch, res) => {
    dispatch({
        type: types.FETCH_SCHOOLS_SUCCESS,
        payload: res,
    });
};

const fetchSchoolFail = (dispatch, msg) => {
    dispatch({
        type: types.FETCH_SCHOOLS_FAIL,
        payload: msg,
    });
};

export const updateStudentProfileInfo = ({
    userInfo,
    profilePicInfo,
    studentHomeAddress,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_STUDENT_PROFILE_INFO,
            payload: { userInfo, profilePicInfo, studentHomeAddress },
        });
    };
};

// const updateStudentProfileInfoSuccess = (
//     dispatch,
//     user,
//     profilePicInfo,
//     studentHomeAddress
// ) => {
//     dispatch({
//         type: types.UPDATE_STUDENT_PROFILE_INFO_SUCCESS,
//     });
// };

// const updateStudentProfileInfoFail = (
//     dispatch,
//     user,
//     profilePicInfo,
//     studentHomeAddress
// ) => {
//     dispatch({
//         type: types.UPDATE_STUDENT_PROFILE_INFO_FAIL,
//     });
// };

// TODO: REMOVE
export const createStudentAccount = ({
    userInfo,
    profilePicInfo,
    studentHomeAddress,
}) => {
    let lowerCaseEmail = userInfo.email.toLowerCase().trim();
    return dispatch => {
        dispatch({
            type: types.CREATE_STUDENT_ACCOUNT,
            payload: { userInfo, profilePicInfo, studentHomeAddress },
        });

        WS.socket.emit('user:createAccount', {
            phone_number: userInfo.phoneNumber,
            user_type: 'student',
            first_name: userInfo.firstName.trim(),
            last_name: userInfo.lastName.trim(),
            email: lowerCaseEmail,
            password: userInfo.password,
            is_with_proposed_school: false,
            expected_yog: userInfo.yearOfGraduation,
            intended_major: userInfo.intendedMajor,
            bio: userInfo.bio,
        });
        WS.socket.once('user:createAccount', res => {
            if (res.success) {
                createStudentAccountSuccess(
                    dispatch,
                    res.data.user,
                    profilePicInfo,
                    studentHomeAddress
                );
            } else {
                createStudentAccountFail(dispatch, res.message);
            }
        });
    };
};

const createStudentAccountSuccess = (
    dispatch,
    user,
    profilePicInfo,
    studentHomeAddress
) => {
    dispatch({
        type: types.CREATE_ACCOUNT_SUCCESS,
    });

    dispatch({
        type: userTypes.LOGIN_USER_SUCCESS,
        payload: user,
    });

    uploadUserProfilePic(
        user,
        profilePicInfo.image,
        profilePicInfo.profile_pic_name
    );

    if (studentHomeAddress) {
        createUserHometownGeo({
            address: studentHomeAddress,
            relatedTo: {
                id: user.id,
                type: 'user',
            },
        });
    }
};
const createStudentAccountFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_ACCOUNT_FAIL,
        payload: msg
            ? msg
            : 'Error creating account. Please contact support@pangea.app for assistance.',
    });
};

export const createBusinessAccount = ({ userInfo, profilePicInfo }) => {
    let lowerCaseEmail = userInfo.email.toLowerCase().trim();
    return dispatch => {
        dispatch({
            type: types.CREATE_BUSINESS_ACCOUNT,
            payload: { userInfo, profilePicInfo },
        });

        WS.socket.emit('user:createAccount', {
            phone_number: userInfo.phoneNumber,
            user_type: 'business',
            first_name: userInfo.firstName.trim(),
            last_name: userInfo.lastName.trim(),
            email: lowerCaseEmail,
            password: userInfo.password,
            bio: userInfo.bio,
        });
        WS.socket.once('user:createAccount', res => {
            if (res.success) {
                createBusinessAccountSuccess(
                    dispatch,
                    res.data.user,
                    profilePicInfo
                );
            } else {
                createBusinessAccountFail(dispatch, res.message);
            }
        });
    };
};

const createBusinessAccountSuccess = (
    dispatch,
    user,
    profilePicInfo,
    studentHomeAddress
) => {
    dispatch({
        type: types.CREATE_ACCOUNT_SUCCESS,
    });

    dispatch({
        type: userTypes.LOGIN_USER_SUCCESS,
        payload: user,
    });

    uploadUserProfilePic(
        user,
        profilePicInfo.image,
        profilePicInfo.profile_pic_name
    );
};
const createBusinessAccountFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_ACCOUNT_FAIL,
        payload: msg
            ? msg
            : 'Error creating account. Please contact support@pangea.app for assistance.',
    });
};
