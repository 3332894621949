import WS from '../api/WS';
import { push } from 'connected-react-router';
// import { NavigationActions, StackActions } from 'react-navigation';
// import { AsyncStorage } from 'react-native';

// import Sentry from 'sentry-expo';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';

import moment from 'moment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    UPDATE_LOGIN: 'update_login',

    AUTHENTICATE: 'authenticate',
    AUTHENTICATE_SUCCESS: 'authenticate_success',
    AUTHENTICATE_FAIL: 'authenticate_fail',

    LOGIN_USER: 'login_user',
    LOGIN_USER_SUCCESS: 'login_user_success',
    LOGIN_USER_FAIL: 'login_user_fail',

    LOGOUT_USER: 'logout_user',

    UPDATE_USER: 'update_user',
    UPDATE_USER_SUCCESS: 'update_user_success',
    UPDATE_USER_FAIL: 'update_user_fail',

    UPDATE_USER_PROFILE_PIC: 'update_user_profile_pic',
    UPDATE_USER_PROFILE_PIC_SUCCESS: 'update_user_profile_pic_success',
    UPDATE_USER_PROFILE_PIC_FAIL: 'update_user_profile_pic_fail',

    UPDATE_EDIT_STUDENT_PROFILE_INFO: 'update_edit_student_profile_info',
    UPDATE_WORK_HISTORY_INFO: 'update_work_history_info',
    UPDATE_EDITED_WORK_HISTORY: 'update_is_new_work_history',
    UPDATE_REFERRAL_INFO: 'update_referral_info',
    UPDATE_EDITED_REFERRAL: 'update_edited_referral',

    UPDATE_EDIT_BUSINESS_PROFILE_INFO: 'update_edit_business_profile_info',
    UPDATE_TESTIMONIAL_INFO: 'update_testimonial_info',
    UPDATE_EDITED_TESTIMONIAL: 'update_edited_testimonial',

    DEACTIVATE_ACCOUNT: 'deactivate_account',
    DEACTIVATE_ACCOUNT_SUCCESS: 'deactivate_account_success',
    DEACTIVATE_ACCOUNT_FAIL: 'deactivate_account_fail',

    WAS_REACTIVATED: 'was_reactivated',

    SEND_FORGOT_PASSWORD: 'send_forgot_password',
    SEND_FORGOT_PASSWORD_SUCCESS: 'send_forgot_password_success',
    SEND_FORGOT_PASSWORD_FAIL: 'send_forgot_password_fail',

    RESET_USER_BADGE_COUNT: 'reset_user_badge_count',
    RESET_USER_BADGE_COUNT_SUCCESS: 'reset_user_badge_count_success',
    RESET_USER_BADGE_COUNT_FAIL: 'reset_user_badge_count_fail',

    CLEAR_SUBSCRIPTIONS: 'clear_subscriptions',
    CLEAR_SUBSCRIPTIONS_SUCCESS: 'clear_subscriptions_success',
    CLEAR_SUBSCRIPTIONS_FAIL: 'clear_subscriptions_fail',

    UPDATE_TOUCH_ID_ENABLED: 'update_touch_id_enabled',

    CHECK_PASSWORD_FOR_TOUCH_ID: 'check_password_for_touch_id',
    CHECK_PASSWORD_FOR_TOUCH_ID_SUCCESS: 'check_password_for_touch_id_success',
    CHECK_PASSWORD_FOR_TOUCH_ID_FAIL: 'check_password_for_touch_id_fail',

    UPDATE_TOUCH_ID_ATTEMPT_ERROR: 'update_touchID_attempt_error',
    RESET_TOUCH_ID_SETTINGS: 'reset_touch_id_settings',

    RESET_USER_SESSION: 'reset_user_session',

    UPDATE_USER_BOOLEANS: 'update_user_booleans',
    UPDATE_USER_BOOLEANS_SUCCESS: 'update_user_booleans_success',
    UPDATE_USER_BOOLEANS_FAIL: 'update_user_booleans_fail',

    UPDATE_STUDENT_HOME_ADDRESS: 'UPDATE_STUDENT_HOME_ADDRESS',
    UPDATE_USER_NEW_HOMETOWN_GEO: 'UPDATE_USER_NEW_HOMETOWN_GEO',
    UPDATE_USER_NEW_HOMETOWN_GEO_SUCCESS:
        'UPDATE_USER_NEW_HOMETOWN_GEO_SUCCESS',
    UPDATE_USER_NEW_HOMETOWN_GEO_FAIL: 'UPDATE_USER_NEW_HOMETOWN_GEO_FAIL',

    UPDATE_USER_EXISTING_HOMETOWN_GEO: 'UPDATE_USER_EXISTING_HOMETOWN_GEO',
    UPDATE_USER_EXISTING_HOMETOWN_GEO_SUCCESS:
        'UPDATE_USER_EXISTING_HOMETOWN_GEO_SUCCESS',
    UPDATE_USER_EXISTING_HOMETOWN_GEO_FAIL:
        'UPDATE_USER_EXISTING_HOMETOWN_GEO_FAIL',

    UPDATE_ACTIVE_GEO: 'UPDATE_ACTIVE_GEO',

    UPDATE_INDIVIDUAL_ADDRESS: 'UPDATE_INDIVIDUAL_ADDRESS',
    UPDATE_INDIVIDUAL_EXISTING_GEO: 'UPDATE_INDIVIDUAL_EXISTING_GEO',
    UPDATE_INDIVIDUAL_EXISTING_GEO_SUCCESS:
        'UPDATE_INDIVIDUAL_EXISTING_GEO_SUCCESS',
    UPDATE_INDIVIDUAL_EXISTING_GEO_FAIL: 'UPDATE_INDIVIDUAL_EXISTING_GEO_FAIL',

    SAVE_STRIPE_CARD_TOKEN: 'save_stripe_card_token',
    SAVE_STRIPE_CARD_TOKEN_SUCCESS: 'save_stripe_card_token_success',
    SAVE_STRIPE_CARD_TOKEN_FAIL: 'save_stripe_card_token_fail',

    ADD_USER_TO_NOTIFY_ONCE_PAYABLE: 'ADD_USER_TO_NOTIFY_ONCE_PAYABLE',
    ADD_USER_TO_NOTIFY_ONCE_PAYABLE_SUCCESS:
        'ADD_USER_TO_NOTIFY_ONCE_PAYABLE_SUCCESS',
    ADD_USER_TO_NOTIFY_ONCE_PAYABLE_FAIL:
        'ADD_USER_TO_NOTIFY_ONCE_PAYABLE_FAIL',

    RESET_NOTIFY_ONCE_PAYABLE_MESSAGE: 'RESET_NOTIFY_ONCE_PAYABLE_MESSAGE',

    PING_API: 'PING_API',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    email: null,
    password: null,
    user: null,
    authenticated: false,
    error: '',
    authenticateError: '',
    loading: false,
    resetPasswordStatus: false,

    // edit profile
    firstName: null,
    lastName: null,
    expectedYOG: null,
    intendedMajor: null,
    bio: null,
    websiteUrl: null,
    skills: null,
    proposedSkills: null,
    baseRate: null,
    profilePic: null,
    studentHomeAddress: null,
    activeGeo: null,
    updatedGeo: null,
    individualAddress: null,

    // work history
    editedWorkHistory: null,
    title: null,
    companyName: null,
    startDate: null,
    endDate: null,
    description: null,

    // referral
    referrerName: null,
    referrerCompany: null,
    referrerPhoneNumber: null,
    referrerEmail: null,
    editedReferral: null,

    faceID_touchID_enabled: null,
    faceID_touchID_configured: false,
    touchID_attempt_error: null,

    wasReactivated: null,

    notifyOncePayableMessage: '',
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.UPDATE_LOGIN:
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password,
            };

        case types.AUTHENTICATE:
            return {
                ...state,
                loading: true,
            };
        case types.AUTHENTICATE_SUCCESS:
            return {
                ...state,
                loading: false,
                authenticated: true,
                user: action.payload,
            };
        case types.AUTHENTICATE_FAIL:
            return {
                ...state,
                loading: false,
                authenticated: false,
                authenticateError: action.payload,
            };

        case types.LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: '',
            };
        case types.LOGIN_USER_SUCCESS:
            // return {...state, ...INITIAL_STATE, user: action.payload};
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case types.LOGIN_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.LOGOUT_USER:
            return {
                ...INITIAL_STATE,
                loading: false,
            };

        case types.UPDATE_USER:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case types.UPDATE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_USER_BOOLEANS:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_USER_BOOLEANS_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload,
            };
        case types.UPDATE_USER_BOOLEANS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_USER_PROFILE_PIC:
            return {
                ...state,
                loading: true,
            };

        case types.UPDATE_EDIT_STUDENT_PROFILE_INFO:
            return {
                ...state,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                expectedYOG: action.payload.expectedYOG,
                intendedMajor: action.payload.intendedMajor,
                bio: action.payload.bio,
                websiteUrl: action.payload.websiteUrl,
                skills: action.payload.skills,
                proposedSkills: action.payload.proposedSkills,
                baseRate: action.payload.baseRate,
                isBaseRateSkipped: action.payload.isBaseRateSkipped,
            };

        case types.UPDATE_EDIT_BUSINESS_PROFILE_INFO:
            return {
                ...state,
                companyName: action.payload.companyName,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                bio: action.payload.bio,
                title: action.payload.title,
                numEmployees: action.payload.numEmployees,
            };

        case types.UPDATE_WORK_HISTORY_INFO:
            return {
                ...state,
                title: action.payload.title,
                companyName: action.payload.companyName,
                startDate: action.payload.startDate,
                endDate: action.payload.endDate,
                description: action.payload.description,
            };

        case types.UPDATE_EDITED_WORK_HISTORY:
            return {
                ...state,
                editedWorkHistory: action.payload,
            };

        case types.UPDATE_REFERRAL_INFO:
            return {
                ...state,
                referrerName: action.payload.referrerName,
                referrerCompany: action.payload.referrerCompany,
                referrerPhoneNumber: action.payload.referrerPhoneNumber,
                referrerEmail: action.payload.referrerEmail,
            };

        case types.UPDATE_EDITED_REFERRAL:
            return {
                ...state,
                editedReferral: action.payload,
            };

        case types.UPDATE_TESTIMONIAL_INFO:
            return {
                ...state,
                testimonialName: action.payload.testimonialName,
                testimonialTitle: action.payload.testimonialTitle,
                testimonialDescription: action.payload.testimonialDescription,
            };

        case types.UPDATE_EDITED_TESTIMONIAL:
            return {
                ...state,
                editedTestimonial: action.payload,
            };

        case types.DEACTIVATE_ACCOUNT:
            return {
                ...state,
                loading: true,
            };
        case types.DEACTIVATE_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.DEACTIVATE_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.WAS_REACTIVATED:
            return {
                ...state,
                loading: false,
                wasReactivated: action.payload,
            };

        case types.SEND_FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
            };
        case types.SEND_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                resetPasswordStatus: true,
            };
        case types.SEND_FORGOT_PASSWORD_FAIL:
            return {
                ...state,
                loading: false,
                resetPasswordStatus: false,
                error: action.payload,
            };

        case types.RESET_USER_BADGE_COUNT:
            return {
                ...state,
                loading: true,
            };
        case types.RESET_USER_BADGE_COUNT_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.RESET_USER_BADGE_COUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.CLEAR_SUBSCRIPTIONS:
            return {
                ...state,
                loading: true,
            };
        case types.CLEAR_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.CLEAR_SUBSCRIPTIONS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.PROFILE_FETCH_EMBEDDED_DATA:
            return {
                ...state,
                loading: action.payload,
            };
        case types.PROFILE_FETCH_EMBEDDED_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
            };
        case types.PROFILE_FETCH_EMBEDDED_DATA_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };
        case types.UPDATE_TOUCH_ID_ENABLED:
            return {
                ...state,
                faceID_touchID_enabled: action.payload,
            };
        case types.CHECK_PASSWORD_FOR_TOUCH_ID:
            return {
                ...state,
                loading: true,
            };
        case types.CHECK_PASSWORD_FOR_TOUCH_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                faceID_touchID_enabled: action.payload,
                faceID_touchID_configured: true,
            };
        case types.CHECK_PASSWORD_FOR_TOUCH_ID_FAIL:
            return {
                ...state,
                loading: false,
                touchID_attempt_error: action.payload,
            };

        case types.UPDATE_TOUCH_ID_ATTEMPT_ERROR:
            return {
                ...state,
                loading: false,
                touchID_attempt_error: action.payload,
            };
        case types.RESET_TOUCH_ID_SETTINGS:
            return {
                ...state,
                loading: false,
                touchID_attempt_error: null,
                faceID_touchID_configured: false,
            };

        case types.UPDATE_STUDENT_HOME_ADDRESS:
            return {
                ...state,
                studentHomeAddress: action.payload,
            };
        case types.UPDATE_USER_NEW_HOMETOWN_GEO:
            return {
                ...state,
                loading: true,
            };
        case types.UPDATE_USER_NEW_HOMETOWN_GEO_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case types.UPDATE_USER_NEW_HOMETOWN_GEO_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            };

        case types.UPDATE_USER_PROFILE_PIC_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        case types.UPDATE_USER_EXISTING_HOMETOWN_GEO:
            return { ...state, loading: true };
        case types.UPDATE_USER_EXISTING_HOMETOWN_GEO_SUCCESS:
            return { ...state, loading: false, updatedGeo: action.payload };
        case types.UPDATE_USER_EXISTING_HOMETOWN_GEO_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPDATE_ACTIVE_GEO:
            return {
                ...state,
                activeGeo: action.payload,
            };
        case types.UPDATE_INDIVIDUAL_ADDRESS:
            return {
                ...state,
                individualAddress: action.payload,
            };
        case types.UPDATE_INDIVIDUAL_EXISTING_GEO:
            return { ...state, loading: true };
        case types.UPDATE_INDIVIDUAL_EXISTING_GEO_SUCCESS:
            return { ...state, loading: false, updatedGeo: action.payload };
        case types.UPDATE_INDIVIDUAL_EXISTING_GEO_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.RESET_USER_SESSION:
            return {
                ...INITIAL_STATE,
            };

        case types.SAVE_STRIPE_CARD_TOKEN:
            return { ...state, loading: true, error: '' };
        case types.SAVE_STRIPE_CARD_TOKEN_SUCCESS:
            return { ...state, loading: false, user: action.payload };
        case types.SAVE_STRIPE_CARD_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
                    ? action.payload
                    : 'unable to save payment method',
            };

        case types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE:
            return { ...state, loading: true };
        case types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE_SUCCESS:
            return {
                ...state,
                loading: false,
                notifyOncePayableMessage: action.payload,
            };
        case types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.RESET_NOTIFY_ONCE_PAYABLE_MESSAGE:
            return { ...state, notifyOncePayableMessage: '' };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const updateLogin = ({ email, password }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_LOGIN,
            payload: {
                email,
                password,
            },
        });
    };
};

export const authenticate = ({ token }) => {
    return dispatch => {
        dispatch({
            type: types.AUTHENTICATE,
        });
        WS.socket.once('authenticated', res => {
            if (res.data && res.data.token) {
                localStorage.setItem('auth_token', res.data.token);
                // AsyncStorage.setItem('auth_token', res.data.token);

                authenticateSuccess(dispatch, res.data.user);

                // Sentry.setUserContext({
                //     email: res.data.user.email,
                //     id: res.data.user.id,
                // });
            } else {
                authenticateFail(dispatch, res.status);
            }
        });

        WS.socket.once('disconnect', res => {});
    };
};
const authenticateSuccess = (dispatch, user) => {
    dispatch({
        type: types.AUTHENTICATE_SUCCESS,
        payload: user,
    });
};
const authenticateFail = async (dispatch, msg) => {
    dispatch({
        type: types.AUTHENTICATE_FAIL,
        payload: msg,
    });
    // await AsyncStorage.getItem('seenWalkthrough').then(token => {
    //     const resetAction = StackActions.reset({
    //         index: 0,
    //         key: null,
    //         actions: [
    //             NavigationActions.navigate({
    //                 key: 'Welcome',
    //                 routeName: 'Welcome',
    //             }),
    //         ],
    //     });
    //     const resetAction2 = StackActions.reset({
    //         index: 0,
    //         key: null,
    //         actions: [
    //             NavigationActions.navigate({
    //                 key: 'Welcome',
    //                 routeName: 'Welcome',
    //             }),
    //         ],
    //     });

    //     token !== null ? dispatch(resetAction2) : dispatch(resetAction);
    // });
};

export const loginUser = ({ email, password }) => {
    // Sentry.captureMessage('loginUser', {
    //     level: 'info',
    // });
    let lowerCaseEmail = email.toLowerCase().trim();

    return dispatch => {
        dispatch({
            type: types.LOGIN_USER,
        });
        WS.socket.emit('user:login', {
            email: lowerCaseEmail,
            password,
        });
        WS.socket.once('user:login', res => {
            if (!res.success) {
                loginUserFail(dispatch, res.message);
            }
        });

        WS.socket.once('authenticated', res => {
            if (res.success) {
                localStorage.setItem('auth_token', res.data.token);
                // AsyncStorage.setItem('auth_token', res.data.token);

                loginUserSuccess(
                    dispatch,
                    res.data.user,
                    res.data.was_reactivated
                );

                // Sentry.setUserContext({
                //     email: res.data.user.email,
                //     id: res.data.user.id,
                // });
            } else {
                loginUserFail(dispatch, res.message);
            }
        });
    };
};
const loginUserSuccess = (dispatch, user, was_reactivated) => {
    dispatch({
        type: types.LOGIN_USER_SUCCESS,
        payload: user,
    });

    if (was_reactivated) {
        dispatch({
            type: types.WAS_REACTIVATED,
            payload: true,
        });
    }

    let updatedUser = {};
    updatedUser.id = user.id;
    let now = moment(new Date(Date.now())).toISOString();

    updatedUser.last_signed_in_at = now;
    updatedUser.last_active = now;
    dispatch(
        updateUser({
            user: updatedUser,
        })
    );

    console.log('loginUserSuccess, push myProfile');

    dispatch(push('/myProfile'));
};
const loginUserFail = (dispatch, msg) => {
    // Sentry.captureMessage('loginUserFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.LOGIN_USER_FAIL,
        payload: msg,
    });
};

export const clearWasReactivated = () => {
    return dispatch => {
        dispatch({
            type: types.WAS_REACTIVATED,
            payload: false,
        });
    };
};

export const logoutUser = () => {
    return dispatch => {
        dispatch({
            type: types.LOGOUT_USER,
        });

        localStorage.removeItem('auth_token');

        dispatch(push('/welcome'));
    };
};

export const updateUser = ({ user }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER,
        });

        WS.socket.emit('user:update', {
            user,
        });
        WS.socket.once('user:update', res => {
            if (res.success) {
                updateUserSuccess(dispatch, res.data);
            } else {
                updateUserFail(dispatch, res.message);
            }
        });
    };
};
const updateUserSuccess = (dispatch, user) => {
    // AmplitudeAnalytics.track('Update User Success', user);
    dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: user,
    });

    // dispatch({
    //     type: types.LOGIN_USER_SUCCESS,
    //     payload: user
    // });
};
const updateUserFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateUserFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_USER_FAIL,
        payload: msg,
    });
};

export const updateEditStudentProfileInfo = ({
    firstName,
    lastName,
    expectedYOG,
    intendedMajor,
    bio,
    websiteUrl,
    skills,
    proposedSkills,
    baseRate,
    isBaseRateSkipped,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_EDIT_STUDENT_PROFILE_INFO,
            payload: {
                firstName,
                lastName,
                expectedYOG,
                intendedMajor,
                bio,
                websiteUrl,
                skills,
                proposedSkills,
                baseRate,
                isBaseRateSkipped,
            },
        });
    };
};

export const updateEditBusinessProfileInfo = ({
    companyName,
    firstName,
    lastName,
    bio,
    title,
    numEmployees,
}) => {
    return dispatch => {
        // Sentry.captureMessage('updateEditBusinessProfileInfo', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateEditBusinessProfileInfo', {
        //     companyName,
        //     firstName,
        //     lastName,
        //     bio,
        //     title,
        //     numEmployees,
        // });

        dispatch({
            type: types.UPDATE_EDIT_BUSINESS_PROFILE_INFO,
            payload: {
                companyName,
                firstName,
                lastName,
                bio,
                title,
                numEmployees,
            },
        });
    };
};

export const updateEditedWorkHistory = ({ editedWorkHistory }) => {
    return dispatch => {
        // Sentry.captureMessage('updateEditedWorkHistory', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateEditedWorkHistory', {
        //     editedWorkHistory,
        // });

        dispatch({
            type: types.UPDATE_EDITED_WORK_HISTORY,
            payload: editedWorkHistory,
        });
    };
};

export const updateReferralInfo = ({
    referrerName,
    referrerCompany,
    referrerPhoneNumber,
    referrerEmail,
}) => {
    return dispatch => {
        // Sentry.captureMessage('updateReferralInfo', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateReferralInfo', {
        //     referrerName,
        //     referrerCompany,
        //     referrerPhoneNumber,
        //     referrerEmail,
        // });

        dispatch({
            type: types.UPDATE_REFERRAL_INFO,
            payload: {
                referrerName,
                referrerCompany,
                referrerPhoneNumber,
                referrerEmail,
            },
        });
    };
};

export const updateEditedReferral = ({ editedReferral }) => {
    return dispatch => {
        // Sentry.captureMessage('updateEditedReferral', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateEditedReferral', {
        //     editedReferral,
        // });

        dispatch({
            type: types.UPDATE_EDITED_REFERRAL,
            payload: editedReferral,
        });
    };
};

export const updateWorkHistory = ({
    title,
    companyName,
    startDate,
    endDate,
    description,
}) => {
    return dispatch => {
        // Sentry.captureMessage('updateWorkHistory', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateWorkHistory', {
        //     title,
        //     companyName,
        //     startDate,
        //     endDate,
        //     description,
        // });

        dispatch({
            type: types.UPDATE_WORK_HISTORY_INFO,
            payload: {
                title,
                companyName,
                startDate,
                endDate,
                description,
            },
        });
    };
};

export const updateTestimonialInfo = ({
    testimonialName,
    testimonialTitle,
    testimonialDescription,
}) => {
    return dispatch => {
        // Sentry.captureMessage('updateTestimonialInfo', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateTestimonialInfo', {
        //     testimonialName,
        //     testimonialTitle,
        //     testimonialDescription,
        // });

        dispatch({
            type: types.UPDATE_TESTIMONIAL_INFO,
            payload: {
                testimonialName,
                testimonialTitle,
                testimonialDescription,
            },
        });
    };
};

export const updateEditedTestimonial = ({ editedTestimonial }) => {
    return dispatch => {
        // Sentry.captureMessage('updateEditedTestimonial', {
        //     level: 'info',
        // });

        // AmplitudeAnalytics.track('updateEditedTestimonial', {
        //     editedTestimonial,
        // });

        dispatch({
            type: types.UPDATE_EDITED_TESTIMONIAL,
            payload: editedTestimonial,
        });
    };
};

export const deactivateAccount = ({ user }) => {
    return dispatch => {
        dispatch({
            type: types.DEACTIVATE_ACCOUNT,
        });

        WS.socket.emit('user:deactivateAccount', {
            user,
        });
        WS.socket.once('user:deactivateAccount', res => {
            if (res.success) {
                deactivateAccountSuccess(dispatch, res);
            } else {
                deactivateAccountFail(dispatch, res);
            }
        });
    };
};
const deactivateAccountSuccess = (dispatch, res) => {
    dispatch({
        type: types.DEACTIVATE_ACCOUNT_SUCCESS,
        payload: res,
    });

    // AsyncStorage.removeItem('auth_token');

    // AmplitudeAnalytics.identify(null);

    // const resetAction = StackActions.reset({
    //     index: 0,
    //     key: null,
    //     actions: [
    //         NavigationActions.navigate({
    //             key: 'Welcome',
    //             routeName: 'Welcome',
    //         }),
    //     ],
    // });
    // dispatch(resetAction);
};
const deactivateAccountFail = (dispatch, msg) => {
    // Sentry.captureMessage('deactivateAccount', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.DEACTIVATE_ACCOUNT_FAIL,
        payload: msg,
    });
};

export const sendForgotPassword = email => {
    // Sentry.captureMessage('sendForgotPassword', {
    //     level: 'info',
    // });

    return dispatch => {
        dispatch({
            type: types.SEND_FORGOT_PASSWORD,
        });
        WS.socket.emit('user:sendForgotPassword', {
            email,
        });
        WS.socket.once('user:sendForgotPassword', res => {
            if (res.success) {
                sendForgotPasswordSuccess(dispatch, res);
            } else {
                sendForgotPasswordFail(dispatch, res.message);
            }
        });
    };
};
const sendForgotPasswordSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Send Forgot Password Success', res);
    dispatch({
        type: types.SEND_FORGOT_PASSWORD_SUCCESS,
        payload: res,
    });
};
const sendForgotPasswordFail = (dispatch, msg) => {
    // Sentry.captureMessage('sendForgotPasswordFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SEND_FORGOT_PASSWORD_FAIL,
        payload: msg,
    });
};

export const resetUserBadgeCount = user_id => {
    return dispatch => {
        dispatch({
            type: types.RESET_USER_BADGE_COUNT,
        });
        WS.socket.emit('user:resetUserBadgeCount', user_id);
        WS.socket.once('user:resetUserBadgeCount', res => {
            if (res.success) {
                resetUserBadgeCountSuccess(dispatch, res);
            } else {
                resetUserBadgeCountFail(dispatch, res.message);
            }
        });
    };
};
const resetUserBadgeCountSuccess = (dispatch, res) => {
    dispatch({
        type: types.RESET_USER_BADGE_COUNT_SUCCESS,
        payload: res,
    });
};
const resetUserBadgeCountFail = (dispatch, msg) => {
    // Sentry.captureMessage('resetUserBadgeCountFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.RESET_USER_BADGE_COUNT_FAIL,
        payload: msg,
    });
};

export const clearSubscriptions = user_id => {
    return dispatch => {
        dispatch({
            type: types.CLEAR_SUBSCRIPTIONS,
        });
        WS.socket.emit('user:clearSubscriptions', {
            id: user_id,
        });
        WS.socket.once('user:clearSubscriptions', res => {
            if (res.success) {
                clearSubscriptionsSuccess(dispatch, res);
            } else {
                clearSubscriptionsFail(dispatch, res.message);
            }
        });
    };
};
const clearSubscriptionsSuccess = (dispatch, res) => {
    let hasListener = WS.socket.hasListeners('message:in');
    if (hasListener) {
        WS.socket.removeListener('message:in');
    }
    dispatch({
        type: types.CLEAR_SUBSCRIPTIONS_SUCCESS,
        payload: res,
    });
};
const clearSubscriptionsFail = (dispatch, msg) => {
    // Sentry.captureMessage('clearSubscriptionsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CLEAR_SUBSCRIPTIONS_FAIL,
        payload: msg,
    });
};

export const checkEmailVerification = user_id => {
    return dispatch => {
        dispatch({
            type: types.CHECK_VERIFICATION_EMAIL,
        });
        WS.socket.emit('user:checkEmailVerification', {
            user_id,
        });
        WS.socket.once('user:checkEmailVerification', res => {
            if (res.success) {
                checkEmailVerificationSuccess(dispatch, res);
            } else {
                checkEmailVerificationFail(dispatch, res);
            }
        });
    };
};
const checkEmailVerificationSuccess = (dispatch, res) => {
    dispatch({
        type: types.CHECK_VERIFICATION_EMAIL_SUCCESS,
        payload: res,
    });
    // dispatch(
    //     NavigationActions.navigate({
    //         key: 'QuickSetUp',
    //         routeName: 'QuickSetUp',
    //     })
    // );
};
const checkEmailVerificationFail = (dispatch, msg) => {
    // Sentry.captureMessage('checkEmailVerification', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CHECK_VERIFICATION_EMAIL_FAIL,
        payload: msg,
    });
};

// currently unused
export const sendVerificationEmail = userId => {
    // Sentry.captureMessage('sendVerificationEmail', {
    //     level: 'info',
    // });
    return dispatch => {
        dispatch({
            type: types.SEND_VERIFICATION_EMAIL,
        });
        WS.socket.emit('user:sendVerificationEmail', {
            user_id: userId,
        });
        WS.socket.once('user:sendVerificationEmail', res => {
            if (res.success) {
                sendVerificationEmailSuccess(dispatch, res);
            } else {
                sendVerificationEmailFail(dispatch, res);
            }
        });
    };
};
const sendVerificationEmailSuccess = (dispatch, res) => {
    dispatch({
        type: types.SEND_VERIFICATION_EMAIL_SUCCESS,
        payload: res,
    });
};
const sendVerificationEmailFail = (dispatch, msg) => {
    // Sentry.captureMessage('sendVerificationEmailFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SEND_VERIFICATION_EMAIL_FAIL,
        payload: msg,
    });
};

export const updateStudentHomeAddress = ({ studentHomeAddress }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_STUDENT_HOME_ADDRESS,
            payload: studentHomeAddress,
        });
    };
};

export const updateUserWithNewHometownGeo = ({
    updatedUser,
    address,
    relatedTo,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER_NEW_HOMETOWN_GEO,
        });

        WS.socket.emit('geo:create', {
            address,
            related_to: relatedTo,
        });
        WS.socket.once('geo:create', res => {
            if (res.success) {
                updateUserWithNewHometownGeoSuccess(
                    dispatch,
                    res.data,
                    updatedUser
                );
            } else {
                updateUserWithNewHometownGeoFail(dispatch, res.message);
            }
        });
    };
};

const updateUserWithNewHometownGeoSuccess = (dispatch, res, updatedUser) => {
    dispatch({
        type: types.UPDATE_USER_NEW_HOMETOWN_GEO_SUCCESS,
        // payload: res,
    });

    let userToUpdate = Object.assign({}, updatedUser);
    userToUpdate.home_geo_id = res.id;
    userToUpdate.active_geo =
        moment(new Date()).format('M') > 5 && moment(new Date()).format('M') < 9
            ? 'home'
            : 'default';

    dispatch(
        updateUser({
            user: userToUpdate,
        })
    );
};

const updateUserWithNewHometownGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateUserWithNewHometownGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_USER_NEW_HOMETOWN_GEO_FAIL,
        payload: msg,
    });
};

export const updateUserWithExistingHometownGeo = ({
    updatedUser,
    geo_id,
    address,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER_EXISTING_HOMETOWN_GEO,
        });

        WS.socket.emit('geo:update', {
            geo: {
                id: geo_id,
                address,
            },
        });
        WS.socket.once('geo:update', res => {
            if (res.success) {
                updateUserWithExistingHometownGeoSuccess(
                    dispatch,
                    res.data,
                    updatedUser
                );
            } else {
                updateUserWithExistingHometownGeoFail(dispatch, res.message);
            }
        });
    };
};

const updateUserWithExistingHometownGeoSuccess = (
    dispatch,
    res,
    updatedUser
) => {
    dispatch({
        type: types.UPDATE_USER_EXISTING_HOMETOWN_GEO_SUCCESS,
        payload: res,
    });

    // in case anything has changed on the user object
    // note: home_geo_id hasn't changed because the home geo is reused
    dispatch(
        updateUser({
            user: updatedUser,
        })
    );
};

const updateUserWithExistingHometownGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateUserWithExistingHometownGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_USER_EXISTING_HOMETOWN_GEO_FAIL,
        payload: msg,
    });
};

export const updateActiveGeo = ({ newActiveGeo }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_ACTIVE_GEO,
            payload: newActiveGeo,
        });
    };
};

export const updateIndividualAddress = ({ individualAddress }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_INDIVIDUAL_ADDRESS,
            payload: individualAddress,
        });
    };
};

export const updateIndividualWithExistingGeo = ({
    updatedUser,
    geo_id,
    address,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_INDIVIDUAL_EXISTING_GEO,
        });

        WS.socket.emit('geo:update', {
            geo: {
                id: geo_id,
                address,
            },
        });
        WS.socket.once('geo:update', res => {
            if (res.success) {
                updateIndividualWithExistingGeoSuccess(
                    dispatch,
                    res.data,
                    updatedUser
                );
            } else {
                updateIndividualWithExistingGeoFail(dispatch, res.message);
            }
        });
    };
};

const updateIndividualWithExistingGeoSuccess = (dispatch, res, updatedUser) => {
    dispatch({
        type: types.UPDATE_INDIVIDUAL_EXISTING_GEO_SUCCESS,
        payload: res,
    });

    // in case anything has changed on the user object
    // note: geo_id hasn't changed because the geo is reused
    dispatch(
        updateUser({
            user: updatedUser,
        })
    );
};

const updateIndividualWithExistingGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateIndividualWithExistingGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_INDIVIDUAL_EXISTING_GEO_FAIL,
        payload: msg,
    });
};

// ---------------------------- update user AFTER ADDING TOKEN IN WHY NOTIFICATIONS ----------------------------
export const updateUserWithToken = ({ user }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER,
        });
        WS.socket.emit('user:update', {
            user,
        });
        WS.socket.once('user:update', res => {
            if (res.success) {
                updateUserWithTokenSuccess(dispatch, res.data);
            } else {
                updateUserFail(dispatch, res.message);
            }
        });
    };
};
const updateUserWithTokenSuccess = (dispatch, user) => {
    // AmplitudeAnalytics.track('User Update With Token Success', user);
    dispatch({
        type: types.UPDATE_USER_SUCCESS,
        payload: user,
    });
    dispatch({
        type: types.LOGIN_USER_SUCCESS,
        payload: user,
    });
    dispatch({
        type: types.SAVE_CURRENT_USER,
    });
    dispatch({
        type: types.SAVE_CURRENT_USER_SUCCESS,
        payload: user,
    });
    // dispatch(NavigationActions.navigate({key: 'NavBar', routeName: 'NavBar'}));
};

export const uploadUserProfilePic = (user, imageUri, photoName, file) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER_PROFILE_PIC,
            payload: {
                imageUri,
                photoName,
                user_name: user.full_name,
                file,
            },
        });
        uploadProfilePic(dispatch, user, imageUri, photoName, file);
    };
};

const uploadProfilePic = (dispatch, user, imageUri, photoName, file) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;

    let filedata = new File([file], photoName, {
        type: file.type,
    });

    const formData = new FormData();
    formData.append('image', filedata);

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'file-path': 'Users/' + user.id + '/profilePic/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            // AmplitudeAnalytics.track('User Update Profile Success', {
            //     'image:url': results.imageUrl,
            // });
            dispatch({
                type: types.UPDATE_USER_PROFILE_PIC_SUCCESS,
                payload: results.imageUrl,
            });
        })
        .catch(error => {
            dispatch({
                type: types.UPDATE_USER_PROFILE_PIC_FAIL,
                payload: error,
            });
        });
};

export const saveFacebookProfile = ({ facebook_id, profile_uri }) => {};

export const updateTouchIDToggle = ({ faceID_touchID_enabled }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_TOUCH_ID_ENABLED,
            payload: faceID_touchID_enabled,
        });
    };
};

export const updateTouchIDAttemptError = () => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_TOUCH_ID_ATTEMPT_ERROR,
            payload: null,
        });
    };
};

export const checkPasswordForTouchID = ({ email, password }) => {
    return dispatch => {
        dispatch({
            type: types.CHECK_PASSWORD_FOR_TOUCH_ID,
        });
        WS.socket.emit('user:verifyPassword', {
            email,
            password,
        });
        WS.socket.once('user:verifyPassword', res => {
            if (res.success) {
                dispatch({
                    type: types.CHECK_PASSWORD_FOR_TOUCH_ID_SUCCESS,
                    payload: true,
                });
            } else {
                dispatch({
                    type: types.CHECK_PASSWORD_FOR_TOUCH_ID_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const resetTouchIDSettings = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_TOUCH_ID_SETTINGS,
        });
    };
};

export const resetUserSession = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_USER_SESSION,
        });
    };
};

export const updateUserBooleans = ({ user }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_USER_BOOLEANS,
        });

        WS.socket.emit('user:update', {
            user,
        });
        WS.socket.once('user:update', res => {
            if (res.success) {
                updateUserBooleansSuccess(dispatch, res.data);
            } else {
                updateUserBooleansFail(dispatch, res.message);
            }
        });
    };
};
const updateUserBooleansSuccess = (dispatch, user) => {
    // AmplitudeAnalytics.track('Update User Booleans Success', user);
    dispatch({
        type: types.UPDATE_USER_BOOLEANS_SUCCESS,
        payload: user,
    });

    // const resetAction = StackActions.reset({
    //     index: 0,
    //     key: null,
    //     actions: [
    //         NavigationActions.navigate({
    //             key: 'NavBar',
    //             routeName: 'NavBar',
    //         }),
    //     ],
    // });
    // dispatch(resetAction);
};
const updateUserBooleansFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateUserFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_USER_BOOLEANS_FAIL,
        payload: msg,
    });
};

export const saveStripeCardToken = (user_id, source_token) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_STRIPE_CARD_TOKEN,
            payload: { user_id, source_token },
        });

        WS.socket.emit('user:saveStripeCardToken', { user_id, source_token });
        WS.socket.once('user:saveStripeCardToken', res => {
            if (res.success) {
                // return the user with stripe_customer updated
                saveStripeCardTokenSuccess(dispatch, res.data);
            } else {
                saveStripeCardTokenFail(dispatch, res.msg);
            }
        });
    };
};

const saveStripeCardTokenSuccess = (dispatch, user) => {
    // AmplitudeAnalytics.track('Save Stripe Token Success', user);
    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: user.id,
    //     cid: user.id,
    // });
    // analytics
    //     .event(new Event('User', 'Save Stripe Token Success', user.id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.SAVE_STRIPE_CARD_TOKEN_SUCCESS,
        payload: user,
    });
};

const saveStripeCardTokenFail = (dispatch, msg) => {
    // Sentry.captureMessage('saveStripeCardTokenFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SAVE_STRIPE_CARD_TOKEN_FAIL,
        payload: msg,
    });
};

export const pingApi = user_id => {
    return dispatch => {
        dispatch({
            type: types.PING_API,
            payload: { user_id },
        });

        WS.socket.emit('user:ping', { user_id }, res => {
            if (res.success) {
                console.log('user ping');
            } else {
                console.log('error user ping');
            }
        });
    };
};

export const addUserToNotifyOncePayable = ({ pangean_id, employer_id }) => {
    return dispatch => {
        dispatch({
            type: types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE,
            payload: { pangean_id, employer_id },
        });

        WS.socket.emit('user:addUserToNotifyOncePayable', {
            pangean_id,
            employer_id,
        });
        WS.socket.once('user:addUserToNotifyOncePayable', res => {
            if (res.success) {
                addUserToNotifyOncePayableSuccess(dispatch, res.data);
            } else {
                addUserToNotifyOncePayableFail(dispatch, res.message);
            }
        });
    };
};
const addUserToNotifyOncePayableSuccess = (dispatch, msg) => {
    dispatch({
        type: types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE_SUCCESS,
        payload: msg,
    });
};
const addUserToNotifyOncePayableFail = (dispatch, msg) => {
    // Sentry.captureMessage('Add User To Notify Once Payable Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.ADD_USER_TO_NOTIFY_ONCE_PAYABLE_FAIL,
        payload: msg,
    });
};

export const resetNotifyOncePayableMessage = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_NOTIFY_ONCE_PAYABLE_MESSAGE,
        });
    };
};
