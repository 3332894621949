// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import { Analytics, Event } from 'expo-analytics';
// import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    PAYMENT_CREATE: 'PAYMENT_CREATE',
    PAYMENT_CREATE_SUCCESS: 'PAYMENT_CREATE_SUCCESS',
    PAYMENT_CREATE_FAIL: 'PAYMENT_CREATE_FAIL',

    UPDATE_AMOUNT: 'UPDATE_AMOUNT',

    UPDATE_NOTE: 'UPDATE_NOTE',

    CLEAR_PAYMENT_DATA: 'CLEAR_PAYMENT_DATA',
    CLEAR_PAYMENT_ERROR: 'CLEAR_PAYMENT_ERROR',
};

const INITIAL_STATE = {
    paymentLoading: false,
    paymentError: '',
    completedPayment: null,
    amount: null,
    note: '',
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PAYMENT_CREATE:
            return { ...state, paymentLoading: true };
        case types.PAYMENT_CREATE_SUCCESS:
            return {
                ...state,
                paymentLoading: false,
                completedPayment: action.payload,
            };
        case types.PAYMENT_CREATE_FAIL:
            return {
                ...state,
                paymentLoading: false,
                paymentError: action.payload,
            };

        case types.UPDATE_AMOUNT:
            return { ...state, amount: action.payload };

        case types.UPDATE_NOTE:
            return { ...state, note: action.payload };

        case types.CLEAR_PAYMENT_DATA:
            return { ...INITIAL_STATE };

        case types.CLEAR_PAYMENT_ERROR:
            return { ...state, paymentError: '' };

        default:
            return state;
    }
};

export const paymentCreate = ({
    pangean_user_id,
    employer_user_id,
    room_id,
    payment_amount,
    card_id,
    note,
}) => {
    // console.log('paymentCreate');
    // console.log({
    //     pangean_user_id,
    //     employer_user_id,
    //     room_id,
    //     payment_amount,
    //     card_id,
    //     note,
    // });
    return dispatch => {
        dispatch({
            type: types.PAYMENT_CREATE,
            payload: {
                pangean_user_id,
                employer_user_id,
                room_id,
                payment_amount,
                card_id,
                note,
            },
        });

        WS.socket.emit('payment:create', {
            pangean_user_id,
            employer_user_id,
            room_id,
            payment_amount,
            card_id,
            note,
        });
        WS.socket.once('payment:create', res => {
            if (res.success) {
                paymentCreateSuccess(dispatch, res.data);
            } else {
                paymentCreateFail(dispatch, res.message);
            }
        });
    };
};
const paymentCreateSuccess = (dispatch, payment) => {
    // AmplitudeAnalytics.track('Payment Create Success', payment);
    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: payment.employer_user_id,
    //     cid: payment.employer_user_id,
    // });
    // analytics
    //     .event(
    //         new Event(
    //             'Payment',
    //             'Payment Create Success',
    //             payment.employer_user_id
    //         )
    //     )
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.PAYMENT_CREATE_SUCCESS,
        payload: payment,
    });
};
const paymentCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('paymentCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.PAYMENT_CREATE_FAIL,
        payload: msg.message,
    });
};

export const updateAmount = ({ amount }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_AMOUNT,
            payload: amount,
        });
    };
};

export const updateNote = note => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_NOTE,
            payload: note,
        });
    };
};

export const clearPaymentData = () => {
    return dispatch => {
        dispatch({
            type: types.CLEAR_PAYMENT_DATA,
        });
    };
};

export const clearPaymentError = () => {
    return dispatch => {
        dispatch({
            type: types.CLEAR_PAYMENT_ERROR,
        });
    };
};
