import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Footer, CustomContainer } from '../common';

import {
    Card,
    GridList,
    GridListTile,
    CardActionArea,
    Typography,
    Switch,
} from '@material-ui/core';

import DoneIcon from '@material-ui/icons/Done';

import { Header } from '../common';

import { selectSubscriptionPlan } from '../../ducks/SubscriptionsDuck';

import Colors from '../../constants/Colors';

const planMonthlyData = [
    {
        typeName: 'Limited',
        priceStr: 'Free',
        planFeatures: [
            { name: 'Post 1 Project', available: true },
            { name: 'Engage with students', available: false },
            { name: 'Employee Account', available: false },
            { name: 'Customer Account', available: false },
        ],
    },
    {
        typeName: 'Startup',
        // duration: '1 Year',
        priceStr: '$59.99/month',
        priceNum: 59.99,
        total: 59.99,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 5 students monthly', available: true },
            { name: '1 Employee Account', available: true },
            { name: 'Customer Support', available: true },
        ],
    },

    {
        typeName: 'Pro',
        // duration: '1 Year',
        priceStr: '$299.99/month',
        priceNum: 299.99,
        total: 299.99,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 25 students monthly', available: true },
            { name: '3 Employee Accounts', available: true },
            { name: 'Customer Support', available: true },
        ],
    },

    {
        typeName: 'Enterprise',
        priceStr: '$599.99/month',
        priceNum: 599.99,
        total: 599.99,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 50 students monthly', available: true },
            { name: 'Unlimited Seats', available: true },
            { name: 'Personal Account Manager', available: true },
        ],
    },
];

const planYearlyData = [
    {
        typeName: 'Limited',
        priceStr: 'Free',
        planFeatures: [
            { name: 'Post 1 Project', available: true },
            { name: 'Engage with students', available: false },
            { name: 'Employee Account', available: false },
            { name: 'Customer Account', available: false },
        ],
    },
    {
        typeName: 'Startup',
        // duration: '1 Year',
        priceStr: '$49.99/month',
        priceNum: 49.99,
        total: 49.99 * 12,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 5 students monthly', available: true },
            { name: '1 Employee Account', available: true },
            { name: 'Customer Support', available: true },
        ],
    },

    {
        typeName: 'Pro',
        // duration: '1 Year',
        priceStr: '$249.99/month',
        priceNum: 249.99,
        total: 249.99 * 12,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 25 students monthly', available: true },
            { name: '3 Employee Accounts', available: true },
            { name: 'Customer Support', available: true },
        ],
    },

    {
        typeName: 'Enterprise',
        priceStr: '$499.99/month',
        priceNum: 499.99,
        total: 499.99 * 12,
        planFeatures: [
            { name: 'Unlimited project postings', available: true },
            { name: 'Engage up to 50 students monthly', available: true },
            { name: 'Unlimited Seats', available: true },
            { name: 'Personal Account Manager', available: true },
        ],
    },
];

const styles = {
    rootContainer: {
        paddingLeft: 42,
        paddingRight: 42,
        paddingTop: 49,
        paddingBottom: 51,
        backgroundColor: '#f2f2f7',
    },

    purchaseText: {
        fontSize: 18,
        fontWeight: '600',
        marginBottom: 10,
        padding: 0,
    },

    purchasePrompt: {
        fontSize: 36,
        fontWeight: '400',
        marginBottom: 36,
        padding: 0,
    },

    subscriptionCard: {
        backgroundColor: '#fff',
        border: 'solid 1px #c7c7cc',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        margin: 5,
    },

    subscriptionCardActionArea: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'stretch',
        paddingTop: 22,
        paddingLeft: 30,
        paddingBottom: 22,
        paddingRight: 35,
    },

    subscriptionCardHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 19,
    },

    featuresList: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
    },

    featureListItemAvailable: {
        // padding: '0px 10px 20px 30px',
        marginBottom: 20,
        backgroundImage: 'url() no-repeat',
        display: 'flex',
    },

    featureListItemUnavailable: {
        // padding: '0px 10px 20px 30px',
        marginBottom: 20,
        backgroundImage: 'url() no-repeat',
        display: 'flex',
    },
};

class PickSubscriptionScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            yearlySelected: true,
        };
    }

    render() {
        let planData = [];
        if (this.state.yearlySelected) {
            planData = planYearlyData;
        } else {
            planData = planMonthlyData;
        }
        return (
            <div style={{ backgroundColor: Colors.lightGray }}>
                <Header hasSearch />

                <CustomContainer>
                    <Typography
                        variant="subtitle1"
                        gutterBottom
                        style={{ fontWeight: '600', marginTop: 50 }}
                    >
                        Purchase Subscription
                    </Typography>
                    <Typography variant="h2" style={{ marginBottom: 36 }}>
                        Choose your subscription.
                    </Typography>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: 20,
                        }}
                    >
                        <Typography
                            variant={this.state.yearlySelected ? 'h4' : 'h3'}
                        >
                            Monthly
                        </Typography>
                        <Switch
                            checked={this.state.yearlySelected}
                            onChange={() => {
                                this.setState({
                                    yearlySelected: !this.state.yearlySelected,
                                });
                            }}
                            color="primary"
                        />
                        <Typography
                            variant={this.state.yearlySelected ? 'h3' : 'h4'}
                        >
                            Yearly
                        </Typography>
                    </div>

                    <GridList
                        cols={2}
                        cellHeight={'auto'}
                        spacing={20}
                        style={{ marginBottom: 36 }}
                    >
                        {planData.map((plan, index) => (
                            <GridListTile key={index} cols={1}>
                                <Card style={styles.subscriptionCard}>
                                    <CardActionArea
                                        style={
                                            styles.subscriptionCardActionArea
                                        }
                                        onClick={() => {
                                            this.props.selectSubscriptionPlan({
                                                yearlySelected: this.state
                                                    .yearlySelected,
                                                planType: plan.typeName,
                                                monthlyCost: plan.priceNum,
                                                total: plan.total,
                                            });
                                        }}
                                    >
                                        <div
                                            style={
                                                styles.subscriptionCardHeader
                                            }
                                        >
                                            <Typography variant="h3">
                                                {plan.typeName}{' '}
                                                {plan.duration
                                                    ? `(${plan.duration})`
                                                    : ''}
                                            </Typography>

                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                }}
                                            >
                                                <Typography variant="h3">
                                                    {plan.priceStr}
                                                </Typography>

                                                <Typography
                                                    variant="h3"
                                                    style={{
                                                        color: 'orange',
                                                    }}
                                                >
                                                    {plan.priceStr !== 'Free'
                                                        ? '*'
                                                        : null}
                                                </Typography>
                                            </div>
                                        </div>

                                        <ul style={styles.featuresList}>
                                            {plan.planFeatures.map(feature => {
                                                return (
                                                    <li
                                                        style={
                                                            feature.available
                                                                ? styles.featureListItemAvailable
                                                                : styles.featureListItemUnavailable
                                                        }
                                                    >
                                                        <DoneIcon />
                                                        <div
                                                            style={{
                                                                width: 10,
                                                            }}
                                                        />
                                                        <Typography>
                                                            {feature.name}
                                                        </Typography>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </CardActionArea>
                                </Card>
                            </GridListTile>
                        ))}
                    </GridList>
                </CustomContainer>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {
    selectSubscriptionPlan,
})(PickSubscriptionScreen);
