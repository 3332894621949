import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import {
    requestToJoinCompany,
    resetLookupCompany,
} from '../../../ducks/OnboardingDuck';

import { updateUser } from '../../../ducks/UserSessionDuck';

import GlobalStyles from '../../../constants/GlobalStyles';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import { push } from 'connected-react-router';
import { store } from '../../..';

const globalStyles = GlobalStyles;

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/

const styles = {
    button: {
        marginBottom: 20,
    },
};

class BusinessCompanyExistsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderLeftColumn = () => {
        return (
            <div>
                <Typography variant="h4" gutterBottom>
                    A company with this name exists!
                </Typography>
                <Typography variant="h2">
                    Do you want to join {`${this.props.companyName}`}?
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <div>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            if (this.props.user) {
                                let updatedUser = {};
                                updatedUser.id = this.props.user.id;
                                updatedUser.geo_id = this.props.existingCompany.geo_id;

                                this.props.updateUser({
                                    user: updatedUser,
                                });
                            }

                            this.props.requestToJoinCompany({
                                companyName: this.props.companyName,
                                newUser: this.props.user,
                            });
                        }}
                        style={{ ...globalStyles.button, ...styles.button }}
                    >
                        Yes, request to join
                    </Button>
                </div>
                <div>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                            this.props.resetLookupCompany();
                            store.dispatch(push('/businessAddCompanyInfo'));
                        }}
                        style={{ ...globalStyles.button }}
                    >
                        No, I will make a new page
                    </Button>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, userSession }) => {
    const { loading, error, companyName, existingCompany } = onboarding;

    const { user } = userSession;

    return {
        user,
        loading,
        error,
        companyName,
        existingCompany,
    };
};

export default connect(mapStateToProps, {
    requestToJoinCompany,
    updateUser,
    resetLookupCompany,
})(BusinessCompanyExistsScreen);
