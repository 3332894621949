import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_PROPOSED_SKILL: 'create_proposed_skill',
    CREATE_PROPOSED_SKILL_SUCCESS: 'create_proposed_skill_success',
    CREATE_PROPOSED_SKILL_FAIL: 'create_proposed_skill_fail',

    UPDATE_PROPOSED_SKILL: 'update_proposed_skill',
    UPDATE_PROPOSED_SKILL_SUCCESS: 'update_proposed_skill_success',
    UPDATE_PROPOSED_SKILL_FAIL: 'update_proposed_skill_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    geo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_PROPOSED_SKILL:
            return { ...state, loading: true };
        case types.CREATE_PROPOSED_SKILL_SUCCESS:
            return { ...state, loading: false, geo: action.payload };
        case types.CREATE_PROPOSED_SKILL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.UPDATE_PROPOSED_SKILL:
            return { ...state, loading: true };
        case types.UPDATE_PROPOSED_SKILL_SUCCESS:
            return { ...state, loading: false, geo: action.payload };
        case types.UPDATE_PROPOSED_SKILL_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const createProposedSkill = ({ skillName, user, type, content_id }) => {
    return dispatch => {
        dispatch({ type: types.CREATE_PROPOSED_SKILL });

        WS.socket.emit('proposedSkill:create', {
            skillData: {
                name: skillName.trim(),
                proposer_id: user.id,
                related_to: {
                    type: type,
                    id: content_id,
                },
            },
        });
        WS.socket.once('proposedSkill:create', res => {
            if (res.success) {
                createProposedSkillSuccess(dispatch, res.data);
            } else {
                createProposedSkillFail(dispatch, res.message);
            }
        });
    };
};

const createProposedSkillSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Create Proposed Skill Success', res);

    dispatch({
        type: types.CREATE_PROPOSED_SKILL_SUCCESS,
        payload: res,
    });
};
const createProposedSkillFail = (dispatch, msg) => {
    dispatch({
        type: types.CREATE_PROPOSED_SKILL_FAIL,
        payload: msg,
    });
};

export const updateProposedSkill = ({ school }) => {
    return dispatch => {
        dispatch({ type: types.UPDATE_PROPOSED_SKILL });

        WS.socket.emit('proposedSkill:update', { school });
        WS.socket.once('proposedSkill:update', res => {
            if (res.success) {
                updateProposedSkillSuccess(dispatch, res.data.proposedSkill);
            } else {
                updateProposedSkillFail(dispatch, res.message);
            }
        });
    };
};

const updateProposedSkillSuccess = (dispatch, school) => {
    // AmplitudeAnalytics.track('Update Proposed Skill Success', school);

    dispatch({ type: types.UPDATE_PROPOSED_SKILL_SUCCESS, payload: school });
};

const updateProposedSkillFail = (dispatch, msg) => {
    dispatch({ type: types.UPDATE_PROPOSED_SKILL_FAIL, payload: msg });
};

export const createProposedSkills = (
    dispatch,
    proposed_skills,
    ownerId,
    postId,
    type
) => {
    if (proposed_skills) {
        for (let i = 0; i < proposed_skills.length; i++) {
            dispatch(
                createProposedSkill({
                    skillName: proposed_skills[i].name,
                    user: { id: ownerId },
                    type,
                    content_id: postId,
                })
            );
        }
    }
};
