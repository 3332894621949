// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SAVED_POSTS_FETCH: 'SAVED_POSTS_FETCH',
    SAVED_POSTS_RELOAD: 'SAVED_POSTS_RELOAD',
    SAVED_POSTS_FETCH_SUCCESS: 'SAVED_POSTS_FETCH_SUCCESS',
    SAVED_POSTS_FETCH_FAIL: 'SAVED_POSTS_FETCH_FAIL',

    SAVE_CLICKED_SAVED_POST: 'SAVE_CLICKED_SAVED_POST',
};

const INITIAL_STATE = {
    loading: false,
    savedPostsArray: [],
    clickedSavedPost: null,
    error: '',
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // fetch all rooms that a user is a part of
        case types.SAVED_POSTS_FETCH:
            return { ...state, loading: true };
        case types.SAVED_POSTS_RELOAD:
            return { ...state, loading: true, savedPostsArray: [] };
        case types.SAVED_POSTS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                savedPostsArray: action.payload,
            };
        case types.SAVED_POSTS_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.SAVE_CLICKED_SAVED_POST:
            return { ...state, clickedSavedPost: action.payload };

        default:
            return state;
    }
};

export const saveClickedSavedPost = clickedSavedPost => {
    return dispatch => {
        dispatch({
            type: types.SAVE_CLICKED_SAVED_POST,
            payload: clickedSavedPost,
        });
    };
};

export const fetchSavedPosts = ({ user_id }) => {
    return dispatch => {
        dispatch({
            type: types.SAVED_POSTS_FETCH,
        });

        WS.socket.emit('user:fetchSavedPosts', { user_id });
        WS.socket.once('user:fetchSavedPosts', res => {
            if (res.success) {
                fetchSavedPostsSuccess(dispatch, res.data);
            } else {
                fetchSavedPostsFail(dispatch, res.message);
            }
        });
    };
};

const fetchSavedPostsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch My SavedPosts Success', {});
    dispatch({
        type: types.SAVED_POSTS_FETCH_SUCCESS,
        payload: res,
    });
};

const fetchSavedPostsFail = (dispatch, msg) => {
    // Sentry.captureMessage('fetchSavedPostsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SAVED_POSTS_FETCH_FAIL,
        payload: msg,
    });
};
