import React from 'react';

import { FormControl, Select, MenuItem, Typography } from '@material-ui/core';

import { CustomInputLabel, CustomInputBase } from '../../common';

import { Field } from 'react-final-form';

const projectTypes = ['one_time', 'internship', 'full_time'];
const projectTypeLabels = ['One-Time', 'Internship', 'Full-Time'];
const required = value => (value ? undefined : 'Required');

const ProjectTypeForm = props => {
    return (
        <Field name="projectType" validate={required}>
            {({ input, meta }) => (
                <div>
                    <Typography variant="h5" gutterBottom>
                        Type of Project
                    </Typography>
                    <FormControl
                        style={{
                            minWidth: '50%',
                        }}
                        error={meta.error && meta.touched}
                    >
                        <CustomInputLabel variant="outlined">
                            Type of Project
                        </CustomInputLabel>
                        <Select
                            variant="outlined"
                            {...input}
                            onChange={e => {
                                input.onChange(e.target.value);
                            }}
                            labelWidth={'100%'}
                            input={
                                <CustomInputBase
                                    error={meta.error && meta.touched}
                                />
                            }
                        >
                            <MenuItem value="" disabled>
                                Type of Project
                            </MenuItem>
                            {projectTypes.map((projectType, index) => {
                                return (
                                    <MenuItem value={projectType}>
                                        {projectTypeLabels[index]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            )}
        </Field>
    );
};

export default ProjectTypeForm;
