// import Sentry from 'sentry-expo';
import WS from '../api/WS';

import { push } from 'connected-react-router';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import { Analytics, Event } from 'expo-analytics';
// import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SELECT_SUBSCRIPTION_PLAN: 'select_subscription_plan',
    SAVE_STRIPE_CARD_TOKEN: 'save_stripe_token',
    SAVE_STRIPE_CARD_TOKEN_SUCCESS: 'save_stripe_token_success',
    SAVE_STRIPE_CARD_TOKEN_FAIL: 'save_stripe_token_fail',
    CHARGE: 'charge',
    CHARGE_SUCCESS: 'charge_success',
    CHARGE_FAIL: 'charge_fail',
};

const INITIAL_STATE = {
    loading: false,
    error: '',
    paymentLoading: false,
    selectedSubscription: null,
    paidSubscription: null,
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SELECT_SUBSCRIPTION_PLAN:
            return {
                ...state,
                loading: false,
                selectedSubscription: action.payload,
            };

        case types.SAVE_STRIPE_CARD_TOKEN:
            return { ...state, loading: true, error: '' };
        case types.SAVE_STRIPE_CARD_TOKEN_SUCCESS:
            return { ...state, loading: false };
        case types.SAVE_STRIPE_CARD_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
                    ? action.payload
                    : 'unable to complete transaction',
            };

        case types.CHARGE:
            return { ...state, paymentLoading: true, error: '' };
        case types.CHARGE_SUCCESS:
            return {
                ...state,
                paymentLoading: false,
                paidSubscription: action.payload,
            };
        case types.CHARGE_FAIL:
            return {
                ...state,
                paymentLoading: false,
                error: action.payload
                    ? action.payload
                    : 'unable to complete transaction',
            };

        default:
            return state;
    }
};

export const selectSubscriptionPlan = ({
    yearlySelected,
    planType,
    monthlyCost,
    total,
}) => {
    return dispatch => {
        dispatch({
            type: types.SELECT_SUBSCRIPTION_PLAN,
            payload: { yearlySelected, planType, monthlyCost, total },
        });
        dispatch(push('/purchaseSubscription'));
    };
};

export const saveStripeCardToken = (user_id, source_token) => {
    return dispatch => {
        dispatch({ type: types.SAVE_STRIPE_CARD_TOKEN });

        WS.socket.emit('user:saveStripeCardToken', { user_id, source_token });
        WS.socket.once('user:saveStripeCardToken', res => {
            if (res.success) {
                // return the user with stripe_customer updated
                saveStripeCardTokenSuccess(dispatch, res);
            } else {
                saveStripeCardTokenFail(dispatch, res.msg);
            }
        });
    };
};

const saveStripeCardTokenSuccess = (dispatch, user) => {
    // AmplitudeAnalytics.track('Save Stripe Token Success', user);
    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: user.id,
    //     cid: user.id,
    // });
    // analytics
    //     .event(new Event('User', 'Save Stripe Token Success', user.id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.SAVE_STRIPE_CARD_TOKEN_SUCCESS,
        payload: user,
    });
};

const saveStripeCardTokenFail = (dispatch, msg) => {
    // Sentry.captureMessage('saveStripeCardTokenFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.SAVE_STRIPE_CARD_TOKEN_FAIL,
        payload: msg,
    });
};

export const charge = (transaction_id, tip) => {
    return dispatch => {
        dispatch({ type: types.CHARGE });

        WS.socket.emit('transaction:charge', { transaction_id, tip });
        WS.socket.once('transaction:charge', res => {
            if (res.success) {
                chargeSuccess(dispatch, res.data);
            } else {
                chargeFail(dispatch, res.msg);
            }
        });
    };
};

// must change transaction object with subscription plan data
const chargeSuccess = (dispatch, transaction) => {
    // AmplitudeAnalytics.track('Subscription Charge Success', transaction);
    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: transaction.owner_id,
    //     cid: transaction.owner_id,
    // });
    // analytics
    //     .event(
    //         new Event(
    //             'Subscription',
    //             'Subscription Charge Success',
    //             transaction.invoice_id
    //         )
    //     )
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.CHARGE_SUCCESS,
        payload: transaction,
    });
};

const chargeFail = (dispatch, msg) => {
    // AmplitudeAnalytics.track('Transaction Charge Fail', msg);

    // Sentry.captureMessage('chargeFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CHARGE_FAIL,
        payload: msg,
    });
};
