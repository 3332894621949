import { InputBase } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

const CustomInput = withStyles(theme => ({
    input: {
        borderRadius: 22,
        paddingLeft: 14,
        paddingRight: 14,
        paddingTop: 9.5,
        paddingBottom: 9.5,
        position: 'relative',
        borderColor: props =>
            props.error ? theme.palette.error.main : '#8e8e93',
        '&:hover': {
            borderRadius: 22,
            borderColor: props =>
                props.error
                    ? theme.palette.error.main
                    : theme.palette.text.primary,
            backgroundColor: 'white',
            borderWidth: 1,
        },
        '&:focus': {
            paddingTop: 8.5,
            paddingBottom: 8.5,
            paddingLeft: 13,
            paddingRight: 13,
            borderRadius: 22,
            borderColor: props =>
                props.error
                    ? theme.palette.error.main
                    : theme.palette.primary.main,
            backgroundColor: 'white',
            borderWidth: 2,
        },
    },
}))(InputBase);

export default CustomInput;
