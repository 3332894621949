import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
    FormHelperText,
    Typography,
    List,
    ListItem,
    Divider,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import PlacesAutocomplete from 'react-places-autocomplete';

import OnboardingContainerView from './OnboardingContainerView';
import {
    addProfilePhotoInfo,
    addBusinessCompanyInfo,
    addBusinessCompanyLookupInfo,
    addCompanyAboutUs,
    createCompanyWithLogo,
} from '../../../ducks/OnboardingDuck';

import { Form, Field } from 'react-final-form';

import FileUpload from '../../common/fileUpload/FileUpload';
import addPhoto from '../../../assets/images/common/addPhoto.svg';

import { push } from 'connected-react-router';
import { store } from '../../..';

import GlobalStyles from '../../../constants/GlobalStyles';

import {
    OnboardingHeader,
    FooterShort,
    CustomContainer,
    CustomInputBase,
    CustomInputLabel,
} from '../../common';

const globalStyles = GlobalStyles;
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/

const searchOptions = {
    types: ['geocode'],
};

const styles = {
    textArea: {
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20,
        minHeight: 85,
    },
    button: {
        marginTop: 20,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
};

class BusinessAddCompanyInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            numOfEmployeesPickerItems: [
                {
                    label: '1',
                    value: '1',
                },
                {
                    label: '2-10',
                    value: '2-10',
                },
                {
                    label: '11-50',
                    value: '11-50',
                },
                {
                    label: '51-100',
                    value: '51-100',
                },
                {
                    label: '101+',
                    value: '101+',
                },
            ],
            companyNameError: false,
            numOfEmployeesError: false,
            companyAboutError: false,
            addressError: false,
            address: '',
        };
    }

    renderLeftColumn = () => {
        return (
            <div>
                <Typography variant="h4" gutterBottom>
                    Alright {this.props.firstName}
                </Typography>
                <Typography variant="h2">Enter your company's info</Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        const { classes } = this.props;
        return (
            <Form
                onSubmit={values => {
                    console.log('onSubmit');

                    let owner_email_domain = this.props.user
                        ? this.props.user.email.substring(
                              this.props.user.email.indexOf('@') + 1
                          )
                        : this.props.user
                        ? this.props.user.email.substring(
                              this.props.user.email.indexOf('@') + 1
                          )
                        : '';

                    let timeStamp = `${Date.now()}`;

                    let company = {
                        owner_id: this.props.user.id,
                        domains: [owner_email_domain],
                        company_name: values.companyName,
                        num_employees: values.numOfEmployees,
                        member_ids: [this.props.user.id],
                        stripe_customer: this.props.user.stripe_customer,
                        lightrail_contact: this.props.user.lightrail_contact,
                        address: values.address,
                        logo_pic: timeStamp,
                        about_us: values.companyBio,
                    };

                    this.props.createCompanyWithLogo(
                        company,
                        this.state.selectedPicture
                    );

                    // store.dispatch(push('/businessCompleteSignUp'));
                }}
                validate={values => {
                    const errors = {};

                    if (!values.companyName) {
                        errors.companyName = 'Required';
                    }

                    if (!values.numOfEmployees) {
                        errors.numOfEmployees = 'Required';
                    }

                    if (
                        !this.state.selectedPicture ||
                        !this.state.selectedPictureUrl
                    ) {
                        errors.companyLogo = 'Required';
                    }

                    if (!this.state.address) {
                        errors.address = 'Required';
                    }

                    if (!values.companyBio) {
                        errors.companyBio = 'Required';
                    }

                    return errors;
                }}
                initialValues={{
                    companyName: this.props.companyName
                        ? this.props.companyName
                        : '',
                }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        error={this.props.error}
                        loading={this.props.loading}
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 170px)',
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            Company Name
                        </Typography>
                        <Field name="companyName">
                            {({ input, meta }) => (
                                <TextField
                                    variant="outlined"
                                    label="Company Name"
                                    name="company-name"
                                    id="company-name"
                                    fullWidth
                                    error={meta.error && meta.touched}
                                    {...input}
                                />
                            )}
                        </Field>

                        <div style={{ height: 20 }} />
                        <Typography variant="h5" gutterBottom>
                            Number of Employees
                        </Typography>
                        <Field name="numOfEmployees">
                            {({ input, meta }) => (
                                <FormControl
                                    style={{ minWidth: '50%' }}
                                    error={meta.error && meta.touched}
                                >
                                    <CustomInputLabel variant="outlined">
                                        Number of Employees
                                    </CustomInputLabel>
                                    <Select
                                        id="expected-yog"
                                        variant="outlined"
                                        {...input}
                                        onChange={e => {
                                            input.onChange(e.target.value);
                                        }}
                                        input={
                                            <CustomInputBase
                                                error={
                                                    meta.error && meta.touched
                                                }
                                            />
                                        }
                                    >
                                        <MenuItem value="" disabled>
                                            Number of Employees
                                        </MenuItem>
                                        {this.state.numOfEmployeesPickerItems.map(
                                            (numOfEmployeesItem, index) => {
                                                return (
                                                    <MenuItem
                                                        value={
                                                            numOfEmployeesItem.value
                                                        }
                                                        key={index}
                                                    >
                                                        {
                                                            numOfEmployeesItem.label
                                                        }
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                    {this.state.numOfEmployeesError && (
                                        <FormHelperText>
                                            Please select the number of
                                            employees in the company
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        </Field>
                        <div style={{ height: 20 }} />
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Company Logo
                            </Typography>
                            <Field name="companyLogo">
                                {({ input, meta }) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            // flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {this.state.selectedPictureUrl ? (
                                            <img
                                                src={
                                                    this.state
                                                        .selectedPictureUrl
                                                }
                                                alt={addPhoto}
                                                height="140"
                                                width="140"
                                                style={{
                                                    borderRadius: 70,
                                                    marginRight: 20,
                                                }}
                                            />
                                        ) : (
                                            <img
                                                src={addPhoto}
                                                alt=""
                                                height="140"
                                                width="140"
                                                style={{
                                                    marginRight: 20,
                                                    // borderRadius: 70,
                                                }}
                                            />
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'flex-end',
                                            }}
                                        >
                                            {meta.error && meta.touched && (
                                                <Typography
                                                    variant="subtitle2"
                                                    color="error"
                                                    gutterBottom
                                                >
                                                    Company Logo Required
                                                </Typography>
                                            )}
                                            <FileUpload
                                                mode="basic"
                                                chooseLabel="Upload Company Logo"
                                                uploadLabel="upload"
                                                style={globalStyles.fileUpload}
                                                accept="image/*"
                                                multiple={false}
                                                url=""
                                                maxFileSize={2000000}
                                                onSelect={
                                                    this.fileSelectedHandler
                                                }
                                                onClear={this.cancelFileHandler}
                                            />
                                        </div>
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div style={{ height: 20 }} />

                        <Typography variant="h5" gutterBottom>
                            Address
                        </Typography>

                        <Field name="address">
                            {({ input, meta }) => (
                                <PlacesAutocomplete
                                    searchOptions={searchOptions}
                                    onChange={address => {
                                        input.onChange(address);
                                    }}
                                    onSelect={address => {
                                        input.onChange(address);
                                        this.setState({ address });
                                    }}
                                    {...input}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <div style={{ marginTop: 20 }}>
                                            <TextField
                                                id="address-input"
                                                label="Address"
                                                variant="outlined"
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                {...input}
                                                inputProps={{
                                                    ...getInputProps({
                                                        className:
                                                            'location-search-input',
                                                    }),
                                                }}
                                                style={{ minWidth: '75%' }}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && (
                                                    <Typography>
                                                        Loading...
                                                    </Typography>
                                                )}

                                                <List>
                                                    {suggestions.map(
                                                        suggestion => {
                                                            return (
                                                                <ListItem
                                                                    {...getSuggestionItemProps(
                                                                        suggestion
                                                                    )}
                                                                    className={
                                                                        classes.listItem
                                                                    }
                                                                >
                                                                    <Typography>
                                                                        {
                                                                            suggestion.description
                                                                        }
                                                                    </Typography>
                                                                    <Divider />
                                                                </ListItem>
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            )}
                        </Field>

                        <Typography variant="h5" gutterBottom>
                            Company Bio
                        </Typography>
                        <Field name="companyBio">
                            {({ input, meta }) => (
                                <TextField
                                    variant="outlined"
                                    label="Company Bio"
                                    placeholder={
                                        'For example “Stark Industries, co-founded by Howard Stark, is a leading technological innovation company.”'
                                    }
                                    rows="6"
                                    fullWidth
                                    maxLength={5000}
                                    multiline
                                    error={meta.error && meta.touched}
                                    {...input}
                                />
                            )}
                        </Field>
                        <div style={{ height: 20 }} />
                        {this.props.error && (
                            <Typography color="error" gutterBottom>
                                {this.props.error}
                            </Typography>
                        )}
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            // style={{ ...globalStyles.button}}
                        >
                            Continue
                            {this.props.loading && (
                                <CircularProgress
                                    size={24}
                                    style={globalStyles.buttonProgress}
                                />
                            )}
                        </Button>
                        <div style={{ height: 20 }} />
                    </form>
                )}
            </Form>
        );
    };

    fileSelectedHandler = ({ event, files }) => {
        this.setState({
            selectedPictureUrl: files[0].objectURL,
        });
        this.setState({
            selectedPicture: files[0],
        });
    };

    cancelFileHandler = () => {
        this.setState({
            selectedPictureUrl: '',
            selectedPicture: null,
        });
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, userSession }) => {
    const {
        profilePhotoInfo,
        loading,
        error,
        businessCompanyInfo,
        personalInfo,
        businessCompanyLookupInfo,
        companyAboutUs,
    } = onboarding;

    const { user } = userSession;

    return {
        user,
        image: profilePhotoInfo ? profilePhotoInfo.image : null,
        profile_pic_name: profilePhotoInfo
            ? profilePhotoInfo.profile_pic_name
            : null,
        loading,
        error,
        firstName: personalInfo ? personalInfo.firstName : '',
        numOfEmployees: businessCompanyInfo
            ? businessCompanyInfo.numOfEmployees
            : '',
        address: businessCompanyInfo ? businessCompanyInfo.address : '',
        companyName: businessCompanyLookupInfo
            ? businessCompanyLookupInfo.companyName
            : '',
        companyAboutUs:
            companyAboutUs || companyAboutUs === '' // second check ensures that user can delete entire existing bio
                ? companyAboutUs
                : user.company && user.company.about_us
                ? user.company.about_us
                : '',
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        addProfilePhotoInfo,
        addBusinessCompanyInfo,
        addBusinessCompanyLookupInfo,
        addCompanyAboutUs,
        createCompanyWithLogo,
    })(BusinessAddCompanyInfoScreen)
);
