// // PROD
// export default {
//     GCLOUDBUCKET: 'radiant-basin-61985',
//     SOCKETAPI: 'socket.pangeamart.com',
//     RESTAPI: 'api.pangeamart.com',
//     STRIPEKEY: 'pk_live_seWzpzxJTYWBxlaEgUMyWjxw',
//     STRIPECLIENTID: 'ca_9ufbzU2h3ngZD6tR8wShLu356xnlklj1',
//     ANALYTICS: 'UA-125102595-1',
//     AMPLITUDEANALYTICS: '9032c2ae474bac73da3d7c73110ad5f7',
//     ENVIRONMENT: 'Production',
//     VERSION: 'v3.2.0',
//     LOGGER: true,
// };

// DEV
export default {
    GCLOUDBUCKET: 'radiant-basin-61985',
    SOCKETAPI: 'socket.dev.pangeamart.com',
    RESTAPI: 'api.dev.pangeamart.com',
    STRIPEKEY: 'pk_test_w3MzZBbLHNxXnQCDjlBzVyKn',
    STRIPECLIENTID: 'ca_9ufbdnT5mhHrNocY0Sv26VMhXDYeFuza',
    ANALYTICS: 'UA-122141694-2',
    AMPLITUDEANALYTICS: 'db1be44c165afcbac3518f7c7a114c22',
    ENVIRONMENT: 'Dev',
    VERSION: 'v3.2.0',
    LOGGER: true,
};

// // LOCAL
// export default {
//     // GCLOUDBUCKET: 'radiant-basin-61985-dev',
//     GCLOUDBUCKET: 'radiant-basin-61985',
//     SOCKETAPI: '4eab1a1f.ngrok.io',
//     RESTAPI: 'api.pangeamart.com',
//     STRIPEKEY: 'pk_test_w3MzZBbLHNxXnQCDjlBzVyKn',
//     STRIPECLIENTID: 'ca_9ufbdnT5mhHrNocY0Sv26VMhXDYeFuza',
//     ANALYTICS: 'UA-122141694-2',
//     AMPLITUDEANALYTICS: 'db1be44c165afcbac3518f7c7a114c22',
//     ENVIRONMENT: 'Local',
//     VERSION: 'v3.2.0',
//     LOGGER: true,
// };

export const FeatureFlags = {
    services: false,
    optionalProfilePic: false,
    subscriptionLimits: false,
};
