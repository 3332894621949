import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import createRootReducer from './rootReducer';
import Environment from './constants/Environment';

import thunk from 'redux-thunk';
import logger from 'redux-logger';

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
    whitelist: ['userSession'],
    blacklist: [
        'admin',
        'company',
        'createPost',
        'deeplink',
        'geo',
        'mainFeed',
        'moderator',
        'myCandidatePools',
        'myInvoices',
        'myPosts',
        'savedPosts',
        'myReviews',
        'myRooms',
        'navigation',
        'onboarding',
        'proposedSchool',
        'proposedSkill',
        'reportBlockFriend',
        'skills',
        'transactionMessenger',
        // 'userSession',
        'searchTags',
        'review',
        'payments',
        'campusCompetition',
    ],
};

export const history = createBrowserHistory();

const persistedReducer = persistReducer(
    persistConfig,
    createRootReducer(history)
);

export default function configureStore(preloadedState) {
    // const composeEnhancer =
    //     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
              })
            : compose;

    let applyMiddlewareObj;
    if (Environment.LOGGER) {
        applyMiddlewareObj = applyMiddleware(
            routerMiddleware(history),
            thunk,
            logger
        );
    } else {
        applyMiddlewareObj = applyMiddleware(routerMiddleware(history), thunk);
    }

    const store = createStore(
        persistedReducer,
        preloadedState,
        composeEnhancers(applyMiddlewareObj)
    );

    // Hot reloading
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./rootReducer', () => {
            store.replaceReducer(persistedReducer);
        });
    }

    let persistor = persistStore(store);

    return { store, persistor };
    // return store;
}

// import { createBrowserHistory } from 'history';
// import { applyMiddleware, compose, createStore } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
// import thunk from 'redux-thunk';
// import logger from 'redux-logger';

// import createRootReducer from './rootReducer';
// import Environment from './constants/Environment';

// export const history = createBrowserHistory();

// export default function configureStore(preloadedState) {
//     let applyMiddlewareObj;

//     if (Environment.LOGGER) {
//         applyMiddlewareObj = applyMiddleware(
//             routerMiddleware(history),
//             thunk,
//             logger
//         );
//     } else {
//         applyMiddlewareObj = applyMiddleware(routerMiddleware(history), thunk);
//     }

//     const store = createStore(
//         createRootReducer(history), // root reducer with router state
//         preloadedState,
//         compose(applyMiddleware(applyMiddlewareObj))
//     );
//     return store;
// }

// import { browserHistory } from 'react-router';
// import { routerMiddleware, push } from 'react-router-redux';

// import thunk from 'redux-thunk';
// import rootReducer from './rootReducer';
// import Environment from './constants/Environment';

// // Logger with default options
// import logger from 'redux-logger';

// const routerReduxMiddleware = routerMiddleware(browserHistory);

// let applyMiddlewareObj;

// if (Environment.LOGGER) {
//     applyMiddlewareObj = applyMiddleware(thunk, routerReduxMiddleware, logger);
// } else {
//     applyMiddlewareObj = applyMiddleware(thunk, routerReduxMiddleware);
// }

// const store = createStore(rootReducer, applyMiddlewareObj);

// export default store;
