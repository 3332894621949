import React from 'react';
import Colors from '../../constants/Colors';
import { Typography, Link } from '@material-ui/core';

const styles = {
    container: {
        height: 80,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: '#1d252d',
    },
    containerLight: {
        height: 80,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: Colors.lightGray,
    },
    divider: {
        borderTop: '1px solid rgba(155, 158, 184, 0.26)',
    },
    dividerLight: {
        borderBottom: '1px solid #C7C7CC',
    },
};

const FooterMini = props => (
    <div style={{ width: '100%', marginTop: 20 }}>
        <div style={props.light ? styles.dividerLight : styles.divider} />
        <div style={props.light ? styles.containerLight : styles.container}>
            <div
                style={{
                    marginTop: 20,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    marginBottom: 10,
                }}
            >
                <Typography variant="caption">
                    <Link href="/privacyPolicy"
                        target="_blank"
                        rel="noopener noreferrer" color='textPrimary'>Privacy Policy</Link>
                </Typography>
                <div style={{ width: 20 }} />
                <Typography variant="caption"><Link href="/termsOfService"
                        target="_blank"
                        rel="noopener noreferrer" color='textPrimary'>Terms of Service</Link></Typography>
            </div>

            <Typography variant="caption">
                Copyright © 2019 Pangea.app
            </Typography>
        </div>
    </div>
);

export default FooterMini;
