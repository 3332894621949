import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import GlobalStyles from '../../../constants/GlobalStyles';

import { push } from 'connected-react-router';

import { store } from '../../..';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

const globalStyles = GlobalStyles;

const styles = {
    marginRight: {
        marginRight: 20,
    },
};

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class BusinessCompleteSignUpScreen extends Component {
    renderLeftColumn = () => {
        return (
            <div>
                <Typography variant="h4" gutterBottom>
                    Onboarding Complete
                </Typography>
                <Typography variant="h2" gutterBottom>
                    Welcome to Pangea.app!
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    On Pangea.app, we'll help you find student talent that best
                    align with the projects you need to complete.
                </Typography>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            store.dispatch(push('/myProfile'));
                        }}
                        style={{
                            ...globalStyles.button,
                            ...styles.marginRight,
                        }}
                    >
                        View Profile
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            store.dispatch(push('/pangeans'));
                        }}
                        style={globalStyles.button}
                    >
                        Find Pangeans
                    </Button>
                </div>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    backgroundImage:
                        "url('images/pangea/pangea-right-panel.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right top',
                }}
            />
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(BusinessCompleteSignUpScreen);
