import React from 'react';

import { Typography } from '@material-ui/core';

import '../../common/CustomCarousel.css';
import { Carousel } from 'react-responsive-carousel';
import Environment from '../../../constants/Environment';

import moment from 'moment';

const styles = {
    rootContainer: {
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        paddingTop: 20,
        // paddingLeft: 15,
        // paddingRight: 15,
        paddingBottom: 10,
        marginTop: 20,
        // marginBottom: 20,
    },
};

const ProfileReviewsCard = props => {
    const reviews = props.reviews;
    return (
        <div style={styles.rootContainer}>
            <Typography variant="h4" gutterBottom style={{ paddingLeft: 20 }}>
                Reviews
            </Typography>
            <Carousel showThumbs={false} showStatus={false}>
                {reviews.map((review, index) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            paddingLeft: 20,
                        }}
                        key={review.id}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flex: 1,
                                flexDirection: 'column',
                            }}
                        >
                            <img
                                src={
                                    review.type === 'on_pangean'
                                        ? `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${review.reviewer.id}/logoPic/${review.reviewer.logo_pic}`
                                        : `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${review.reviewer_pangean.id}/profilePic/${review.reviewer_pangean.profile_pic}`
                                }
                                alt="reviewer"
                                style={{
                                    width: '100%',
                                    borderRadius: 1000,
                                }}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flex: 6,
                                flexDirection: 'column',
                                marginLeft: 20,
                            }}
                        >
                            <Typography variant="button" align="left">
                                {review.type === 'on_pangean'
                                    ? review.reviewer
                                        ? review.reviewer.company_name
                                        : ''
                                    : review.reviewer_pangean
                                    ? review.reviewer_pangean.full_name
                                    : ''}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                align="left"
                                gutterBottom
                                color="textSecondary"
                            >
                                {moment(review.last_modified).format(
                                    'MMM YYYY'
                                )}
                            </Typography>
                            <Typography variant="subtitle2" align="left">
                                {review.public_review_text
                                    ? review.public_review_text
                                    : ''}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', flex: 1 }}></div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ProfileReviewsCard;
