// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';

// import { NavigationActions } from 'react-navigation';
import { createProposedSkills } from './ProposedSkillDuck';
import { createGeo } from './GeoDuck';

import moment from 'moment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    PROJECT_CHOOSE_PROJECT_TYPE: 'project_choose_project_type',
    PROJECT_ADD_SKILLS: 'project_add_skills',
    PROJECT_ADD_DETAILS: 'project_add_details',
    PROJECT_ADD_PAYMENT_INFO: 'project_add_payment_info',
    PROJECT_ADD_COMPENSATION: 'project_add_compensation',
    PROJECT_ADD_FIXED_FREQUENCY: 'project_add_fixed_frequency',
    PROJECT_SKIP_TO_MAKE_NEGOTIABLE: 'toggle_skip_to_make_negotiable',
    PROJECT_ADD_DURATION: 'project_add_duration',
    PROJECT_SKIP_DURATION: 'project_skip_duration',
    PROJECT_CHOOSE_TIME_COMMITMENT: 'project_choose_time_commitment',
    PROJECT_SKIP_TIME_COMMITMENT: 'project_skip_time_commitment',
    PROJECT_CHOOSE_START_DATE: 'project_choose_start_date',
    PROJECT_ADD_GEO_ID: 'project_add_geo_id',
    PROJECT_ADD_ADDRESS: 'project_add_address',
    PROJECT_CHOOSE_ASAP_START_DATE: 'project_choose_asap_start_date',
    PROJECT_ADD_PHOTOS: 'project_add_photos',
    PROJECT_REMOVE_PHOTO: 'project_remove_photo',
    PROJECT_PREFER_LOCAL: 'project_prefer_local',
    PROJECT_DISCARD: 'project_discard',

    // LEGACY
    PROJECT_CHOOSE_EXPIRATION: 'project_choose_expiration',

    POST_CREATE: 'post_create',
    POST_CREATE_SUCCESS: 'post_create_success',
    POST_CREATE_FAIL: 'post_create_fail',

    RESET_POST_CREATE: 'reset_post_create',
    UPDATE_PROJECT_IN_CREATE: 'update_project_in_create',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    project: {
        // selectedSkills: null,
        skills: null,
        // proposedSkills: null,
        proposed_skills: null,
        title: null,
        description: null,
        // isPaid: null,
        is_paid: null,
        compensation: null,
        duration: null,
        isDurationSkipped: null,
        // timeCommitment: null,
        time_commitment: null,
        isTimeCommitmentSkipped: null,
        images: null,
        photo_names: null,
        // preferLocal: null,
        prefer_local: null,
        // projectType: null,
        project_type: null,
        // start_date
        start_date: null,
        // paymentFrequency: null,
        payment_frequency: null,
        skipToMakeNegotiable: false,
    },
    postCreateSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.PROJECT_ADD_SKILLS:
            return {
                ...state,
                project: {
                    ...state.project,
                    skills: action.payload.skills,
                    proposed_skills: action.payload.proposed_skills,
                },
            };
        case types.PROJECT_ADD_DETAILS:
            return {
                ...state,
                project: {
                    ...state.project,
                    title: action.payload.title,
                    description: action.payload.description,
                },
            };
        case types.PROJECT_ADD_PAYMENT_INFO:
            return {
                ...state,
                project: {
                    ...state.project,
                    ...action.payload,
                },
            };
        case types.PROJECT_ADD_COMPENSATION:
            return {
                ...state,
                project: {
                    ...state.project,
                    compensation: action.payload.compensation,
                },
            };
        case types.PROJECT_CHOOSE_EXPIRATION:
            return {
                ...state,
                project: {
                    ...state.project,
                    expiration: action.payload.expiration,
                    expirationDisplayValue:
                        action.payload.expirationDisplayValue,
                },
            };
        case types.PROJECT_ADD_PHOTOS:
            return {
                ...state,
                project: {
                    ...state.project,
                    images: action.payload.images,
                    photo_names: action.payload.photo_names,
                },
            };
        case types.PROJECT_REMOVE_PHOTO:
            return {
                ...state,
                project: {
                    ...state.project,
                    images: [
                        ...state.project.images.slice(0, action.payload),
                        ...state.project.images.slice(action.payload + 1),
                    ],
                },
            };
        case types.PROJECT_PREFER_LOCAL:
            return {
                ...state,
                project: {
                    ...state.project,
                    prefer_local: action.payload,
                },
            };
        case types.PROJECT_CHOOSE_PROJECT_TYPE:
            return {
                ...state,
                project: {
                    ...state.project,
                    ...action.payload,
                },
            };
        case types.PROJECT_ADD_FIXED_FREQUENCY:
            return {
                ...state,
                project: {
                    ...state.project,
                    payment_frequency: action.payload,
                },
            };
        case types.PROJECT_SKIP_TO_MAKE_NEGOTIABLE:
            return {
                ...state,
                project: {
                    ...state.project,
                    skipToMakeNegotiable: action.payload,
                },
            };
        case types.PROJECT_ADD_DURATION:
            return {
                ...state,
                project: {
                    ...state.project,
                    duration: action.payload,
                },
            };
        case types.PROJECT_SKIP_DURATION:
            return {
                ...state,
                project: {
                    ...state.project,
                    isDurationSkipped: action.payload,
                },
            };
        case types.PROJECT_CHOOSE_TIME_COMMITMENT:
            return {
                ...state,
                project: {
                    ...state.project,
                    time_commitment: action.payload,
                },
            };
        case types.PROJECT_SKIP_TIME_COMMITMENT:
            return {
                ...state,
                project: {
                    ...state.project,
                    isTimeCommitmentSkipped: action.payload,
                },
            };
        case types.PROJECT_CHOOSE_START_DATE:
            return {
                ...state,
                project: {
                    ...state.project,
                    start_date: action.payload,
                },
            };
        case types.PROJECT_ADD_GEO_ID:
            return {
                ...state,
                project: {
                    ...state.project,
                    geoId: action.payload,
                    address: null, // for checks that new address was input
                },
            };
        case types.PROJECT_ADD_ADDRESS:
            return {
                ...state,
                project: {
                    ...state.project,
                    address: action.payload,
                    geoId: null, // for checks that old geo is to be used
                },
            };
        case types.UPDATE_PROJECT_IN_CREATE:
            return {
                ...state,
                project: action.payload,
            };
        case types.PROJECT_DISCARD:
            return { ...INITIAL_STATE };
        case types.POST_CREATE:
            return { ...state, loading: true };
        case types.POST_CREATE_SUCCESS:
            return { ...state, loading: false, postCreateSuccess: true };
        case types.POST_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.RESET_POST_CREATE:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
// Create Project
export const projectAddSkills = ({ skills, proposed_skills }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_SKILLS,
            payload: { skills, proposed_skills },
        });
    };
};
export const projectAddDetails = ({ title, description }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_DETAILS,
            payload: { title, description },
        });
    };
};
export const projectAddPaymentInfo = paymentInfo => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_PAYMENT_INFO,
            payload: paymentInfo,
        });
    };
};
export const projectAddCompensation = ({ compensation }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_COMPENSATION,
            payload: { compensation },
        });
    };
};
export const projectAddExpiration = ({
    expiration,
    expirationDisplayValue,
}) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_CHOOSE_EXPIRATION,
            payload: { expiration, expirationDisplayValue },
        });
    };
};
export const projectAddPhotos = ({ images, photo_names }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_PHOTOS,
            payload: { images, photo_names },
        });
    };
};
export const projectRemovePhoto = index => {
    return dispatch => {
        dispatch({ type: types.PROJECT_REMOVE_PHOTO, payload: index });
    };
};
export const projectPreferLocal = ({ prefer_local }) => {
    return dispatch => {
        dispatch({ type: types.PROJECT_PREFER_LOCAL, payload: prefer_local });
    };
};
export const projectChooseProjectType = project => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_CHOOSE_PROJECT_TYPE,
            payload: project,
        });
    };
};
export const projectAddPaymentFrequency = ({ payment_frequency }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_FIXED_FREQUENCY,
            payload: payment_frequency,
        });
    };
};
export const projectSkipToMakeNegotiable = ({ skipToMakeNegotiable }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_SKIP_TO_MAKE_NEGOTIABLE,
            payload: skipToMakeNegotiable,
        });
    };
};
export const projectAddDuration = ({ duration }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_DURATION,
            payload: duration,
        });
    };
};
export const projectSkipDuration = ({ isDurationSkipped }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_SKIP_DURATION,
            payload: isDurationSkipped,
        });
    };
};
export const projectChooseTimeCommitment = ({ time_commitment }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_CHOOSE_TIME_COMMITMENT,
            payload: time_commitment,
        });
    };
};
export const projectSkipTimeCommitment = ({ isTimeCommitmentSkipped }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_SKIP_TIME_COMMITMENT,
            payload: isTimeCommitmentSkipped,
        });
    };
};
export const projectChooseStartDate = ({ start_date }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_CHOOSE_START_DATE,
            payload: start_date,
        });
    };
};
export const projectAddGeoId = ({ geoId }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_GEO_ID,
            payload: geoId,
        });
    };
};
export const projectAddAddress = ({ address }) => {
    return dispatch => {
        dispatch({
            type: types.PROJECT_ADD_ADDRESS,
            payload: address,
        });
    };
};

export const projectDiscard = () => {
    return dispatch => {
        dispatch({ type: types.PROJECT_DISCARD, payload: true });
        // dispatch(
        //     NavigationActions.navigate({
        //         key: 'FeedStack',
        //         routeName: 'FeedStack',
        //     })
        // );
    };
};

export const updateProjectInCreate = ({ post }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_IN_CREATE,
            payload: post,
        });
    };
};

// Post Create
export const postCreate = ({
    ownerId,
    company_id,
    postType,
    geoId,
    project_type,
    skills,
    proposed_skills,
    title,
    description,
    compensation,
    payment_frequency,
    is_paid,
    isDurationSkipped,
    duration,
    isTimeCommitmentSkipped,
    time_commitment,
    start_date,
    images,
    photo_names,
    prefer_local,
    visibility,
    address,
}) => {
    return dispatch => {
        dispatch({
            type: types.POST_CREATE,
            payload: {
                owner_id: ownerId,
                company_id,
                post_type: postType,
                geo_id: geoId,
                project_type,
                skills,
                proposed_skills,
                title: title.trim(),
                description: description.trim(),
                compensation,
                payment_frequency,
                is_paid,
                duration: isDurationSkipped ? null : parseInt(duration),
                time_commitment: isTimeCommitmentSkipped
                    ? null
                    : time_commitment
                    ? parseInt(time_commitment)
                    : null,
                start_date,
                photos: photo_names,
                prefer_local,
                visibility,
            },
        });

        let skill_ids = skills.map(function(skill) {
            return skill['id'];
        });

        let postObject = {
            owner_id: ownerId,
            company_id,
            post_type: postType,
            geo_id: geoId,
            project_type,
            skills,
            skill_ids,
            title: title.trim(),
            description: description.trim(),
            compensation,
            payment_frequency,
            is_paid,
            duration: isDurationSkipped ? null : duration,
            time_commitment: isTimeCommitmentSkipped ? null : time_commitment,
            start_date: moment
                .utc(moment(Date.now()).format('MM-DD-YYYY'), 'MM-DD-YYYY')
                .add(1, 'days')
                .toISOString(),
            photos: photo_names,
            prefer_local,
            visibility,
        };

        if (proposed_skills) {
            postObject.proposed_skills = proposed_skills;
        }

        WS.socket.emit('post:create', postObject);
        WS.socket.once('post:create', res => {
            if (res.success) {
                postCreateSuccess(dispatch, res.data, images, photo_names);

                // create any proposed skills tied to newly created post if they exist
                if (proposed_skills) {
                    createProposedSkills(
                        dispatch,
                        proposed_skills,
                        ownerId,
                        res.data.id,
                        'post'
                    );
                }

                // create geo object for new address if one exists
                if (address) {
                    dispatch(
                        createGeo({
                            address,
                            relatedTo: { id: res.data.id, type: 'post' },
                        })
                    );
                }
            } else {
                postCreateFail(dispatch, res.message);
            }
        });
    };
};

const uploadImages = (res, images, photo_names) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;

    images.forEach((current, index) => {
        const uri = current;
        const uriParts = uri.split('.');
        const fileType = uriParts[uriParts.length - 1];
        const formData = new FormData();
        formData.append('image', {
            uri,
            type: `image/${fileType}`,
            name: photo_names[index],
            originalname: photo_names[index],
        });

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'file-path': 'Posts/' + res.id + '/',
            },
            body: formData,
        })
            .then(res => {
                res.json();
            })
            .catch(e => {
                console.log(e);
            });
    });
};

/*
    We call offeringsFetch/requestsFetch to update the mainfeed with the new post.
*/
const postCreateSuccess = (dispatch, res, images, photo_names) => {
    uploadImages(res, images, photo_names);

    // AmplitudeAnalytics.track('Post Create Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(new Event('Post', 'Post Create Success', res.owner_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.POST_CREATE_SUCCESS,
        payload: res,
    });

    // dispatch(NavigationActions.navigate({key: 'FeedStack', routeName: 'FeedStack'}));
    // if (filter.post_type === 'offering') {
    //     dispatch(
    //         offeringsFetch({
    //             filter,
    //             skipVal: 0,
    //             shouldReload: true,
    //             isPullUpReload: true,
    //         })
    //     );
    // } else {
    //     dispatch(
    //         requestsFetch({
    //             filter,
    //             skipVal: 0,
    //             shouldReload: true,
    //             isPullUpReload: true,
    //         })
    //     );
    // }
};

const postCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('postCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.POST_CREATE_FAIL,
        payload: msg,
    });
};

export const resetPostCreate = () => {
    return dispatch => {
        dispatch({ type: types.RESET_POST_CREATE });

        // dispatch(
        //     NavigationActions.navigate({
        //         key: 'FeedStack',
        //         routeName: 'FeedStack',
        //     })
        // );
    };
};
