import React from 'react';
import { InputLabel } from '@material-ui/core';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Colors from '../../constants/Colors';
// import { makeStyles } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: Colors.pangeaBlue,
        },
        secondary: {
            main: Colors.pangeaGreen,
        },
        text: {
            primary: Colors.fontBlack,
        },
    },
    typography: {
        htmlFontSize: 18,
        h1: {
            fontSize: 36,
            fontWeight: '700',
        },
        h2: {
            fontSize: 36,
            letterSpacing: 0.26,
            fontWeight: '400',
        },
        h3: {
            fontSize: 21,
            fontWeight: '700',
        },
        h4: {
            fontSize: 21,
            letterSpacing: 0.16,
            fontWeight: '400',
        },
        h5: {
            fontSize: 16,
            letterSpacing: 0.12,
            fontWeight: '700',
        },
        subtitle1: {
            fontSize: 16,
            letterSpacing: 0.15,
            fontWeight: '400',
        },
        body1: {
            fontSize: 18,
            letterSpacing: 0.5,
            fontWeight: '400',
        },
        body2: {
            fontSize: 16,
            letterSpacing: 0.44,
            fontWeight: '400',
        },
        subtitle2: {
            fontSize: 14,
            letterSpacing: 0.39,
            fontWeight: '400',
        },
        caption: {
            fontSize: 12,
            fontWeight: '400',
        },
        button: {
            fontSize: 16,
            letterSpacing: '0.01071em',
            fontWeight: '700',
            textTransform: 'none',
        },
        h6: {
            fontSize: 14,
            fontWeight: '400',
        },
        overline: {},
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 18,
    },
    overrides: {
        MuiInputLabel: {
            root: {
                marginTop: -8,
                // zIndex: 1,
                backgroundColor: 'white',
            },
            shrink: {
                marginTop: 0,
                // zIndex: 1,
            },
        },
    },
});

const CustomInputLabel = props => (
    <MuiThemeProvider theme={theme}>
        <InputLabel {...props} style={{ ...props.style }} />
    </MuiThemeProvider>
);

export default CustomInputLabel;
