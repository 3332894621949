import React from 'react';
import Colors from '../../constants/Colors';
import { Grid, Typography } from '@material-ui/core';

const styles = {
    container: {
        height: 80,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: '#1d252d',
    },
    containerLight: {
        height: 80,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: Colors.lightGray,
    },
    divider: {
        borderTop: '1px solid rgba(155, 158, 184, 0.26)',
    },
    dividerLight: {
        borderBottom: '1px solid #C7C7CC',
    },
};

const FooterShort = props => (
    <div style={{ position: 'fixed', bottom: 0, width: '100vw' }}>
        <div style={props.light ? styles.dividerLight : styles.divider} />
        <div style={props.light ? styles.containerLight : styles.container}>
            <Grid container spacing={2}>
                <Grid item md={5} style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <Typography variant="body2">
                            Copyright © 2019 Pangea.app. All rights reserved.
                        </Typography>
                    </div>
                </Grid>

                <Grid item md={2} style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            Terms of Service
                        </Typography>
                    </div>
                </Grid>

                <Grid item md={2} style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginTop: 10,
                        }}
                    >
                        <Typography variant="body2" gutterBottom>
                            Privacy Policy
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>
);

export default FooterShort;
