import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Button,
    Grid,
    CircularProgress,
    Typography,
} from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';

import {
    updateUser,
    uploadUserProfilePic,
} from '../../../ducks/UserSessionDuck';

import { Form, Field } from 'react-final-form';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import FileUpload from '../../common/fileUpload/FileUpload';
import addPhoto from '../../../assets/images/common/addPhoto.svg';

import GlobalStyles from '../../../constants/GlobalStyles';
import OnboardingStyles from '../../../constants/OnboardingStyles';

import { push } from 'connected-react-router';
import { store } from '../../..';

const globalStyles = GlobalStyles;

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/

class BusinessAddAccountInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pictures: [],
            selectedPictureUrl: null,
            selectedPicture: null,
            imageError: false,
            yourTitleError: false,
            bioError: false,
        };
    }

    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.subTitleText} gutterBottom>
                    Alright {this.props.user.first_name}
                </Typography>
                <Typography style={OnboardingStyles.titleText}>
                    Enter your info:
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <Form
                onSubmit={values => {
                    let updatedUser = {
                        id: this.props.user.id,
                        bio: values.bio,
                        your_title: values.yourTitle,
                        has_reonboarded: true,
                    };

                    if (
                        this.state.selectedPictureUrl &&
                        this.state.selectedPicture
                    ) {
                        let timeStamp = `${Date.now()}`;

                        updatedUser.profile_pic = timeStamp;

                        this.props.uploadUserProfilePic(
                            this.props.user,
                            this.state.selectedPictureUrl,
                            timeStamp,
                            this.state.selectedPicture
                        );
                    }

                    this.props.updateUser({ user: updatedUser });
                    store.dispatch(push('/businessCompleteSignUp'));
                }}
                validate={values => {
                    const errors = {};

                    // if we make profile pic required:
                    if (
                        !this.state.selectedPicture ||
                        !this.state.selectedPictureUrl
                    ) {
                        errors.profilePic = 'Required';
                    }

                    if (!values.yourTitle) {
                        errors.yourTitle = 'Required';
                    }

                    if (!values.bio) {
                        errors.bio = 'Required';
                    }

                    return errors;
                }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        error={!!this.props.error}
                        loading={this.props.loading}
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 170px)',
                        }}
                    >
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Profile Picture
                            </Typography>
                            <Field name="profilePic">
                                {({ input, meta }) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // paddingTop: 20,
                                            paddingBottom: 20,
                                            flex: 1,
                                        }}
                                    >
                                        <Grid item md={4}>
                                            {this.state.selectedPictureUrl ? (
                                                <img
                                                    src={
                                                        this.state
                                                            .selectedPictureUrl
                                                    }
                                                    alt={addPhoto}
                                                    height="140"
                                                    width="140"
                                                    style={{
                                                        borderRadius: 70,
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={addPhoto}
                                                    alt=""
                                                    height="140"
                                                    width="140"
                                                    style={
                                                        {
                                                            // borderRadius: 70,
                                                        }
                                                    }
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            md={8}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <Typography variant="body1">
                                                    A profile picture helps
                                                    increase the chances of
                                                    employers viewing your
                                                    profile.
                                                </Typography>
                                                {meta.error && meta.touched && (
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="error"
                                                        style={{
                                                            marginTop: 10,
                                                            marginBottom: 10,
                                                        }}
                                                    >
                                                        Profile Picture Required
                                                    </Typography>
                                                )}
                                            </div>

                                            <FileUpload
                                                mode="basic"
                                                chooseLabel="Upload Profile Picture"
                                                uploadLabel="upload"
                                                style={globalStyles.fileUpload}
                                                accept="image/*"
                                                multiple={false}
                                                url=""
                                                maxFileSize={2000000}
                                                onSelect={
                                                    this.fileSelectedHandler
                                                }
                                                onClear={this.cancelFileHandler}
                                            />
                                        </Grid>
                                    </div>
                                )}
                            </Field>
                        </div>
                        <div>
                            <Typography variant="h5" gutterBottom>
                                Position at Company
                            </Typography>
                            <Field name="yourTitle">
                                {({ input, meta }) => (
                                    <TextField
                                        variant="outlined"
                                        name="company-position"
                                        id="company-position"
                                        label="Your Title"
                                        placeholder="Recruiter, HR, Talent Acquisition…"
                                        fullWidth
                                        error={meta.error && meta.touched}
                                        {...input}
                                    />
                                )}
                            </Field>
                        </div>
                        <div style={{ height: 20 }} />
                        <Typography variant="h5" gutterBottom>
                            Tell potential hires more about yourself
                        </Typography>
                        <Field name="bio">
                            {({ input, meta }) => (
                                <TextField
                                    variant="outlined"
                                    label="Personal Bio"
                                    placeholder={
                                        'For example “I’m a design university recruiter from Stark Industries looking for the best student talent.”'
                                    }
                                    rows="6"
                                    fullWidth
                                    maxLength={5000}
                                    multiline
                                    error={meta.error && meta.touched}
                                    {...input}
                                />
                            )}
                        </Field>
                        {/* <StayConnected /> */}
                        <div style={{ height: 20 }} />
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={this.props.loading}
                        >
                            {this.props.loading && (
                                <CircularProgress
                                    size={24}
                                    style={globalStyles.buttonProgress}
                                />
                            )}
                            Continue
                        </Button>
                        <div style={{ height: 20 }} />
                    </form>
                )}
            </Form>
        );
    };

    fileSelectedHandler = ({ event, files }) => {
        this.setState({
            selectedPictureUrl: files[0].objectURL,
        });
        this.setState({
            selectedPicture: files[0],
        });
    };

    cancelFileHandler = () => {
        this.setState({
            selectedPictureUrl: '',
            selectedPicture: null,
        });
    };

    onDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, userSession }) => {
    const {
        bio,
        profilePhotoInfo,
        loading,
        error,
        businessCompanyLookupInfo,
    } = onboarding;

    const { user } = userSession;

    return {
        user,
        image: profilePhotoInfo ? profilePhotoInfo.image : null,
        profile_pic_name: profilePhotoInfo
            ? profilePhotoInfo.profile_pic_name
            : null,
        companyName: businessCompanyLookupInfo
            ? businessCompanyLookupInfo.companyName
            : user && user.company_name
            ? user.company_name
            : '',
        yourTitle: businessCompanyLookupInfo
            ? businessCompanyLookupInfo.yourTitle
            : user && user.your_title
            ? user.your_title
            : '',
        bio: bio ? bio : '',
        loading,
        error,
    };
};

export default connect(mapStateToProps, {
    updateUser,
    uploadUserProfilePic,
})(BusinessAddAccountInfoScreen);

// const StayConnected = ({ isEmailNotificationEnabled, toggleStayConnected }) => {
//     return (
//         <div>
//             <Typography>Stay Connected*</Typography>
//             <div
//                 style={{
//                     display: 'flex',
//                     flexDirection: 'row',
//                 }}
//             >
//                 <Checkbox
//                     checked={isEmailNotificationEnabled}
//                     onChange={toggleStayConnected}
//                     value="full-time"
//                     inputProps={{
//                         'aria-label': 'primary checkbox',
//                     }}
//                     color="primary"
//                 />
//                 <Typography>
//                     Never miss out on an opportunity by subscribing to our email
//                     list!
//                 </Typography>
//             </div>
//         </div>
//     );
// };
