import React, { Component } from 'react';

import { Grid } from '@material-ui/core';
import { CustomContainer } from '../../common';
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
export default class OnboardingContainerView extends Component {
    render() {
        return (
            // <CustomContainer>
            <Grid
                container
                spacing={2}
                style={{ minHeight: '100vh' }}
                // justifyContent="center"
                alignItems="center"
            >
                <Grid item md={5} sm={12}>
                    {this.props.renderLeftColumn()}
                </Grid>
                <Grid item md={1} sm={12} />
                <Grid item md={6} sm={12}>
                    {this.props.renderRightColumn()}
                </Grid>
            </Grid>
            // </CustomContainer>
        );
    }
}
