import { Container } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const CustomContainer = withStyles(theme => ({
    root: {
        paddingLeft: 150,
        paddingRight: 150,
    },
}))(Container);

export default CustomContainer;
