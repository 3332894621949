import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    Header,
    Footer,
    ProfileAboutCard,
    SkillCard,
    CustomContainer,
} from '../common';

import { Grid, Button, Typography } from '@material-ui/core';
import Colors from '../../constants/Colors';

import Environment from '../../constants/Environment';

import moment from 'moment';

import {
    saveClickedPangean,
    clearClickedPangean,
    clearClickedPost,
} from '../../ducks/MainFeedDuck';
import {
    getMessengerScreenData,
    initializeRoom,
} from '../../ducks/TransactionMessengerDuck';
import { roomUpdate } from '../../ducks/MyRoomsDuck';
import { saveMyClickedPost } from '../../ducks/MyPostsDuck';
import { saveClickedCompany } from '../../ducks/CompanyDuck';

import { push } from 'connected-react-router';
import { store } from '../..';

const profileCardStyles = {
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    profileContainer: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        // borderTopWidth: 0,
        // borderBottomWidth: 1,
        // borderLeftWidth: 1,
        // borderRightWidth: 1,
        // borderColor: '#c7c7cc',
        backgroundColor: '#ffffff',
        paddingTop: 10,
        paddingBottom: 20,
        paddingLeft: 15,
        paddingRight: 15,
        display: 'flex',
    },

    backgroundImage: {
        height: 155,
        border: 'solid 1px #c7c7cc',
        borderTopWidth: 0,
        borderBottomWidth: 0,
        borderLeftWidth: 1,
        borderRightWidth: 1,
    },

    bioContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    shortInfo: {
        display: 'flex',
    },

    companyLogo: {
        marginTop: -70,
        marginRight: 20,
        width: 125,
        height: 125,
        borderRadius: 5,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        border: 'solid 2px #ffffff',
        backgroundColor: 'white',
    },

    projectBio: {
        display: 'flex',
        flexDirection: 'column',
    },

    projectCompanyText: {
        marginRight: 20,
    },
};

const ProjectDescriptionCard = props => {
    let clickedPost = props.clickedPost;

    return (
        <div style={profileCardStyles.rootContainer}>
            <img
                style={profileCardStyles.backgroundImage}
                alt="User background"
                src="images/darkbg.jpg"
            />

            <div style={profileCardStyles.profileContainer}>
                <div style={profileCardStyles.bioContainer}>
                    <div style={profileCardStyles.shortInfo}>
                        <img
                            src={
                                clickedPost.owner.user_type === 'business'
                                    ? `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${clickedPost.company.id}/logoPic/${clickedPost.company.logo_pic}`
                                    : clickedPost.owner.user_type === 'student'
                                    ? 'images/Logo-Gray--2x-p-500.png'
                                    : 'images/Logo-Gray--2x-p-500.png'
                            }
                            style={profileCardStyles.companyLogo}
                            alt="Company"
                        />

                        <div style={profileCardStyles.projectBio}>
                            <Typography variant="h4">
                                {clickedPost.title}
                            </Typography>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 10,
                                    marginBottom: 20,
                                }}
                            >
                                <Typography
                                    style={profileCardStyles.projectCompanyText}
                                >
                                    {clickedPost.owner.user_type === 'business'
                                        ? clickedPost.company.company_name
                                        : clickedPost.owner.user_type ===
                                          'student'
                                        ? clickedPost.owner.school.name
                                        : ''}
                                </Typography>
                                <Typography
                                    variant="body2"
                                    style={{
                                        fontSize: 12,
                                        color: '#8E8E93',
                                    }}
                                >
                                    {clickedPost.owner.user_type === 'business'
                                        ? `${clickedPost.company.loc.address.components.city}, ${clickedPost.company.loc.address.components.state}`
                                        : clickedPost.owner.user_type ===
                                          'student'
                                        ? `${clickedPost.owner.school.loc.address.components.city}, ${clickedPost.owner.school.loc.address.components.state}`
                                        : ''}
                                </Typography>
                            </div>
                        </div>
                    </div>

                    <Typography variant="body2">
                        {clickedPost.description}
                    </Typography>
                </div>

                {/* <a href="/">Share</a> */}
            </div>
        </div>
    );
};

const projectOverviewStyles = {
    rootContainer: {
        borderRadius: 10,
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 20,
        marginTop: 20,
        marginBottom: 20,
    },

    projectDescRow: {
        display: 'flex',
        marginTop: 25,
    },

    projectDescCol: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 50,
    },

    skillsSectionText: {
        display: 'block',
        marginTop: 23,
        marginBottom: 15,
    },

    skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    skillChip: {
        // borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        // paddingTop: 4,
        // paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        marginBottom: 10,
    },
};

const ProjectOverviewCard = props => {
    let clickedPost = props.clickedPost;
    let projectTypeString =
        clickedPost.project_type === 'one_time'
            ? 'One-time Project'
            : clickedPost.project_type === 'internship'
            ? 'Internship'
            : 'Full-time Position';

    let compensationString =
        clickedPost.payment_frequency === 'project' ||
        clickedPost.payment_frequency === 'year'
            ? `$${clickedPost.compensation}`
            : clickedPost.payment_frequency === 'hour' ||
              clickedPost.payment_frequency === 'day' ||
              clickedPost.payment_frequency === 'week' ||
              clickedPost.payment_frequency === 'month'
            ? `$${clickedPost.compensation} / ${clickedPost.payment_frequency}`
            : clickedPost.payment_frequency === 'negotiable'
            ? `Negotiable`
            : `Unpaid`;

    let startDateString =
        clickedPost.start_date < moment(Date.now()).toISOString()
            ? `Immediately`
            : moment(clickedPost.start_date).format('MMM Do, YYYY');

    let locationString = clickedPost.prefer_local
        ? clickedPost.loc &&
          clickedPost.loc.address &&
          clickedPost.loc.address.components
            ? `${clickedPost.loc.address.components.city}, ${clickedPost.loc.address.components.state}`
            : 'Remote'
        : 'Remote';

    return (
        <div style={projectOverviewStyles.rootContainer}>
            <Typography variant="h4">Project Overview</Typography>

            <div style={projectOverviewStyles.projectDescRow}>
                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Project Type</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {projectTypeString}
                    </Typography>
                </div>

                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Compensation</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {compensationString}
                    </Typography>
                </div>

                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Location</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {locationString}
                    </Typography>
                </div>
            </div>

            <div style={projectOverviewStyles.projectDescRow}>
                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Duration</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {clickedPost.duration
                            ? `${clickedPost.duration} week(s)`
                            : 'N/A'}
                    </Typography>
                </div>

                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Time Commitment</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {clickedPost.time_commitment
                            ? `${clickedPost.time_commitment} hours/week`
                            : 'N/A'}
                    </Typography>
                </div>

                <div style={projectOverviewStyles.projectDescCol}>
                    <Typography>Start Date</Typography>
                    <Typography variant="body1" style={{ fontWeight: 700 }}>
                        {startDateString}
                    </Typography>
                </div>
            </div>

            <Typography style={projectOverviewStyles.skillsSectionText}>
                Required Skills
            </Typography>

            <div style={projectOverviewStyles.skillsContainer}>
                {clickedPost.skills && clickedPost.skills.length > 0
                    ? clickedPost.skills.map((skill, index) => (
                          <SkillCard
                              backgroundColor={Colors.white}
                              skillName={skill.name}
                              isDeletable={false}
                              isAddable={false}
                              fontColor={'blue'}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
};

const posterCardStyles = {
    rootContainer: {
        borderRadius: 10,
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        paddingTop: 10,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 20,
        marginTop: 20,
        marginBottom: 20,
    },

    posterBioContainer: {
        marginTop: 20,
        display: 'flex',
        alignItems: 'stretch',
    },

    posterProfilePic: {
        width: 105,
        height: 105,
        borderRadius: 105 / 2,
        marginRight: 20,
    },

    posterBio: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },

    messageBtn: {
        borderRadius: 40,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#006c9e',
        color: '#ffffff',
        paddingLeft: 30,
        paddingRight: 30,
    },
};

const PosterCard = props => {
    let clickedPost = props.clickedPost;

    return (
        <div style={posterCardStyles.rootContainer}>
            <Typography variant="h4">Posted By</Typography>

            <div style={posterCardStyles.posterBioContainer}>
                <img
                    src={`https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${clickedPost.owner.id}/profilePic/${clickedPost.owner.profile_pic}`}
                    alt="Posted by person"
                    style={posterCardStyles.posterProfilePic}
                />

                <div style={posterCardStyles.posterBio}>
                    <Typography variant="h4">
                        {clickedPost.owner.full_name}
                    </Typography>
                    <Typography variant="body1">
                        {clickedPost.owner.user_type === 'business'
                            ? `${clickedPost.owner.your_title} at ${clickedPost.company.company_name}`
                            : clickedPost.owner.user_type === 'student'
                            ? `${clickedPost.owner.intended_major} at ${clickedPost.owner.school.name}`
                            : ''}
                    </Typography>
                    {/* <Button
                        variant="contained"
                        style={posterCardStyles.messageBtn}
                    >
                        Message
                    </Button> */}
                </div>
            </div>
        </div>
    );
};

class DetailedPostScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        if (this.props.loading) {
            return <div />;
        } else {
            return (
                <div style={{ backgroundColor: Colors.lightGray }}>
                    <Header hasSearch={true} user={this.props.user} />
                    <CustomContainer>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <ProjectDescriptionCard
                                    clickedPost={this.props.clickedPost}
                                />
                                <PosterCard
                                    clickedPost={this.props.clickedPost}
                                />

                                <ProjectOverviewCard
                                    clickedPost={this.props.clickedPost}
                                />
                            </Grid>

                            <Grid item md={4}>
                                {this.props.clickedPost &&
                                this.props.clickedPost.owner.user_type ===
                                    'business' ? (
                                    <ProfileAboutCard
                                        type="company"
                                        company={this.props.clickedPost.company}
                                        user={this.props.clickedPost.owner}
                                        onClick={() => {
                                            if (
                                                this.props.user.id ===
                                                this.props.clickedPost.owner_id
                                            ) {
                                                this.props.saveClickedCompany({
                                                    company_id: this.props.user
                                                        .company.id,
                                                });
                                                store.dispatch(
                                                    push('/myCompany')
                                                );
                                            } else {
                                                this.props.saveClickedCompany({
                                                    company_id: this.props
                                                        .clickedPost.company_id,
                                                });
                                                store.dispatch(
                                                    push('/detailedCompany')
                                                );
                                            }
                                        }}
                                    />
                                ) : this.props.clickedPost &&
                                  this.props.clickedPost.owner.user_type ===
                                      'student' ? (
                                    <ProfileAboutCard
                                        type="student"
                                        school={
                                            this.props.clickedPost.owner.school
                                        }
                                        user={this.props.clickedPost.owner}
                                    />
                                ) : null}
                                {/* <ProjectSuggestions /> */}
                            </Grid>
                        </Grid>
                    </CustomContainer>

                    <Footer light />
                </div>
            );
        }
    }
}

const mapStateToProps = ({ userSession, mainFeed, transactionMessenger }) => {
    const { user } = userSession;
    const { clickedPost, loading } = mainFeed;
    const { clickedRoom } = transactionMessenger;

    return {
        user,
        clickedPost,
        loading,
        clickedRoom,
    };
};

export default connect(mapStateToProps, {
    saveClickedPangean,
    clearClickedPost,
    clearClickedPangean,
    getMessengerScreenData,
    initializeRoom,
    roomUpdate,
    saveMyClickedPost,
    saveClickedCompany,
})(DetailedPostScreen);

// const peopleSuggestionsStyle = {
//     rootContainer: {
//         width: '100%',
//         display: 'flex',
//         flexDirection: 'column',
//     },

//     sectionTitle: {
//         marginBottom: 20,
//     },

//     rule: {
//         border: 'solid 1px #979797',
//         marginTop: 20,
//         marginBottom: 15,
//         width: '100%',
//     },
// };

// const projectCardStyles = {
//     rootContainer: {
//         borderRadius: 5,
//         boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
//         border: 'solid 1px #c7c7cc',
//         backgroundColor: '#ffffff',
//         padding: 10,
//         marginTop: 20,
//         marginBottom: 20,
//     },

//     bioContainer: {
//         display: 'flex',
//         alignItems: 'center',
//     },

//     profileImage: {
//         borderRadius: 30,
//         width: 60,
//         height: 60,
//         border: 'solid 1px #c7c7cc',
//         marginRight: 20,
//     },

//     bioTextContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//     },

//     boldBioText: {
//         fontWeight: 'bold',
//         display: 'block',
//         fontSize: 16,
//         marginBottom: 10,
//     },

//     bioText: {
//         fontSize: 14,
//         display: 'block',
//         marginBottom: 10,
//     },

//     skillsContainer: {
//         marginTop: 10,
//         display: 'flex',
//         justifyContent: 'space-between',
//     },

//     skillChip: {
//         borderRadius: 15.5,
//         border: 'solid 1px #006c9e',
//         backgroundColor: '#ffffff',
//         color: '#006c9e',
//         paddingTop: 4,
//         paddingBottom: 4,
//         paddingLeft: 10,
//         paddingRight: 10,
//         flex: 1,
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         marginRight: 10,
//     },
// };

// const ProjectCard = ({
//     title,
//     company,
//     compensation,
//     profilePicSrc,
//     skills,
// }) => {
//     let SkillChips = [];

//     if (skills && skills.length > 0) {
//         SkillChips.push(
//             <div style={projectCardStyles.skillChip}>{skills[0]}</div>
//         );

//         if (skills.length > 1) {
//             SkillChips.push(
//                 <div style={projectCardStyles.skillChip}>{skills[1]}</div>
//             );
//         }

//         if (skills.length === 3) {
//             SkillChips.push(
//                 <div style={projectCardStyles.skillChip}>{skills[2]}</div>
//             );
//         } else if (skills.length > 3) {
//             SkillChips.push(
//                 <div style={projectCardStyles.skillChip}>{`+ ${skills.length -
//                     2}`}</div>
//             );
//         }
//     }

//     return (
//         <div style={projectCardStyles.rootContainer}>
//             <div style={projectCardStyles.bioContainer}>
//                 <img
//                     src={profilePicSrc}
//                     style={projectCardStyles.profileImage}
//                     alt={company}
//                 />

//                 <div style={projectCardStyles.bioTextContainer}>
//                     <span style={projectCardStyles.boldBioText}>{title}</span>
//                     <span style={projectCardStyles.bioText}>{company}</span>
//                     <span style={projectCardStyles.bioText}>
//                         {compensation}
//                     </span>
//                 </div>
//             </div>

//             <div style={projectCardStyles.skillsContainer}>{SkillChips}</div>
//         </div>
//     );
// };

// const ProjectSuggestions = () => {
//     return (
//         <div style={peopleSuggestionsStyle.rootContainer}>
//             <span style={peopleSuggestionsStyle.sectionTitle}>
//                 People also viewed
//             </span>

//             <hr style={peopleSuggestionsStyle.rule} />

//             <ProjectCard
//                 title="Project Manager"
//                 company="Google"
//                 compensation="$30/hour"
//                 skills={['#UI Design', '#UX Design', '#Software']}
//             />

//             <ProjectCard
//                 title="Project Manager"
//                 company="Google"
//                 compensation="$30/hour"
//                 skills={['#UI Design', '#UX Design', '#Software']}
//             />

//             <ProjectCard
//                 title="Project Manager"
//                 company="Google"
//                 compensation="$30/hour"
//                 skills={['#UI Design', '#UX Design', '#Software']}
//             />
//         </div>
//     );
// };
