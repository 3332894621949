import React from 'react';

import {
    FormControl,
    Select,
    MenuItem,
    Typography,
    TextField,
    List,
    ListItem,
    Divider,
} from '@material-ui/core';

import { CustomInputLabel, CustomInputBase } from '../../common';

import PlacesAutocomplete from 'react-places-autocomplete';

import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
});

const searchOptions = {
    types: ['geocode'],
};

const LocationPreferenceForm = props => {
    const classes = useStyles();
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Where is this project located?
            </Typography>
            <Typography variant="body2" gutterBottom>
                Enter a location to get connected with local students! Or, if
                this job can be done remotely, select remote.
            </Typography>

            <Field name="address">
                {({ input, meta }) => (
                    <FormControl
                        style={{ minWidth: '50%' }}
                        error={meta.error && meta.touched}
                    >
                        <CustomInputLabel variant="outlined">
                            Location
                        </CustomInputLabel>
                        <Select
                            {...input}
                            labelWidth={'100%'}
                            input={
                                <CustomInputBase
                                    error={meta.error && meta.touched}
                                />
                            }
                        >
                            <MenuItem value="" disabled>
                                Location
                            </MenuItem>
                            {props.addressOptions.map(addressOption => {
                                return (
                                    <MenuItem value={addressOption.value}>
                                        {addressOption.value}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            </Field>

            {props.values.address === 'Other Address' && (
                <Field name="otherAddress">
                    {({ input, meta }) => (
                        <PlacesAutocomplete
                            value={props.address}
                            onChange={address => {
                                // this.setState({ address });
                                input.onChange(address);
                            }}
                            onSelect={address => {
                                // this.setState({ address });
                                // props._updatePost({ address });
                                input.onChange(address);
                            }}
                            searchOptions={searchOptions}
                            {...input}
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div style={{ marginTop: 20 }}>
                                    <TextField
                                        id="project-title"
                                        label="Address"
                                        variant="outlined"
                                        error={meta.error && meta.touched}
                                        {...input}
                                        inputProps={{
                                            ...getInputProps({
                                                // placeholder: 'Address',
                                                className:
                                                    'location-search-input',
                                            }),
                                        }}
                                        style={{ minWidth: '75%' }}
                                    />
                                    <div className="autocomplete-dropdown-container">
                                        {loading && (
                                            <Typography>Loading...</Typography>
                                        )}

                                        <List>
                                            {suggestions.map(suggestion => {
                                                return (
                                                    <ListItem
                                                        {...getSuggestionItemProps(
                                                            suggestion
                                                        )}
                                                        className={
                                                            classes.listItem
                                                        }
                                                    >
                                                        <Typography>
                                                            {
                                                                suggestion.description
                                                            }
                                                        </Typography>
                                                        <Divider />
                                                    </ListItem>
                                                );
                                            })}
                                        </List>
                                        {/* {suggestions.map(suggestion => {
                                            const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                            // inline style for demonstration purpose
                                            const style = suggestion.active
                                                ? {
                                                      backgroundColor:
                                                          '#fafafa',
                                                      cursor: 'pointer',
                                                      marginBottom: 10,
                                                      paddingLeft: 20,
                                                  }
                                                : {
                                                      backgroundColor:
                                                          '#ffffff',
                                                      cursor: 'pointer',
                                                      marginBottom: 10,
                                                      paddingLeft: 20,
                                                  };
                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        {
                                                            className,
                                                            style,
                                                        }
                                                    )}
                                                >
                                                    <span>
                                                        {suggestion.description}
                                                    </span>
                                                </div>
                                            );
                                        })} */}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    )}
                </Field>
            )}
        </div>
    );
};

export default LocationPreferenceForm;
