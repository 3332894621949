import React, { Component } from 'react';

import { Footer } from '../common';
class TermsOfServiceScreen extends Component {
    render() {
        return (
            <div>
                <div>
                    <div class="w-layout-grid grid">
                        <a href="/" class="link-block-3 w-inline-block">
                            <img
                                src="images/Logo-Gray--2x.png"
                                srcset="images/Logo-Gray--2x-p-500.png 500w, images/Logo-Gray--2x.png 602w"
                                sizes="10vw"
                                alt=""
                                class="image-17"
                            />
                        </a>
                        <div
                            id="w-node-746ad0da5bf8-b6ccc7c8"
                            class="text-block-18"
                        >
                            Terms of Service
                        </div>
                        <div
                            id="w-node-24aa3745517b-b6ccc7c8"
                            class="w-richtext"
                        >
                            <p>
                                <strong>
                                    PANGEA.APP, INC. - TERMS OF SERVICE{' '}
                                </strong>
                                <em>LAST UPDATED: AUGUST 19, 2019 </em>
                            </p>
                            <p>
                                <strong>1. PANGEA.APP ACCOUNTS </strong>
                                <em>
                                    Section 1 discusses what you must agree to
                                    before using the Platform and the different
                                    types of accounts that can be created on the
                                    Platform, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>
                                    1.1 REGISTRATION AND ACCEPTANCE{' '}
                                </strong>
                                By registering for an account to use the
                                Platform (an “<strong>Account</strong>”), by
                                using the Platform after the Effective Date if
                                you had an Account on the Effective Date, or by
                                clicking to accept the Terms of Service when
                                prompted on the Platform, you agree to abide by
                                this Agreement and the other Terms of Service.{' '}
                            </p>
                            <p>
                                To access and use certain portions of the
                                Platform and the Platform Services, you must
                                register for an Account. Subject to the Platform
                                Terms of Service, certain portions of the
                                Platform are available to Platform Visitors,
                                including those portions before your Account
                                registration is accepted. Pangea.app reserves
                                the right to decline a registration to join
                                Pangea.app or to add an Account type as a
                                Company or Pangean, for any lawful reason,
                                including supply and demand, cost to maintain
                                data, or other business considerations.{' '}
                            </p>
                            <p>
                                If you create an Account as an employee or agent
                                on behalf of a company, you represent and
                                warrant that you are authorized to enter into
                                binding contracts, including this Terms of
                                Service, on behalf of yourself and the company.{' '}
                            </p>
                            <p>
                                <strong>1.2 ACCOUNT ELIGIBILITY </strong>
                                Pangea.app offers the Platform for your business
                                purposes only and not for personal, household,
                                or consumer use. To register for an Account or
                                use the Platform, you must, and hereby represent
                                that you (a) have or are an employee or agent of
                                and authorized to act for an independent
                                business (whether it be as a self-employed
                                individual/sole proprietor or as a corporation,
                                limited liability company, or other entity); (b)
                                will use the Platform for business purposes
                                only; (c) will comply with any licensing,
                                registration, or other requirements with respect
                                to your business, or the business for which you
                                are acting, and the provision of Pangean
                                Services; and (d) a legal entity or an
                                individual 18 years or older (or have otherwise
                                reached the age of majority in the jurisdiction
                                in which you conduct business) who can form
                                legally binding contracts.{' '}
                            </p>
                            <p>
                                <strong>1.3 ACCOUNT PROFILE </strong>To register
                                for an Account to join the Platform, you must
                                complete a User profile (“
                                <strong>Profile</strong>”), which you consent to
                                be shown to other Users and, unless you change
                                your privacy settings, the public. You agree to
                                provide true, accurate, and complete information
                                on your Profile and all registration and other
                                forms you access on the Platform or provide to
                                us and to update your information to maintain
                                its truthfulness,{' '}
                            </p>
                            <p>
                                accuracy, and completeness. You agree not to
                                provide any false or misleading information
                                about your identity or location, your business,
                                your skills, or the services your business
                                provides and to correct any such information
                                that is or becomes false or misleading.{' '}
                            </p>
                            <p>
                                <strong>1.4 ACCOUNT TYPES </strong>We reserve
                                the right to revoke the privileges of the
                                Account or access to or use of the Platform, and
                                those of any and all linked Accounts without
                                warning if, in our sole discretion, false or
                                misleading information has been provided in
                                creating, marketing, or maintaining your Profile
                                or Account. Account types are collectively
                                called (“<strong>Users</strong>”){' '}
                            </p>
                            <p>
                                <strong>1.4.1 COMPANY ACCOUNT </strong>You can
                                register for an Account or add an Account type
                                to use the Platform as a Company (a “
                                <strong>Company Account</strong>”). Each User
                                under a Company Account (“
                                <strong>Team Member</strong>”) can be given
                                different permissions to act on behalf of the
                                Company Account. Team Members can post projects
                                on Pangea and reach out to Pangeans.{' '}
                            </p>
                            <p>
                                <strong>1.4.2 PANGEAN ACCOUNT </strong>You can
                                register for an Account or add an Account type
                                to use the Platform as a Pangean (a “
                                <strong>Pangean Account</strong>”). In order to
                                register for a Pangean Account, you must be
                                currently enrolled in an nationally accredited
                                undergraduate or graduate degree program, or
                                have explicit approval from Pangea.app. Only
                                Pangean Accounts can complete projects.{' '}
                            </p>
                            <p>
                                <strong>1.5 ACCOUNT PERMISSIONS </strong>You
                                agree not to request or allow another person to
                                create an Account on your behalf, for your use,
                                or for your benefit, except that an authorized
                                employee or agent may create an Account on
                                behalf of your business. By granting other Users
                                permissions under your Account, including as a
                                Team, you represent and warrant that (a) the
                                User is authorized to act on your behalf, (b)
                                you are financially responsible for the User’s
                                actions taken in accordance with those
                                permissions, including, if applicable, entering
                                into binding contracts on behalf of the owner of
                                the Account, and (c) you are fully responsible
                                and liable for any action of any User to whom
                                you have provided any permissions and any other
                                person who uses the Account, including making
                                payments and entering into Engagements and the
                                Terms of Service. If any such User violates the
                                Terms of Service, it may affect your ability to
                                use the Platform. Upon closure of an Account,
                                Pangea.app may close any or all related
                                Accounts.{' '}
                            </p>
                            <p>
                                <strong>
                                    1.6 IDENTITY AND LOCATION VERIFICATION{' '}
                                </strong>
                                When you register for an Account and from time
                                to time thereafter, your Account will be subject
                                to verification, including, but not limited to,
                                validation against third-party databases or the
                                verification of one or more official government
                                or legal documents that confirm your identity,
                                your location, and your ability to act on behalf
                                of your business on Pangea.app. You authorize
                                Pangea.app, directly or through third parties,
                                to make any inquiries necessary to validate your
                                identity, your location, and confirm your
                                ownership of your email address or financial
                                accounts, subject to applicable law. When
                                requested, you{' '}
                            </p>
                            <p>
                                must timely provide us with complete information
                                about yourself and your business, which
                                includes, but is not limited to, providing
                                official government or legal documents.{' '}
                            </p>
                            <p>
                                <strong>1.7 USERNAMES AND PASSWORDS </strong>
                                When you register for an Account, you will be
                                asked to choose a username and password for the
                                Account. You are entirely responsible for
                                safeguarding and maintaining the confidentiality
                                of your username and password and agree not to
                                share your username or password with any person
                                who is not authorized to use your Account. You
                                authorize Pangea.app to assume that any person
                                using the Platform with your username and
                                password, either is you or is authorized to act
                                for you. You agree to notify us immediately if
                                you suspect or become aware of any unauthorized
                                use of your Account or any unauthorized access
                                to the password for any Account. You further
                                agree not to use the Account or log in with the
                                username and password of another User of the
                                Platform if (a) you are not authorized to use
                                either or (b) the use would violate the Terms of
                                Service.{' '}
                            </p>
                            <p>
                                <strong>2. PURPOSE OF PANGEA.APP </strong>
                                <em>
                                    Section 2 discusses what Pangea.app does and
                                    does not do when providing the Platform and
                                    some of your responsibilities when using the
                                    Platform to find or enter intoan engagement
                                    with a Pangean or Company, as detailed
                                    below.{' '}
                                </em>
                            </p>
                            <p>
                                The Platform is a marketplace where Companies
                                and Pangeans can identify each other and
                                advertise, buy, and sell Pangean Services
                                online. Subject to the Terms of Service,
                                Pangea.app provides the Platform Services to
                                Users, including hosting and maintaining the
                                Platform, facilitating payment and reviews, and
                                assisting Users in resolving disputes which may.
                                When a User enters an engagement the User uses
                                the Platform to invoice and pay any amounts
                                owed.{' '}
                            </p>
                            <p>
                                <strong>
                                    2.1 RELATIONSHIP WITH PANGEA.APP{' '}
                                </strong>
                                Pangea.app merely makes the Platform available
                                to enable Pangeans and Companies to find and
                                transact directly with each other. Pangea.app
                                does not introduce Pangeans to Companies, find
                                Projects for Pangeans, or find Pangeans for
                                Companies. Through the Platform, Pangeans may be
                                notified of Companies that may be seeking the
                                services they offer, and Companies may be
                                notified of Pangeans that may offer the services
                                they seek; at all times, however, Users are
                                responsible for evaluating and determining the
                                suitability of any Project, Company, or Pangean
                                on their own. If Users decide to enter intoan
                                engagement, the Engagement is directly between
                                the Users and Pangea.app is not a party to that
                                Engagement.{' '}
                            </p>
                            <p>
                                You acknowledge, agree, and understand that
                                Pangea.app is not a party to the relationship or
                                any dealings between Company and Pangean.
                                Without limitation, Users are solely responsible
                                for (a) ensuring the accuracy and legality of
                                any User Content, (b) determining the
                                suitability of other Users foran engagement
                                (such as any interviews, vetting, background
                                checks, or similar actions), (c) negotiating,
                                agreeing to, and executing any terms or
                                conditions of Engagements, (d) performing
                                Pangean Services, or (e) paying for Engagements
                                or Pangean Services. You further acknowledge,
                                agree, and understand that{' '}
                            </p>
                            <p>
                                you are solely responsible for assessing whether
                                to enter into an engagement with another User
                                and for verifying any information about another
                                User. Pangea.app does not make any
                                representations about or guarantee the truth or
                                accuracy of any Pangean’s or Company’s listings
                                or other User Content on the Platform; does not
                                verify any feedback or information provided by
                                Users about Pangeans or Companies; and does not
                                vet or otherwise perform background checks on
                                Pangeans or Companies. You acknowledge, agree,
                                and understand that Pangea.app does not, in any
                                way, supervise, direct, control, or evaluate
                                Pangeans or their work and is not responsible
                                for any Project, Project terms or Work Product.
                                Pangea.app makes no representations about and
                                does not guarantee, and you agree not to hold
                                Pangea.app responsible for, the quality, safety,
                                or legality of Pangean Services; the
                                qualifications, background, or identities of
                                Users; the ability of Pangeans to deliver
                                Pangean Services; the ability of Companies to
                                pay for Pangean Services; User Content,
                                statements or posts made by Users; or the
                                ability or willingness of a Company or Pangean
                                to actually complete a transaction.{' '}
                            </p>
                            <p>
                                You also acknowledge, agree, and understand that
                                Pangeans are solely responsible for determining,
                                and have the sole right to determine, which
                                Projects to accept; the time, place, manner, and
                                means of providing any Pangean Services; the
                                type of services they provide; and the price
                                they charge for their services or how that
                                pricing is determined or set. You further
                                acknowledge, agree, and understand that: (i) you
                                are not an employee of Pangea.app, and you are
                                not eligible for any of the rights or benefits
                                of employment (including unemployment and/or
                                workers compensation insurance); (ii) Pangea.app
                                will not have any liability or obligations under
                                or related to Engagements and/or Pangean
                                Services for any acts or omissions by you or
                                other Users; (iii) Pangea.app does not, in any
                                way, supervise, direct, or control any Pangean
                                or Pangean Services; does not impose quality
                                standards or a deadline for completion of any
                                Pangean Services; and does not dictate the
                                performance, methods, or process Pangean uses to
                                perform services; (iv) Pangean is free to
                                determine when and if to perform Pangean
                                Services, including the days worked and time
                                periods of work, and Pangea.app does not set or
                                have any control over Pangean’s pricing, work
                                hours, work schedules, or work location, nor is
                                Pangea.app involved in any other way in
                                determining the nature and amount of any
                                compensation that may be charged by or paid to
                                Pangean for a Project; (v) Pangean will be paid
                                at such times and amounts as agreed with a
                                Company in a given Engagement, and Pangea.app
                                does not, in any way, provide or guarantee
                                Pangean a regular salary or any minimum, regular
                                payment; (vi) Pangea.app does not provide
                                Pangeans with training or any equipment, labor,
                                tools, or materials related to any Engagement;
                                and (vii) Pangea.app does not provide the
                                premises at which Pangeans will perform the
                                work. Pangeans are free to use subcontractors or
                                employees to perform Pangean Services and may
                                delegate work on fixed-price contracts or by
                                agreeing with their Companies to have hourly
                                contracts for Pangean’s subcontractor(s) or
                                employee(s). If a Pangean uses subcontractors or
                                employees, Pangean further agrees and
                                acknowledges that this paragraph applies to
                                Pangea.app’s relationship, if any, with
                                Pangean’s subcontractors and employees as well
                                and Pangean is solely responsible for Pangean’s
                                subcontractors and employees.{' '}
                            </p>
                            <p>
                                Nothing in this Agreement is intended to
                                prohibit or discourage (nor should be construed
                                as prohibiting or discouraging) any User from
                                engaging in any other business activities or
                                providing any services through any other
                                channels they choose, provided, if applicable,
                                Users comply with the Opt Out provisions{' '}
                            </p>
                            <p>
                                described in Section 7. Users are free at all
                                times to engage in such other business
                                activities and services and are encouraged to do
                                so.{' '}
                            </p>
                            <p>
                                <strong>2.2 TAXES AND BENEFITS </strong>Pangean
                                acknowledges and agrees that Pangean is solely
                                responsible (a) for all tax liability associated
                                with payments received from Pangean’s Companies
                                and through Pangea.app, and that Pangea.app will
                                not withhold any taxes from payments to Pangean;
                                (b) to obtain any liability, health, workers’
                                compensation, disability, unemployment, or other
                                insurance needed, desired, or required by law,
                                and that Pangean is not covered by or eligible
                                for any insurance from Pangea.app; (c) for
                                determining whether Pangean is required by
                                applicable law to issue any particular invoices
                                for the Pangean Fees and for issuing any
                                invoices so required; (d) for determining
                                whether Pangean is required by applicable law to
                                remit to the appropriate authorities any value
                                added tax or any other taxes or similar charges
                                applicable to the Pangean Fees and remitting any
                                such taxes or charges to the appropriate
                                authorities, as appropriate; and (e) if outside
                                of the United States, for determining if
                                Pangea.app is required by applicable law to
                                withhold any amount of the Pangean Fees and for
                                notifying Pangea.app of any such requirement and
                                indemnifying Pangea.app for any requirement to
                                pay any withholding amount to the appropriate
                                authorities (including penalties and interest).
                                In the event of an audit of Pangea.app, Pangean
                                agrees to promptly cooperate with Pangea.app and
                                provide copies of Pangean’s tax returns and
                                other documents as may be reasonably requested
                                for purposes of such audit, including but not
                                limited to records showing Pangean is engaging
                                in an independent business as represented to
                                Pangea.app.{' '}
                            </p>
                            <p>
                                Pangea.app will assist with the issuance of
                                1099k’s in line with being a third party payment
                                processor.{' '}
                            </p>
                            <p>
                                <strong>
                                    2.3 MARKETPLACE FEEDBACK AND USER CONTENT
                                    You hereby acknowledge and agree that Users
                                    publish and request Pangea.app to publish on
                                    their behalf information on the Platform
                                    about the User, such as feedback,
                                    compositefeedback, geographical location, or
                                    verification of identity or credentials.
                                    However, such information is based solely on
                                    unverified data that Pangeans or Companies
                                    voluntarily submit to Pangea.app and does
                                    not constitute and will not be construed as
                                    an introduction, endorsement, or
                                    recommendation by Pangea.app; Pangea.app
                                    provides such information solely for the
                                    convenience of Users.{' '}
                                </strong>
                            </p>
                            <p>
                                You acknowledge and agree that User feedback
                                benefits the marketplace, all Users, and the
                                efficiency of the Platform and you specifically
                                request that Pangea.app post compositeor
                                compiled feedback about Users, including
                                yourself, on User Profiles and elsewhere on the
                                Platform. You acknowledge and agree that
                                feedback results for you wherever referenced,
                                and other User Content highlighted by Pangea.app
                                on the Platform or otherwise (“
                                <strong>Composite Information </strong>”), if
                                any, will include User comments, User ratings,
                                indicators of User satisfaction, and other
                                feedback left exclusively by other Users. You
                                further acknowledge and agree that Pangea.app
                                will make Composite Information available to
                                other Users, including compositeor compiled
                                feedback. Pangea.app provides its feedback
                                system as a means through which Users can share
                                their opinions of other Users publicly, and
                                Pangea.app does not monitor,{' '}
                            </p>
                            <p>
                                influence, contribute to, or censor these
                                opinions. You acknowledge and agree that posted
                                composite or compiled feedback and any other
                                Composite Information relates only to the
                                business advertised in the Profile and not to
                                any individual person.{' '}
                            </p>
                            <p>
                                Pangea.app does not generally investigate any
                                remarks posted by Users or other User Content
                                for accuracy or reliability and does not
                                guarantee that User Content is accurate. You are
                                solely responsible for your User Content,
                                including the accuracy of any User Content, and
                                are solely responsible for any legal action that
                                may be instituted by other Users or third
                                parties as a result of or in connection with
                                your User Content if such User Content is
                                legally actionable or defamatory. Pangea.app is
                                not legally responsible for any feedback or
                                comments posted or made available on the
                                Platform by any Users or third parties, even if
                                that information is defamatory or otherwise
                                legally actionable. In order to protect the
                                integrity of the feedback system and protect
                                Users from abuse, Pangea.app reserves the right
                                (but is under no obligation) to remove posted
                                feedback or information that, in Pangea.app’s
                                sole judgment, violates the Terms of Service or
                                negatively affects our marketplace, diminishes
                                the integrity of the feedback system, or
                                otherwise is inconsistent with the business
                                interests of Pangea.app. You acknowledge and
                                agree that you will notify Pangea.app of any
                                error or inaccurate statement in your feedback
                                results, including the Composite Information ,
                                and that if you do not do so, Pangea.app may
                                rely on the accuracy of such information.{' '}
                            </p>
                            <p>
                                <strong>
                                    3. CONTRACTUAL RELATIONSHIP BETWEEN COMPANY
                                    AND PANGEAN{' '}
                                </strong>
                                <em>
                                    Section 3 discusses the relationship you may
                                    decide to enter into with another User,
                                    including Engagements between Users, as
                                    detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>3.1 ENGAGEMENTS </strong>If a Company
                                and Pangean decide to enter into an engagement,
                                the Engagement is a contractual relationship
                                directly between the Company and Pangean.
                                Company and Pangean have complete discretion
                                both with regard to whether to enter into an
                                engagement with each other and with regard to
                                the terms of any Engagement. You acknowledge,
                                agree, and understand that Pangea.app is not a
                                party to any Engagement, that the formation of
                                an engagement between Users will not, under any
                                circumstance, create an employment or other
                                service relationship between Pangea.app and any
                                Pangean or a partnership or joint venture
                                between Pangea.app and any User.{' '}
                            </p>
                            <p>
                                With respect to any Engagement, Companies and
                                Pangeans may enter into any additional written
                                agreements that they deem appropriate (e.g.,
                                confidentiality agreements, invention assignment
                                agreements, assignment of rights, etc.) provided
                                that any such agreements do not conflict with,
                                narrow, or expand Pangea.app’s rights and
                                obligations under the Terms of Service.{' '}
                            </p>
                            <p>
                                <strong>3.2 DISPUTES AMONG USERS </strong>
                                Non-binding dispute assistance (“Dispute
                                Assistance”) is available within 30 days of the
                                date of the last release of funds from Company
                                to Pangean. If Company or Pangean contacts
                                Pangea.app via email to{' '}
                            </p>
                            <p>
                                adam@pangea.app within 30 days of the date of
                                the last payment from Company to Pangean and
                                requests non-binding dispute assistance for any
                                dispute among them (a “Dispute”), Pangea.app
                                will attempt to assist Company and Pangean by
                                reviewing the Dispute and proposing a mutual,
                                non-binding resolution. Pangea.app will only
                                review the 30 days of work performed prior to
                                the date a User requests Dispute Assistance.{' '}
                            </p>
                            <p>
                                If Pangean or Company intends to obtain an order
                                from any arbitrator or any court that might
                                direct Pangea.app to take or refrain from taking
                                any action with respect to an Account, that
                                party will (a) give us at least five business
                                days’ prior notice of the hearing; (b) include
                                in any such order a provision that, as a
                                precondition to obligation affecting Pangea.app,
                                we be paid in full for any amounts to which we
                                would otherwise be entitled; and (c) be paid for
                                the reasonable value of the services to be
                                rendered pursuant to such order.{' '}
                            </p>
                            <p>
                                • The Pangea Disputes team will notify Company
                                and Pangean via ticket by providing a notice of
                                dispute along with a request for information and
                                supporting documentation.{' '}
                            </p>
                            <p>
                                • If both Company and Pangean respond to the
                                notice and request for information, then the
                                Disputes team will review the documentation
                                submitted and any information available on the
                                Platform that pertains to the Dispute. After
                                review, the Disputes team will propose a mutual,
                                non- binding resolution based on the results of
                                the review.{' '}
                            </p>
                            <p>
                                • The proposed resolution is non-binding;
                                Company and Pangean can choose whether or not to
                                agree to it. If Company and Pangean agree in
                                writing to the proposed resolution, Company and
                                Pangean agree that Pangea.app is authorized and
                                irrevocably instructed to immediately release
                                funds in accordance with the proposed
                                resolution.{' '}
                            </p>
                            <p>
                                • If Company or Pangean rejects Pangea.app’s
                                proposed, non-binding resolution then Company
                                and/or Pangean must pursue the Dispute
                                independently.{' '}
                            </p>
                            <p>
                                • Pangea.app reserves the right to review the
                                Pangean’s work for 30 days prior to the date of
                                the request for Dispute Assistance and in its
                                sole discretion, to make adjustments to
                                invoices, and to direct Pangea.app to make
                                appropriate releases to Company if it finds work
                                that clearly does not relate Hourly Contract
                                requirements or Company instructions in the Work
                                Diaries or violations of the Terms of Service
                                during its review of the work.{' '}
                            </p>
                            <p>
                                <strong>
                                    3.3 CONFIDENTIAL INFORMATION AND OWNERSHIP{' '}
                                </strong>
                                Users may agree to any terms they deem
                                appropriate with respect to confidentiality,
                                including those set forth in any Engagement
                                Terms. If and to the extent that the Users do
                                not articulate any different Engagement
                                agreement, then they agree that this Section 3.3
                                (Confidentiality) applies.{' '}
                            </p>
                            <p>
                                <strong>3.3.1 Confidentiality </strong>To the
                                extent a User provides Confidential Information
                                to the other, the recipient will protect the
                                secrecy of the discloser’s Confidential
                                Information with the same degree of care as it
                                uses to protect its own Confidential
                                Information, but in no event with less than due
                                care. On a User’s written request, the party
                                that received Confidential Information will
                                promptly destroy or return the disclosing
                                party’s Confidential Information and any copies
                                thereof contained in or on its premises,
                                systems, or any other equipment otherwise under
                                its control.{' '}
                            </p>
                            <p>
                                <strong>3.3.2 Return </strong>If and when
                                Confidential Information is no longer needed for
                                the performance of the Pangean Services for a
                                Services Contract or at Company’s or Pangean’s
                                written request (which may be made at any time
                                at Company’s or Pangean’s sole discretion), the
                                party that received Confidential Information,
                                will, at its expense, promptly destroy or return
                                the disclosing party’s Confidential Information
                                and any copies thereof contained in or on its
                                premises, systems, or any other equipment
                                otherwise under its control. The party that
                                received Confidential Information agrees to
                                provide written certification to the party
                                disclosing the Confidential Information of
                                compliance with this subsection within ten days
                                after the receipt of disclosing party’s written
                                request for such certification.{' '}
                            </p>
                            <p>
                                <strong>
                                    3.3.3 Ownership of Work Product{' '}
                                </strong>
                                Upon Pangean’s receipt of full payment from
                                Company, the Work Product (except for any
                                Background Technology), including without
                                limitation all Intellectual Property Rights in
                                the Work Product (except for any Background
                                Technology), will be the sole and exclusive
                                property of Company, and Company will be deemed
                                to be the author thereof. If Company has any
                                Intellectual Property Rights to the Work Product
                                that are not owned by Company upon Pangeans’s
                                receipt of payment from Company, Pangean hereby
                                automatically irrevocably assigns to Company all
                                right, title, and interest worldwide in and to
                                such Intellectual Property Rights. Except as set
                                forth above, Pangean retains no rights to use,
                                and will not challenge the validity of Company’s
                                ownership in, such Intellectual Property Rights.
                                Pangean hereby waives any moral rights, rights
                                of paternity, integrity, disclosure, and
                                withdrawal or inalienable rights under
                                applicable law in and to the Work Product. If
                                payment is made only for partial delivery of
                                Work Product, the assignment described herein
                                applies only to the portion of Work Product
                                delivered and paid for.{' '}
                            </p>
                            <p>
                                <strong>
                                    3.3.4 LICENSE TO BACKGROUND TECHNOLOGY{' '}
                                </strong>
                                Upon Pangean’s receipt of full payment from
                                Company for delivery of Work Product, Pangean
                                hereby automatically grants to Copany a
                                non-exclusive, perpetual, fully-paid and
                                royalty-free, irrevocable and worldwide right,
                                with rights to sublicense through multiple
                                levels of sublicensees, to reproduce, make
                                derivative works of, distribute, publicly
                                perform, and publicly display in any form or
                                medium, whether now known or later developed,
                                make, have made, use, sell, import, offer for
                                sale, and exercise any and all present or future
                                rights in the Background Technology incorporated
                                in Work Product delivered for that payment. If
                                payment is made only for partial delivery of
                                Work Product, the license described herein
                                applies only to the portion of Work Product
                                delivered and paid for.{' '}
                            </p>
                            <p>
                                <strong>
                                    3.3.5 LICENSE TO OR WAIVER OF OTHER RIGHTS{' '}
                                </strong>
                                If Pangean has any right to the Work Product,
                                including without limitation any Intellectual
                                Property Right, that cannot be assigned to
                                Company by Pangean, Pangean hereby
                                automatically, upon Pangean’s receipt of full
                                payment from Company, unconditionally and
                                irrevocably grants to Company during the term of
                                such rights, an exclusive, even as to Pangean,
                                irrevocable, perpetual, worldwide, fully-paid
                                and royalty- free license to such rights, with
                                rights to sublicense through multiple levels of
                                sublicensees, to reproduce, make derivative
                                works of, distribute, publicly perform and
                                publicly display in any form or medium, whether
                                now known or later developed, make, use, sell,
                                import, offer for sale and exercise any and all
                                such rights. If Pangean has any rights to such
                                Work Product that cannot be assigned or
                                licensed, Pangean hereby automatically, upon
                                Pangean’s receipt of payment from Company,
                                unconditionally and irrevocably waives the
                                enforcement of such rights, and all claims and
                                causes of action of any kind against Company or
                                related to Company’s customers, with respect to
                                such rights, and will, at Company’s request and
                                expense, consent to and join in any action to
                                enforce such rights. If payment is made only for
                                partial delivery of Work Product, the grant
                                described herein applies only to the portion of
                                Work Product delivered.{' '}
                            </p>
                            <p>
                                <strong>3.3.6 ASSISTANCE </strong>Pangean will
                                assist Company in every way, including by
                                signing any documents or instruments reasonably
                                required, both during and after the term of the
                                Engagement, to obtain and enforce Intellectual
                                Property Rights relating to Work Product in all
                                countries. In the event Company is unable, after
                                reasonable effort, to secure Pangean’s signature
                                on any document needed in connection with the
                                foregoing, Pangean hereby designates and
                                appoints Company and its duly authorized
                                officers and agents as its agent and attorney in
                                fact to act on its behalf to further the
                                purposes of this Section with the same legal
                                force and effect as if executed by Pangean.{' '}
                            </p>
                            <p>
                                <strong>3.3.7 THIRD-PARTY RIGHTS </strong>
                                Pangean represents and warrants that Pangean
                                will not incorporate or use the materials of any
                                third party including those of any other Company
                                or any employer, in performing the Pangean
                                Services that are not generally available for
                                use by the public or have not been legally
                                transferred to the Company.{' '}
                            </p>
                            <p>
                                <strong>3.3.8 BACKGROUND TECHNOLOGY </strong>
                                Pangean will disclose in the Engagement terms
                                any Background Technology which Pangean proposes
                                to incorporate into Work Product or upon which
                                use or distribution of the Work Product will
                                depend. If Pangean discloses no Background
                                Technology, Pangean warrants that it will not
                                incorporate any Background Technology into Work
                                Product provided pursuant thereto. Pangean will
                                separately provide, with each delivery of Work
                                Product to Company, a bill of materials that
                                identifies all Background Technology and other
                                third-party materials that have been
                                incorporated into the Work Product and provides,
                                for each item of Background Technology
                                identified, (a) the name and any associated
                                version number, (b) the applicable license or
                                licensing terms, (c) whether the item has been
                                modified by Pangean, and (d) how the item has
                                been incorporated into, is used by, or is relied
                                upon by the Work Product. Notwithstanding the
                                foregoing, unless otherwise agreed in the
                                Engagement terms, Pangean{' '}
                            </p>
                            <p>
                                agrees that it will not incorporate into Work
                                Product or otherwise deliver to Company any
                                software code for which the use or distribution
                                of the code will create (or purport to create)
                                obligations for Company to grant any rights or
                                immunities under Company intellectual property
                                to a third-party, including without limitation
                                any obligation that the Work Product or Company
                                software combined with, derived from, or
                                distributed with such Work Product (x) be
                                disclosed or distributed in source code form,
                                (y) be licensed for the purpose of making
                                derivative works, or (z) be redistributable at
                                no charge.{' '}
                            </p>
                            <p>
                                <strong>3.3.9 COMPANY MATERIALS </strong>Company
                                grants Pangean a limited, non-exclusive,
                                revocable (at any time, at Company’s sole
                                discretion) right to use the Company Materials
                                as necessary solely for the performance of the
                                Pangean Services under the applicable
                                Engagement. Company reserves all other rights
                                and interest, including, without limitation, all
                                Intellectual Property Rights, in and to the
                                Company Materials. Upon completion or
                                termination of the Engagement, or upon Company’s
                                written request, Pangean will immediately return
                                all Company Materials to Company and further
                                agrees to destroy all copies of Company
                                Materials and Deliverables (except for
                                Background Technology as permitted by the
                                Engagement) contained in or on Pangean’s
                                premises, systems, or any other equipment or
                                location otherwise under Pangean’s control.
                                Within ten days of such request from Company,
                                Pangean agrees to provide written certification
                                to Company that Pangean has returned or
                                destroyed all Company Materials and Work Product
                                as provided in this subsection.{' '}
                            </p>
                            <p>
                                <strong>3.4 THIRD-PARTY BENEFICIARIES </strong>
                                It is the intent of the Parties to this
                                Agreement that Users who have entered into
                                Engagements or disclosed or received
                                Confidential Information to another User are
                                third-party beneficiaries of this Agreement with
                                respect to this Section 3 only.{' '}
                            </p>
                            <p>
                                <strong>4. WORKER CLASSIFICATION </strong>
                                <em>
                                    Section 4 discusses what you agree to
                                    concerning whether a Pangean is an employee
                                    or independent contractor and when you agree
                                    to pay Pangea.app an opt-out fee, as
                                    detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>4.1 WORKER CLASSIFICATION </strong>
                                Nothing in this Agreement is intended to or
                                should be construed to create a partnership,
                                joint venture, franchisor/franchisee or
                                employer-employee relationship between
                                Pangea.app and a User. Company is solely
                                responsible for and has complete discretion with
                                regard to selection of any Pangean for any
                                Project. Company is solely responsible, warrants
                                its decisions regarding classification are
                                correct, and assumes all liability, for
                                determining whether Pangeans should be engaged
                                as independent contractors or employees of
                                Company and engaging them accordingly;
                                Pangea.app will have no input into, or
                                involvement in, worker classification as between
                                Company and Pangean and Users agree that
                                Pangea.app has no involvement in and will have
                                no liability arising from or relating to the
                                classification of a Pangean generally or with
                                regard to a particular Project.{' '}
                            </p>
                            <p>
                                <strong>
                                    It will be assumed that if a Company pays a
                                    Pangean through our Platform that they have
                                    been classified as an independent
                                    contractor. In a scenario where a Pangean is
                                    deemed to be an employee or if it will
                                    receive services from a Pangean under terms
                                    and conditions that would give rise to an
                                    employment engagement, Company agrees to pay
                                    opt-out fee (outlined in section 6.2).{' '}
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    Pangean, acknowledges, understands, and
                                    agrees that Pangea.app will have no control
                                    over, or involvement in determining or
                                    influencing, the terms and conditions of any
                                    employment relationship that may arise
                                    between Pangean and Staffing Provider and/or
                                    Company, including the selection of an
                                    employee, pay rate, work hours, employment
                                    dates, and working conditions. Pangean will
                                    not have any contract on the Pangea.app
                                    Platform or contact with Pangea.app
                                    regarding such employment terms{' '}
                                </strong>
                            </p>
                            <p>
                                <strong>5. PANGEA.APP FEES </strong>
                                <em>
                                    Section 5 describes what fees you agree to
                                    pay to Pangea.app in exchange for Pangea.app
                                    providing the Platform to you and what taxes
                                    Pangea.app may collect, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>5.1 SERVICE FEES </strong>Companies pay
                                Pangea.app a 12.9% Service Fee (as defined in
                                this Section 5.1) for the use of the Platform.
                                Pangea.app may also charge service fees to
                                Pangeans for using the Platform’s communication,
                                invoicing, reporting, dispute resolution, and
                                payment services, including facilitating
                                arbitration services. Pangea.app uses Stripe as
                                a third party payment processor and will deduct
                                a certain percentage from the total payout when
                                remitting payment for a project posted on the
                                platform.{' '}
                            </p>
                            <p>
                                Pangea.app functions as a Third Party Payment
                                Processor in accordance with Section 2091,
                                section 6050W (c) (3) of Housing and Economic
                                Recovery Act of 2008.{' '}
                            </p>
                            <p>
                                <strong>
                                    5.2 MEMBERSHIP FEES AND CONNECTS{' '}
                                </strong>
                                Companies may pay Pangea.app a membership fee if
                                they subscribe for a paid membership which will
                                subscribe Companies to different levels of
                                participation and privileges on the Platform, by
                                payment of subscription fees.{' '}
                            </p>
                            <p>
                                <strong>5.3 VAT AND OTHER TAXES </strong>
                                Pangea.app may be required by applicable law to
                                collect taxes or levies including, without
                                limitation, withholding income tax or VAT (while
                                some countries may refer to VAT using other
                                terms, e.g. GST, we’ll just refer to VAT, GST
                                and any local sales taxes collectively as “
                                <strong>VAT</strong>”) in the jurisdiction of
                                the Pangean (the &quot;<strong>Taxes</strong>
                                &quot;). In such instances, any amounts
                                Pangea.app is required to collect or withhold
                                for the payment of any such Taxes shall be
                                collected in addition to the fees owed to
                                Pangea.app under the Terms of Service.{' '}
                            </p>
                            <p>
                                <strong>
                                    5.4 NO FEE FOR INTRODUCING OR FOR FINDING
                                    PROJECTS{' '}
                                </strong>
                            </p>
                            <p>
                                Pangea.app does not introduce Companies to
                                Pangeans and does not help Pangeans secure
                                Projects. Pangea.app merely makes the Platform
                                available to enable Pangeans to do so themselves
                                and may from time to time highlight Projects
                                that may be of interest. Therefore, Pangea.app
                                does not charge a fee when a Pangean finds a
                                suitable Company or finds a Project. In
                                addition, Pangea.app does not charge any fee or
                                dues for posting public feedback and compositeor
                                compiled feedback, including Composite
                                Information . <strong>5.5 NON-PAYMENT </strong>
                                If Company is in “<strong>default</strong>”,
                                meaning the Company fails to pay the Pangean
                                Fees or any other amounts when due under the
                                Terms of Service, or a written agreement for
                                payment terms incorporating the Terms of Service
                                (signed by an authorized representative of
                                Pangea.app), Pangea.app will be entitled to the
                                remedies described in this Section 6.4 in
                                addition to such other remedies that may be
                                available under applicable law or in such
                                written agreement. For the avoidance of doubt,
                                Company will be deemed to be in default on the
                                earliest occurrence of any of the following: (a)
                                Company fails to pay the Pangean Fees when due,
                                (b) Company fails to pay a balance that is due
                                or to bring, within a reasonable period of time
                                but no more than 30 days, an account current
                                after a credit or debit card is declined or
                                expires, (c) Company fails to pay an invoice
                                issued to the Company by Pangea.app within the
                                time period agreed or, if none, within 30 days,
                                (d) Company initiates a chargeback with a bank
                                or other financial institution resulting in a
                                charge made by Pangea.app for Pangean Fees or
                                such other amount due being reversed to the
                                Company, or (e) Company takes other actions or
                                fails to take any action that results in a
                                negative or past-due balance on the Company’s
                                account.{' '}
                            </p>
                            <p>
                                If Company is in default, Pangea.app may,
                                without notice, temporarily or permanently close
                                Company’s Account and revoke Company’s access to
                                the Platform, including Company’s authority to
                                use the Platform to process any additional
                                payments, enter into Engagements, or obtain any
                                additional Pangean Services from other Users
                                through the Platform. However, Company will
                                remain responsible for any amounts that accrue
                                on any open Projects at the time a limitation is
                                put on the Company’s Account as a result of the
                                default. Without limiting other available
                                remedies, Company must pay Pangea.app upon
                                demand for any amounts owed, plus interest on
                                the outstanding amount at the lesser of one and
                                one-half percent (1.5%) per month or the maximum
                                interest allowed by applicable law, plus
                                attorneys’ fees and other costs of collection to
                                the extent permitted by applicable law.{' '}
                            </p>
                            <p>
                                Pangea.app, at our discretion and to the extent
                                permitted by applicable law, may set off amounts
                                due against other amounts received from Company
                                or held by Pangea.app for Company, make
                                appropriate reports to credit reporting agencies
                                and law enforcement authorities, and cooperate
                                with credit reporting agencies and law
                                enforcement authorities in any investigation or
                                prosecution.{' '}
                            </p>
                            <p>
                                <strong>5.6 NO RETURN OF FUNDS </strong>Company
                                acknowledges and agrees that Pangea.app will
                                charge Company’s designated Payment Method for
                                the Pangean Fees when the Company remits
                                payment, and that once Pangea.app charges or
                                debits the Company’s designated Payment Method
                                for the Pangean Fees plus Service Fee, the
                                charge or{' '}
                            </p>
                            <p>
                                debit is non-refundable, except as otherwise
                                required by applicable law. Company also
                                acknowledges and agrees that the Terms of
                                Service provide a dispute resolution process as
                                a way for Company resolve disputes. To the
                                extent permitted by applicable law, Company
                                therefore agrees not to ask its credit card
                                company, bank, or other Payment Method provider
                                to charge back any Pangean Fees or other fees
                                charged pursuant to the Terms of Service for any
                                reason. A chargeback in breach of the foregoing
                                obligation is a material breach of the Terms of
                                Service. If Company initiates a chargeback in
                                violation of this Agreement, Company agrees that
                                Pangea.app may dispute or appeal the chargeback
                                and institute collection action against Company
                                and take such other action it deems appropriate.{' '}
                            </p>
                            <p>
                                <strong>5.7 PAYMENT METHODS </strong>In order to
                                use certain Platform Services, Company must
                                provide account information for at least one
                                valid Payment Method.{' '}
                            </p>
                            <p>
                                Company hereby authorizes Pangea.app to run
                                credit card authorizations on all credit cards
                                provided by Company, Ti store credit card
                                information using Stripe Connect a PCI compliant
                                service), and to charge Company’s credit card
                                (or any other Payment Method) for the Pangean
                                Fees and any other amounts owed under the Terms
                                of Service. To the extent permitted by
                                applicable law and subject to our Privacy
                                Policy, you acknowledge and agree that we may
                                use certain third-party vendors and service
                                providers to process payments and manage your
                                Payment Method information.{' '}
                            </p>
                            <p>
                                Payment Methods will be charged by Pangea.app
                                via Stripe in most countries.{' '}
                            </p>
                            <p>
                                By providing Payment Method information through
                                the Platform and authorizing payments with the
                                Payment Method, Company represents, warrants,
                                and covenants that: (a) Company is legally
                                authorized to provide such information; (b)
                                Company is legally authorized to make payments
                                using the Payment Method(s); (c) if Company is
                                an employee or agent of a company or person that
                                owns the Payment Method, that Company is
                                authorized by the company or person to use the
                                Payment Method to make payments on Pangea.app;
                                and (d) such actions do not violate the terms
                                and conditions applicable to Company’s use of
                                such Payment Method(s) or applicable law. When
                                Company authorizes a payment using a Payment
                                Method via the Platform, Company represents and
                                warrants that there are sufficient funds or
                                credit available to complete the payment using
                                the designated Payment Method. To the extent
                                that any amounts owed under this Agreement or
                                the other Terms of Service cannot be collected
                                from Company’s Payment Method(s), Company is
                                solely responsible for paying such amounts by
                                other means.{' '}
                            </p>
                            <p>
                                Because the use of any Payment Method may be
                                limited by applicable law or by written
                                agreement with your financial institution,
                                Pangea.app is not liable to any User if
                                Pangea.app does not complete a transaction as a
                                result of any such limit, or if a financial
                                institution fails to honor any credit or debit
                                to or from an account associated with such
                                Payment Method. Pangea.app will make
                                commercially reasonable efforts to work with any
                                such affected Users to resolve such transactions
                                in a manner consistent with this Agreement.{' '}
                            </p>
                            <p>
                                <strong>
                                    5.8 U.S. DOLLARS AND FOREIGN CURRENCY
                                    CONVERSION{' '}
                                </strong>
                                The Platform and the Platform Services operate
                                in U.S. Dollars. If a User&#x27;s Payment Method
                                is denominated in a currency other than U.S.
                                Dollars and requires currency conversion to make
                                or receive payments in U.S. Dollars, the
                                Platform may display foreign currency conversion
                                rates that Pangea.app currently makes available
                                to convert supported foreign currencies to U.S.
                                Dollars. These foreign currency conversion rates
                                adjust regularly based on market conditions.
                                Please note that the wholesale currency
                                conversion rates at which we or our Affiliates
                                obtain foreign currency will usually be
                                different than the foreign currency conversion
                                rates offered on the Platform. Each User, at its
                                sole discretion and risk, may authorize the
                                charge, debit, or credit of its Payment Method
                                in a supported foreign currency and the
                                conversion of the payment to U.S. Dollars at the
                                foreign currency conversion rate displayed on
                                the Platform. A list of supported foreign
                                currencies is available on the Platform. If
                                foreign currency conversion is required to make
                                a payment in U.S. Dollars and either Pangea.app
                                does not support the foreign currency or the
                                User does not authorize the conversion of such
                                payment at the foreign currency conversion rate
                                displayed on the Platform, Pangea.app or one of
                                our Affiliates will charge, debit, or credit the
                                User&#x27;s Payment Method in U.S. Dollars and
                                the User&#x27;s Payment Method provider will
                                convert the payment at a foreign currency
                                conversion rate selected by the User&#x27;s
                                Payment Method provider. The User&#x27;s Payment
                                Method provider may also charge fees directly to
                                the Payment Method even when no currency
                                conversion is involved. The User&#x27;s
                                authorization of a payment using a foreign
                                currency conversion rate displayed on the
                                Platform is at the User&#x27;s sole risk.
                                Pangea.app are not responsible for currency
                                fluctuations that occur when billing or
                                crediting a Payment Method denominated in a
                                currency other than U.S. Dollars. Pangea.app is
                                not responsible for currency fluctuations that
                                occur when receiving or sending payments to and
                                from the Account.{' '}
                            </p>
                            <p>
                                <strong>6. NON-CIRCUMVENTION </strong>
                                <em>
                                    Section 7 discusses your agreement to make
                                    and receive payments only through Pangea.app
                                    for two years from the date you first meet
                                    your Company or Pangean on the Platform,
                                    unless you pay an Opt- Out-Fee, as detailed
                                    below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>
                                    6.1 MAKING PAYMENTS THROUGH PANGEA.APP{' '}
                                </strong>
                                You acknowledge and agree that a substantial
                                portion of the compensation Pangea.app receives
                                for making the Platform available to you is
                                collected through the Service Fee described in
                                Section 5.1. Pangea.app only receives this
                                Service Fee when a Company and a Pangean pay and
                                receive payment through the Platform. Therefore,
                                for 24 months from the time you identify or are
                                identified by any party through the Platform
                                (the “<strong>Non-Circumvention Period</strong>
                                ”), you agree to use the Platform as your
                                exclusive method to request, make, and receive
                                all payments for work directly or indirectly
                                with that party or arising out of your
                                relationship with that party (the “
                                <strong>Pangea.app Relationship</strong>”). For
                                the avoidance of doubt, if you, or the business
                                you represent, did not identify and were not
                                identified by another party through the
                                Platform, such as if you and another User worked
                                together before meeting on the Platform, then
                                the Non- Circumvention Period does not apply. If
                                you use the Platform as an employee, agent, or
                                representative of another business, then the
                                Non-Circumvention Period applies to you and
                                other employees, agents, or representatives of
                                the business when acting in that capacity with
                                respect to the other User.{' '}
                            </p>
                            <p>
                                Except if you pay the Opt-Out Fee (see Section
                                6.2), you agree not to circumvent the Payment
                                Methods offered by the Platform. By way of
                                illustration and not in limitation of the
                                foregoing, you agree not to:{' '}
                            </p>
                            <p>
                                • Submit proposals or solicit parties identified
                                through the Platform to contract, hire, work
                                with, or pay outside the Platform.{' '}
                            </p>
                            <p>
                                • Accept proposals or solicit parties identified
                                through the Platform to contract, invoice, or
                                receive payment outside the Platform.{' '}
                            </p>
                            <p>
                                • Invoice or report on the Platform or in an
                                Opt-Out Fee request an invoice or payment amount
                                lower than that actually agreed between Users.{' '}
                            </p>
                            <p>
                                • Refer a User you identified on the Platform to
                                a third-party who is not a User of the Platform
                                for purposes of making or receiving payments off
                                the Platform.{' '}
                            </p>
                            <p>
                                You agree to notify Pangea.app immediately if a
                                person suggests to you making or receiving
                                payments outside of the Platform in violation of
                                this Section 6.1. If you are aware of a breach
                                or potential breach of this non-circumvention
                                agreement, please submit a confidential report
                                to Pangea.app by sending an email message to:
                                Adam@Pangea.app{' '}
                            </p>
                            <p>
                                If you refuse to accept any new version of the
                                Terms of Service or elect not to comply with
                                certain conditions of using the Platform, such
                                as minimum rates supported on the Platform, and
                                therefore choose to cease using the Platform,
                                you may pay the Opt-Out Fee for each other User
                                you wish to continue working with on whatever
                                terms you agree after you cease using the
                                Platform.{' '}
                            </p>
                            <p>
                                <strong>6.2 OPTING OUT </strong>You may opt-out
                                of the obligation in Section 6.1 with respect to
                                each Pangea.app Relationship only if the Company
                                or prospective Company or Pangean pays
                                Pangea.app an opt-out fee for each such
                                relationship (the “<strong>Opt-Out Fee</strong>
                                ”).{' '}
                            </p>
                            <p>
                                The Opt-Out Fee is computed as follows (a)
                                interest at the rate of 18% per annum or the
                                maximum rate permitted by applicable law,
                                whichever is less, on the amount calculated in
                                (b), from the date Company first makes payment
                                to the subject Pangean until the date the
                                Opt-Out Fee is paid; and (b) the greater of: (i)
                                $500; or (ii) 10% of the anticipated annualized
                                salary or wages for one year if the Company
                                offers Pangean employment directly; or (iii) all
                                Service Fees that would be earned by Pangea.app
                                from the Pangea.app Relationship during the
                                Non-Circumvention Period, computed based on the
                                annualized amount earned by Pangean from Company
                                during the most recent normalized 8-week period,
                                or during such shorter period as data is
                                available to Pangea.app;{' '}
                            </p>
                            <p>
                                (iv) provided, however, that if the amount in
                                (ii) and (iii) cannot be ascertained due to
                                uncertainty or lack of sufficient information,
                                then Pangea.app and you agree that fee shall be
                                $500; if only one of (ii) or (iii) can be
                                ascertained, then Pangea.app and you agree that
                                amount shall be used if it is greater than $500.
                                To pay the Opt-Out Fee, you must request
                                instructions by sending an email message to
                                Adam@Pangea.app{' '}
                            </p>
                            <p>
                                If Pangea.app determines, in its sole
                                discretion, that you have violated Section 7,
                                Pangea.app to the maximum extent permitted by
                                law (x) charge your Payment Method the Opt-Out
                                Fee (including interest) if permitted by law or
                                send you an invoice for the Opt-Out Fee
                                (including interest), which you agree to pay
                                within 30 days, (y) close your Account and
                                revoke your authorization to use the Platform,
                                and/or (z) charge you for all losses and costs
                                (including any and all time of Pangea.app’s
                                employees) and reasonable expenses (including
                                attorneys’ fees) related to investigating such
                                breach and collecting such fees.{' '}
                            </p>
                            <p>
                                <strong>7. RECORDS OF COMPLIANCE </strong>
                                <em>
                                    Section 7 discusses your agreement to make
                                    and keep all required records, as detailed
                                    below.{' '}
                                </em>
                                Users will each (a) create and maintain records
                                to document satisfaction of their respective
                                obligations under this Agreement, including,
                                without limitation, their respective payment
                                obligations and compliance with tax and
                                employment laws, and (b) provide copies of such
                                records to Pangea.app upon request. Nothing in
                                this subsection requires or will be construed as
                                requiring Pangea.app to supervise or monitor a
                                User’s compliance with this Agreement, the other
                                Terms of Service, oran engagement. You are
                                solely responsible for creation, storage, and
                                backup of your business records. This Agreement
                                and any registration for or subsequent use of
                                the Platform will not be construed as creating
                                any responsibility on Pangea.app’s part to
                                store, backup, retain, or grant access to any
                                information or data for any period.{' '}
                            </p>
                            <p>
                                <strong>8. WARRANTY DISCLAIMER </strong>
                                <em>
                                    Section 8 discusses your agreement and
                                    understanding that the Platform may not
                                    always be available or work perfectly, as
                                    detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                YOU AGREE NOT TO RELY ON THE PLATFORM, THE
                                PLATFORM SERVICES, ANY INFORMATION ON THE
                                PLATFORM OR THE CONTINUATION OF THE PLATFORM.
                                THE PLATFORM AND THE PLATFORM SERVICES ARE
                                PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS.
                                PANGEA.APP MAKES NO REPRESENTATIONS OR
                                WARRANTIES WITH REGARD TO THE PLATFORM, THE
                                PLATFORM SERVICES, WORK PRODUCT, OR ANY
                                ACTIVITIES OR ITEMS RELATED TO THIS AGREEMENT OR
                                THE TERMS OF SERVICE. TO THE MAXIMUM EXTENT
                                PERMITTED BY APPLICABLE LAW, PANGEA.APP
                                DISCLAIMS ALL EXPRESS AND IMPLIED CONDITIONS,
                                REPRESENTATIONS, AND WARRANTIES INCLUDING, BUT
                                NOT LIMITED TO, THE WARRANTIES OF
                                MERCHANTABILITY, ACCURACY, FITNESS FOR A
                                PARTICULAR PURPOSE, TITLE, AND NON-
                                INFRINGEMENT. SOME JURISDICTIONS MAY NOT ALLOW
                                FOR ALL OF THE FOREGOING LIMITATIONS ON
                                WARRANTIES, SO TO THAT EXTENT, SOME OR ALL OF
                                THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
                                SECTION 14 (TERM AND TERMINATION) STATES USER’S{' '}
                            </p>
                            <p>
                                SOLE AND EXCLUSIVE REMEDY AGAINST PANGEA.APP
                                WITH RESPECT TO ANY DEFECTS, NON- CONFORMANCES,
                                OR DISSATISFACTION.{' '}
                            </p>
                            <p>
                                <strong>9. LIMITATION OF LIABILITY </strong>
                                <em>
                                    Section 9 discusses your agreement that
                                    Pangea.app usually will not have to pay you
                                    damages relating to your use of the Platform
                                    and, if it is, at most it will be required
                                    to pay you $2,500, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                Pangea.app is not liable, and you agree not to
                                hold us responsible, for any damages or losses
                                arising out of or in connection with the Terms
                                of Service, including, but not limited to:{' '}
                            </p>
                            <p>
                                • your use of or your inability to use our
                                Platform;{' '}
                            </p>
                            <p>• delays or disruptions in our Platform; </p>
                            <p>
                                • viruses or other malicious software obtained
                                by accessing, or linking to, our Platform;{' '}
                            </p>
                            <p>
                                • glitches, bugs, errors, or inaccuracies of any
                                kind in our Platform;{' '}
                            </p>
                            <p>
                                • damage to your hardware device from the use of
                                the Platform;{' '}
                            </p>
                            <p>
                                • the content, actions, or inactions of third
                                parties’ use of the Platform;{' '}
                            </p>
                            <p>
                                • a suspension or other action taken with
                                respect to your Account;{' '}
                            </p>
                            <p>
                                • your reliance on the quality, accuracy, or
                                reliability of job postings, Profiles, ratings,
                                recommendations, and feedback (including their
                                content, order, and display), Composite
                                Information , or metrics found on, used on, or
                                made available through the Platform; and{' '}
                            </p>
                            <p>
                                • your need to modify practices, content, or
                                behavior or your loss of or inability to do
                                business, as a result of changes to the Terms of
                                Service.{' '}
                            </p>
                            <p>
                                ADDITIONALLY, IN NO EVENT WILL PANGEA.APP, OUR
                                AFFILIATES, OUR LICENSORS, OR OUR THIRD-PARTY
                                SERVICE PROVIDERS BE LIABLE FOR ANY SPECIAL,
                                CONSEQUENTIAL, INCIDENTAL, PUNITIVE, EXEMPLARY,
                                OR INDIRECT COSTS OR DAMAGES, INCLUDING, BUT NOT
                                LIMITED TO, LITIGATION COSTS, INSTALLATION AND
                                REMOVAL COSTS, OR LOSS OF DATA, PRODUCTION,
                                PROFIT, OR BUSINESS OPPORTUNITIES. THE LIABILITY
                                OF PANGEA.APP, OUR AFFILIATES, OUR LICENSORS,
                                AND OUR THIRD-PARTY SERVICE PROVIDERS TO ANY
                                USER FOR ANY CLAIM ARISING OUT OF OR IN
                                CONNECTION WITH THIS AGREEMENT OR THE OTHER
                                TERMS OF SERVICE WILL NOT EXCEED THE LESSER OF:
                                (A) $2,500; OR (B) ANY FEES RETAINED BY
                                PANGEA.APP WITH RESPECT TO ENGAGEMENTS ON WHICH
                                USER WAS INVOLVED AS COMPANY OR PANGEAN DURING
                                THE SIX-MONTH PERIOD PRECEDING THE DATE OF THE
                                CLAIM. THESE LIMITATIONS WILL APPLY TO ANY
                                LIABILITY, ARISING FROM ANY CAUSE OF ACTION
                                WHATSOEVER ARISING OUT OF OR IN CONNECTION WITH
                                THIS AGREEMENT OR THE OTHER TERMS OF SERVICE,
                                WHETHER IN CONTRACT, TORT (INCLUDING
                                NEGLIGENCE), STRICT LIABILITY, OR OTHERWISE,
                                EVEN IF ADVISED OF THE POSSIBILITY OF SUCH COSTS
                                OR DAMAGES AND EVEN IF THE LIMITED REMEDIES
                                PROVIDED HEREIN FAIL OF THEIR ESSENTIAL PURPOSE.
                                SOME STATES AND JURISDICTIONS DO NOT ALLOW FOR
                                ALL OF{' '}
                            </p>
                            <p>
                                THE FOREGOING EXCLUSIONS AND LIMITATIONS, SO TO
                                THAT EXTENT, SOME OR ALL OF THESE LIMITATIONS
                                AND EXCLUSIONS MAY NOT APPLY TO YOU.{' '}
                            </p>
                            <p>
                                <strong>10. RELEASE </strong>
                                <em>
                                    Section 10 discusses your agreement not to
                                    hold us responsible for any dispute you may
                                    have with another User, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                In addition to the recognition that Pangea.app
                                is not a party to any contract between Users,
                                you hereby release Pangea.app, our Affiliates,
                                and our respective officers, directors, agents,
                                subsidiaries, joint ventures, employees and
                                service providers from claims, demands, and
                                damages (actual and consequential) of every kind
                                and nature, known and unknown, arising out of or
                                in any way connected with any dispute you have
                                with another User, whether it be at law or in
                                equity that exist as of the time you enter into
                                this agreement. This release includes, for
                                example and without limitation, any disputes
                                regarding the performance, functions, and
                                quality of the Pangean Services provided to
                                Company by a Pangean and requests for refunds
                                based upon disputes.{' '}
                            </p>
                            <p>
                                <strong>
                                    TO THE EXTENT APPLICABLE, YOU HEREBY WAIVE
                                    THE PROTECTIONS OF CALIFORNIA CIVIL CODE §
                                    1542 (AND ANY ANALOGOUS LAW IN ANY OTHER
                                    APPLICABLE JURISDICTION) WHICH SAYS: “A
                                    GENERAL RELEASE DOES NOT EXTEND TO CLAIMS
                                    WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT
                                    TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                                    EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM
                                    OR HER MUST HAVE MATERIALLY AFFECTED HIS OR
                                    HER SETTLEMENT WITH THE DEBTOR.”{' '}
                                </strong>
                            </p>
                            <p>
                                This release will not apply to a claim that
                                Pangea.app failed to meet our obligations under
                                the Terms of Service.{' '}
                            </p>
                            <p>
                                <strong>11. INDEMNIFICATION </strong>
                                <em>
                                    Section 11 discusses your agreement to pay
                                    for any costs or losses we have as a result
                                    of a claim brought against us related to
                                    your use of the Platform or your illegal or
                                    harmful conduct, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                You will indemnify, defend, and hold harmless
                                Pangea.app, our Affiliates, and our respective
                                directors, officers, employees, representatives,
                                and agents (each an “
                                <strong>Indemnified Party</strong>”) for all
                                Indemnified Claims (defined below) and
                                Indemnified Liabilities (defined below) relating
                                to or arising out of: (a) the use of the
                                Platform and the Platform Services by you or
                                your agents, including any payment obligations
                                or default (described in Section 6.4
                                (Non-Payment)) incurred through use of the
                                Platform Services; (b) any Engagement entered
                                into by you or your agents, including, but not
                                limited to, the classification of a Pangean as
                                an independent contractor; the classification of
                                Pangea.app as an employer or joint employer of
                                Pangean; any employment-related claims, such as
                                those relating to employment termination,
                                employment discrimination, harassment, or
                                retaliation; and any claims for unpaid wages or
                                other compensation, overtime pay, sick leave,
                                holiday or vacation pay, retirement benefits,
                                worker’s{' '}
                            </p>
                            <p>
                                compensation benefits, unemployment benefits, or
                                any other employee benefits; (c) failure to
                                comply with the Terms of Service by you or your
                                agents; (d) failure to comply with applicable
                                law by you or your agents; (e) negligence,
                                willful misconduct, or fraud by you or your
                                agents; and (f) defamation, libel, violation of
                                privacy rights, unfair competition, or
                                infringement of Intellectual Property Rights or
                                allegations thereof to the extent caused by you
                                or your agents. For purposes of this Section 12,
                                your agents includes any person who has apparent
                                authority to access or use your account
                                demonstrated by using your username and
                                password.{' '}
                            </p>
                            <p>
                                “<strong>Indemnified Claim</strong>” means any
                                and all claims, damages, liabilities, costs,
                                losses, and expenses (including reasonable
                                attorneys’ fees and all related costs and
                                expenses) arising from or relating to any claim,
                                suit, proceeding, demand, or action brought by
                                you or a third party or other User against an
                                Indemnified Party.{' '}
                            </p>
                            <p>
                                “<strong>Indemnified Liability</strong>” means
                                any and all claims, damages, liabilities, costs,
                                losses, and expenses (including reasonable
                                attorneys’ fees and all related costs and
                                expenses) arising from or relating to any claim,
                                suit, proceeding, demand, or action brought by
                                an Indemnified Party against you or a third
                                party or other User.{' '}
                            </p>
                            <p>
                                <strong>
                                    12. AGREEMENT TERM AND TERMINATION{' '}
                                </strong>
                                <em>
                                    Section 12 discusses your and Pangea.app’s
                                    agreement about when and how long this
                                    Agreement will last, when and how either you
                                    or Pangea.app can end this Agreement, and
                                    what happens if either of us ends the
                                    Agreement, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>12.1 TERMINATION </strong>Unless both
                                you and Pangea.app expressly agree otherwise in
                                writing, either of us may terminate this
                                Agreement in our sole discretion, at any time,
                                without explanation, upon written notice to the
                                other, which will result in the termination of
                                the other Terms of Service as well, except as
                                otherwise provided herein. You may provide
                                written notice to Adam@Pangea.app. In the event
                                you properly terminate this Agreement, your
                                right to use the Platform is automatically
                                revoked, and your Account will be closed.
                                Pangea.app is not a party to any Engagement
                                between Users. Consequently, User understands
                                and acknowledges that termination of this
                                Agreement (or attempt to terminate this
                                Agreement) does not terminate or otherwise
                                impact any Engagement or Project entered into
                                between Users. If you attempt to terminate this
                                Agreement while having one or more open
                                Projects, you agree (a) you hereby instruct
                                Pangea.app to close any open contracts; (b) you
                                will continue to be bound by this Agreement and
                                the other Terms of Service until all such
                                Projects have closed on the Platform; (c)
                                Pangea.app will continue to perform those
                                Platform Services necessary to complete any open
                                Project or related transaction between you and
                                another User; and (d) you will continue to be
                                obligated to pay any amounts accrued but unpaid
                                as of the date of termination or as of the
                                closure of any open Engagements, whichever is
                                later, to Pangea.app for any Platform Services
                                or such other amounts owed under the Terms of
                                Service and to any Pangeans for any Pangean
                                Services.{' '}
                            </p>
                            <p>
                                Without limiting Pangea.app’s other rights or
                                remedies, we may, but are not obligated to,
                                temporarily or indefinitely revoke access to the
                                Platform, deny your registration, or permanently
                                revoke your access to the Platform and refuse to
                                provide any or all Platform Services to you if:
                                (i) you breach the letter or spirit of any terms
                                and conditions of this Agreement or any other
                                provisions of the Terms of Service; (ii) we
                                suspect or become aware that you have provided
                                false or misleading information to us; or (iii)
                                we believe, in our sole discretion, that your
                                actions may cause legal liability for you, our
                                Users, or Pangea.app or our Affiliates; may be
                                contrary to the interests of the Platform or the
                                User community; or may involve illicit or
                                illegal activity. If your Account is temporarily
                                or permanently closed, you may not use the
                                Platform under the same Account or a different
                                Account or reregister under a new Account
                                without Pangea.app’s prior written consent. If
                                you attempt to use the Platform under a
                                different Account, we reserve the right to
                                reclaim available funds in that Account and/or
                                use an available Payment Method to pay for any
                                amounts owed by you to the extent permitted by
                                applicable law.{' '}
                            </p>
                            <p>
                                You acknowledge and agree that the value,
                                reputation, and goodwill of the Platform depend
                                on transparency of User’s Account status to all
                                Users, including both yourself and other Users
                                who have entered into Engagements with you. You
                                therefore agree as follows:{' '}
                                <strong>
                                    IF PANGEA.APP DECIDES TO TEMPORARILY OR
                                    PERMANENTLY CLOSE YOUR ACCOUNT, PANGEA.APP
                                    HAS THE RIGHT WHERE ALLOWED BY LAW BUT NOT
                                    THE OBLIGATION TO: (A) NOTIFY OTHER USERS
                                    THAT HAVE ENTERED INTO ENGAGEMENTS WITH YOU
                                    TO INFORM THEM OF YOUR CLOSED ACCOUNT
                                    STATUS, (B) PROVIDE THOSE USERS WITH A
                                    SUMMARY OF THE REASONS FOR YOUR ACCOUNT
                                    CLOSURE. YOU AGREE THAT PANGEA.APP WILL HAVE
                                    NO LIABILITY ARISING FROM OR RELATING TO ANY
                                    NOTICE THAT IT MAY PROVIDE TO ANY USER
                                    REGARDING CLOSED ACCOUNT STATUS OR THE
                                    REASON(S) FOR THE CLOSURE.{' '}
                                </strong>
                            </p>
                            <p>
                                <strong>12.2 ACCOUNT DATA ON CLOSURE </strong>
                                Except as otherwise required by law, if your
                                Account is closed for any reason, you will no
                                longer have access to data, messages, files, or
                                other material you keep on the Platform and that
                                that any closure of your Account may involve
                                deletion of any content stored in your Account
                                for which Pangea.app will have no liability
                                whatsoever. Pangea.app, in its sole discretion
                                and as permitted or required by law, may retain
                                some or all of your Account information.{' '}
                            </p>
                            <p>
                                <strong>12.3 SURVIVAL </strong>After this
                                Agreement terminates, the terms of this
                                Agreement and the other Terms of Service that
                                expressly or by their nature contemplate
                                performance after this Agreement terminates or
                                expires will survive and continue in full force
                                and effect. For example, the provisions
                                requiring arbitration, permitting audits,
                                protecting intellectual property, requiring
                                non-circumvention, indemnification, payment of
                                fees, reimbursement and setting forth
                                limitations of liability each, by their nature,
                                contemplate performance or observance after this
                                Agreement terminates. Without limiting any other
                                provisions of the Terms of Service, the
                                termination of this Agreement for any reason
                                will not release you or Pangea.app from any
                                obligations incurred prior to termination of
                                this Agreement or that thereafter may accrue in
                                respect of any act or omission prior to such
                                termination.{' '}
                            </p>
                            <p>
                                <strong>
                                    13. DISPUTES BETWEEN YOU AND PANGEA.APP{' '}
                                </strong>
                                <em>
                                    Section 13 discusses your agreement with
                                    Pangea.app and our agreement with you about
                                    how we will resolve any disputes, including
                                    that we will both first try to resolve any
                                    dispute informally and, if you are in the
                                    United States, that we both agree to use
                                    arbitration instead of going to court or
                                    using a jury if we can’t resolve the dispute
                                    informally, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>
                                    13.1 DISPUTE PROCESS, ARBITRATION, AND SCOPE{' '}
                                </strong>
                                If a dispute arises between you and Pangea.app
                                or our Affiliates, our goal is to resolve the
                                dispute quickly and cost-effectively.
                                Accordingly, unless you opt out as provided in
                                Section 13.4.4 below, you, Pangea.app, and our
                                Affiliates agree to resolve any claim, dispute,
                                or controversy that arises out of or relates to
                                this Agreement, the other Terms of Service, your
                                relationship with Pangea.app (including without
                                limitation any claimed employment with
                                Pangea.app or one of our Affiliates or
                                successors), the termination of your
                                relationship with Pangea.app, or the Platform
                                Services (each, a “<strong>Claim</strong>”) in
                                accordance with this Section 14 (sometimes
                                referred to as the “
                                <strong>Arbitration Provision</strong>”).{' '}
                            </p>
                            <p>
                                Claims covered by this Arbitration Provision
                                include, but are not limited to, all claims,
                                disputes, or controversies arising out of or
                                relating to this Agreement, the Terms of
                                Service, any Engagement, payments or agreements,
                                any payments or monies you claim are due to you
                                from Pangea.app or our Affiliates or successors,
                                trade secrets, unfair competition, false
                                advertising, consumer protection, privacy,
                                compensation, classification, minimum wage,
                                seating, expense reimbursement, overtime, breaks
                                and rest periods, termination, discrimination,
                                retaliation or harassment and claims arising
                                under the Defend Trade Secrets Act of 2016,
                                Civil Rights Act of 1964, Rehabilitation Act,
                                Civil Rights Acts of 1866 and 1871, Civil Rights
                                Act of 1991, the Pregnancy Discrimination Act,
                                Americans With Disabilities Act, Age
                                Discrimination in Employment Act, Family Medical
                                Leave Act, Fair Labor Standards Act, Employee
                                Retirement Income Security Act (except for
                                claims for employee benefits under any benefit
                                plan sponsored by the Company and (a) covered by
                                the Employee Retirement Income Security Act of
                                1974 or (b) funded by insurance), Affordable
                                Care Act, Genetic Information Non-Discrimination
                                Act, Uniformed Services Employment and
                                Reemployment Rights Act, Worker Adjustment and
                                Retraining Notification Act, Older Workers
                                Benefits Protection Act of 1990, Occupational
                                Safety and Health Act, Consolidated Omnibus
                                Budget Reconciliation Act of 1985, False Claims
                                Act, state statutes or regulations addressing
                                the same or similar subject matters, and all
                                other federal or state legal claims arising out
                                of or relating to your relationship with
                                Pangea.app or the termination of that
                                relationship.{' '}
                            </p>
                            <p>
                                Disputes between the parties that may not be
                                subject to predispute arbitration agreement as
                                provided by the Dodd-Frank Wall Street Reform
                                and Consumer Protection Act (Public Law 111-203)
                                or as provided by an Act of Congress or lawful,
                                enforceable Executive Order, are excluded from
                                the coverage of this Agreement.{' '}
                            </p>
                            <p>
                                <strong>13.2 CHOICE OF LAW </strong>
                            </p>
                            <p>
                                These Platform Terms of Use, the other Terms of
                                Service, and any Claim will be governed by and
                                construed in accordance with the laws of the
                                State of Delaware, without regard to its
                                conflict of law provisions and excluding the
                                United Nations Convention on Contracts for the
                                International Sale of Goods (CISG); provided,
                                however, that any Claims made by any Pangean
                                located within the United States will be
                                governed by the law of the state in which such
                                Pangean resides. However, notwithstanding the
                                foregoing sentence, this Arbitration Provision
                                is governed by the Federal Arbitration Act (9
                                U.S.C. §§ 1 et seq.).{' '}
                            </p>
                            <p>
                                <strong>
                                    13.3 INFORMAL DISPUTE RESOLUTION{' '}
                                </strong>
                                Before serving a demand for arbitration of a
                                Claim, you and Pangea.app agree to first notify
                                each other of the Claim. You agree to notify
                                Pangea.app of the Claim at Attn: Pangea.app, 225
                                Dyer St. Providence, RI 02903 or by email to
                                adam@pangea.app, and Pangea.app agrees to
                                provide to you a notice at your email address on
                                file (in each case, a “<strong>Notice</strong>
                                ”). You and Pangea.app then will seek informal
                                voluntary resolution of the Claim. Any Notice
                                must include pertinent account information, a
                                brief description of the Claim, and contact
                                information, so that you or Pangea.app, as
                                applicable, may evaluate the Claim and attempt
                                to informally resolve the Claim. Both you and
                                Pangea.app will have 60 days from the date of
                                the receipt of the Notice to informally resolve
                                the other party’s Claim, which, if successful,
                                will avoid the need for further action.{' '}
                            </p>
                            <p>
                                <strong>
                                    13.4 BINDING ARBITRATION AND CLASS
                                    ACTION/JURY TRIAL WAIVER (DOES NOT APPLY TO
                                    USERS LOCATED OUTSIDE THE UNITED STATES AND
                                    ITS TERRITORIES){' '}
                                </strong>
                                This Arbitration Provision applies to all Users
                                except Users located outside of the United
                                States and its territories.{' '}
                            </p>
                            <p>
                                In the unlikely event the parties are unable to
                                resolve a Claim within 60 days of the receipt of
                                the applicable Notice, you, Pangea.app, and our
                                Affiliates agree to resolve the Claim by final
                                and binding arbitration before an arbitrator
                                from JAMS, instead of a court or jury. JAMS may
                                be contacted at www.jamsadr.com.{' '}
                            </p>
                            <p>
                                <strong>
                                    13.4.1. SCOPE OF ARBITRATION AGREEMENT AND
                                    CONDUCT OF ARBITRATION{' '}
                                </strong>
                                This Arbitration Provision applies to any Claim
                                (defined above) the parties may have and
                                survives after your relationship with Pangea.app
                                ends. Claims covered by this Arbitration
                                Provision include, but are not limited to, all
                                claims, disputes or controversies arising out of
                                or relating to the Terms of Service. This
                                Arbitration Provision is intended to apply to
                                the resolution of disputes that otherwise would
                                be resolved in a court of law or before a forum
                                other than arbitration. If for any reason JAMS
                                will not administer the arbitration, either
                                party may apply to a court of competent
                                jurisdiction with authority over the location
                                where the arbitration will be conducted for
                                appointment of a neutral arbitrator.{' '}
                            </p>
                            <p>
                                Except as otherwise provided herein, arbitration
                                will be conducted in Santa Clara County,
                                California in accordance with the JAMS
                                Comprehensive Arbitration Rules and Procedures’
                                Optional Expedited{' '}
                            </p>
                            <p>
                                Arbitration Procedures then in effect.
                                Arbitration of disputes brought by a User that
                                allege a violation of a consumer protection
                                statute also will be subject to the JAMS
                                Consumer Arbitration Minimum Standards, and such
                                arbitrations will be conducted in the same state
                                and within 25 miles of where the User is
                                located. Claims by Pangeans that allege
                                employment or worker classification disputes or
                                will be conducted in the state and within 25
                                miles of where Pangean is located in accordance
                                with the JAMS Employment Arbitration Rules and
                                Procedures then in effect. The applicable JAMS
                                arbitration rules may be found at
                                www.jamsadr.com or by searching online for “JAMS
                                Comprehensive Arbitration Rules and Procedures,”
                                “JAMS Employment Arbitration Rules,” or “JAMS
                                Consumer Arbitration Minimum Standards.” Any
                                dispute regarding the applicability of a
                                particular set of JAMS rules shall be resolved
                                exclusively by the arbitrator. Any party will
                                have the right to appear at the arbitration by
                                telephone and/or video rather than in person.{' '}
                            </p>
                            <p>
                                You and Pangea.app will follow the applicable
                                JAMS rules with respect to arbitration fees. In
                                any arbitration under the JAMS Employment
                                Arbitration Rules and Procedures, the Pangean
                                will pay JAMS arbitration fees only to the
                                extent those fees are no greater than the filing
                                or initial appearance fees applicable to court
                                actions in the jurisdiction where the
                                arbitration will be conducted, with Pangea.app
                                to make up the difference, if any. In any
                                arbitration under the JAMS Comprehensive
                                Arbitration Rules and Procedures’ Optional
                                Expedited Arbitration Procedures then in effect
                                in which a User makes a claim under a consumer
                                protection statute, the User will pay JAMS
                                arbitration fees only to the extent those fees
                                are no greater than the filing or initial
                                appearance fees applicable to court actions in
                                the jurisdiction where the arbitration will be
                                conducted, or $250.00, whichever is less, with
                                Pangea.app to make up the difference, if any.
                                The arbitrator must follow applicable law and
                                may award only those remedies that would have
                                applied had the matter been heard in court.
                                Judgment may be entered on the arbitrator’s
                                decision in any court having jurisdiction.{' '}
                            </p>
                            <p>
                                This Arbitration Provision does not apply to
                                litigation between Pangea.app and you that is or
                                was already pending in a state or federal court
                                or arbitration before the expiration of the
                                opt-out period set forth in Section 13.4.4,
                                below. Notwithstanding any other provision of
                                this Agreement, no amendment to this Arbitration
                                Provision will apply to any matter pending in an
                                arbitration proceeding brought under this
                                Section 14 unless all parties to that
                                arbitration consent in writing to that
                                amendment. This Arbitration Provision also does
                                not apply to claims for workers compensation,
                                state disability insurance or unemployment
                                insurance benefits.{' '}
                            </p>
                            <p>
                                Nothing in this Arbitration Provision prevents
                                you from making a report to or filing a claim or
                                charge with a government agency, including
                                without limitation the Equal Employment
                                Opportunity Commission, U.S. Department of
                                Labor, U.S. Securities and Exchange Commission,
                                National Labor Relations Board, or Office of
                                Federal Contract Compliance Programs. Nothing in
                                this Arbitration Provision prevents the
                                investigation by a government agency of any
                                report, claim or charge otherwise covered by
                                this Arbitration Provision. This Arbitration
                                Provision also does not prevent federal
                                administrative agencies from adjudicating claims
                                and awarding remedies based on those claims,
                                even if the claims would otherwise be covered by
                                this Arbitration Provision. Nothing in this
                                Arbitration Provision prevents or excuses a
                                party{' '}
                            </p>
                            <p>
                                from satisfying any conditions precedent and/or
                                exhausting administrative remedies under
                                applicable law before bringing a claim in
                                arbitration. Pangea.app will not retaliate
                                against you for filing a claim with an
                                administrative agency or for exercising rights
                                (individually or in concert with others) under
                                Section 7 of the National Labor Relations Act.{' '}
                            </p>
                            <p>
                                <strong>
                                    13.4.2. INTERPRETATION AND ENFORCEMENT OF
                                    THIS ARBITRATION PROVISION{' '}
                                </strong>
                                This Arbitration Provision is the full and
                                complete agreement relating to the formal
                                resolution of Claims. Except as otherwise
                                provided in this Arbitration Provision, this
                                Arbitration Provision covers, and the arbitrator
                                shall have exclusive jurisdiction to decide, all
                                disputes arising out of or relating to the
                                interpretation, enforcement, or application of
                                this Arbitration Provision, including the
                                enforceability, revocability, scope, or validity
                                of the Arbitration Provision or any portion of
                                the Arbitration Provision. All such matters
                                shall be decided by an arbitrator and not by a
                                court. The parties expressly agree that the
                                arbitrator and not a court will decide any
                                question of whether the parties agreed to
                                arbitrate, including but not limited to any
                                claim that all or part of this Arbitration
                                Provision, this Agreement, or any other part of
                                the Terms of Service is void or voidable.{' '}
                            </p>
                            <p>
                                In the event any portion of this Arbitration
                                Provision is deemed unenforceable, the remainder
                                of this Arbitration Provision will be
                                enforceable. If any portion of the Class Action
                                Waiver in subsection C, below, of this
                                Arbitration Provision is deemed to be
                                unenforceable, you and Pangea.app agree that
                                this Arbitration Provision will be enforced to
                                the fullest extent permitted by law.{' '}
                            </p>
                            <p>
                                <strong>
                                    13.4.3. CLASS AND COLLECTIVE WAIVER Private
                                    attorney general representative actions
                                    under the California Labor Code are not
                                    arbitrable, not within the scope of this
                                    Arbitration Provision and may be maintained
                                    in a court of law. However, this Arbitration
                                    Provision affects your ability to
                                    participate in class or collective actions.
                                    Both you and Pangea.app agree to bring any
                                    dispute in arbitration on an individual
                                    basis only, and not on a class or collective
                                    basis on behalf of others. There will be no
                                    right or authority for any dispute to be
                                    brought, heard, or arbitrated as a class or
                                    collective action, or as a member in any
                                    such class or collective proceeding (“Class
                                    Action Waiver”). Notwithstanding any other
                                    provision of this Agreement or the JAMS
                                    rules, disputes regarding the
                                    enforceability, revocability, scope, or
                                    validity or breach of the Class Action
                                    Waiver may be resolved only by a civil court
                                    of competent jurisdiction and not by an
                                    arbitrator. In any case in which (1) the
                                    dispute is filed as a class or collective
                                    action and (2) there is a final judicial
                                    determination that all or part of the Class
                                    Action Waiver is unenforceable, the class or
                                    collective action to that extent must be
                                    litigated in a civil court of competent
                                    jurisdiction, but the portion of the Class
                                    Action Waiver that is enforceable shall be
                                    enforced in arbitration. You and Pangea.app
                                    agree that you will not be retaliated
                                    against, disciplined or threatened with
                                    discipline as a result of your filing or
                                    participating in a class or collective
                                    action in any forum. However, Pangea.app may
                                    lawfully seek enforcement of this
                                    Arbitration Provision and the Class Action
                                    Waiver under the Federal Arbitration Act and
                                    seek dismissal of such class or collective
                                    actions or claims.{' '}
                                </strong>
                            </p>
                            <p>
                                <strong>
                                    13.4.4. RIGHT TO OPT OUT OF THE ARBITRATION
                                    PROVISION You may opt out of the Arbitration
                                    Provision contained in this Section 14 by
                                    notifying Pangea.app in writing within 30
                                    days of the date you first registered for
                                    the Platform.{' '}
                                </strong>
                                To opt out, you must send a written notification
                                to Pangea.app at Attn: Legal, Pangea.app 225
                                Dyer St, Providence, RI 02903 that includes (a)
                                your Account username, (b) your name, (c) your
                                address, (d) your telephone number, (e) your
                                email address, and (f) a statement indicating
                                that you wish to opt out of the Arbitration
                                Provision. Alternatively, you may send this
                                written notification to adam@pangea.app. Opting
                                out of this Arbitration Provision will not
                                affect any other terms of this Agreement.{' '}
                            </p>
                            <p>
                                If you do not opt out as provided in this
                                Section 14.4.4, continuing your relationship
                                with Pangea.app constitutes mutual acceptance of
                                the terms of this Arbitration Provision by you
                                and Pangea.app. You have the right to consult
                                with counsel of your choice concerning this
                                Agreement and the Arbitration Provision.{' '}
                            </p>
                            <p>
                                <strong>
                                    13.4.5. Enforcement of this Arbitration
                                    Provision.{' '}
                                </strong>
                                This Arbitration Provision replaces all prior
                                agreements regarding the arbitration of disputes
                                and is the full and complete agreement relating
                                to the formal resolution of disputes covered by
                                this Arbitration Provision. In the event any
                                portion of this Arbitration Provision is deemed
                                unenforceable, the remainder of this Arbitration
                                Provision will be enforceable. If any portion of
                                the Class Action Waiver in Section 14.4.3,
                                above, is deemed to be unenforceable, you and
                                Pangea.app agree that this Arbitration Provision
                                will be enforced to the fullest extent permitted
                                by law.{' '}
                            </p>
                            <p>
                                <strong>14. GENERAL </strong>
                                <em>
                                    Section 14 discusses additional terms of the
                                    agreement between you and Pangea.app,
                                    including that the Terms of Service contain
                                    our full agreement, how the agreement will
                                    be interpreted and applied, and your
                                    agreement not to access the Platform from
                                    certain locations, as detailed below.{' '}
                                </em>
                            </p>
                            <p>
                                <strong>14.1 ENTIRE AGREEMENT </strong>This
                                Agreement, together with the other Terms of
                                Service, sets forth the entire agreement and
                                understanding between you and Pangea.app
                                relating to the subject matter hereof and
                                thereof and cancels and supersedes any prior or
                                contemporaneous discussions, agreements,
                                representations, warranties, and other
                                communications between you and us, written or
                                oral, to the extent they relate in any way to
                                the subject matter hereof and thereof. The
                                section headings in the Terms of Service are
                                included for ease of reference only and have no
                                binding effect. Even though Pangea.app drafted
                                the Terms of Service, you represent that you had
                                ample time to review and decide whether to agree
                                to the Terms of Service. If an ambiguity or
                                question of intent or interpretation of the
                                Terms of Service arises, no presumption or
                                burden of proof will arise favoring or
                                disfavoring you or Pangea.app because of the
                                authorship of any provision of the Terms of
                                Service.{' '}
                            </p>
                            <p>
                                <strong>14.2 MODIFICATIONS; WAIVER </strong>
                            </p>
                            <p>
                                No modification or amendment to the Terms of
                                Service will be binding upon Pangea.app unless
                                in a written instrument signed by a duly
                                authorized representative of Pangea.app or
                                posted on the Platform by Pangea.app. Our
                                failure to act with respect to a breach by you
                                or others does not waive our right to act with
                                respect to subsequent or similar breaches. We do
                                not guarantee we will take action against all
                                breaches of this User Agreement.{' '}
                            </p>
                            <p>
                                <strong>14.3 ASSIGNABILITY </strong>User may not
                                assign the Terms of Service, or any of its
                                rights or obligations hereunder, without
                                Pangea.app’s prior written consent in the form
                                of a written instrument signed by a duly
                                authorized representative of Pangea.app.
                                Pangea.app may freely assign this Agreement and
                                the other Terms of Service without User’s
                                consent. Any attempted assignment or transfer in
                                violation of this subsection will be null and
                                void. Subject to the foregoing restrictions, the
                                Terms of Service are binding upon and will inure
                                to the benefit of the successors, heirs, and
                                permitted assigns of the parties.{' '}
                            </p>
                            <p>
                                <strong>14.4 SEVERABILITY </strong>If and to the
                                extent any provision of this Agreement or the
                                other Terms of Service is held illegal, invalid,
                                or unenforceable in whole or in part under
                                applicable law, such provision or such portion
                                thereof will be ineffective as to the
                                jurisdiction in which it is illegal, invalid, or
                                unenforceable to the extent of its illegality,
                                invalidity, or unenforceability and will be
                                deemed modified to the extent necessary to
                                conform to applicable law so as to give the
                                maximum effect to the intent of the parties. The
                                illegality, invalidity, or unenforceability of
                                such provision in that jurisdiction will not in
                                any way affect the legality, validity, or
                                enforceability of such provision in any other
                                jurisdiction or of any other provision in any
                                jurisdiction.{' '}
                            </p>
                            <p>
                                <strong>14.5 FORCE MAJEURE </strong>The parties
                                to this Agreement will not be responsible for
                                the failure to perform or any delay in
                                performance of any obligation hereunder for a
                                reasonable period due to labor disturbances,
                                accidents, fires, floods, telecommunications or
                                Internet failures, strikes, wars, riots,
                                rebellions, blockades, acts of government,
                                governmental requirements, and regulations or
                                restrictions imposed by law or any other similar
                                conditions beyond the reasonable control of such
                                party.{' '}
                            </p>
                            <p>
                                <strong>
                                    14.6 PREVAILING LANGUAGE AND LOCATION{' '}
                                </strong>
                                The English language version of the Terms of
                                Service will be controlling in all respects and
                                will prevail in case of any inconsistencies with
                                translated versions, if any. The Platform is
                                controlled and operated from our facilities in
                                the United States.{' '}
                            </p>
                            <p>
                                <strong>
                                    14.7 ACCESS OF THE PLATFORM OUTSIDE THE
                                    UNITED STATES{' '}
                                </strong>
                                Pangea.app makes no representations that the
                                Platform is appropriate or available for use
                                outside of the United States. Those who access
                                or use the Platform from other jurisdictions do
                                so at their own risk and are entirely
                                responsible for compliance with all applicable
                                foreign, United States, state, and local laws
                                and regulations, including, but not limited to,
                                export and import regulations, including the
                                Export Administration{' '}
                            </p>
                            <p>
                                Regulations maintained by the United States
                                Department of Commerce and the sanctions
                                programs maintained by the Department of the
                                Treasury Office of Foreign Assets Control. You
                                must not directly or indirectly sell, export,
                                re-export, transfer, divert, or otherwise
                                dispose of any software or service to any end
                                user without obtaining any and all required
                                authorizations from the appropriate government
                                authorities. You also warrant that you are not
                                prohibited from receiving U.S. origin products,
                                including services or software.{' '}
                            </p>
                            <p>
                                In order to access or use the Platform, you must
                                and hereby represent that you are not: (a) a
                                citizen or resident of a geographic area in
                                which access to or use of the Platform is
                                prohibited by applicable law, decree,
                                regulation, treaty, or administrative act; (b) a
                                citizen or resident of, or located in, a
                                geographic area that is subject to U.S. or other
                                sovereign country sanctions or embargoes; or (c)
                                an individual, or an individual employed by or
                                associated with an entity, identified on the
                                U.S. Department of Commerce Denied Persons or
                                Entity List, the U.S. Department of Treasury
                                Specially Designated Nationals or Blocked
                                Persons Lists, or the U.S. Department of State
                                Debarred Parties List or otherwise ineligible to
                                receive items subject to U.S. export control
                                laws and regulations or other economic sanction
                                rules of any sovereign nation. You agree that if
                                your country of residence or other circumstances
                                change such that the above representations are
                                no longer accurate, that you will immediately
                                cease using the Platform and your license to use
                                the Platform will be immediately revoked.{' '}
                            </p>
                            <p>
                                <strong>
                                    14.8 CONSENT TO USE ELECTRONIC RECORDS{' '}
                                </strong>
                                In connection with the Platform Terms of Use,
                                you may be entitled to receive certain records
                                from Pangea.app or our Affiliates, such as
                                contracts, notices, and communications, in
                                writing. To facilitate your use of the Platform
                                and the Platform Services, you give us
                                permission to provide these records to you
                                electronically instead of in paper form.{' '}
                            </p>
                            <p>
                                <strong>15. DEFINITIONS </strong>
                                <em>
                                    Section 15 gives you some definitions of
                                    capitalized terms that appear in the Terms
                                    of Service but other capitalized terms are
                                    defined above, which you can tell because
                                    the term is put in quotation marks and bold
                                    font.{' '}
                                </em>
                            </p>
                            <p>
                                Capitalized terms not defined below or above
                                have the meanings described in the Platform
                                Terms of Use or elsewhere in the Terms of
                                Service.{' '}
                            </p>
                            <p>
                                “<strong>Background Technology</strong>” means
                                all Inventions developed by Pangean other than
                                in the course of providing Pangean Services to
                                Company under the Engagement and all Inventions
                                that Pangean incorporates into Work Product.{' '}
                            </p>
                            <p>
                                “<strong>Company Materials</strong>” means
                                requests, intellectual property, and any other
                                information or materials that Company provides
                                to Pangean for Pangean to perform Pangean
                                Services.{' '}
                            </p>
                            <p>
                                “<strong>Confidential Information</strong>”
                                means any material or information provided to,
                                or created by, a User to evaluate a Project or
                                the suitability of another User for the Project,
                                regardless of whether the information is in
                                tangible, electronic, verbal, graphic, visual,
                                or other form. Confidential Information does not
                                include material or information that is known to
                                the public or that: (a) is generally known by
                                third parties as a result of no act or omission
                                of Pangean or Company; (b) subsequent to
                                disclosure hereunder, was lawfully received
                                without restriction on disclosure from a third
                                party having the right to disseminate the
                                information; (c) was already known by User prior
                                to receiving it from the other party and was not
                                received from a third party in breach of that
                                third party’s obligations of confidentiality; or
                                (d) was independently developed by User without
                                use of another person’s Confidential
                                Information.{' '}
                            </p>
                            <p>
                                “<strong>Fixed-Price Contract</strong>” meansan
                                engagement for which Company is charged a fixed
                                fee agreed between a Company and a Pangean,
                                prior to the commencement ofan engagement, for
                                the completion of all Pangean Services
                                contracted by Company for such Engagement.{' '}
                            </p>
                            <p>
                                “<strong>Pangean Services</strong>” means all
                                services performed for or delivered to Companys
                                by Pangeans.{' '}
                            </p>
                            <p>
                                “<strong>Pangean Fees</strong>” means: (a) for
                                an Hourly Contract, the amount reflected in the
                                Hourly Invoice (the number of hours invoiced by
                                Pangean, multiplied by the hourly rate charged
                                by Pangean); (b) for a Fixed- Price Contract,
                                the fixed fee agreed between a Company and a
                                Pangean; and (c) any bonuses or other payments
                                made by a Company to a Pangean.{' '}
                            </p>
                            <p>
                                “<strong>Hourly Contract</strong>” meansan
                                engagement for which Company is charged based on
                                the hourly rate charged by Pangean.{' '}
                            </p>
                            <p>
                                “<strong>Hourly Invoice</strong>” means the
                                report of hours invoiced for a stated period by
                                a Pangean for Pangean Services performed for a
                                Company.{' '}
                            </p>
                            <p>
                                The term “<strong>including</strong>” as used
                                herein means including without limitation.{' '}
                            </p>
                            <p>
                                “<strong>Intellectual Property Rights</strong>”
                                means all patent rights, copyright rights, mask
                                work rights, moral rights, rights of publicity,
                                trademark, trade dress and service mark rights,
                                goodwill, trade secret rights and other
                                intellectual property rights as may now exist or
                                hereafter come into existence, and all
                                applications therefore and registrations,
                                renewals and extensions thereof, under the laws
                                of any state, country, territory, or other
                                jurisdiction.{' '}
                            </p>
                            <p>
                                “<strong>Payment Method</strong>” means a valid
                                credit card issued by a bank acceptable to
                                Pangea.app, a bank account linked to your
                                Account, a Stripe account, a debit card, or such
                                other method of payment as Pangea.app may accept
                                from time to time in our sole discretion.{' '}
                            </p>
                            <p>
                                “<strong>Project</strong>” means an engagement
                                for Pangean Services that a Pangean provides to
                                a Company underan engagement on the Platform.{' '}
                            </p>
                            <p>
                                “<strong>Engagement</strong>” means, as
                                applicable, (a) the contractual provisions
                                between a Company and a Pangean governing the
                                Pangean Services to be performed by a Pangean
                                for Company for a Project; or{' '}
                            </p>
                            <p>
                                “<strong>Substantial Change</strong>” means a
                                change to the terms of the Terms of Service that
                                reduces your rights or increases your
                                responsibilities.{' '}
                            </p>
                            <p>
                                “<strong>Work Product</strong>” means any
                                tangible or intangible results or deliverables
                                that Pangean agrees to create for, or actually
                                delivers to, Company as a result of performing
                                the Pangean Services, including, but not limited
                                to, configurations, computer programs, or other
                                information, or customized hardware, and any
                                intellectual property developed in connection
                                therewith.{' '}
                            </p>
                            <p>
                                <br />
                                <br />
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default TermsOfServiceScreen;
