import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Typography,
    TextField,
    Button,
    FormControl,
    FormHelperText,
    CircularProgress,
    Link,
} from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import { addAccountInfo, createAccount } from '../../../ducks/OnboardingDuck';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import GlobalStyles from '../../../constants/GlobalStyles';
import OnboardingStyles from '../../../constants/OnboardingStyles';
import { withStyles } from '@material-ui/core/styles';

import Colors from '../../../constants/Colors';

const globalStyles = GlobalStyles;

// props:
const styles = theme => ({
    listItem: {},
    ...globalStyles,
    ...OnboardingStyles,
});

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/

class AddAccountInfoScreen extends Component {
    _onCreateAccount = () => {
        if (!this._areInputsValid()) {
            return;
        }

        const {
            phoneNumber,
            userType,
            firstName,
            lastName,
            email,
            password,
        } = this.props;

        this.props.createAccount({
            userInfo: {
                phoneNumber,
                userType,
                firstName,
                lastName,
                email,
                password,
            },
        });
    };

    _areInputsValid = () => {
        // password has to be at least 6 characters long
        return (
            this.props.email !== '' &&
            this.props.password !== '' &&
            this.props.password.length >= 6 &&
            this.props.phoneNumber &&
            this.props.userType &&
            this.props.firstName &&
            this.props.lastName
        );
    };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography gutterBottom>Ok {this.props.firstName},</Typography>
                <Typography style={OnboardingStyles.titleText}>
                    Please enter your account info
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <form
                onSubmit={event => {
                    event.preventDefault();
                    this._onCreateAccount();
                }}
                loading={this.props.loading}
            >
                <TextField
                    type="email"
                    name="email"
                    id="email"
                    variant="outlined"
                    label={
                        this.props.userType === 'student'
                            ? 'School Email'
                            : 'Company Email'
                    }
                    autoFocus={true}
                    value={this.props.email}
                    fullWidth
                    onChange={event => {
                        this.props.addAccountInfo({
                            accountInfo: {
                                email: event.target.value,
                                password: this.props.password,
                            },
                        });
                    }}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
                <div style={{ height: 20 }} />
                <TextField
                    type="password"
                    name="password"
                    id="password"
                    variant="outlined"
                    label="Password"
                    value={this.props.password}
                    fullWidth
                    onChange={event => {
                        this.props.addAccountInfo({
                            accountInfo: {
                                email: this.props.email,
                                password: event.target.value,
                            },
                        });
                    }}
                />

                <div style={{ height: 10 }} />
                <Typography variant="caption">
                    By signing up you agree to the Pangea.app{' '}
                    <Link
                        color="inherit"
                        href="/termsOfService"
                        style={{
                            color: Colors.fontBlack,
                            fontWeight: 700,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </Link>{' '}
                    and{' '}
                    <Link
                        color="inherit"
                        href="/privacyPolicy"
                        style={{
                            color: Colors.fontBlack,
                            fontWeight: 700,
                        }}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </Link>
                    .
                </Typography>

                {this.props.error && (
                    <FormControl error={!!this.props.error} fullWidth>
                        <div style={{ height: 10 }} />
                        <FormHelperText>{this.props.error}</FormHelperText>
                    </FormControl>
                )}

                <div style={{ height: 10 }} />
                <div style={{ position: 'relative' }}>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={
                                !this._areInputsValid() || this.props.loading
                            }
                            style={globalStyles.button}
                        >
                            Continue
                            {this.props.loading && (
                                <CircularProgress
                                    size={24}
                                    style={globalStyles.buttonProgress}
                                />
                            )}
                        </Button>
                    </div>
                </div>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader hasHomeLink />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const {
        phoneNumber,
        personalInfo,
        accountInfo,
        loading,
        error,
        userType,
    } = onboarding;

    return {
        phoneNumber: phoneNumber ? phoneNumber : '',
        firstName: personalInfo ? personalInfo.firstName : '',
        lastName: personalInfo ? personalInfo.lastName : '',
        userType: userType ? userType : '',
        email: accountInfo ? accountInfo.email : '',
        password: accountInfo ? accountInfo.password : '',
        loading,
        error,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        addAccountInfo,
        createAccount,
    })(AddAccountInfoScreen)
);
