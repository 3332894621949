const tintColor = '#2f95dc';

export default {
    tintColor,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColor,
    tabBar: '#fefefe',
    errorBackground: 'red',
    errorText: '#fff',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: tintColor,
    noticeText: '#fff',
    ratingColor: '#ff7f5b',
    white: '#fff',
    black: '#000',
    instagramGray: '#F5F5F5',
    ratingOrange: '#ff7f5b',
    ratingCountGray: '#717171',
    pangeaBlue: '#006C9E', // '#006C9E', //'#76abc0', // '#73aabf' or '#76abc0', or '#66ACC3', NEW BLUE '#3896A3', 007597 - mayan treasure
    pangeaGreen: '#17A279', // '#009e32', // '#7CC688', // '#5BC681' or '#7DC788'
    pangeaLightBlue: '#99DCFA',
    pangeaPickerBlue: '#7BAEC3',
    pangeaLightGreen: '#ceece2',
    pangeaDarkGreen: '#76c48f',
    pangeaGrey: '#b2b2b2',
    pangeaRed: 'rgb(237, 24, 73)',
    pangeaOrange: '#F5A623',
    placeholderText: '#F5F5F580',
    dividerGray: '#979797',
    popoverDividerGray: '#f2f2f2',
    backgroundGray: 'rgba(242, 242, 242, 1.0)',
    mainFeedBackgroundColor: '#f9f9f9', // 'rgba(88, 110, 124, 0.09)'
    fontBlue: '#586e7c',
    fontLightGray: '#9B9B9B',
    fontGray: '#565656',
    fontDarkGray: '#4a4a4a',
    fontBlack: '#1D252D',
    red: '#cc004b',
    transparentOffWhite: 'rgba(88, 110, 124, 0.00)',
    transparentTint: 'rgba(0, 0, 0, 0.3)',
    transparent: '#00000000',
    lightGray: '#F2F2F7',
    searchGray: '#D5D5D5',
    darkGray: '#2F2F2F',
    shadow: 'rgba(0, 0, 0, 0.2)',
    darkShadow: 'rgba(0, 0, 0, 0.6)',
    subscriptionStartup: '#678841',
    subscriptionPro: '#BDB00D',
    subscriptionEnterprise: '#6D7392',
    offWhite: '#E0E0E0',
    disabledTextGray: '#8E8E93',
    iconGray: '8E8E93',
};
