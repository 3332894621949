// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { NavigationActions } from 'react-navigation';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SAVE_CLICKED_COMPANY: 'save_clicked_company',
    SAVE_CLICKED_COMPANY_SUCCESS: 'save_clicked_company_success',
    SAVE_CLICKED_COMPANY_FAIL: 'save_clicked_company_fail',
    UPDATE_COMPANY: 'update_company',
    UPDATE_COMPANY_SUCCESS: 'update_company_success',
    UPDATE_COMPANY_FAIL: 'update_company_fail',
    UPDATE_EDIT_COMPANY_INFO: 'update_edit_company_info',
    UPLOAD_COMPANY_LOGO_PIC: 'upload_company_logo_pic',
    UPLOAD_COMPANY_LOGO_PIC_SUCCESS: 'upload_company_logo_pic_success',
    UPLOAD_COMPANY_LOGO_PIC_FAIL: 'upload_company_logo_pic_fail',
    FETCH_COMPANY_ACTIVE_POSTS: 'fetch_company_active_posts',
    FETCH_COMPANY_ACTIVE_POSTS_SUCCESS: 'fetch_company_active_posts_success',
    FETCH_COMPANY_ACTIVE_POSTS_FAIL: 'fetch_company_active_posts_fail',
    UPDATE_COMPANY_ADDRESS: 'UPDATE_COMPANY_ADDRESS',
    UPDATE_COMPANY_GEO: 'UPDATE_COMPANY_GEO',
    UPDATE_COMPANY_GEO_SUCCESS: 'UPDATE_COMPANY_GEO_SUCCESS',
    UPDATE_COMPANY_GEO_FAIL: 'UPDATE_COMPANY_GEO_FAIL',
    COMPANY_TYPEAHEAD: 'COMPANY_TYPEAHEAD',
    COMPANY_TYPEAHEAD_SUCCESS: 'COMPANY_TYPEAHEAD_SUCCESS',
    COMPANY_TYPEAHEAD_FAIL: 'COMPANY_TYPEAHEAD_FAIL',
    COMPANY_RESET_TYPEAHEAD: 'COMPANY_RESET_TYPEAHEAD',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    loadingActivePosts: false,
    error: '',
    clickedCompany: null,
    editCompanyInfo: {
        companyName: null,
        aboutUs: null,
        numEmployees: null,
    },
    logoURL: null,
    clickedCompanyActivePosts: null,
    companyAddress: null,
    updatedGeo: null,
    typeaheadCompanies: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SAVE_CLICKED_COMPANY:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_COMPANY_SUCCESS:
            return { ...state, loading: false, clickedCompany: action.payload };
        case types.SAVE_CLICKED_COMPANY_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.UPDATE_COMPANY:
            return { ...state, loading: true };
        case types.UPDATE_COMPANY_SUCCESS:
            return { ...state, loading: false, clickedCompany: action.payload };
        case types.UPDATE_COMPANY_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.UPDATE_EDIT_COMPANY_INFO:
            return {
                ...state,
                loading: false,
                editCompanyInfo: action.payload,
            };
        case types.UPLOAD_COMPANY_LOGO_PIC:
            return { ...state, loading: true };
        case types.UPLOAD_COMPANY_LOGO_PIC_SUCCESS:
            return { ...state, loading: false, logoURL: action.payload };
        case types.UPLOAD_COMPANY_LOGO_PIC_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.FETCH_COMPANY_ACTIVE_POSTS:
            return { ...state, loadingActivePosts: true };
        case types.FETCH_COMPANY_ACTIVE_POSTS_SUCCESS:
            return {
                ...state,
                loadingActivePosts: false,
                clickedCompanyActivePosts: action.payload,
            };
        case types.FETCH_COMPANY_ACTIVE_POSTS_FAIL:
            return {
                ...state,
                loadingActivePosts: false,
                error: action.payload,
            };
        case types.UPDATE_COMPANY_ADDRESS:
            return { ...state, companyAddress: action.payload };
        case types.UPDATE_COMPANY_GEO:
            return { ...state, loading: true };
        case types.UPDATE_COMPANY_GEO_SUCCESS:
            return { ...state, loading: false, updatedGeo: action.payload };
        case types.UPDATE_COMPANY_GEO_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.COMPANY_TYPEAHEAD:
            return { ...state, loading: true };
        case types.COMPANY_TYPEAHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                typeaheadCompanies: action.payload,
            };
        case types.COMPANY_TYPEAHEAD_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.COMPANY_RESET_TYPEAHEAD:
            return { ...state, typeaheadCompanies: [] };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const saveClickedCompany = ({ company_id }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_COMPANY });

        WS.socket.emit('company:embed', { company_id });
        WS.socket.once('company:embed', res => {
            if (res.success) {
                // AmplitudeAnalytics.track('Save Clicked Company Success', res);
                dispatch(fetchCompanyActivePosts({ company_id }));
                dispatch({
                    type: types.SAVE_CLICKED_COMPANY_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.SAVE_CLICKED_COMPANY_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const updateCompany = ({ company }) => {
    return dispatch => {
        dispatch({ type: types.UPDATE_COMPANY });

        WS.socket.emit('company:update', { company });
        WS.socket.once('company:update', res => {
            if (res.success) {
                // AmplitudeAnalytics.track('Update Company Success', res);
                dispatch({
                    type: types.UPDATE_COMPANY_SUCCESS,
                    payload: res.data,
                });
                dispatch(saveClickedCompany({ company_id: company.id }));
                // dispatch(NavigationActions.back());
            } else {
                dispatch({
                    type: types.UPDATE_COMPANY_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const updateEditCompanyInfo = companyInfo => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_EDIT_COMPANY_INFO,
            payload: companyInfo,
        });
    };
};

export const uploadCompanyLogoPic = (newCompany, image, logo_pic) => {
    return dispatch => {
        dispatch({
            type: types.UPLOAD_COMPANY_LOGO_PIC,
            payload: { newCompany, image, logo_pic },
        });
        uploadLogoPic(dispatch, newCompany, image, logo_pic);
    };
};

const uploadLogoPic = (dispatch, company, imageUri, photoName) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;
    const uri = imageUri;
    const uriParts = uri.split('.');
    const fileType = uriParts[uriParts.length - 1];
    const formData = new FormData();
    formData.append('image', {
        uri,
        type: `image/${fileType}`,
        name: photoName,
        originalname: photoName,
    });

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'file-path': 'Companies/' + company.id + '/logoPic/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            // AmplitudeAnalytics.track('Company Update Logo Success', {
            //     'image:url': results.imageUrl,
            // });

            dispatch({
                type: types.UPLOAD_COMPANY_LOGO_PIC_SUCCESS,
                payload: results.imageUrl,
            });
        })
        .catch(error => {
            dispatch({
                type: types.UPLOAD_COMPANY_LOGO_PIC_FAIL,
                payload: error,
            });
        });
};

export const fetchCompanyActivePosts = ({ company_id }) => {
    return dispatch => {
        dispatch({ type: types.FETCH_COMPANY_ACTIVE_POSTS });

        WS.socket.emit('company:fetchActivePosts', { company_id });
        WS.socket.once('company:fetchActivePosts', res => {
            if (res.success) {
                // AmplitudeAnalytics.track(
                //     'Fetch Company Active Posts Success',
                //     res
                // );
                dispatch({
                    type: types.FETCH_COMPANY_ACTIVE_POSTS_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.FETCH_COMPANY_ACTIVE_POSTS_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const updateCompanyAddress = ({ companyAddress }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_COMPANY_ADDRESS,
            payload: companyAddress,
        });
    };
};

export const updateCompanyGeo = ({ company, geo_id, address }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_COMPANY_GEO,
        });

        WS.socket.emit('geo:update', {
            geo: {
                id: geo_id,
                address,
            },
        });
        WS.socket.once('geo:update', res => {
            if (res.success) {
                updateCompanyGeoSuccess(dispatch, res.data, company);
            } else {
                updateCompanyGeoFail(dispatch, res.message);
            }
        });
    };
};

const updateCompanyGeoSuccess = (dispatch, res, company) => {
    dispatch({
        type: types.UPDATE_COMPANY_GEO_SUCCESS,
        payload: res,
    });

    // in case anything has changed on the user object
    // note: geo_id hasn't changed because the geo is reused
    dispatch(
        updateCompany({
            company,
        })
    );
};

const updateCompanyGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateCompanyGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_COMPANY_GEO_FAIL,
        payload: msg,
    });
};

export const companiesTypeahead = ({ text, size }) => {
    return dispatch => {
        dispatch({ type: types.COMPANY_TYPEAHEAD });

        WS.socket.emit('companies:search:typeahead', {
            text,
            size,
        });

        WS.socket.once('companies:search:typeahead', res => {
            if (res.success) {
                companiesTypeaheadSuccess(dispatch, res.data);
            } else {
                companiesTypeaheadFail(dispatch, res.message);
            }
        });
    };
};

const companiesTypeaheadSuccess = (dispatch, res) => {
    dispatch({ type: types.COMPANY_TYPEAHEAD_SUCCESS, payload: res });
};
const companiesTypeaheadFail = (dispatch, msg) => {
    dispatch({ type: types.COMPANY_TYPEAHEAD_FAIL, payload: msg });
};

export const resetTypeaheadCompanies = () => {
    return dispatch => {
        dispatch({ type: types.COMPANY_RESET_TYPEAHEAD });
    };
};
