import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Footer } from '../common';

import { Grid } from '@material-ui/core';

import {
    ProfileMainCard,
    ProfileSkillsAndRatingsCard,
    ProfileExperiencesCard,
    ProfileReferralsCard,
    ProfileAboutCard,
    ProfileAdCard,
    ProfileSuggestionsCard,
    CustomContainer,
} from '../common';

import { saveClickedCompany } from '../../ducks/CompanyDuck';

import { store } from '../../';
import { push } from 'connected-react-router';

const styles = {
    rootContainer: {
        // paddingLeft: 42,
        // paddingRight: 42,
        minHeight: 'calc(100vh - 375px)',
        paddingBottom: 40,
        backgroundColor: '#f2f2f7',
    },
};

class MyProfileScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {}

    render() {
        return (
            <div style={{ backgroundColor: '#f2f2f7', overflow: 'hidden' }}>
                <Header hasSearch user={this.props.user} />

                <CustomContainer
                    style={{ paddingLeft: 150, paddingRight: 150 }}
                >
                    <div style={styles.rootContainer}>
                        <Grid container spacing={2}>
                            <Grid item md={8} sm={12}>
                                <ProfileMainCard
                                    type={'my_profile'}
                                    user={this.props.user}
                                />
                                {this.props.user &&
                                this.props.user.user_type === 'student' ? (
                                    <div>
                                        <ProfileSkillsAndRatingsCard
                                            type="student"
                                            user={this.props.user}
                                        />
{/*
                                        <ProfileExperiencesCard
                                            user={this.props.user}
                                        /> */}

                                        {/* <ProfileReferralsCard
                                            user={this.props.user}
                                        /> */}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </Grid>

                            <Grid item md={4} sm={12}>
                                {this.props.user &&
                                this.props.user.user_type === 'student' ? (
                                    <div>
                                        <ProfileAboutCard
                                            type="school"
                                            school={this.props.user.school}
                                            user={this.props.user}
                                        />

                                        <ProfileAdCard />

                                        <ProfileSuggestionsCard />
                                    </div>
                                ) : this.props.user.company ? (
                                    <div>
                                        <ProfileAboutCard
                                            type="company"
                                            company={
                                                this.props.user
                                                    ? this.props.user.company
                                                    : null
                                            }
                                            user={this.props.user}
                                            onClick={() => {
                                                this.props.saveClickedCompany({
                                                    company_id: this.props.user
                                                        .company.id,
                                                });
                                                store.dispatch(
                                                    push('/myCompany')
                                                );
                                            }}
                                        />
                                        <ProfileAdCard />
                                    </div>
                                ) : null}
                            </Grid>
                        </Grid>
                    </div>
                </CustomContainer>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, { saveClickedCompany })(
    MyProfileScreen
);
