import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    FormControl,
    Select,
    MenuItem,
    Checkbox,
    Typography,
    TextField,
} from '@material-ui/core';

import {
    SkillAutocompleteView,
    OnboardingHeader,
    FooterShort,
    CustomContainer,
    CustomInputBase,
    CustomInputLabel,
} from '../../common';

import OnboardingContainerView from './OnboardingContainerView';
import { addStudentSkills } from '../../../ducks/OnboardingDuck';
import { updateUser } from '../../../ducks/UserSessionDuck';
import { createProposedSkill } from '../../../ducks/ProposedSkillDuck';
import Colors from '../../../constants/Colors';

import { Form, Field } from 'react-final-form';

import { push } from 'connected-react-router';
import { store } from '../../..';

const styles = {
    titleContainer: {
        height: 400,
    },

    textInput: {
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 20,
        flex: 1,
    },

    submitButton: {
        borderRadius: 20,
        backgroundColor: '#006c9e',
        color: '#fff',
        paddingTop: 9,
        paddingBottom: 9,
        paddingRight: 20,
        paddingLeft: 20,
    },
};

const baseHourlyWageOptions = [
    {
        key: '11',
        text: '$11',
        value: '11',
    },
    {
        key: '12',
        text: '$12',
        value: '12',
    },
    {
        key: '13',
        text: '$13',
        value: '13',
    },
    {
        key: '14',
        text: '$14',
        value: '14',
    },
    {
        key: '15',
        text: '$15',
        value: '15',
    },
    {
        key: '16',
        text: '$16',
        value: '16',
    },
    {
        key: '17',
        text: '$17',
        value: '17',
    },
    {
        key: '18',
        text: '$18',
        value: '18',
    },
    {
        key: '19',
        text: '$19',
        value: '19',
    },
    {
        key: '20',
        text: '$20',
        value: '20',
    },
    {
        key: '21',
        text: '$21',
        value: '21',
    },
    {
        key: '22',
        text: '$22',
        value: '22',
    },
    {
        key: '23',
        text: '$23',
        value: '23',
    },
    {
        key: '24',
        text: '$24',
        value: '24',
    },
    {
        key: '25',
        text: '$25',
        value: '25',
    },
    {
        key: '26',
        text: '$26',
        value: '26',
    },
    {
        key: '27',
        text: '$27',
        value: '27',
    },
    {
        key: '28',
        text: '$28',
        value: '28',
    },
    {
        key: '29',
        text: '$29',
        value: '29',
    },
    {
        key: '30',
        text: '$30',
        value: '30',
    },
    {
        key: '31',
        text: '$31',
        value: '31',
    },
    {
        key: '32',
        text: '$32',
        value: '32',
    },
    {
        key: '33',
        text: '$33',
        value: '33',
    },
    {
        key: '34',
        text: '$34',
        value: '34',
    },
    {
        key: '35',
        text: '$35',
        value: '35',
    },
    {
        key: '40',
        text: '$40',
        value: '40',
    },
    {
        key: '45',
        text: '$45',
        value: '45',
    },
    {
        key: '50',
        text: '$50',
        value: '50',
    },
    {
        key: '55',
        text: '$55',
        value: '55',
    },
    {
        key: '60',
        text: '$60',
        value: '60',
    },
    {
        key: '65',
        text: '$65',
        value: '65',
    },
    {
        key: '70',
        text: '$70',
        value: '70',
    },
    {
        key: '75',
        text: '$75',
        value: '75',
    },
    {
        key: '80',
        text: '$80',
        value: '80',
    },
    {
        key: '90',
        text: '$90',
        value: '90',
    },
    {
        key: '100',
        text: '$100',
        value: '100',
    },
    {
        key: '110',
        text: '$110',
        value: '110',
    },
    {
        key: '120',
        text: '$120',
        value: '120',
    },
    {
        key: '130',
        text: '$130',
        value: '130',
    },
    {
        key: '140',
        text: '$140',
        value: '140',
    },
    {
        key: '150',
        text: '$150',
        value: '150',
    },
    {
        key: '160',
        text: '$160',
        value: '160',
    },
    {
        key: '170',
        text: '$170',
        value: '170',
    },
    {
        key: '180',
        text: '$180',
        value: '180',
    },
    {
        key: '190',
        text: '$190',
        value: '190',
    },
    {
        key: '200',
        text: '$200',
        value: '200',
    },
    {
        key: '210',
        text: '$210',
        value: '210',
    },
    {
        key: '220',
        text: '$220',
        value: '220',
    },
    {
        key: '230',
        text: '$230',
        value: '230',
    },
    {
        key: '240',
        text: '$240',
        value: '240',
    },
    {
        key: '250',
        text: '$250',
        value: '250',
    },
    {
        key: '260',
        text: '$260',
        value: '260',
    },
    {
        key: '270',
        text: '$270',
        value: '270',
    },
    {
        key: '280',
        text: '$280',
        value: '280',
    },
    {
        key: '290',
        text: '$290',
        value: '290',
    },
    {
        key: '300',
        text: '$300',
        value: '300',
    },
];
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class StudentAddProfessionalInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oneTimeSelected: false,
            internshipSelected: false,
            fullTimeSelected: false,
        };
    }

    renderLeftColumn = () => {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Alright{' '}
                    {this.props.user ? this.props.user.first_name : 'friend'},
                    help us help you!
                </Typography>
                <Typography variant="h2">
                    We’ll use your skills and job interests to match you to
                    potential employers.
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <Form
                onSubmit={values => {
                    console.log('onSubmit');

                    let updatedUser = {
                        id: this.props.user.id,
                        has_reonboarded: true,
                    };

                    // skills
                    if (
                        this.props.proposedSkills &&
                        this.props.proposedSkills.length > 0
                    ) {
                        // create proposed skills
                        for (
                            let i = 0;
                            i < this.props.proposedSkills.length;
                            i++
                        ) {
                            this.props.createProposedSkill({
                                skillName: this.props.proposedSkills[i].name,
                                user: this.props.user,
                                type: 'user',
                                content_id: this.props.user.id,
                            });
                        }
                    }
                    updatedUser.skills = this.props.selectedSkills;
                    updatedUser.skill_ids = this.props.selectedSkills.map(
                        function(skill) {
                            return skill['id'];
                        }
                    );

                    // website
                    if (!!values.website) {
                        updatedUser.website_url = values.website;
                    }
                    updatedUser.has_reonboarded = true;

                    // basewage
                    if (values.isBaseHourlyRateSkipped) {
                        updatedUser.base_hourly_wage = null;
                    } else {
                        updatedUser.base_hourly_wage = parseInt(
                            values.baseHourlyRate
                        );
                    }

                    // opportunity_interests
                    updatedUser.opportunity_interests = {
                        one_time: !!values.one_time,
                        internship: !!values.internship,
                        full_time: !!values.full_time,
                    };

                    // stayConnected
                    if (values.isEmailNotificationEnabled) {
                        updatedUser.email_notification_post_enabled = true;
                    } else {
                        updatedUser.email_notification_post_enabled = false;
                    }

                    console.log('updatedUser');
                    console.log(updatedUser);

                    this.props.updateUser({ user: updatedUser });
                    store.dispatch(push('/studentCompleteSignUp'));
                }}
                validate={values => {
                    const errors = {};

                    if (this.props.selectedSkills.length < 3) {
                        errors.skills = 'Please select at least 3 skills';
                    }

                    if (
                        !values.isBaseHourlyRateSkipped &&
                        !values.baseHourlyRate
                    ) {
                        errors.baseHourlyRate = 'Required';
                    }

                    if (values.website) {
                        const isValid = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}$|^$/.test(
                            values.website
                        );

                        if (!isValid) {
                            errors.website = 'Enter a Valid Website';
                        }
                    }

                    if (
                        !values.one_time &&
                        !values.internship &&
                        !values.full_time
                    ) {
                        errors.opportunityTypes = 'Required';
                    }

                    return errors;
                }}
                initialValues={{
                    isEmailNotificationEnabled: true,
                }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        error={!!this.props.error}
                        loading={this.props.loading}
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 170px)',
                        }}
                    >
                        <AddSkills
                            selectedSkills={this.props.selectedSkills}
                            proposedSkills={this.props.proposedSkills}
                            _updateSkills={this._updateSkills}
                        />
                        <div style={{ height: 20 }} />
                        <AddWebsite />
                        <div style={{ height: 20 }} />
                        <AddBaseRate values={values} />
                        <div style={{ height: 20 }} />

                        <AddOpportunityInterests
                            oneTimeSelected={this.state.oneTimeSelected}
                            internshipSelected={this.state.internshipSelected}
                            fullTimeSelected={this.state.fullTimeSelected}
                            toggleOpportunityInterests={
                                this._toggleOpportunityInterests
                            }
                        />
                        <div style={{ height: 20 }} />

                        <StayConnected />
                        <div style={{ height: 20 }} />

                        {/* {this.props.error ? (
                                    <Message error content={this.props.error} />
                                ) : null} */}
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Complete Onboarding
                        </Button>
                        <div style={{ height: 20 }} />
                    </form>
                )}
            </Form>
        );
    };

    _updateSkills = (selectedSkills, proposedSkills) => {
        this.props.addStudentSkills({
            selectedSkills,
            proposedSkills,
        });
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, userSession }) => {
    const { user, loading, error } = userSession;
    const {
        website_url,
        baseHourlyRate,
        selectedSkills,
        proposedSkills,
    } = onboarding;

    return {
        user,
        loading,
        error,
        selectedSkills: selectedSkills
            ? selectedSkills
            : user && user.skills && user.skills !== []
            ? user.skills
            : [],
        proposedSkills,
        website_url: website_url
            ? website_url
            : user && user.website_url
            ? user.website_url
            : '',
        baseHourlyRate: baseHourlyRate
            ? baseHourlyRate
            : user && user.base_hourly_wage
            ? user.base_hourly_wage // THINK YOU HAVE TO TOSTRING THIS!!!
            : '15',
    };
};

export default connect(mapStateToProps, {
    updateUser,
    addStudentSkills,
    createProposedSkill,
})(StudentAddProfessionalInfoScreen);

const AddSkills = props => {
    return (
        <Field name="skills">
            {({ input, meta }) => (
                <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                        Add Your Skills (Select at least 3)
                    </Typography>
                    <SkillAutocompleteView
                        selectedSkills={props.selectedSkills}
                        proposedSkills={props.proposedSkills}
                        color={Colors.pangeaBlue}
                        autoFocus={true}
                        hasSuggestedSkills={true}
                        updateSelectedSkills={skills =>
                            props._updateSkills(skills, props.proposedSkills)
                        }
                        updateProposedSkills={proposed_skills =>
                            props._updateSkills(
                                props.selectedSkills,
                                proposed_skills
                            )
                        }
                        error={meta.error && meta.touched}
                        helperText={
                            meta.error && meta.touched
                                ? 'Please select at least 3 skills'
                                : ''
                        }
                        fieldInput={{ ...input }}
                    />
                </React.Fragment>
            )}
        </Field>
    );
};

const AddWebsite = () => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Website (optional)
            </Typography>
            <Typography gutterBottom>
                Showcase your skills by linking your portfolio or website
            </Typography>
            <Field name="website">
                {({ input, meta }) => (
                    <React.Fragment>
                        {meta.error && meta.touched && (
                            <Typography
                                color="error"
                                variant="subtitle2"
                                gutterBottom
                            >
                                Please enter a valid url
                            </Typography>
                        )}
                        <TextField
                            variant="outlined"
                            label="Website"
                            name="website-field"
                            id="website-field"
                            placeholder="www.pangea.app"
                            fullWidth
                            error={meta.error && meta.touched}
                            {...input}
                        />
                    </React.Fragment>
                )}
            </Field>
        </div>
    );
};

const AddBaseRate = ({ values }) => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Base Wage
            </Typography>
            <Typography gutterBottom>
                We’ll match you with jobs that pay you what you’re worth! This
                number indicates the lowest hourly wage you are willing to work
                for.
            </Typography>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                <Field name="baseHourlyRate">
                    {({ input, meta }) => (
                        <FormControl
                            style={{ minWidth: '30%' }}
                            disabled={values.isBaseHourlyRateSkipped}
                            error={meta.error && meta.touched}
                        >
                            <CustomInputLabel variant="outlined">
                                Base Wage
                            </CustomInputLabel>
                            <Select
                                variant={'outlined'}
                                {...input}
                                input={
                                    <CustomInputBase
                                        error={meta.error && meta.touched}
                                    />
                                }
                            >
                                <MenuItem value="" disabled>
                                    Base Wage
                                </MenuItem>
                                {baseHourlyWageOptions.map(
                                    (baseHourlyWage, index) => {
                                        return (
                                            <MenuItem
                                                value={baseHourlyWage.value}
                                                key={index}
                                            >
                                                {baseHourlyWage.text}
                                            </MenuItem>
                                        );
                                    }
                                )}
                            </Select>
                        </FormControl>
                    )}
                </Field>

                <Typography>/hour</Typography>
                <Field name="isBaseHourlyRateSkipped" type="checkbox">
                    {({ input, meta }) => (
                        <React.Fragment>
                            <Checkbox
                                checked={input.checked}
                                onChange={() => {
                                    input.onChange(!input.checked);
                                }}
                                value="isBaseHourlyRateSkipped"
                                color="primary"
                            />
                            <Typography>skipped</Typography>
                        </React.Fragment>
                    )}
                </Field>
            </div>
        </div>
    );
};

const AddOpportunityInterests = () => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Opportunity Interests (Select at least 1)
            </Typography>
            <Field name="opportunityTypes" type="checkbox">
                {({ input, meta }) => (
                    <div>
                        {meta.error && meta.touched && (
                            <Typography color="error" variant="subtitle2">
                                Please select at least 1 interest
                            </Typography>
                        )}
                    </div>
                )}
            </Field>
            <React.Fragment>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Field name="one_time" type="checkbox">
                        {({ input, meta }) => (
                            <Checkbox {...input} color="primary" />
                        )}
                    </Field>
                    <Typography>One-Time Projects</Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Field name="internship" type="checkbox">
                        {({ input, meta }) => (
                            <Checkbox {...input} color="primary" />
                        )}
                    </Field>
                    <Typography>Internships</Typography>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Field name="full_time" type="checkbox">
                        {({ input, meta }) => (
                            <Checkbox {...input} color="primary" />
                        )}
                    </Field>
                    <Typography>Full-Time Positions</Typography>
                </div>
            </React.Fragment>
        </div>
    );
};

const StayConnected = () => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                Stay Connected
            </Typography>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Field name="isEmailNotificationEnabled" type="checkbox">
                    {({ input, meta }) => (
                        <Checkbox
                            checked={input.checked}
                            onChange={() => {
                                input.onChange(!input.checked);
                            }}
                            color="primary"
                        />
                    )}
                </Field>

                <Typography>
                    Never miss out on a relevant opportunity by subscribing to
                    our email list!
                </Typography>
            </div>
        </div>
    );
};
