import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    Button,
    FormControl,
    RadioGroup,
    Radio,
    FormControlLabel,
    Typography,
} from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import { addUserType } from '../../../ducks/OnboardingDuck';

import OnboardingStyles from '../../../constants/OnboardingStyles';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import { push } from 'connected-react-router';
import { store } from '../../..';

const styles = {
    continueButton: {
        borderRadius: 20,
        fontSize: 16,
        fontWeight: '400',
    },
};
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class SelectUserTypeScreen extends Component {
    handleUserTypeChange = event => {
        this.props.addUserType({
            userType: event.target.value,
        });
    };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.subTitleText} gutterBottom>
                    Thank you for verifying your phone number
                </Typography>
                <Typography
                    style={OnboardingStyles.titleText}
                >{`So ${this.props.firstName}, are you a...`}</Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <form
                onSubmit={event => {
                    event.preventDefault();
                    store.dispatch(push('/addAccountInfo'));
                }}
                loading={this.props.loading}
            >
                <FormControl component="fieldset">
                    <RadioGroup
                        name="userType"
                        value={this.props.userType}
                        onChange={this.handleUserTypeChange}
                    >
                        <FormControlLabel
                            value="student"
                            control={<Radio color="primary" />}
                            label="Student"
                        />
                        <FormControlLabel
                            value="business"
                            control={<Radio color="primary" />}
                            label="Company Employee/Business Owner"
                        />
                    </RadioGroup>
                </FormControl>
                <div style={{ height: 20 }} />
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        style={styles.continueButton}
                    >
                        Continue
                    </Button>
                </div>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader hasHomeLink />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const { personalInfo, userType } = onboarding;

    return {
        firstName: personalInfo ? personalInfo.firstName : '',
        userType: userType ? userType : 'student',
    };
};

export default connect(mapStateToProps, {
    addUserType,
})(SelectUserTypeScreen);
