import React, { Component } from 'react';
import { connect } from 'react-redux';

import Colors from '../../../constants/Colors';
import Environment from '../../../constants/Environment';

import PropTypes from 'prop-types';

import { Card, CardActionArea, Typography, GridList } from '@material-ui/core';

import { SkillCard } from '..';

const pangeanCardStyles = {
    rootContainer: {
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        marginBottom: 20,
        width: '100%',
        // padding: 10,
    },

    bioContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
    },

    profileImage: {
        borderRadius: 30,
        width: 60,
        height: 60,
        border: 'solid 1px #c7c7cc',
        marginRight: 20,
    },

    schoolImage: {
        borderRadius: 30,
        width: 60,
        height: 60,
        border: 'solid 1px #c7c7cc',
        marginLeft: 20,
    },

    bioTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    boldBioText: {
        fontWeight: 'bold',
        display: 'block',
        fontSize: 16,
        marginBottom: 1,
    },

    bioText: {
        fontSize: 14,
        display: 'block',
    },

    // skillsContainer: {
    //     marginTop: 10,
    //     display: 'flex',
    //     justifyContent: 'space-between',
    // },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingBottom: 10,
    },
};

class PangeanItem extends Component {
    _renderSelectedSkillItem = (item, index) => {
        return (
            // <GridListTile>
            <SkillCard
                backgroundColor={Colors.white}
                skillName={item.name}
                isDeletable={false}
                isAddable={false}
                fontColor={'blue'}
            />
            // </GridListTile>
        );
    };

    render() {
        console.log('PangeanItem props');
        console.log(this.props);
        return (
            <Card style={pangeanCardStyles.rootContainer}>
                <CardActionArea
                    style={{ padding: 10, paddingBottom: 0 }}
                    onClick={this.props.onPress}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={pangeanCardStyles.bioContainer}>
                            <img
                                src={`https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${this.props.pangean.id}/profilePic/${this.props.pangean.profile_pic}`}
                                style={pangeanCardStyles.profileImage}
                                alt={''}
                            />
                            <div style={pangeanCardStyles.bioTextContainer}>
                                <Typography variant="h5">
                                    {this.props.pangean.full_name}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {this.props.pangean.intended_major}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {`Class of ${this.props.pangean.expected_yog}`}
                                </Typography>
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                                marginBottom: 10,
                            }}
                        >
                            <Typography variant="subtitle2" align="right">
                                {this.props.pangean.school.name}
                            </Typography>
                            <img
                                src={this.props.pangean.school.crest}
                                style={pangeanCardStyles.schoolImage}
                                alt={''}
                            />
                        </div>
                    </div>
                    {/* <List /> */}
                    <GridList style={pangeanCardStyles.gridList}>
                        {this.props.pangean.skills &&
                        this.props.pangean.skills.length > 0
                            ? this.props.pangean.skills.map((skill, index) =>
                                  this._renderSelectedSkillItem(skill, index)
                              )
                            : null}
                    </GridList>
                    {/* <div style={{ height: 42, overflowX: 'scroll' }}>
                        {this.props.pangean.skills &&
                        this.props.pangean.skills.length > 0
                            ? this.props.pangean.skills.map((skill, index) =>
                                  this._renderSelectedSkillItem(skill, index)
                              )
                            : null}
                    </div> */}
                    {/* <SkillCard
                        backgroundColor={Colors.pangeaBlue}
                        skillName={'Web Development'}
                        isDeletable={false}
                        isAddable={false}
                        fontColor={this.props.white ? 'blue' : 'white'}
                    /> */}
                    {/* <div style={pangeanCardStyles.skillsContainer}>{SkillChips}</div> */}
                </CardActionArea>
            </Card>
        );
    }
}

PangeanItem.propTypes = {
    pangean: PropTypes.object.isRequired,
};

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;
    return {
        user,
    };
};

export default connect(mapStateToProps, {})(PangeanItem);
