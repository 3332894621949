import React from 'react';

import { Typography } from '@material-ui/core';

import '../../common/CustomCarousel.css';
import { Carousel } from 'react-responsive-carousel';

const styles = {
    rootContainer: {
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        paddingTop: 20,
        // paddingLeft: 15,
        // paddingRight: 15,
        paddingBottom: 10,
        marginTop: 20,
        // marginBottom: 20,
    },
};
/*
testimonial: {
    testimonialName,
    testimonialTitle,
    testimonialDescription
}
*/
const ProfileTestimonialsCard = props => {
    const testimonials = props.testimonials;
    return (
        <div style={styles.rootContainer}>
            <Typography variant="h4" gutterBottom style={{ paddingLeft: 20 }}>
                Testimonials
            </Typography>
            <Carousel showThumbs={false} showStatus={false}>
                {testimonials.map((testimonial, index) => (
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            paddingLeft: 20,
                        }}
                        key={index}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flex: 7,
                                flexDirection: 'column',
                            }}
                        >
                            <Typography variant="button" align="left">
                                {testimonial.testimonialName}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                align="left"
                                gutterBottom
                                color="textSecondary"
                            >
                                {testimonial.testimonialTitle}
                            </Typography>
                            <Typography variant="subtitle2" align="left">
                                {testimonial.testimonialDescription}
                            </Typography>
                        </div>
                        <div style={{ display: 'flex', flex: 1 }}></div>
                    </div>
                ))}
            </Carousel>
        </div>
    );
};

export default ProfileTestimonialsCard;
