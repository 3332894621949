import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header as HeaderItem, Footer, CustomContainer } from '../common';
import Colors from '../../constants/Colors';

import {
    Card,
    GridList,
    GridListTile,
    CardContent,
    Typography,
    CardActionArea,
    Button,
} from '@material-ui/core';

const styles = {
    rootContainer: {
        backgroundColor: Colors.lightGray,
        height: '100vh',
    },

    titleText: {
        marginBottom: 29,
    },

    headerText: {
        display: 'block',
        marginBottom: 9,
        textAlign: 'center',
    },

    // projectCard: {
    //     backgroundColor: '#fff',
    //     borderRadius: 20,
    //     boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    //     padding: 30,
    //     minHeight: 200,
    //     maxHeight: 200,
    //     margin: 20,
    // },

    projectTypeCard: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // borderRadius: 20,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: '#ffffff',
        margin: 5,
        // marginTop: 20,
        // marginRight: 20,
        // marginLeft: 20,
        // marginBottom: 20,
    },

    customProjectActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    customProjectText: {
        marginLeft: 5,
        marginRight: 5,
    },

    newProjectBtn: {
        borderRadius: 15,
        border: 'solid 1px #000000',
        display: 'inline',
        marginLeft: 5,
        marginRight: 5,
        padding: 8,
    },

    newProjectButton: {
        borderRadius: 20,
        // fontSize: 16,
        // fontWeight: '400',
    },
};

const projectData = [
    { name: 'Test', description: 'Test Test Test' },
    { name: 'Test', description: 'Test Test Test' },
    { name: 'Test', description: 'Test Test Test' },
    { name: 'Test', description: 'Test Test Test' },
];

class PostChooseProjectTemplateScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div style={styles.rootContainer}>
                    <HeaderItem user={this.props.user} />
                    <CustomContainer>
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={{ marginTop: 50, paddingBottom: 10 }}
                        >
                            Post a New Project
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            Check out some of these popular One-Time Projects.
                        </Typography>
                        <div style={styles.customProjectActionsContainer}>
                            <Typography
                                variant="body1"
                                style={{ marginRight: 20 }}
                            >
                                Not what you're looking for?
                            </Typography>
                            <Button
                                variant="contained"
                                color={'primary'}
                                style={styles.newProjectButton}
                            >
                                Post a new project from scratch
                            </Button>
                        </div>
                        {/* <div style={styles.rootContainer}>
                            <h3 style={styles.titleText}>Post a New Project</h3>
                            <span style={styles.headerText}>
                                Check out some of these popular One-Time
                                Projects.
                            </span>

                            <div style={styles.customProjectActionsContainer}>
                                <span style={styles.customProjectText}>
                                    Not what you're looking for?
                                </span>
                                <input
                                    type="button"
                                    style={styles.newProjectBtn}
                                    value="Post a new project from scratch"
                                />
                            </div> */}

                        <GridList cellHeight={'auto'} cols={3} spacing={10}>
                            {projectData.map((project, index) => (
                                <GridListTile key={index} cols={1}>
                                    <Card style={styles.projectTypeCard}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Typography
                                                    variant="h6"
                                                    gutterBottom
                                                >
                                                    {project.name}
                                                </Typography>
                                                <Typography
                                                    variant="body1"
                                                    gutterBottom
                                                >
                                                    {project.description}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                    {/* <div
                                        style={styles.projectCard}
                                        raised={true}
                                    >
                                        <h3>{project.name}</h3>
                                        <p>{project.description}</p>
                                    </div> */}
                                </GridListTile>
                            ))}
                        </GridList>
                        {/* </div> */}
                    </CustomContainer>
                </div>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(PostChooseProjectTemplateScreen);
