import React from 'react';

import { Typography, TextField } from '@material-ui/core';

import { Field } from 'react-final-form';

const MAX_TITLE_LENGTH = 50;

const required = value => (value ? undefined : 'Required');

const BasicDetailsForm = props => {
    return (
        <React.Fragment>
            <Field name="title" validate={required}>
                {({ input, meta }) => (
                    <div>
                        <Typography variant="h5" gutterBottom>
                            Title
                        </Typography>
                        <TextField
                            id="project-title"
                            label="Title"
                            variant="outlined"
                            fullWidth
                            maxLength={MAX_TITLE_LENGTH}
                            error={meta.error && meta.touched}
                            {...input}
                        />
                    </div>
                )}
            </Field>
            <div style={{ height: 20 }} />
            <Field name="description" validate={required}>
                {({ input, meta }) => (
                    <div>
                        <Typography variant="h5" gutterBottom>
                            Description
                        </Typography>
                        <TextField
                            placeholder={
                                'For example “Looking for a web developer to help with our company website.”'
                            }
                            variant="outlined"
                            label="Description"
                            fullWidth
                            maxLength={5000}
                            rows="6"
                            multiline
                            error={meta.error && meta.touched}
                            {...input}
                        />
                    </div>
                )}
            </Field>
        </React.Fragment>
    );
};

export default BasicDetailsForm;
