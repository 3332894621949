import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Grid, Typography, Link } from '@material-ui/core';

import Environment from '../../../constants/Environment';
import Colors from '../../../constants/Colors';

import {
    EditStudentProfileForm,
    EditBusinessProfileForm,
    EditBusinessCompanyForm,
} from '../../common';

const styles = {
    rootContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    profileContainer: {
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        paddingTop: 10,
        display: 'flex',
        flex: 1,
    },

    backgroundImage: {
        height: 155,
        // border: 'solid 1px #c7c7cc',
    },

    profileImage: {
        width: 'calc(100% - 30px)',
        // height: 110,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        border: 'solid 2px #c7c7cc',
        backgroundColor: 'white',
        borderRadius: 500,
        marginTop: '-50%',
        marginLeft: 20,
        marginRight: 10,
    },

    shortBioContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'stretch',
    },

    shortBioColumn: {
        display: 'flex',
        flexDirection: 'column',
    },

    shortBioText: {
        marginBottom: 10,
    },

    shortBioRatingContainer: {
        marginBottom: 10,
        display: 'flex',
        alignItems: 'center',
        marginRight: 60,
    },

    schoolContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 20,
    },

    schoolIcon: {
        width: 24,
        height: 24,
        // borderRadius: 3,
        // border: 'solid 1px #979797',
        marginRight: 10,
    },

    bodyText: {
        fontSize: 14,
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },

    titleText: {
        fontSize: 21,
        fontFamily: 'Inter',
        color: Colors.fontBlack,
    },
};

class ProfileMainCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: null,
        };
    }

    async componentDidMount() {}

    render() {
        // boolean logic, if it's not a profile, it's a company.
        const isProfile =
            this.props.type === 'detailed_profile' ||
            this.props.type === 'my_profile';
        const isOwner =
            this.props.type === 'my_profile' ||
            this.props.type === 'my_company';
        const isStudentProfile =
            isProfile && this.props.user && this.props.user_type === 'student';
        let hasRating = false;
        if (
            this.props.type === 'detailed_profile' ||
            (this.props.type === 'my_profile' && this.props.user)
        ) {
            if (this.props.user.num_overall_rating > 0) {
                hasRating = true;
            }
        } else if (this.props.company) {
            if (this.props.company.num_overall_rating > 0) {
                hasRating = true;
            }
        }

        //edit form conditional
        let EditForm = null;
        if (isOwner) {
            if (isProfile && isStudentProfile) {
                EditForm = (
                    <EditStudentProfileForm
                        isVisible={this.state.visibleModal === 1}
                        onSubmit={() => {
                            console.log('submitform');
                        }}
                        close={() => this.setState({ visibleModal: null })}
                    />
                );
            } else if (isProfile && !isStudentProfile) {
                EditForm = (
                    <EditBusinessProfileForm
                        isVisible={this.state.visibleModal === 1}
                        onSubmit={() => {
                            console.log('submitform');
                        }}
                        close={() => this.setState({ visibleModal: null })}
                    />
                );
            } else if (!isProfile) {
                EditForm = (
                    <EditBusinessCompanyForm
                        isVisible={this.state.visibleModal === 1}
                        onSubmit={() => {
                            console.log('submitform');
                        }}
                        close={() => this.setState({ visibleModal: null })}
                    />
                );
            }
        }

        let bio = '';
        if (isProfile && this.props.user) {
            bio = this.props.user.bio;
        } else if (this.props.company) {
            bio = this.props.company.about_us;
        }
        let name = '';
        if (isProfile && this.props.user) {
            name = this.props.user.full_name;
        } else if (this.props.company) {
            name = this.props.company.company_name;
        }
        let pic_uri = '';
        if (isProfile && this.props.user) {
            pic_uri = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${this.props.user.id}/profilePic/${this.props.user.profile_pic}`;
        } else if (this.props.company) {
            pic_uri = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${this.props.company.id}/logoPic/${this.props.company.logo_pic}`;
        }

        let firstSubtitle = null;
        let secondSubtitle = null;
        if (isStudentProfile) {
            firstSubtitle = (
                <div style={styles.schoolContainer}>
                    <span
                        role="img"
                        aria-label="grad cap"
                        style={styles.bodyText}
                    >
                        🎓
                    </span>
                    <img
                        src="http://212a0c40eaa2d07d7ce5-8f475f2fa394bc8453917e1fb7321b33.r83.cf1.rackcdn.com/blk-brown-university-1d7ea30-logo.jpg"
                        style={styles.schoolIcon}
                        alt="User school"
                    />

                    <Typography variant="subtitle2">
                        {this.props.user.intended_major}
                    </Typography>
                </div>
            );
            secondSubtitle = (
                <div style={styles.schoolContainer}>
                    <img
                        src={`https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Schools/${this.props.user.school_id}`}
                        style={styles.schoolIcon}
                        alt="User school"
                    />

                    <Typography variant="subtitle2">
                        {this.props.user.school.name}
                    </Typography>
                </div>
            );
        } else if (isProfile && this.props.user && this.props.user.company) {
            firstSubtitle = (
                <div style={styles.schoolContainer}>
                    <img
                        src={`https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${this.props.user.company.id}/logoPic/${this.props.user.company.logo_pic}`}
                        style={styles.schoolIcon}
                        alt=""
                    />

                    <Typography variant="subtitle2">
                        {this.props.user.company
                            ? this.props.user.company.company_name
                            : 'individual'}
                    </Typography>
                </div>
            );
            secondSubtitle = (
                <div style={styles.schoolContainer}>
                    <span
                        role="img"
                        aria-label="brief case"
                        style={{ marginRight: 10 }}
                    >
                        💼
                    </span>
                    <Typography variant="subtitle2">
                        {this.props.user.your_title
                            ? this.props.user.your_title
                            : 'Member'}
                    </Typography>
                </div>
            );
        } else if (this.props.company) {
            firstSubtitle = (
                <div style={styles.schoolContainer}>
                    <span
                        role="img"
                        aria-label="building"
                        style={styles.bodyText}
                    >
                        🏢
                    </span>
                    <Typography variant="subtitle2">{`${
                        this.props.company.loc
                            ? this.props.company.loc.address.components.city
                            : ''
                    }, ${
                        this.props.company.loc
                            ? this.props.company.loc.address.components.state
                            : ''
                    }`}</Typography>
                </div>
            );
            secondSubtitle = (
                <div style={styles.schoolContainer}>
                    <span
                        role="img"
                        aria-label="person"
                        style={styles.bodyText}
                    >
                        👤
                    </span>
                    <Typography variant="subtitle2">
                        {this.props.company.num_employees}
                    </Typography>
                </div>
            );
        }

        return (
            <div style={styles.rootContainer}>
                {EditForm}
                <img
                    style={styles.backgroundImage}
                    alt="User background"
                    src="https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_960_720.jpg"
                />

                <div style={styles.profileContainer}>
                    <div
                        style={{
                            display: 'flex',
                            flex: 2,
                            flexDirection: 'column',
                        }}
                    >
                        <img
                            style={styles.profileImage}
                            alt="User profile"
                            src={pic_uri}
                        ></img>
                        {/* <div style={{ marginTop: '100%', height: 0 }} /> */}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 5,
                            flexDirection: 'column',
                            marginLeft: 10,
                            marginRight: 10,
                        }}
                    >
                        <div style={styles.shortBioContainer}>
                            <div style={styles.shortBioColumn}>
                                <Typography
                                    variant="h4"
                                    style={{ fontWeight: '400' }}
                                    gutterBottom
                                >
                                    {name}
                                </Typography>
                            </div>

                            <div style={styles.shortBioColumn}>
                                {hasRating ? (
                                    <div style={styles.shortBioRatingContainer}>
                                        <Typography variant="subtitle2">
                                            Excellent
                                        </Typography>
                                    </div>
                                ) : null}
                                {isStudentProfile ? (
                                    <Typography
                                        variant="subtitle2"
                                        style={styles.shortBioText}
                                    >
                                        Base Rate: $25
                                    </Typography>
                                ) : null}
                            </div>
                        </div>
                        {firstSubtitle}
                        {secondSubtitle}

                        {/* <div style={styles.schoolContainer}>
                            <img
                                src="http://212a0c40eaa2d07d7ce5-8f475f2fa394bc8453917e1fb7321b33.r83.cf1.rackcdn.com/blk-brown-university-1d7ea30-logo.jpg"
                                style={styles.schoolIcon}
                                alt="User school"
                            />

                            <span>Brown University</span>
                        </div> */}

                        <Typography variant="subtitle2" gutterBottom>
                            {bio}
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            marginLeft: 10,
                        }}
                    >
                        {isOwner ? (
                            <Grid item md={2} direction="column">
                                {/* <Link
                                    href="#"
                                    onClick={e => {
                                        e.preventDefault();
                                        this.setState({ visibleModal: 1 });
                                    }}
                                >
                                    <Typography variant="h6">Edit</Typography>
                                </Link> */}
                            </Grid>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

ProfileMainCard.propTypes = {
    type: PropTypes.oneOf([
        'detailed_profile',
        'detailed_company',
        'my_profile',
        'my_company',
    ]),
    user: PropTypes.object,
    company: PropTypes.object,
};

export default ProfileMainCard;
