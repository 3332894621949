import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { ReonboardPromptOverlay } from '../components/common';
import { logoutUser } from '../ducks/UserSessionDuck';

import MobileHOC from './MobileHOC';

const PrivateRoute = ({ component, user, logoutUser, ...rest }) => {
    let ComponentToRender = component;

    return (
        <Route
            {...rest}
            render={props =>
                user ? (
                    user.has_reonboarded ? (
                        <MobileHOC>
                            <ComponentToRender {...props} />
                        </MobileHOC>
                    ) : (
                        <MobileHOC>
                            <React.Fragment>
                                <ReonboardPromptOverlay
                                    user={user}
                                    logoutUser={logoutUser}
                                />
                                <ComponentToRender {...props} />
                            </React.Fragment>
                        </MobileHOC>
                    )
                ) : (
                    <Redirect
                        to={{
                            pathname: '/welcome',
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

const mapStateToProps = ({ userSession }) => {
    const { user, authenticated } = userSession;

    return {
        authenticated,
        user,
    };
};

export default withRouter(
    connect(mapStateToProps, { logoutUser })(PrivateRoute)
);
