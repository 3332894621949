import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AdminReducer from './ducks/AdminDuck';
import CompanyReducer from './ducks/CompanyDuck';
import CreatePostReducer from './ducks/CreatePostDuck';
import DeepLinkReducer from './ducks/DeepLinkDuck';
import GeoReducer from './ducks/GeoDuck';
import MainFeedReducer from './ducks/MainFeedDuck';
import ModeratorReducer from './ducks/ModeratorDuck';
import MyCandidatePoolsReducer from './ducks/MyCandidatePoolsDuck';
import MyInvoicesReducer from './ducks/MyInvoicesDuck';
import MyPostsReducer from './ducks/MyPostsDuck';
import SavedPostsReducer from './ducks/SavedPostsDuck';
import MyReviewsReducer from './ducks/MyReviewsDuck';
import MyRoomsReducer from './ducks/MyRoomsDuck';
// import NavigationReducer from './ducks/NavigationDuck';
import OnboardingReducer from './ducks/OnboardingDuck';
import ProposedSchoolReducer from './ducks/ProposedSchoolDuck';
import ProposedSkillDuck from './ducks/ProposedSkillDuck';
import ReportBlockFriendReducer from './ducks/ReportBlockFriendDuck';
import SkillsReducer from './ducks/SkillsDuck';
import TransactionMessengerReducer from './ducks/TransactionMessengerDuck';
import UserSessionReducer from './ducks/UserSessionDuck';
import SearchTagsReducer from './ducks/SearchTagsDuck';
import SubscriptionsReducer from './ducks/SubscriptionsDuck';
import ReviewReducer from './ducks/ReviewDuck';
import PaymentsReducer from './ducks/PaymentsDuck';

const createRootReducer = history =>
    combineReducers({
        router: connectRouter(history),
        admin: AdminReducer,
        company: CompanyReducer,
        createPost: CreatePostReducer,
        deeplink: DeepLinkReducer,
        geo: GeoReducer,
        mainFeed: MainFeedReducer,
        moderator: ModeratorReducer,
        myCandidatePools: MyCandidatePoolsReducer,
        myInvoices: MyInvoicesReducer,
        myPosts: MyPostsReducer,
        savedPosts: SavedPostsReducer,
        myReviews: MyReviewsReducer,
        myRooms: MyRoomsReducer,
        // navigation: NavigationReducer,
        onboarding: OnboardingReducer,
        proposedSchool: ProposedSchoolReducer,
        proposedSkill: ProposedSkillDuck,
        reportBlockFriend: ReportBlockFriendReducer,
        skills: SkillsReducer,
        transactionMessenger: TransactionMessengerReducer,
        userSession: UserSessionReducer,
        searchTags: SearchTagsReducer,
        subscriptions: SubscriptionsReducer,
        review: ReviewReducer,
        payments: PaymentsReducer,
    });

export default createRootReducer;
