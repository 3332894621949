import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import { addPersonalInfo } from '../../../ducks/OnboardingDuck';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import { push } from 'connected-react-router';
import { store } from '../../..';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    textInput: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        flex: 1,
        fontSize: 18,
        // fontFamily: 'Inter',
    },

    continueButton: {
        borderRadius: 20,
        fontSize: 16,
        fontWeight: '400',
    },
};

class AddNameScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            firstNameError: false,
            lastNameError: false,
        };
    }

    handlePersonalInfoSubmit = e => {
        e.preventDefault();
        let error = false;

        if (this.props.firstName === '') {
            this.setState({ firstNameError: true });
            error = true;
        } else {
            this.setState({ firstNameError: false });
        }

        if (this.props.lastName === '') {
            this.setState({ lastNameError: true });
            error = true;
        } else {
            this.setState({ lastNameError: false });
        }

        if (!error) {
            store.dispatch(push('/addPhoneNumber'));
        }
    };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography variant="h4" gutterBottom>
                    Hi! We can't wait for you to join us.
                </Typography>
                <Typography variant="h2">What is your name?</Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <form
                className={styles.container}
                noValidate
                onSubmit={event => {
                    this.handlePersonalInfoSubmit(event);
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    <TextField
                        name="firstName-field"
                        id="firstName-field"
                        label="First Name"
                        variant="outlined"
                        autoFocus
                        value={this.props.firstName}
                        error={this.state.firstNameError}
                        helperText={
                            this.state.firstNameError
                                ? 'Please enter your first name.'
                                : ''
                        }
                        onChange={event => {
                            this.props.addPersonalInfo({
                                personalInfo: {
                                    firstName: event.target.value,
                                    lastName: this.props.lastName,
                                },
                            });
                        }}
                        InputProps={{
                            disableUnderline: true,
                            autoCapitalize: 'words',
                        }}
                        style={{ flex: 1 }}
                    />
                    <div
                        style={{
                            width: 20,
                        }}
                    />
                    <TextField
                        name="lastName-field"
                        id="lastName-field"
                        label="Last Name"
                        variant="outlined"
                        value={this.props.lastName}
                        error={this.state.lastNameError}
                        helperText={
                            this.state.lastNameError
                                ? 'Please enter your last name.'
                                : ''
                        }
                        onChange={event => {
                            this.props.addPersonalInfo({
                                personalInfo: {
                                    firstName: this.props.firstName,
                                    lastName: event.target.value,
                                },
                            });
                        }}
                        InputProps={{
                            disableUnderline: true,
                            autoCapitalize: 'words',
                        }}
                        style={{ flex: 1 }}
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={styles.continueButton}
                >
                    Continue
                </Button>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader hasHomeLink />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const { personalInfo } = onboarding;

    return {
        firstName: personalInfo ? personalInfo.firstName : '',
        lastName: personalInfo ? personalInfo.lastName : '',
    };
};

export default connect(mapStateToProps, {
    addPersonalInfo,
})(AddNameScreen);
