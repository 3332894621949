import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Footer, CustomContainer } from '../common';

import {
    Grid,
    Card,
    Typography,
    FormControlLabel,
    Checkbox,
    Button,
    Radio,
    Link,
} from '@material-ui/core';
import Colors from '../../constants/Colors';
import { Header } from '../common';

const styles = {
    rootContainer: {
        paddingTop: 32,
        paddingBottom: 20,
        backgroundColor: '#f2f2f7',
    },

    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    titleText: {
        fontWeight: 'bold',
    },
};

const accountCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },

    cardHeaderText: {
        fontWeight: 'normal',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    editLink: {
        color: '#8e8e93',
    },

    accoutnInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },

    infoText: {
        flex: 1,
        fontWeight: 'normal',
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    actionLinkText: {
        fontSize: 16,
        fontWeight: 'normal',
    },

    separatorText: {
        color: '#8e8e93',
        marginLeft: 10,
        marginRight: 10,
    },
};

const AccountCard = () => {
    return (
        <Card style={accountCardStyles.cardContainer}>
            <div style={accountCardStyles.cardHeader}>
                <Typography
                    variant="h6"
                    style={accountCardStyles.cardHeaderText}
                >
                    Account
                </Typography>

                <Link>
                    <Typography
                        variant="button"
                        style={accountCardStyles.editLink}
                    >
                        Edit
                    </Typography>
                </Link>
            </div>

            <hr style={accountCardStyles.rule}></hr>

            <div style={accountCardStyles.accoutnInfoContainer}>
                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    Email
                </Typography>

                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    john@pangea.app
                </Typography>
            </div>

            <div style={accountCardStyles.accoutnInfoContainer}>
                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    Password
                </Typography>

                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    ****
                </Typography>
            </div>

            <div style={accountCardStyles.accoutnInfoContainer}>
                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    Phone Number
                </Typography>

                <Typography
                    variant="subtitle1"
                    style={accountCardStyles.infoText}
                >
                    504
                </Typography>
            </div>

            <div style={accountCardStyles.actionContainer}>
                <Link href="#">
                    <Typography
                        variant="h6"
                        style={accountCardStyles.actionLinkText}
                    >
                        Delete Account
                    </Typography>
                </Link>

                <Typography style={accountCardStyles.separatorText}>
                    {' '}
                    |{' '}
                </Typography>

                <Link href="#">
                    <Typography
                        variant="h6"
                        style={accountCardStyles.actionLinkText}
                    >
                        Sign Out
                    </Typography>
                </Link>
            </div>
        </Card>
    );
};

const subscriptionCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardHeaderText: {
        fontWeight: 'normal',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    accoutnInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 20,
        marginBottom: 20,
    },

    infoText: {
        flex: 1,
        fontWeight: 'normal',
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    actionLinkText: {
        fontSize: 16,
        fontWeight: 'normal',
    },

    separatorText: {
        color: '#8e8e93',
        marginLeft: 10,
        marginRight: 10,
    },

    renewCheckbox: {
        marginTop: 20,
        marginBottom: 20,
    },
};

const SubscriptionCard = () => {
    return (
        <Card style={subscriptionCardStyles.cardContainer}>
            <Typography
                variant="h6"
                style={subscriptionCardStyles.cardHeaderText}
            >
                Subscription
            </Typography>

            <hr style={subscriptionCardStyles.rule}></hr>

            <div style={subscriptionCardStyles.accoutnInfoContainer}>
                <Typography
                    variant="subtitle1"
                    style={subscriptionCardStyles.infoText}
                >
                    Pangea Pro
                </Typography>

                <Typography
                    variant="subtitle1"
                    style={subscriptionCardStyles.infoText}
                >
                    Ends September 15, 2020
                </Typography>
            </div>

            <FormControlLabel
                control={<Checkbox checked={true} onChange={() => {}} />}
                label="Automatically renew subscription on September 15, 2020"
                style={subscriptionCardStyles.renewCheckbox}
            />

            <div style={accountCardStyles.actionContainer}>
                <Link href="#">
                    <Typography
                        variant="h6"
                        style={accountCardStyles.actionLinkText}
                    >
                        Edit Payment Method
                    </Typography>
                </Link>

                <Typography style={accountCardStyles.separatorText}>
                    {' '}
                    |{' '}
                </Typography>

                <Link href="#">
                    <Typography
                        variant="h6"
                        style={accountCardStyles.actionLinkText}
                    >
                        Cancel Subscription
                    </Typography>
                </Link>
            </div>
        </Card>
    );
};

const paymentCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardHeaderText: {
        fontWeight: 'normal',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    cardOptionContainer: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    cardOptionLeftContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    cardOptionRightContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    paymentActionContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    paymentActionLinkText: {
        fontSize: 16,
        fontWeight: 'normal',
    },

    paymentSeparatorText: {
        color: '#8e8e93',
        marginLeft: 10,
        marginRight: 10,
    },

    cardRadio: {
        marginLeft: 2,
        marginRight: 22,
    },

    cardImage: {
        width: 106,
        height: 66,
        borderRadius: 2,
    },

    selectedCardImage: {
        width: 106,
        height: 66,
        borderRadius: 2,
        border: 'solid 2px #006c9e',
        marginRight: 20,
    },

    selectedCardInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.pangeaBlue,
    },

    cardInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: '#8e8e93',
    },

    cardNameText: {
        fontWeight: 'bold',
        marginBottom: 10,
    },

    cardSubtitle: {
        fontWeight: 'normal',
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 10,
    },

    actionLinkText: {
        fontSize: 16,
        fontWeight: 'normal',
    },
};

const PaymentCard = ({ cardOptions }) => {
    const [selectedCardTypeStr, setSelectedCardTypeStr] = React.useState('a');

    const handleChange = event => {
        setSelectedCardTypeStr(event.target.value);
    };

    return (
        <Card style={paymentCardStyles.cardContainer}>
            <Typography variant="h6" style={paymentCardStyles.cardHeaderText}>
                Payment Methods
            </Typography>

            <hr style={paymentCardStyles.rule}></hr>

            <div style={paymentCardStyles.cardOptionContainer}>
                <div style={paymentCardStyles.cardOptionLeftContainer}>
                    <Radio
                        checked={'Visa' === selectedCardTypeStr}
                        onChange={handleChange}
                        value="Visa"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Visa Radio' }}
                        style={paymentCardStyles.cardRadio}
                    />

                    <img
                        src="/"
                        alt="Visa Card Logo"
                        style={paymentCardStyles.selectedCardImage}
                    />

                    <div style={paymentCardStyles.selectedCardInfoContainer}>
                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardNameText}
                        >
                            American Express
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardSubtitle}
                        >
                            Ending in 0000
                        </Typography>
                    </div>
                </div>

                <div style={paymentCardStyles.cardOptionRightContainer}>
                    <div style={paymentCardStyles.paymentActionContainer}>
                        <Link href="#">
                            <Typography
                                variant="h6"
                                style={paymentCardStyles.paymentActionLinkText}
                            >
                                Edit
                            </Typography>
                        </Link>

                        <Typography
                            style={paymentCardStyles.paymentSeparatorText}
                        >
                            {' '}
                            |{' '}
                        </Typography>

                        <Link href="#">
                            <Typography
                                variant="h6"
                                style={paymentCardStyles.paymentActionLinkText}
                            >
                                Delete
                            </Typography>
                        </Link>
                    </div>
                </div>
            </div>

            <div style={paymentCardStyles.cardOptionContainer}>
                <div style={paymentCardStyles.cardOptionLeftContainer}>
                    <Radio
                        checked={'PayPal' === selectedCardTypeStr}
                        onChange={handleChange}
                        value="PayPal"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'PayPal Radio' }}
                        style={paymentCardStyles.cardRadio}
                    />

                    <img
                        src="/"
                        alt="PayPal Card Logo"
                        style={paymentCardStyles.cardImage}
                    />

                    <div style={paymentCardStyles.cardInfoContainer}>
                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardNameText}
                        >
                            PayPal
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardSubtitle}
                        >
                            jamie@pangea.app
                        </Typography>
                    </div>
                </div>

                <div style={paymentCardStyles.cardOptionRightContainer}>
                    <div style={paymentCardStyles.paymentActionContainer}>
                        <Link href="#">
                            <Typography
                                variant="h6"
                                style={paymentCardStyles.paymentActionLinkText}
                            >
                                Edit
                            </Typography>
                        </Link>

                        <Typography
                            style={paymentCardStyles.paymentSeparatorText}
                        >
                            {' '}
                            |{' '}
                        </Typography>

                        <Link href="#">
                            <Typography
                                variant="h6"
                                style={paymentCardStyles.paymentActionLinkText}
                            >
                                Delete
                            </Typography>
                        </Link>
                    </div>
                </div>
            </div>

            <div style={paymentCardStyles.actionContainer}>
                <Link href="#">
                    <Typography
                        variant="h6"
                        style={paymentCardStyles.actionLinkText}
                    >
                        Show all
                    </Typography>
                </Link>

                <Link href="#">
                    <Typography
                        variant="h6"
                        style={paymentCardStyles.actionLinkText}
                    >
                        New Payment Method
                    </Typography>
                </Link>
            </div>
        </Card>
    );
};

const privacyCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardHeaderText: {
        fontWeight: 'normal',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    blockedUsersButton: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
    },
};

const PrivacyCard = () => {
    return (
        <Card style={privacyCardStyles.cardContainer}>
            <Typography variant="h6" style={privacyCardStyles.cardHeaderText}>
                Privacy
            </Typography>

            <hr style={privacyCardStyles.rule}></hr>

            <Button style={privacyCardStyles.blockedUsersButton}>
                <Typography variant="body1">Blocked Users</Typography>
                <span>></span>
            </Button>
        </Card>
    );
};

const connectCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        paddingBottom: 7,
        marginBottom: 20,
    },

    cardHeaderText: {
        fontWeight: 'normal',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    socialMediaContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    connectContainer: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
    },

    connectBtn: {
        color: Colors.pangeaBlue,
    },

    disconnectBtn: {
        color: '#8e8e93',
    },
};

const ConnectCard = () => {
    return (
        <Card style={connectCardStyles.cardContainer}>
            <Typography variant="h6" style={privacyCardStyles.cardHeaderText}>
                Connect
            </Typography>

            <hr style={privacyCardStyles.rule}></hr>

            <div style={connectCardStyles.socialMediaContainer}>
                <div style={connectCardStyles.connectContainer}>
                    <Typography variant="body1">Facebook</Typography>
                    <Button style={connectCardStyles.connectBtn}>
                        <Typography variant="body1">Connect</Typography>
                    </Button>
                </div>

                <div style={connectCardStyles.connectContainer}>
                    <Typography variant="body1">Instagram</Typography>
                    <Button style={connectCardStyles.disconnectBtn}>
                        <Typography variant="body1">Disconnect</Typography>
                    </Button>
                </div>

                <div style={connectCardStyles.connectContainer}>
                    <Typography variant="body1">LinkedIn</Typography>
                    <Button style={connectCardStyles.connectBtn}>
                        <Typography variant="body1">Connect</Typography>
                    </Button>
                </div>

                <div style={connectCardStyles.connectContainer}>
                    <Typography variant="body1">Twitter</Typography>
                    <Button style={connectCardStyles.connectBtn}>
                        <Typography variant="body1">Connect</Typography>
                    </Button>
                </div>
            </div>
        </Card>
    );
};

class SettingsScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldAutoRenewSubscription: false,
        };

        this.handleClickShouldAutoRenew = this.handleClickShouldAutoRenew.bind(
            this
        );
    }

    handleClickShouldAutoRenew() {
        this.setState(currState => {
            return {
                shouldAutoRenewSubscription: !currState.shouldAutoRenewSubscription,
            };
        });
    }

    render() {
        return (
            <div>
                <Header user={this.props.user} hasSearch />

                <div style={styles.rootContainer}>
                    <CustomContainer style={styles.cardContainer}>
                        <Grid container spacing={2}>
                            <Grid
                                item
                                md={2}
                                style={{ display: 'flex' }}
                            ></Grid>
                            <Grid item md={8}>
                                <Typography
                                    variant="h3"
                                    gutterBottom
                                    style={styles.titleText}
                                >
                                    Settings
                                </Typography>

                                <AccountCard />

                                <SubscriptionCard />

                                <PaymentCard />

                                <PrivacyCard />

                                <ConnectCard />
                            </Grid>
                            <Grid
                                item
                                md={2}
                                style={{ display: 'flex' }}
                            ></Grid>
                        </Grid>
                    </CustomContainer>
                </div>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(SettingsScreen);
