import React from 'react';

import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import Environment from '../../../constants/Environment';
import Colors from '../../../constants/Colors';

import { Typography } from '@material-ui/core';

const schoolSectionStyle = {
    rootContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        width: '100%',
    },

    sectionTitle: {
        marginBottom: 20,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
        fontSize: 21,
    },

    rule: {
        border: 'solid 1px #979797',
        marginTop: 20,
        marginBottom: 15,
    },

    bioContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
    },

    profileImage: {
        borderRadius: 30,
        width: 60,
        height: 60,
        // border: 'solid 1px #c7c7cc',
        marginRight: 20,
        backgroundColor: Colors.white,
    },

    bioTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    boldBioText: {
        display: 'block',
        marginBottom: 1,
        fontSize: 16,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
    },

    bioText: {
        margin: 0,
        marginTop: 3,
        padding: 0,
        fontSize: 14,
        display: 'block',
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
    },

    extraInfo: {
        marginTop: 16,
        fontSize: 14,
        display: 'block',
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
    },

    map: {
        marginTop: 20,
        marginBottom: 20,
        height: 150,
        borderRadius: 5,
        border: 'solid 1px #8e8e93',
    },
};

const ProfileAboutCard = props => {
    console.log('props');

    console.log(props);

    return (
        <div style={schoolSectionStyle.rootContainer}>
            <Typography variant="h4" gutterBottom>
                {props.type === 'company'
                    ? `About ${props.company.company_name}`
                    : `About ${props.user.first_name}'s School`}
            </Typography>

            <hr style={schoolSectionStyle.rule}></hr>

            <div style={schoolSectionStyle.bioContainer}>
                <img
                    src={
                        props.type === 'company'
                            ? `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${props.company.id}/logoPic/${props.company.logo_pic}`
                            : `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Schools/${props.school.id}`
                    }
                    style={schoolSectionStyle.profileImage}
                    alt={'School'}
                    onClick={() => props.onClick()}
                />

                <div style={schoolSectionStyle.bioTextContainer}>
                    <Typography variant="body2">
                        {props.type === 'company'
                            ? `${props.company.about_us}`
                            : `${props.school.about}`}
                    </Typography>
                </div>
            </div>

            {props.type === 'company' ? (
                <div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2">
                            No. of Employees:
                        </Typography>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                            2-10
                        </Typography>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2">Location:</Typography>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                            {`${
                                props.company.loc
                                    ? props.company.loc.address.components.city
                                    : ''
                            }, ${
                                props.company.loc
                                    ? props.company.loc.address.components.state
                                    : ''
                            }`}
                        </Typography>
                    </div>
                    {/* <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="body2">Other Stats:</Typography>
                        <Typography variant="body2" style={{ fontWeight: 700 }}>
                            Other Stats
                        </Typography>
                    </div> */}
                </div>
            ) : (
                // <Typography style={schoolSectionStyle.extraInfo}>
                //     {`No. of Employees: ${props.company.num_employees}`}{' '}
                //     <br></br>
                //     {`Location: ${
                //         props.company.loc
                //             ? props.company.loc.address.components.city
                //             : ''
                //     }, ${
                //         props.company.loc
                //             ? props.company.loc.address.components.state
                //             : ''
                //     }`}
                //     <br></br>
                //     Other stats…
                // </Typography>
                <Typography style={schoolSectionStyle.extraInfo}>
                    No. of Students: 10,257 <br></br>
                    Acceptance Rate: 9%<br></br>
                    Other stats…
                </Typography>
            )}

            <div style={schoolSectionStyle.map}>
                <GoogleMapReact
                    bootstrapURLKeys={{
                        key: 'AIzaSyCrsku3KbNkMLuy5RnC1FuzHQSU-n4Cgtw',
                    }}
                    defaultCenter={[41.8267718, -71.4025482]}
                    defaultZoom={10}
                >
                    <div
                        lat={
                            props.type === 'company'
                                ? props.company.loc.location.coordinates[1]
                                : props.school.loc.location.coordinates[1]
                        }
                        lng={
                            props.type === 'company'
                                ? props.company.loc.location.coordinates[0]
                                : props.school.loc.location.coordinates[1]
                        }
                        text="headquarters"
                    />
                </GoogleMapReact>
            </div>
        </div>
    );
};

ProfileAboutCard.propTypes = {
    type: PropTypes.oneOf(['company', 'school']),
    school: PropTypes.object,
    company: PropTypes.object,
    user: PropTypes.object,
};

export default ProfileAboutCard;
