// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { NavigationActions } from 'react-navigation';

// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SAVE_OTHER_USER: 'SAVE_OTHER_USER',

    SAVE_REVIEW_DATA_FROM_ROOM: 'SAVE_REVIEW_DATA_FROM_ROOM',

    UPDATE_SUGGESTED_HARD_SKILLS: 'UPDATE_SUGGESTED_HARD_SKILLS',
    UPDATE_SELECTED_HARD_SKILLS: 'UPDATE_SELECTED_HARD_SKILLS',

    SAVE_HARD_SKILL_RATING_INFO: 'SAVE_HARD_SKILL_RATING_INFO',
    UPDATE_HARD_SKILLS_RATING_INFO: 'UPDATE_HARD_SKILLS_RATING_INFO',

    FORMAT_SOFT_SKILLS_FOR_REVIEW: 'FORMAT_SOFT_SKILLS_FOR_REVIEW',

    UPDATE_SOFT_SKILLS_RATING_INFO: 'UPDATE_SOFT_SKILLS_RATING_INFO',

    SAVE_PRIVATE_REVIEW: 'SAVE_PRIVATE_REVIEW',
    SAVE_PUBLIC_REVIEW: 'SAVE_PUBLIC_REVIEW',

    UPDATE_OVERALL_RATING: 'UPDATE_OVERALL_RATING',
    UPDATE_HIRE_OR_ACCEPT_SCORE: 'UPDATE_HIRE_OR_ACCEPT_SCORE',

    CREATE_MAIN_REVIEW: 'CREATE_MAIN_REVIEW',
    CREATE_MAIN_REVIEW_SUCCESS: 'CREATE_MAIN_REVIEW_SUCCESS',
    CREATE_MAIN_REVIEW_FAIL: 'CREATE_MAIN_REVIEW_FAIL',

    REVIEW_ADD_SKILLS: 'REVIEW_ADD_SKILLS',

    MAIN_REVIEW_CREATE: 'MAIN_REVIEW_CREATE',
    MAIN_REVIEW_CREATE_SUCCESS: 'MAIN_REVIEW_CREATE_SUCCESS',
    MAIN_REVIEW_CREATE_FAIL: 'MAIN_REVIEW_CREATE_FAIL',

    RESET_REVIEW_DATA: 'RESET_REVIEW_DATA',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    post_id: null,
    room_id: null,
    reviewLoading: false,
    reviewError: null,
    loading: false,
    error: null,
    otherUser: null,
    selectedHardSkills: [],
    suggestedHardSkills: [
        // { id: '1', name: 'React Native' },
        // { id: '2', name: 'Javascript' },
        // { id: '3', name: 'OCaml' },
        // { id: '4', name: 'Pyret' },
        // { id: '5', name: 'Swift' },
        // { id: '6', name: 'HTML' },
        // { id: '7', name: 'CSS' },
    ],
    hardSkillsRatingInfo: [],
    softSkillsRatingInfo: [],
    privateReview: null,
    publicReview: null,
    overallRating: null,
    hireOrAcceptScore: 4,
    completedReview: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SAVE_OTHER_USER:
            return { ...state, otherUser: action.payload };
        case types.SAVE_REVIEW_DATA_FROM_ROOM:
            return {
                ...state,
                post_id: action.payload.post_id ? action.payload.post_id : null,
                room_id: action.payload.room_id,
                otherUser: action.payload.otherUser,
                type: action.payload.type,
            };
        case types.UPDATE_SUGGESTED_HARD_SKILLS:
            return { ...state, suggestedHardSkills: action.payload };
        case types.UPDATE_SELECTED_HARD_SKILLS:
            return { ...state, selectedHardSkills: action.payload };
        case types.SAVE_HARD_SKILL_RATING_INFO:
            return { ...state, hardSkillsRatingInfo: action.payload };
        case types.UPDATE_HARD_SKILLS_RATING_INFO:
            return { ...state, hardSkillsRatingInfo: action.payload };

        case types.FETCH_SOFT_SKILLS:
            return { ...state, loading: true };
        case types.FORMAT_SOFT_SKILLS_FOR_REVIEW:
            return {
                ...state,
                loading: false,
                softSkillsRatingInfo: action.payload,
            };
        case types.FETCH_SOFT_SKILLS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.UPDATE_SOFT_SKILLS_RATING_INFO:
            return { ...state, softSkillsRatingInfo: action.payload };

        case types.SAVE_PRIVATE_REVIEW:
            return { ...state, privateReview: action.payload };
        case types.SAVE_PUBLIC_REVIEW:
            return { ...state, publicReview: action.payload };

        case types.UPDATE_OVERALL_RATING:
            return { ...state, overallRating: action.payload };
        case types.UPDATE_HIRE_OR_ACCEPT_SCORE:
            return { ...state, hireOrAcceptScore: action.payload };

        case types.REVIEW_ADD_SKILLS:
            return { ...state, selectedHardSkills: action.payload };

        case types.MAIN_REVIEW_CREATE:
            return { ...state, reviewLoading: true };
        case types.MAIN_REVIEW_CREATE_SUCCESS:
            return {
                ...state,
                reviewLoading: false,
                completedReview: action.payload,
            };
        case types.MAIN_REVIEW_CREATE_FAIL:
            return {
                ...state,
                reviewLoading: false,
                reviewError: action.payload,
            };
        case types.RESET_REVIEW_DATA:
            return { ...INITIAL_STATE };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const saveReviewDataFromRoom = data => {
    return dispatch => {
        dispatch({
            type: types.SAVE_REVIEW_DATA_FROM_ROOM,
            payload: data,
        });
    };
};

export const saveOtherUser = ({ otherUser }) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_OTHER_USER,
            payload: otherUser,
        });
    };
};
export const updateSuggestedHardSkills = suggestedHardSkills => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SUGGESTED_HARD_SKILLS,
            payload: suggestedHardSkills,
        });
    };
};

export const updateSelectedHardSkills = selectedHardSkills => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SELECTED_HARD_SKILLS,
            payload: selectedHardSkills,
        });
    };
};

export const saveHardSkillRatingInfo = selectedHardSkills => {
    let formattedHardSkills = [];
    for (let hardSkill of selectedHardSkills) {
        let newHardSkill = {};
        newHardSkill.id = hardSkill.id;
        newHardSkill.name = hardSkill.name;
        newHardSkill.rating = 0;
        formattedHardSkills.push(newHardSkill);
    }

    return dispatch => {
        dispatch({
            type: types.SAVE_HARD_SKILL_RATING_INFO,
            payload: formattedHardSkills,
        });
    };
};

export const saveHardSkillsRatingInfo = hardSkillRatingInfo => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_HARD_SKILLS_RATING_INFO,
            payload: hardSkillRatingInfo,
        });
    };
};

export const formatSoftSkillsForReview = ({ softSkills }) => {
    return dispatch => {
        let formattedSoftSkills = [];
        for (let softSkill of softSkills) {
            let newSoftSkill = {};
            newSoftSkill.id = softSkill.id;
            newSoftSkill.name = softSkill.name;
            newSoftSkill.isNA = false;
            newSoftSkill.rating = 0;
            formattedSoftSkills.push(newSoftSkill);
        }
        dispatch({
            type: types.FORMAT_SOFT_SKILLS_FOR_REVIEW,
            payload: formattedSoftSkills,
        });
    };
};

export const updateHardSkillsRatingInfo = hardSkillsRatingInfo => {
    // AmplitudeAnalytics.track('Update Hard Skills', hardSkillsRatingInfo);

    return dispatch => {
        dispatch({
            type: types.UPDATE_HARD_SKILLS_RATING_INFO,
            payload: hardSkillsRatingInfo,
        });
    };
};

export const updateSoftSkillsRatingInfo = softSkillsRatingInfo => {
    // AmplitudeAnalytics.track('Update Soft Skills', softSkillsRatingInfo);

    return dispatch => {
        dispatch({
            type: types.UPDATE_SOFT_SKILLS_RATING_INFO,
            payload: softSkillsRatingInfo,
        });
    };
};

export const savePrivateReviewText = text => {
    return dispatch => {
        dispatch({
            type: types.SAVE_PRIVATE_REVIEW,
            payload: text,
        });
    };
};

export const savePublicReviewText = text => {
    return dispatch => {
        dispatch({
            type: types.SAVE_PUBLIC_REVIEW,
            payload: text,
        });
    };
};

export const updateOverallRating = overallRating => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_OVERALL_RATING,
            payload: overallRating,
        });
    };
};

export const updateHireOrAcceptScore = hireOrAcceptScore => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_HIRE_OR_ACCEPT_SCORE,
            payload: hireOrAcceptScore,
        });
    };
};

export const createMainReview = ({ test }) => {
    return dispatch => {
        dispatch({
            type: types.CREATE_MAIN_REVIEW,
        });
    };
};

export const reviewAddSkills = selectedHardSkills => {
    return dispatch => {
        dispatch({
            type: types.REVIEW_ADD_SKILLS,
            payload: selectedHardSkills,
        });
    };
};

export const mainReviewCreate = ({ review }) => {
    return dispatch => {
        dispatch({
            type: types.MAIN_REVIEW_CREATE,
            payload: review,
        });

        WS.socket.emit('mainReview:create', {
            review,
        });
        WS.socket.once('mainReview:create', res => {
            if (res.success) {
                reviewCreateSuccess(dispatch, res.data);
            } else {
                reviewCreateFail(dispatch, res.message);
            }
        });
    };
};
const reviewCreateSuccess = (dispatch, review) => {
    // AmplitudeAnalytics.track('Review Create Success', review);
    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: review.reviewer_id,
    //     cid: review.reviewer_id,
    // });
    // analytics
    //     .event(new Event('Review', 'Review Create Success', review.reviewer_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.MAIN_REVIEW_CREATE_SUCCESS,
        payload: review,
    });
};
const reviewCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('reviewCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.MAIN_REVIEW_CREATE_FAIL,
        payload: msg.message,
    });
};

export const resetReviewData = () => {
    return dispatch => {
        dispatch({
            type: types.RESET_REVIEW_DATA,
        });

        // dispatch(
        //     NavigationActions.navigate({
        //         key: 'Messenger',
        //         routeName: 'Messenger',
        //     })
        // );
    };
};
