import React from 'react';
import { Route, Switch } from 'react-router';

import PrivateRoute from './PrivateRoute';
import MobileHOC from './MobileHOC';

// createPost
import PostNewProjectScreen from '../components/createPost/PostNewProjectScreen';
import PostChooseProjectTypeScreen from '../components/createPost/PostChooseProjectTypeScreen';
import PostChooseProjectTemplateScreen from '../components/createPost/PostChooseProjectTemplateScreen';

// login
import ForgotPasswordScreen from '../components/login/ForgotPasswordScreen';
import HomeScreen from '../components/login/HomeScreen';
import IndexScreen from '../components/login/IndexScreen';
import LoadingScreen from '../components/login/LoadingScreen';
import WelcomeScreen from '../components/login/WelcomeScreen';
import DownloadScreen from '../components/login/DownloadScreen';
import AboutScreen from '../components/login/AboutScreen';
import PrivacyPolicyScreen from '../components/login/PrivacyPolicyScreen';
import TermsOfServiceScreen from '../components/login/TermsOfServiceScreen';

// mainfeed
import DetailedProfileScreen from '../components/mainFeed/DetailedProfileScreen';
import DetailedPostScreen from '../components/mainFeed/DetailedPostScreen';
import DetailedCompanyScreen from '../components/mainFeed/DetailedCompanyScreen';
// import PangeansScreen from '../components/mainFeed/PangeansScreen';
// import ProjectsScreen from '../components/mainFeed/ProjectsScreen';
import PangeansMobileScreen from '../components/mainFeed/PangeansMobileScreen';
import ProjectsMobileScreen from '../components/mainFeed/ProjectsMobileScreen';

// menu
import SettingsScreen from '../components/menu/SettingsScreen';

// myProfile
import MyProfileScreen from '../components/myProfile/MyProfileScreen';
import MyCompanyScreen from '../components/myProfile/MyCompanyScreen';

// onboarding
import AddAccountInfoScreen from '../components/onboarding/common/AddAccountInfoScreen';
import AddNameScreen from '../components/onboarding/common/AddNameScreen';
import AddNewSchoolScreen from '../components/onboarding/common/AddNewSchoolScreen';
import AddNewSchoolSuccessScreen from '../components/onboarding/common/AddNewSchoolSuccessScreen';
import AddPhoneNumberScreen from '../components/onboarding/common/AddPhoneNumberScreen';
import BusinessAddCompanyInfoScreen from '../components/onboarding/common/BusinessAddCompanyInfoScreen';
import BusinessAddProfileInfoScreen from '../components/onboarding/common/BusinessAddProfileInfoScreen';
import BusinessCompanyExistsScreen from '../components/onboarding/common/BusinessCompanyExistsScreen';
import BusinessCompleteSignUpScreen from '../components/onboarding/common/BusinessCompleteSignUpScreen';
import BusinessSelectCompanyScreen from '../components/onboarding/common/BusinessSelectCompanyScreen';
import SelectUserTypeScreen from '../components/onboarding/common/SelectUserTypeScreen';
import StudentAddProfessionalInfoScreen from '../components/onboarding/common/StudentAddProfessionalInfoScreen';
import StudentAddProfileInfoScreen from '../components/onboarding/common/StudentAddProfileInfoScreen';
import StudentCompleteSignUpScreen from '../components/onboarding/common/StudentCompleteSignUpScreen';
import VerifyPhoneNumberScreen from '../components/onboarding/common/VerifyPhoneNumberScreen';

// subscription
import CompletedPurchaseSubscriptionScreen from '../components/subscription/CompletedPurchaseSubscriptionScreen';
import PickSubscriptionScreen from '../components/subscription/PickSubscriptionScreen';
import PurchaseSubscriptionScreen from '../components/subscription/PurchaseSubscriptionScreen';

// transactionMessenger
import MessengerScreen from '../components/transactionMessenger/MessengerScreen';

const routes = (
    <div>
        <Switch>
            {/* createPost */}
            {/* <PrivateRoute
                exact
                path="/postNewProject"
                component={PostNewProjectScreen}
            />
            <PrivateRoute
                exact
                path="/postChooseProjectType"
                component={PostChooseProjectTypeScreen}
            />
            <PrivateRoute
                exact
                path="/postChooseProjectTemplate"
                component={PostChooseProjectTemplateScreen}
            /> */}

            {/* login */}
            <Route
                exact
                path="/forgotPassword"
                component={ForgotPasswordScreen}
            />
            <Route exact path="/home" component={HomeScreen} />
            <Route exact path="/index" component={IndexScreen} />
            <Route exact path="/" component={LoadingScreen} />

            <Route
                exact
                path="/welcome"
                render={routeProps => (
                    <MobileHOC>
                        <WelcomeScreen {...routeProps} />
                    </MobileHOC>
                )}
            />

            <Route exact path="/download" component={DownloadScreen} />
            <Route exact path="/about" component={AboutScreen} />

            <Route
                exact
                path="/privacyPolicy"
                component={PrivacyPolicyScreen}
            />
            <Route
                exact
                path="/termsOfService"
                component={TermsOfServiceScreen}
            />

            {/* mainFeed */}
            <PrivateRoute
                exact
                path="/detailedCompany"
                component={DetailedCompanyScreen}
            />
            <PrivateRoute
                exact
                path="/detailedProfile"
                component={DetailedProfileScreen}
            />
            <PrivateRoute
                exact
                path="/detailedPost"
                component={DetailedPostScreen}
            />
            <PrivateRoute
                exact
                path="/pangeans"
                component={PangeansMobileScreen}
            />
            <PrivateRoute
                exact
                path="/projects"
                component={ProjectsMobileScreen}
            />

            {/* menu */}
            {/* <PrivateRoute exact path="/settings" component={SettingsScreen} /> */}

            {/* myProfile */}
            <PrivateRoute exact path="/myProfile" component={MyProfileScreen} />
            {/* this appears redundant, we can just use detailedCompany */}
            <PrivateRoute exact path="/myCompany" component={MyCompanyScreen} />

            {/* onboarding */}
            <Route
                exact
                path="/addAccountInfo"
                component={AddAccountInfoScreen}
            />
            <Route
                exact
                path="/addName"
                render={routeProps => (
                    <MobileHOC>
                        <AddNameScreen {...routeProps} />
                    </MobileHOC>
                )}
            />
            <Route exact path="/addNewSchool" component={AddNewSchoolScreen} />
            <Route
                exact
                path="/addNewSchoolSuccess"
                component={AddNewSchoolSuccessScreen}
            />
            <Route
                exact
                path="/addPhoneNumber"
                component={AddPhoneNumberScreen}
            />
            <Route
                exact
                path="/businessAddCompanyInfo"
                component={BusinessAddCompanyInfoScreen}
            />
            <Route
                exact
                path="/businessAddProfileInfo"
                component={BusinessAddProfileInfoScreen}
            />
            <Route
                exact
                path="/businessCompanyExists"
                component={BusinessCompanyExistsScreen}
            />
            <Route
                exact
                path="/businessCompleteSignUp"
                component={BusinessCompleteSignUpScreen}
            />
            <Route
                exact
                path="/businessSelectCompany"
                component={BusinessSelectCompanyScreen}
            />
            <Route
                exact
                path="/selectUserType"
                component={SelectUserTypeScreen}
            />
            <Route
                exact
                path="/studentAddProfessionalInfo"
                component={StudentAddProfessionalInfoScreen}
            />
            <Route
                exact
                path="/studentAddProfileInfo"
                component={StudentAddProfileInfoScreen}
            />
            <Route
                exact
                path="/studentCompleteSignUp"
                component={StudentCompleteSignUpScreen}
            />
            <Route
                exact
                path="/verifyPhoneNumber"
                component={VerifyPhoneNumberScreen}
            />

            {/* subscription */}
            {/* <PrivateRoute
                exact
                path="/completedPurchaseSubscription"
                component={CompletedPurchaseSubscriptionScreen}
            />
            <PrivateRoute
                exact
                path="/pickSubscription"
                component={PickSubscriptionScreen}
            />
            <PrivateRoute
                exact
                path="/purchaseSubscription"
                component={PurchaseSubscriptionScreen}
            /> */}

            {/* transactionMessenger */}
            {/* <PrivateRoute exact path="/messenger" component={MessengerScreen} /> */}
        </Switch>
    </div>
);

export default routes;
