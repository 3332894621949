import React from 'react';
import Colors from '../../constants/Colors';

import {
    Menu,
    AppBar,
    Toolbar,
    TextField,
    withStyles,
    Link,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Container,
    Button,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';

import { push } from 'connected-react-router';
import { store } from '../..';

import { WhiteButton } from './';

/*
 * This file contains a common component for header which allows text, a left icon,
 * a right icon, or any combination of the three to be passed in such that they
 * will all be correctly aligned. Left and right icons must have width less than
 * 65px and must already have a onPress method attached to them. See end of document
 * and ProfileScreen.js for implementation guidelines.
 */
const styles = {
    container: {
        backgroundColor: '#006C9E',
        // paddingLeft: 42,
        // paddingRight: 42,
        display: 'flex',
        justifyContent: 'space-between',
    },

    leftContainer: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
    },

    logoImg: {
        marginLeft: 15,
        marginRight: 15,
        objectFit:'contain'
    },

    textInput: {
        display: 'flex',
        borderRadius: 20,
        // border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        // paddingTop: 3,
        // paddingBottom: 3,
        flex: 1,
        fontSize: 16,
    },

    linksContainer: {
        flex: 1.0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        // flexDirection: 'row-reverse',
    },

    activeLink: {
        color: 'white',
        marginRight: 20,
        fontSize: 16,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '700',
    },

    link: {
        color: 'white',
        marginRight: 20,
        fontSize: 16,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
    },
};

const WelcomeHeader = ({ activeLink, history }) => {
    const [windowWidth, setWindowWidth] = React.useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const handleMenuIconClick = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    React.useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
    });

    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    };

    const sideDrawerContent = history => (
        <div
            className={{ width: 'auto' }}
            role="presentation"
            onClick={handleMenuIconClick}
            onKeyDown={handleMenuIconClick}
        >
            <List>
                {[
                    { text: 'For Startups', route: '/' },
                    // { text: 'For Students', route: '/students' },
                    { text: 'Log In', route: '/welcome' },
                    { text: 'Download', route: '/download' },
                    { text: 'Sign Up', route: '/addName' },
                ].map(linkObj => (
                    <ListItem
                        button
                        key={linkObj.text}
                        onClick={event => {
                            event.preventDefault();
                            store.dispatch(push(linkObj.route));
                        }}
                    >
                        <ListItemText primary={linkObj.text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <AppBar position="sticky" style={{ height: 64, zIndex: 3 }}>
            <Container>
                <Toolbar
                    style={styles.container}
                    // variant="dense"
                    disableGutters={true}
                >
                    <div style={styles.leftContainer}>
                        <Link href="/">
                            <img
                                src="/images/wordmark-white.png"
                                height={64}
                                // width={40}
                                alt="Pangea logo"
                                style={styles.logoImg}
                            />
                            {/* <img
                                src={globe}
                                height={40}
                                width={40}
                                alt="Pangea logo"
                                style={styles.logoImg}
                            ></img> */}
                        </Link>
                    </div>
                    {windowWidth > 600 ? (
                        <div style={styles.linksContainer}>
                            <Link
                                color="inherit"
                                href="/"
                                style={
                                    activeLink === 'index'
                                        ? styles.activeLink
                                        : styles.link
                                }
                                onClick={event => {
                                    event.preventDefault();
                                    store.dispatch(push('/'));
                                }}
                            >
                                For Startups
                            </Link>
                            {/* <Link
                                color="inherit"
                                href="/students"
                                style={
                                    activeLink === 'students'
                                        ? styles.activeLink
                                        : styles.link
                                }
                            >
                                For Students
                            </Link> */}
                            <Link
                                color="inherit"
                                href="/welcome"
                                style={
                                    activeLink === 'login'
                                        ? styles.activeLink
                                        : styles.link
                                }
                                onClick={event => {
                                    event.preventDefault();
                                    store.dispatch(push('/welcome'));
                                }}
                            >
                                Log In
                            </Link>
                            <WhiteButton
                                onClick={event => {
                                    event.preventDefault();
                                    store.dispatch(push('/download'));
                                }}
                            >
                                Download
                            </WhiteButton>
                            <div style={{ width: 20 }} />
                            <WhiteButton
                                onClick={event => {
                                    event.preventDefault();
                                    store.dispatch(push('/addName'));
                                }}
                            >
                                Sign Up
                            </WhiteButton>
                        </div>
                    ) : (
                        <div style={styles.linksContainer}>
                            <IconButton onClick={handleMenuIconClick}>
                                <MenuIcon
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            </IconButton>
                        </div>
                    )}
                </Toolbar>
            </Container>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            >
                {sideDrawerContent(history)}
            </Drawer>
        </AppBar>
    );
};

WelcomeHeader.propTypes = {};

export default withRouter(WelcomeHeader);
