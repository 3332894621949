import React, { useState } from 'react';
import './Compose.css';
import { TextField, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import PaymentIcon from '@material-ui/icons/Payment';

import GlobalStyles from '../../../../constants/GlobalStyles';

const globalStyles = GlobalStyles;

const styles = {
    compose: {
        position: 'relative',
        bottom: 0,
        left: 0,
        padding: 10,
        display: 'flex',
        alignItems: 'center',
        borderTop: '1px solid #eeeef1',
        // height: 50,
    },
    composeInput: {
        flex: 1,
        border: 'none',
        fontSize: 14,
        height: 40,
        background: 'none',
    },
    composeContainer: {
        position: 'fixed',
        bottom: 0,
        display: 'flex',
        width: '100%',
    },
    messageInput: {
        marginBottom: 0,
    },
};

export default function Compose(props) {
    const [message, setMessage] = useState('');

    return (
        <div style={styles.compose}>
            <IconButton>
                <PaymentIcon />
            </IconButton>
            <TextField
                placeholder={'Type a message...'}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        ...globalStyles.textInput,
                        ...styles.messageInput,
                    },
                }}
                fullWidth
                value={message}
                onChange={event => {
                    setMessage(event.target.value);
                }}
                maxLength={5000}
                multiline
                style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: 5,
                    marginRight: 5,
                }}
            />
            <IconButton
                onClick={() => {
                    props.onSend(message);
                    setMessage('');
                }}
            >
                <SendIcon />
            </IconButton>
        </div>
    );
}
