// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { NavigationActions } from 'react-navigation';
// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import Environment from '../constants/Environment';

import { updateUser } from './UserSessionDuck';
import { updateProposedSchool } from './ProposedSchoolDuck';
import { postUpdate } from './MyPostsDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_GEO: 'create_geo',
    CREATE_GEO_SUCCESS: 'create_geo_success',
    CREATE_GEO_FAIL: 'create_geo_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    geo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_GEO:
            return { ...state, loading: true };
        case types.CREATE_GEO_SUCCESS:
            return { ...state, loading: false, geo: action.payload };
        case types.CREATE_GEO_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const createGeo = ({ address, relatedTo, nextScreen }) => {
    return dispatch => {
        dispatch({ type: types.CREATE_GEO });

        WS.socket.emit('geo:create', {
            address,
            related_to: relatedTo,
        });
        WS.socket.once('geo:create', res => {
            if (res.success) {
                createGeoSuccess(dispatch, res.data, nextScreen);
            } else {
                createGeoFail(dispatch, res.message);
            }
        });
    };
};

const createGeoSuccess = (dispatch, res, nextScreen) => {
    // AmplitudeAnalytics.track('Geo Create Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(new Event('Geo', 'Geo Create Success', res.owner_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.CREATE_GEO_SUCCESS,
        payload: res,
    });

    if (res.related_to.type === 'user') {
        dispatch(
            updateUser({ user: { id: res.related_to.id, geo_id: res.id } })
        );

        // dispatch(
        //     NavigationActions.navigate({
        //         key: nextScreen,
        //         routeName: nextScreen,
        //     })
        // );
    } else if (res.related_to.type === 'school') {
        // not used
        dispatch(
            updateProposedSchool({
                school: { id: res.related_to.id, geo_id: res.id },
            })
        );

        // dispatch(
        //     NavigationActions.navigate({
        //         key: 'AddNewSchoolSuccess',
        //         routeName: 'AddNewSchoolSuccess',
        //     })
        // );
    } else if (res.related_to.type === 'post') {
        // update post related to new geo with the new geo_id
        dispatch(
            postUpdate({
                post: { id: res.related_to.id, geo_id: res.id },
            })
        );
    }
};

const createGeoFail = (dispatch, msg) => {
    // Sentry.captureMessage('createGeoFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CREATE_GEO_FAIL,
        payload: msg,
    });
};
