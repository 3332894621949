import React, { Component } from 'react';

import {} from '@material-ui/core';

const styles = {
    rootContainer: {
        border: 'solid 1px #979797',
        backgroundColor: '#f6f6f6',
        display: 'flex',
        flexDirection: 'column',
    },

    searchCategoryContainer: {
        paddingTop: 14,
        paddingBottom: 14,
        paddingLeft: 20,
        paddingRight: 20,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    searchCategoryItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
    },

    searchCategoryItemIcon: {
        height: 16,
        width: 16,
        backgroundColor: 'grey',
        marginRight: 5,
    },

    rule: {
        border: 'solid 1px #979797',
        width: '100%',
    },

    recentText: {
        marginTop: 14,
        marginBottom: 15,
        marginLeft: 20,
        marginRight: 20,
    },

    peopleContainer: {
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    personContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 40,
    },

    personImg: {
        width: 60,
        height: 60,
        borderRadius: 30,
        border: 'solid 1px #979797',
        backgroundColor: '#d8d8d8',
        marginBottom: 10,
    },

    searchHistoryContainer: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 20,
        marginRight: 20,
        marginBottom: 5,
    },

    horizontalSearchItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 15,
    },
};

const SearchCategoryItem = ({ name, icon, style }) => {
    return (
        <div style={style ? style : styles.searchCategoryItem}>
            <div style={styles.searchCategoryItemIcon} />
            <span>{name}</span>
        </div>
    );
};

const PersonInfo = () => {
    return (
        <div style={styles.personContainer}>
            <div style={styles.personImg} />
            <span>First Last</span>
        </div>
    );
};

class SearchDropdownView extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div style={styles.rootContainer}>
                <div style={styles.searchCategoryContainer}>
                    <SearchCategoryItem name="Pangeans" />
                    <SearchCategoryItem name="Projects" />
                    <SearchCategoryItem name="Skills" />
                    <SearchCategoryItem name="Schools" />
                </div>

                <hr style={styles.rule}></hr>

                <h3 style={styles.recentText}>Recent</h3>

                <div style={styles.peopleContainer}>
                    <PersonInfo />
                    <PersonInfo />
                    <PersonInfo />
                    <PersonInfo />
                    <PersonInfo />
                </div>

                <div style={styles.searchHistoryContainer}>
                    <SearchCategoryItem
                        name="Ivy League"
                        style={styles.horizontalSearchItem}
                    />

                    <SearchCategoryItem
                        name="Art School"
                        style={styles.horizontalSearchItem}
                    />
                </div>
            </div>
        );
    }
}

export default SearchDropdownView;
