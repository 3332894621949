import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import {} from '@material-ui/core';

import Environment from '../../../constants/Environment';

import { logoutUser } from '../../../ducks/UserSessionDuck';

import { store } from '../../..';

import { push } from 'connected-react-router';

const styles = {
    rootContainer: {
        width: 300,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 10,
        paddingBottom: 17,
        paddingLeft: 10,
        paddingRight: 10,
    },

    personaInfoContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    personImg: {
        width: 60,
        height: 60,
        borderRadius: 30,
        border: 'solid 1px #979797',
        backgroundColor: '#d8d8d8',
    },

    personalBio: {
        marginLeft: 20,
        flexDirection: 'column',
    },

    personalNameText: {
        display: 'block',
        marginBottom: 5,
    },

    personalSchoolText: {
        display: 'block',
    },

    rule: {
        border: 'solid 1px #979797',
        width: '100%',
    },

    viewProfileLinkContainer: {
        marginTop: 17,
        marginBottom: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    boldLink: {
        display: 'block',
        color: 'black',
        textDecoration: 'none',
        fontWeight: 600,
    },

    subscriptionContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    linksContainer: {
        marginTop: 19.5,
        display: 'column',
    },

    boldLinkRow: {
        display: 'block',
        color: 'black',
        textDecoration: 'none',
        fontWeight: 600,
        marginBottom: 13,
    },
};

class HeaderDropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div
                style={
                    this.props.containerStyle
                        ? this.props.containerStyle
                        : styles.rootContainer
                }
            >
                <div style={styles.personaInfoContainer}>
                    {this.props.user && this.props.user.profile_pic ? (
                        <img
                            src={`https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${this.props.user.id}/profilePic/${this.props.user.profile_pic}`}
                            alt={''}
                            height="60"
                            width="60"
                            style={{
                                borderRadius: 30,
                            }}
                        />
                    ) : (
                        <div style={styles.personImg} />
                    )}

                    <div style={styles.personalBio}>
                        <span style={styles.personalNameText}>
                            {this.props.user
                                ? this.props.user.full_name
                                : 'First Last'}
                        </span>
                        {this.props.user &&
                        this.props.user.user_type === 'student' ? (
                            <div>
                                <span style={styles.personalSchoolText}>
                                    {this.props.user.intended_major}
                                </span>
                                <span style={styles.personalSchoolText}>
                                    {this.props.user.school.name}
                                </span>
                            </div>
                        ) : (
                            <div>
                                {this.props.user &&
                                this.props.user.your_title ? (
                                    <span style={styles.personalSchoolText}>
                                        {this.props.user.your_title}
                                    </span>
                                ) : null}
                                {this.props.user && this.props.user.company ? (
                                    <span style={styles.personalSchoolText}>
                                        {this.props.user.company.company_name}
                                    </span>
                                ) : null}
                            </div>
                        )}
                    </div>
                </div>
                {/* <PersonalInfo
                    fullName={
                        this.props.user
                            ? this.props.user.full_name
                            : 'First Last'
                    }
                /> */}

                <div style={styles.viewProfileLinkContainer}>
                    <a
                        href="/"
                        style={styles.boldLink}
                        onClick={e => {
                            e.preventDefault();
                            store.dispatch(push('/myProfile'));
                        }}
                    >
                        View Profile
                    </a>
                </div>

                {this.props.user && this.props.user.company && (
                    <div style={styles.subscriptionContainer}>
                        <span>
                            Subscription Tier:{' '}
                            {this.props.user.company.subscription_plan}
                        </span>

                        {/* <a href="/pickSubscription" style={styles.boldLink}>
                            Manage
                        </a> */}
                    </div>
                )}

                <hr style={styles.rule} />

                <div style={styles.linksContainer}>
                    {this.props.user && this.props.user.company && (
                        <a href="/myCompany" style={styles.boldLinkRow}>
                            My Company
                        </a>
                    )}

                    {/* <a href="/projects" style={styles.boldLinkRow}>
                        Posts and Activity
                    </a>

                    <a href="/settings" style={styles.boldLinkRow}>
                        Settings
                    </a> */}

                    <a
                        href="/"
                        style={styles.boldLinkRow}
                        onClick={e => {
                            e.preventDefault();
                            this.props.logoutUser();
                        }}
                    >
                        Sign Out
                    </a>
                </div>
            </div>
        );
    }
}

HeaderDropdown.propTypes = {
    user: PropTypes.object.isRequired,
};

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, { logoutUser })(HeaderDropdown);
