module.exports = {
    getRNPickerHourlyWages: function() {
        return [
            {
                label: '$11',
                value: '11',
            },
            {
                label: '$12',
                value: '12',
            },
            {
                label: '$13',
                value: '13',
            },
            {
                label: '$14',
                value: '14',
            },
            {
                label: '$15',
                value: '15',
            },
            {
                label: '$16',
                value: '16',
            },
            {
                label: '$17',
                value: '17',
            },
            {
                label: '$18',
                value: '18',
            },
            {
                label: '$19',
                value: '19',
            },
            {
                label: '$20',
                value: '20',
            },
            {
                label: '$21',
                value: '21',
            },
            {
                label: '$22',
                value: '22',
            },
            {
                label: '$23',
                value: '23',
            },
            {
                label: '$24',
                value: '24',
            },
            {
                label: '$25',
                value: '25',
            },
            {
                label: '$26',
                value: '26',
            },
            {
                label: '$27',
                value: '27',
            },
            {
                label: '$28',
                value: '28',
            },
            {
                label: '$29',
                value: '29',
            },
            {
                label: '$30',
                value: '30',
            },
            {
                label: '$31',
                value: '31',
            },
            {
                label: '$32',
                value: '32',
            },
            {
                label: '$33',
                value: '33',
            },
            {
                label: '$34',
                value: '34',
            },
            {
                label: '$35',
                value: '35',
            },
            {
                label: '$40',
                value: '40',
            },
            {
                label: '$45',
                value: '45',
            },
            {
                label: '$50',
                value: '50',
            },
            {
                label: '$55',
                value: '55',
            },
            {
                label: '$60',
                value: '60',
            },
            {
                label: '$65',
                value: '65',
            },
            {
                label: '$70',
                value: '70',
            },
            {
                label: '$75',
                value: '75',
            },
            {
                label: '$80',
                value: '80',
            },
            {
                label: '$85',
                value: '85',
            },
            {
                label: '$90',
                value: '90',
            },
            {
                label: '$100',
                value: '100',
            },
            {
                label: '$110',
                value: '110',
            },
            {
                label: '$120',
                value: '120',
            },
            {
                label: '$130',
                value: '130',
            },
            {
                label: '$140',
                value: '140',
            },
            {
                label: '$150',
                value: '150',
            },
            {
                label: '$160',
                value: '160',
            },
            {
                label: '$170',
                value: '170',
            },
            {
                label: '$180',
                value: '180',
            },
            {
                label: '$190',
                value: '190',
            },
            {
                label: '$200',
                value: '200',
            },
            {
                label: '$210',
                value: '210',
            },
            {
                label: '$220',
                value: '220',
            },
            {
                label: '$230',
                value: '230',
            },
            {
                label: '$240',
                value: '240',
            },
            {
                label: '$250',
                value: '250',
            },
            {
                label: '$260',
                value: '260',
            },
            {
                label: '$270',
                value: '270',
            },
            {
                label: '$280',
                value: '280',
            },
            {
                label: '$290',
                value: '290',
            },
            {
                label: '$300',
                value: '300',
            },
        ];
    },
    getPickerHourlyWageValues: function() {
        return [
            11,
            12,
            13,
            14,
            15,
            16,
            17,
            18,
            19,
            20,
            21,
            22,
            23,
            24,
            25,
            26,
            27,
            28,
            29,
            30,
            31,
            32,
            33,
            34,
            35,
            40,
            45,
            50,
            55,
            60,
            65,
            70,
            75,
            80,
            90,
            100,
            110,
            120,
            130,
            140,
            150,
            160,
            170,
            180,
            190,
            200,
            210,
            220,
            230,
            240,
            250,
            260,
            270,
            280,
            290,
            300,
        ];
    },
};
