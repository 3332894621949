import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Footer, CustomContainer } from '../common';

import {
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    Radio,
    Link,
} from '@material-ui/core';

import { Header, AddCreditCardForm } from '../common';
import Colors from '../../constants/Colors';

import { charge, saveStripeCardToken } from '../../ducks/SubscriptionsDuck';

const styles = {
    rootContainer: {
        paddingTop: 49,
        paddingBottom: 60,
        backgroundColor: '#f2f2f7',
    },

    purchaseSubscriptionText: {
        margin: 0,
        padding: 0,
        fontWeight: 'bold',
        marginBottom: 41,
    },

    planCard: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardContent: {
        margin: 0,
        padding: 0,
    },

    cardHeaderText: {
        fontWeight: 'normal',
        marginBottom: 8,
    },

    planTitle: {
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 20,
    },

    planTitleDuration: {
        fontWeight: 'normal',
        marginTop: 20,
        marginBottom: 20,
        marginLeft: 5,
    },

    featuresList: {
        margin: 0,
        padding: 0,
        paddingLeft: 20,
        marginBottom: 20,
    },

    featureListItemAvailable: {
        padding: '0px 0px 10px 10px',
    },

    featureListItemUnavailable: {
        padding: '0px 0px 10px 10px',
    },

    purchaseSubscriptionBtn: {
        width: '100%',
        marginRight: 16,
        borderRadius: 25,
    },

    purchaseSubscriptionActionText: {
        fontWeight: 'bold',
    },

    priceCard: {
        padding: 20,
        paddingTop: 30,
    },

    priceItemRow: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 19,
        marginBottom: 19,
    },

    priceText: {
        fontWeight: 'normal',
    },

    shortHorizontalLine: {
        width: 121,
        border: 'solid 1px #c7c7c7',
    },

    totalPriceText: {
        fontWeight: 'bold',
    },
};

const paymentCardStyles = {
    cardContainer: {
        padding: 20,
        paddingTop: 30,
        marginBottom: 20,
    },

    cardContent: {
        margin: 0,
        padding: 0,

        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
    },

    rule: {
        border: 'solid 1px #c7c7cc',
    },

    cardOptionContainer: {
        marginTop: 30,
        marginBottom: 30,
        display: 'flex',
        alignItems: 'center',
    },

    cardRadio: {
        marginLeft: 2,
        marginRight: 22,
    },

    cardImage: {
        width: 106,
        height: 66,
        borderRadius: 2,
    },

    selectedCardImage: {
        width: 106,
        height: 66,
        borderRadius: 2,
        border: 'solid 2px #006c9e',
        marginRight: 20,
    },

    selectedCardInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.pangeaBlue,
    },

    cardInfoContainer: {
        display: 'flex',
        flexDirection: 'column',
        color: '#8e8e93',
    },

    cardNameText: {
        fontWeight: 'bold',
        marginBottom: 10,
    },

    cardSubtitle: {
        fontWeight: 'normal',
    },

    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginTop: 10,
    },

    actionLinkText: {
        fontWeight: 'normal',
    },
};

class PurchaseSubscriptionScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            shouldAutoRenewSubscription: false,
            visibleModal: null,
            cvc: '',
            expiry: '',
            focus: '',
            name: '',
            number: '',
        };

        this.handleClickShouldAutoRenew = this.handleClickShouldAutoRenew.bind(
            this
        );
    }

    handleClickShouldAutoRenew() {
        this.setState(currState => {
            return {
                shouldAutoRenewSubscription: !currState.shouldAutoRenewSubscription,
            };
        });
    }

    _renderPaymentCard() {
        // const [selectedCardTypeStr, setSelectedCardTypeStr] = React.useState(
        //     'a'
        // );

        // const handleChange = event => {
        //     setSelectedCardTypeStr(event.target.value);
        // };

        return (
            <Card style={paymentCardStyles.cardContainer}>
                <Typography variant="h4" style={styles.cardHeaderText}>
                    Payment Method
                </Typography>

                <hr style={paymentCardStyles.rule}></hr>

                <div style={paymentCardStyles.cardOptionContainer}>
                    <Radio
                        checked={true}
                        onChange={() => {
                            console.log('radio pressed');
                        }}
                        value="Visa"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'Visa Radio' }}
                        style={paymentCardStyles.cardRadio}
                    />

                    <img
                        src="/"
                        alt="Visa Card Logo"
                        style={paymentCardStyles.selectedCardImage}
                    />

                    <div style={paymentCardStyles.selectedCardInfoContainer}>
                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardNameText}
                        >
                            American Express
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardSubtitle}
                        >
                            Ending in 0000
                        </Typography>
                    </div>
                </div>

                <div style={paymentCardStyles.cardOptionContainer}>
                    <Radio
                        checked={false}
                        onChange={() => {
                            console.log('radio pressed');
                        }}
                        value="PayPal"
                        name="radio-button-demo"
                        inputProps={{ 'aria-label': 'PayPal Radio' }}
                        style={paymentCardStyles.cardRadio}
                    />

                    <img
                        src="/"
                        alt="PayPal Card Logo"
                        style={paymentCardStyles.cardImage}
                    />

                    <div style={paymentCardStyles.cardInfoContainer}>
                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardNameText}
                        >
                            PayPal
                        </Typography>

                        <Typography
                            variant="subtitle1"
                            style={paymentCardStyles.cardSubtitle}
                        >
                            jamie@pangea.app
                        </Typography>
                    </div>
                </div>

                <div style={paymentCardStyles.actionContainer}>
                    <Link
                        href="#"
                        onClick={e => {
                            e.preventDefault();
                            this.setState({ visibleModal: 1 });
                        }}
                    >
                        <Typography
                            variant="h6"
                            style={paymentCardStyles.actionLinkText}
                        >
                            New Payment Method
                        </Typography>
                    </Link>
                </div>
            </Card>
        );
    }

    handleInputFocus = e => {
        this.setState({ focus: e.target.name });
    };

    handleInputChange = e => {
        const { name, value } = e.target;

        this.setState({ [name]: value });
    };

    render() {
        return (
            <div>
                <Header hasSearch />
                <AddCreditCardForm
                    isVisible={this.state.visibleModal === 1}
                    close={() => {
                        this.setState({ visibleModal: null });
                    }}
                    onSubmit={() => {
                        console.log('onSubmit');
                    }}
                />
                {/* <Dialog
                    onClose={() => {
                        console.log('onClose');
                        this.setState({ visibleModal: null });
                    }}
                    open={this.state.visibleModal === 1}
                >
                    <DialogTitle>Add Payment Method</DialogTitle>
                    <div id="PaymentForm" style={{ width: 500, height: 500 }}>
                        <Cards
                            cvc={this.state.cvc}
                            expiry={this.state.expiry}
                            focus={this.state.focus}
                            name={this.state.name}
                            number={this.state.number}
                        />
                        <form>
                            <input
                                type="tel"
                                name="number"
                                placeholder="Card Number"
                                onChange={this.handleInputChange}
                                onFocus={this.handleInputFocus}
                            />
                            ...
                        </form>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.okButton}
                            onClick={() => {
                                console.log('save creidt card');
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </Dialog> */}

                <div style={styles.rootContainer}>
                    <CustomContainer>
                        <Typography
                            variant="subtitle1"
                            style={styles.purchaseSubscriptionText}
                        >
                            Purchase Subscription
                        </Typography>

                        <Grid container spacing={3}>
                            <Grid item md={7}>
                                <Card style={styles.planCard}>
                                    <CardContent style={styles.cardContent}>
                                        <Typography
                                            variant="h4"
                                            style={styles.cardHeaderText}
                                        >
                                            Chosen Subscription
                                        </Typography>

                                        <hr
                                            style={{
                                                border: 'solid 1px #c7c7cc',
                                            }}
                                        ></hr>

                                        <div style={{ display: 'flex' }}>
                                            <Typography
                                                variant="h3"
                                                style={styles.planTitle}
                                            >
                                                {
                                                    this.props
                                                        .selectedSubscription
                                                        .planType
                                                }{' '}
                                                Subscription
                                            </Typography>

                                            <Typography
                                                variant="h4"
                                                style={styles.planTitleDuration}
                                            >
                                                {this.props.selectedSubscription
                                                    .yearlySelected
                                                    ? '(Annually)'
                                                    : '(Monthly)'}
                                            </Typography>
                                        </div>

                                        <ul style={styles.featuresList}>
                                            {[
                                                {
                                                    name:
                                                        'Unlimited project postings',
                                                    available: true,
                                                },
                                                {
                                                    name:
                                                        'Engage up to 25 students monthly',
                                                    available: true,
                                                },
                                                {
                                                    name: '3 Employee Accounts',
                                                    available: true,
                                                },
                                                {
                                                    name: 'Customer Support',
                                                    available: true,
                                                },
                                            ].map(feature => {
                                                return (
                                                    <li
                                                        style={
                                                            feature.available
                                                                ? styles.featureListItemAvailable
                                                                : styles.featureListItemUnavailable
                                                        }
                                                    >
                                                        <Typography variant="subtitle1">
                                                            {feature.name}
                                                        </Typography>
                                                    </li>
                                                );
                                            })}
                                        </ul>

                                        {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={
                                                        this.state
                                                            .shouldAutoRenewSubscription
                                                    }
                                                    onChange={
                                                        this
                                                            .handleClickShouldAutoRenew
                                                    }
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Automatically renew
                                                    subscription on September
                                                    15, 2020
                                                </Typography>
                                            }
                                        /> */}
                                    </CardContent>
                                </Card>

                                {this._renderPaymentCard()}

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    style={styles.purchaseSubscriptionBtn}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        style={
                                            styles.purchaseSubscriptionActionText
                                        }
                                    >
                                        Purchase Subscription
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid item md={5}>
                                <Card style={styles.priceCard}>
                                    <CardContent style={styles.cardContent}>
                                        <Typography
                                            variant="h4"
                                            style={styles.cardHeaderText}
                                        >
                                            Order Summary
                                        </Typography>

                                        <hr
                                            style={{
                                                border: 'solid 1px #c7c7cc',
                                            }}
                                        ></hr>

                                        <div style={styles.priceItemRow}>
                                            <Typography
                                                variant={'body2'}
                                                style={styles.priceText}
                                            >
                                                {
                                                    this.props
                                                        .selectedSubscription
                                                        .planType
                                                }{' '}
                                                Subscription (1-Year)
                                            </Typography>

                                            <Typography
                                                variant={'body2'}
                                                style={styles.priceText}
                                            >
                                                {`$${this.props.selectedSubscription.total}`}
                                            </Typography>
                                        </div>

                                        <div style={styles.priceItemRow}>
                                            <Typography
                                                variant={'body2'}
                                                style={styles.priceText}
                                            >
                                                Estimated Tax
                                            </Typography>

                                            <Typography
                                                variant={'body2'}
                                                style={styles.priceText}
                                            >
                                                $17.99
                                            </Typography>
                                        </div>

                                        <hr
                                            align="right"
                                            style={styles.shortHorizontalLine}
                                        ></hr>

                                        <div style={styles.priceItemRow}>
                                            <Typography
                                                variant={'h4'}
                                                style={styles.totalPriceText}
                                            >
                                                Order Total
                                            </Typography>

                                            <Typography
                                                variant={'h4'}
                                                style={styles.totalPriceText}
                                            >
                                                {`$${this.props.selectedSubscription.total}`}
                                            </Typography>
                                        </div>

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            style={
                                                styles.purchaseSubscriptionBtn
                                            }
                                            onClick={() => {}}
                                        >
                                            <Typography
                                                variant="subtitle1"
                                                style={
                                                    styles.purchaseSubscriptionActionText
                                                }
                                            >
                                                Purchase Subscription
                                            </Typography>
                                        </Button>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </CustomContainer>
                </div>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession, subscriptions }) => {
    const { user } = userSession;
    const {
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    } = subscriptions;
    return {
        user,
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    };
};

export default connect(mapStateToProps, {
    charge,
    saveStripeCardToken,
})(PurchaseSubscriptionScreen);
