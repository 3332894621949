// import Sentry from 'sentry-expo';
import WS from '../api/WS';
import { push } from 'connected-react-router';

// import { NavigationActions } from 'react-navigation';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    CREATE_PROPOSED_SCHOOL: 'create_proposed_school',
    CREATE_PROPOSED_SCHOOL_SUCCESS: 'create_proposed_school_success',
    CREATE_PROPOSED_SCHOOL_FAIL: 'create_proposed_school_fail',

    UPDATE_PROPOSED_SCHOOL: 'update_proposed_school',
    UPDATE_PROPOSED_SCHOOL_SUCCESS: 'update_proposed_school_success',
    UPDATE_PROPOSED_SCHOOL_FAIL: 'update_proposed_school_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    geo: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.CREATE_PROPOSED_SCHOOL:
            return { ...state, loading: true };
        case types.CREATE_PROPOSED_SCHOOL_SUCCESS:
            return { ...state, loading: false, geo: action.payload };
        case types.CREATE_PROPOSED_SCHOOL_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.UPDATE_PROPOSED_SCHOOL:
            return { ...state, loading: true };
        case types.UPDATE_PROPOSED_SCHOOL_SUCCESS:
            return { ...state, loading: false, geo: action.payload };
        case types.UPDATE_PROPOSED_SCHOOL_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const createProposedSchool = ({ schoolInfo, user }) => {
    return dispatch => {
        dispatch({ type: types.CREATE_PROPOSED_SCHOOL });

        WS.socket.emit('proposedSchool:create', {
            name: schoolInfo.name.trim(),
            domain: schoolInfo.domain,
            proposer_id: user.id,
            proposer_name: user.first_name.trim() + ' ' + user.last_name.trim(),
        });
        WS.socket.once('proposedSchool:create', res => {
            if (res.success) {
                createProposedSchoolSuccess(
                    dispatch,
                    res.data,
                    schoolInfo.address
                );
            } else {
                createProposedSchoolFail(dispatch, res.message);
            }
        });
    };
};

const createProposedSchoolSuccess = (dispatch, school, address) => {
    dispatch({ type: types.CREATE_PROPOSED_SCHOOL_SUCCESS, payload: school });

    dispatch(push('/addNewSchoolSuccess'));
};
const createProposedSchoolFail = (dispatch, msg) => {
    dispatch({ type: types.CREATE_PROPOSED_SCHOOL_FAIL, payload: msg });
};

export const updateProposedSchool = ({ school }) => {
    return dispatch => {
        dispatch({ type: types.UPDATE_PROPOSED_SCHOOL });

        WS.socket.emit('proposedSchool:update', { school });
        WS.socket.once('proposedSchool:update', res => {
            if (res.success) {
                updateProposedSchoolSuccess(dispatch, res.data.proposedSchool);
            } else {
                updateProposedSchoolFail(dispatch, res.message);
            }
        });
    };
};

const updateProposedSchoolSuccess = (dispatch, school) => {
    // AmplitudeAnalytics.track('Update Proposed School Success', school);

    dispatch({ type: types.UPDATE_PROPOSED_SCHOOL_SUCCESS, payload: school });
};

const updateProposedSchoolFail = (dispatch, msg) => {
    dispatch({ type: types.UPDATE_PROPOSED_SCHOOL_FAIL, payload: msg });
};
