import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import routes from '../../routes';
import WS from '../../api/WS';
// import { ThemeProvider } from '@material-ui/styles';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Colors from '../../constants/Colors';

import './App.css';

const baseTheme = createMuiTheme({
    spacing: 10,
});

const theme = createMuiTheme({
    ...baseTheme,
    palette: {
        primary: {
            main: Colors.pangeaBlue,
        },
        secondary: {
            main: Colors.pangeaGreen,
        },
        text: {
            primary: Colors.fontBlack,
        },
    },
    typography: {
        htmlFontSize: 18,
        h1: {
            fontSize: 36,
            fontWeight: '700',
        },
        h2: {
            fontSize: 36,
            letterSpacing: 0.26,
            fontWeight: '400',
        },
        h3: {
            fontSize: 21,
            fontWeight: '700',
        },
        h4: {
            fontSize: 21,
            letterSpacing: 0.16,
            fontWeight: '400',
        },
        h5: {
            fontSize: 18,
            letterSpacing: 0.12,
            fontWeight: '700',
        },
        subtitle1: {
            fontSize: 16,
            letterSpacing: 0.15,
            fontWeight: '400',
        },
        body1: {
            fontSize: 18,
            letterSpacing: 0.5,
            fontWeight: '400',
        },
        body2: {
            fontSize: 16,
            letterSpacing: 0.44,
            fontWeight: '400',
        },
        subtitle2: {
            fontSize: 14,
            letterSpacing: 0.39,
            fontWeight: '400',
        },
        caption: {
            fontSize: 12,
            fontWeight: '400',
        },
        button: {
            fontSize: 16,
            letterSpacing: '0.01071em',
            fontWeight: '700',
            textTransform: 'none',
        },
        h6: {
            fontSize: 14,
            fontWeight: '400',
        },
        overline: {},
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        fontSize: 18,
    },
    overrides: {
        MuiAppBar: {
            root: {
                height: 50,
            },
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: baseTheme.spacing(2), // 20px
            },
        },
        MuiCard: {
            root: {
                borderRadius: 10,
            },
        },
        MuiTextField: {
            root: {
                '& .MuiInputLabel-outlined': {
                    top: -8,
                },
                '& .MuiInputLabel-shrink': {
                    top: 0,
                },
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderRadius: 22,
                        border: 'solid 1px #8e8e93',
                        fontSize: 18,
                    },
                },
                '& .MuiOutlinedInput-input': {
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                // '& .MuiOutlinedInput-multiline': {
                //     borderRadius: 10,
                //     borderWidth: 3,
                // },
            },
        },
        MuiButton: {
            root: {
                borderRadius: 20,
                paddingLeft: baseTheme.spacing(2),
                paddingRight: baseTheme.spacing(2),
            },
            outlinedPrimary: {
                borderWidth: 1.5,
                borderColor: Colors.pangeaBlue,
                paddingLeft: baseTheme.spacing(2),
                paddingRight: baseTheme.spacing(2),
                '&:hover': {
                    borderWidth: 1.5,
                    borderColor: Colors.pangeaBlue,
                },
                '&:focus': {
                    borderWidth: 1.5,
                    borderColor: Colors.pangeaBlue,
                },
            },
        },
        MuiSelect: {
            root: {
                '& .MuiInputLabel-outlined': {
                    top: -8,
                },
                '& .MuiInputLabel-shrink': {
                    top: 0,
                },
                borderRadius: 22,
                border: 'solid 1px #8e8e93',
                fontSize: 18,
            },
            input: {
                borderRadius: 22,
                border: 'solid 1px #8e8e93',
                fontSize: 18,
            },
        },
        // MuiInputLabel: {
        //     root: {
        //         marginTop: -8,
        //         zIndex: 2,
        //         backgroundColor: 'white',
        //     },
        //     shrink: {
        //         marginTop: 0,
        //         zIndex: 2,
        //     },
        // },
    },
});

class App extends Component {
    constructor() {
        super();
        this.state = {};
        WS.init();
    }
    componentDidMount() {
        console.log('here');

        console.log(theme);

        // WS.init();
    }
    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <ConnectedRouter history={this.props.history}>
                    {routes}
                </ConnectedRouter>
            </MuiThemeProvider>
        );
    }
}
// const App = ({ history }) => {
//     return <ConnectedRouter history={history}>{routes}</ConnectedRouter>;
// };

App.propTypes = {
    history: PropTypes.object,
};

export default App;

// // import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

// // import logo from './logo.svg';
// import './App.css';

// import WS from '../../api/WS';

// // Common Layouts
// import HeaderItemLayout from '../HeaderItemLayout';

// // login
// import WelcomeScreen from '../login/WelcomeScreen';
// import LoginScreen from '../login/LoginScreen.js';
// import ForgotPasswordScreen from '../login/ForgotPasswordScreen';

// // onboarding
// import CreateAccountScreen from '../onboarding/CreateAccountScreen';

// // myProfile
// import MyProfileScreen from '../myProfile/MyProfileScreen';

// export default class App extends Component {
//     constructor() {
//         super();
//         this.state = {};
//     }
//     componentDidMount() {
//         console.log('here');

//         WS.init();
//     }
//     render() {
//         return (
//             <Router>
//                 <div>
//                     {/* <Header /> */}
//                     <Route exact path="/" component={Home} />
//                     <Route path="/login" component={LoginScreen} />
//                     <Route
//                         path="/forgotPassword"
//                         component={ForgotPasswordScreen}
//                     />
//                     <Route path="/welcome" component={WelcomeScreen} />
//                     <Route
//                         path="/createAccount"
//                         component={CreateAccountScreen}
//                     />
//                     <Route path="/myProfile" component={MyProfileScreen} />
//                     <Route path="/support" component={Support} />
//                     <Route path="/homePageLayout" component={HomepageLayout} />
//                     <Route
//                         path="/anotherGridLayout"
//                         component={AnotherGridLayout}
//                     />
//                     <Route
//                         path="/attachedContentLayout"
//                         component={AttachedContentLayout}
//                     />
//                     <Route
//                         path="/bootstrapMigrationLayout"
//                         component={BootstrapMigrationLayout}
//                     />
//                     <Route
//                         path="/fixedMenuLayout"
//                         component={FixedMenuLayout}
//                     />
//                     <Route path="/gridLayout" component={GridLayout} />
//                     <Route path="/hompageLayout" component={HompageLayout} />
//                     <Route path="/loginLayout" component={LoginLayout} />
//                     <Route
//                         path="/responsiveLayout"
//                         component={ResponsiveLayout}
//                     />
//                     <Route path="/stickyLayout" component={StickyLayout} />
//                     <Route path="/themingLayout" component={ThemingLayout} />
//                     <Route path="/topics" component={Topics} />
//                     <Route
//                         path="/headerItemLayout"
//                         component={HeaderItemLayout}
//                     />
//                 </div>
//             </Router>
//         );
//     }
// }
// function Home() {
//     return <h2>Home</h2>;
// }

// function Support() {
//     return <h2>Support</h2>;
// }

// function Topic({ match }) {
//     return <h3>Requested Param: {match.params.id}</h3>;
// }

// function Topics({ match }) {
//     return (
//         <div>
//             <h2>Topics</h2>

//             <ul>
//                 <li>
//                     <Link to={`${match.url}/components`}>Components</Link>
//                 </li>
//                 <li>
//                     <Link to={`${match.url}/props-v-state`}>
//                         Props v. State
//                     </Link>
//                 </li>
//             </ul>

//             <Route path={`${match.path}/:id`} component={Topic} />
//             <Route
//                 exact
//                 path={match.path}
//                 render={() => <h3>Please select a topic.</h3>}
//             />
//         </div>
//     );
// }

// function Header() {
//     return (
//         <ul>
//             <li>
//                 <Link to="/">Home</Link>
//             </li>
//             <li>
//                 <Link to="/support">Support</Link>
//             </li>
//             <li>
//                 <Link to="/login">Login</Link>
//             </li>
//             <li>
//                 <Link to="/welcome">Welcome</Link>
//             </li>
//             <li>
//                 <Link to="/homePageLayout">HomepageLayout</Link>
//             </li>
//             <li>
//                 <Link to="/topics">Topics</Link>
//             </li>
//             <li>
//                 <Link to="/anotherGridLayout">AnotherGridLayout</Link>
//             </li>
//             <li>
//                 <Link to="/attachedContentLayout">AttachedContentLayout</Link>
//             </li>
//             <li>
//                 <Link to="/bootstrapMigrationLayout">
//                     BootstrapMigrationLayout
//                 </Link>
//             </li>
//             <li>
//                 <Link to="/fixedMenuLayout">FixedMenuLayout</Link>
//             </li>
//             <li>
//                 <Link to="/gridLayout">GridLayout</Link>
//             </li>
//             <li>
//                 <Link to="/hompageLayout">HompageLayout</Link>
//             </li>
//             <li>
//                 <Link to="/loginLayout">LoginLayout</Link>
//             </li>
//             <li>
//                 <Link to="/responsiveLayout">ResponsiveLayout</Link>
//             </li>
//             <li>
//                 <Link to="/stickyLayout">StickyLayout</Link>
//             </li>
//             <li>
//                 <Link to="/themingLayout">ThemingLayout</Link>
//             </li>
//             <li>
//                 <Link to="/topics">Topics</Link>
//             </li>
//             <li>
//                 <Link to="/headerItemLayout">HeaderItemLayout</Link>
//             </li>
//         </ul>
//     );
// }
