import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import {
    Dialog,
    TextField,
    Button,
    Typography,
    FormControl,
    List,
    ListItem,
    Divider,
    MenuItem,
    Select,
    Checkbox,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { saveStripeCardToken } from '../../../ducks/SubscriptionsDuck';
import PlacesAutocomplete from 'react-places-autocomplete';
import { CustomInputLabel, CustomInputBase } from '../../common';
import { withStyles } from '@material-ui/core/styles';

// props:
/*
close
isVisible
onSubmit
*/

const styles = theme => ({
    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
});

const searchOptions = {
    types: ['geocode'],
};

const yearOfGraduationPickerItems = [
    '2017',
    '2018',
    '2019',
    '2020',
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
];

const hourlyWageArr = [
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
    33,
    34,
    35,
    40,
    45,
    50,
    55,
    60,
    65,
    70,
    75,
    80,
    90,
    100,
    110,
    120,
    130,
    140,
    150,
    160,
    170,
    180,
    190,
    200,
    210,
    220,
    230,
    240,
    250,
    260,
    270,
    280,
    290,
    300,
];

class EditStudentProfileForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const required = value => (value ? undefined : 'Required');

        const { classes } = this.props;

        let hourlyPickerItems = [];

        for (let i = 0; i < hourlyWageArr.length; i++) {
            hourlyPickerItems.push(
                <MenuItem value={hourlyWageArr[i]} key={hourlyWageArr[i]}>
                    {`$${hourlyWageArr[i]}`}
                </MenuItem>
            );
        }

        return (
            <Dialog
                onClose={() => {
                    console.log('onClose');
                    this.props.close();
                }}
                open={this.props.isVisible}
            >
                <Form onSubmit={this.props.onSubmit}>
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    flex: 1,
                                    padding: 20,
                                    minWidth: '33%',
                                }}
                            >
                                <Typography variant="h4" gutterBottom>
                                    Edit Profile
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <Field name="firstName" validate={required}>
                                        {({ input, meta }) => (
                                            <TextField
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                variant="outlined"
                                                label="First Name"
                                                fullWidth
                                                style={{
                                                    marginBottom: 20,
                                                    marginRight: 10,
                                                }}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                    <Field name="lastName" validate={required}>
                                        {({ input, meta }) => (
                                            <TextField
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                variant="outlined"
                                                label="Last Name"
                                                fullWidth
                                                style={{
                                                    marginBottom: 20,
                                                    marginLeft: 10,
                                                }}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <Field name="bio" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            placeholder={
                                                'For example “I am a RISD undergraduate with a passion for graphic design.”'
                                            }
                                            variant="outlined"
                                            label="Description"
                                            fullWidth
                                            maxLength={5000}
                                            rows="6"
                                            multiline
                                            error={meta.error && meta.touched}
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field name="website" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            variant="outlined"
                                            label="Website"
                                            fullWidth
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field name="expectedYog" validate={required}>
                                    {({ input, meta }) => (
                                        <FormControl
                                            style={{
                                                minWidth: '50%',
                                                marginBottom: 20,
                                            }}
                                            error={meta.error && meta.touched}
                                        >
                                            <CustomInputLabel variant="outlined">
                                                Year of Graduation
                                            </CustomInputLabel>
                                            <Select
                                                variant="outlined"
                                                {...input}
                                                onChange={e => {
                                                    input.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                labelWidth={'100%'}
                                                input={
                                                    <CustomInputBase
                                                        error={
                                                            meta.error &&
                                                            meta.touched
                                                        }
                                                    />
                                                }
                                            >
                                                <MenuItem value="" disabled>
                                                    Year of Graduation
                                                </MenuItem>
                                                {yearOfGraduationPickerItems.map(
                                                    (
                                                        yearOfGraduation,
                                                        index
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    yearOfGraduation
                                                                }
                                                            >
                                                                {
                                                                    yearOfGraduation
                                                                }
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="intendedMajor" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            variant="outlined"
                                            label="Intended Major"
                                            fullWidth
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field name="homeTown">
                                    {({ input, meta }) => (
                                        <PlacesAutocomplete
                                            onChange={address => {
                                                // this.setState({ address });
                                                input.onChange(address);
                                            }}
                                            onSelect={address => {
                                                // this.setState({ address });
                                                // props._updatePost({ address });
                                                input.onChange(address);
                                            }}
                                            searchOptions={searchOptions}
                                            {...input}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div>
                                                    <TextField
                                                        label="Hometown"
                                                        variant="outlined"
                                                        error={
                                                            meta.error &&
                                                            meta.touched
                                                        }
                                                        {...input}
                                                        inputProps={{
                                                            ...getInputProps({
                                                                // placeholder: 'Address',
                                                                className:
                                                                    'location-search-input',
                                                            }),
                                                        }}
                                                        style={{
                                                            minWidth: '100%',
                                                        }}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && (
                                                            <Typography>
                                                                Loading...
                                                            </Typography>
                                                        )}

                                                        <List>
                                                            {suggestions.map(
                                                                suggestion => {
                                                                    return (
                                                                        <ListItem
                                                                            {...getSuggestionItemProps(
                                                                                suggestion
                                                                            )}
                                                                            className={
                                                                                classes.listItem
                                                                            }
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    suggestion.description
                                                                                }
                                                                            </Typography>
                                                                            <Divider />
                                                                        </ListItem>
                                                                    );
                                                                }
                                                            )}
                                                        </List>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    )}
                                </Field>

                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1,
                                        marginBottom: 20,
                                    }}
                                >
                                    <Field name="compensation">
                                        {({ input, meta }) => (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    flex: 1,
                                                }}
                                            >
                                                <FormControl
                                                    style={{ minWidth: '50%' }}
                                                >
                                                    <CustomInputLabel variant="outlined">
                                                        Base Rate
                                                    </CustomInputLabel>
                                                    <Select
                                                        variant="outlined"
                                                        {...input}
                                                        onChange={e => {
                                                            input.onChange(
                                                                e.target.value
                                                            );
                                                        }}
                                                        labelWidth={'100%'}
                                                        disableUnderline
                                                        input={
                                                            <CustomInputBase
                                                                error={
                                                                    meta.error &&
                                                                    meta.touched
                                                                }
                                                            />
                                                        }
                                                    >
                                                        {hourlyPickerItems}
                                                    </Select>
                                                </FormControl>

                                                <Typography
                                                    style={{
                                                        paddingLeft: 20,
                                                    }}
                                                >
                                                    {'/ Hour'}
                                                </Typography>
                                            </div>
                                        )}
                                    </Field>
                                    <Field
                                        name="isTimeCommitmentSkipped"
                                        type="checkbox"
                                    >
                                        {({ input, meta }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    checked={input.checked}
                                                    onChange={() => {
                                                        input.onChange(
                                                            !input.checked
                                                        );
                                                    }}
                                                    value="time-commitment"
                                                    color="primary"
                                                />
                                                <Typography>
                                                    make negotiable
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                    </Field>
                                </div>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting}
                                    style={{ marginBottom: 20 }}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )}
                </Form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ userSession, subscriptions }) => {
    const { user } = userSession;
    const {
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    } = subscriptions;
    return {
        user,
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        saveStripeCardToken,
    })(EditStudentProfileForm)
);
