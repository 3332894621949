// import AmplitudeAnalytics from '../../services/amplitudeAnalytics';

export const simpleDispatch = (dispatch, actionType, payload) => {
    // AmplitudeAnalytics.track(actionType, payload);

    dispatch({
        type: actionType,
        payload: payload,
    });
};
