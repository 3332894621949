import React from 'react';
import AdSense from 'react-adsense';

const GoogleAd = props => (
    <AdSense.Google
        client="ca-pub-6466631063168387"
        slot="7806394673"
        style={{ display: 'block', marginTop: 20 }}
        format="auto"
        responsive="true"
        layoutKey="-gw-1+2a-9x+5c"
    />
);

export default GoogleAd;
