/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div data-collapse="medium" data-animation="default" data-duration="400" className="navbar w-nav"></div>
                <div className="section-hero">
                    <div className="container">
                    <div className="container-hero"></div>
                    </div>
                    <div className="container-hero-2">
                    <h3 className="h1-copy-2">College talent, <br/>on-demand.</h3>
                    <p className="p1-2">Pangea.app gives you immediate, direct access to enthusiastic and talented students (a.k.a Pangeans) ready to undertake any project.</p>
                    <div className="div-block-3"><a href="#Tour" data-w-id="5b8623a3-9b7f-e048-84b0-cc4e2db183e6" className="button-2-copy-copy-copy w-button">Take a Tour</a><a href="download.html" data-w-id="5b8623a3-9b7f-e048-84b0-cc4e2db183e8" className="button-6-copy w-button">Download</a></div>
                    </div>
                    <div data-collapse="none" data-animation="default" data-duration="400" className="navbar-2 w-nav">
                    <div className="nav-container"><a href="index.html" className="brand w-clearfix w-nav-brand w--current"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d64145b9300dad9537d85_Wordmark%20(White)%20%40%202x.png" width="202" alt="logo" className="image-14"/></a>
                        <div className="w-clearfix"><a href="download.html" className="nav-link-2 w-nav-link">Download</a></div>
                        <div className="menu-button w-hidden-main w-hidden-medium w-hidden-small w-hidden-tiny w-nav-button">
                        <div className="w-icon-nav-menu"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="columns-4 w-row">
                    <div className="w-col w-col-6">
                        <h3 className="h2-2">Projects-based work</h3>
                        <p className="paragraph-4">Freelance projects present a <strong>low commitment</strong> opportunity to access college talent affordably and efficiently.  <br/><br/>Projects can be <strong>short-term </strong>and centered around <strong>real work</strong>, allowing you to test to see if the candidate is a good fit for larger roles.<br/><br/>No more having an &quot;intern&quot; sitting around from 9-5 for 3 months with nothing to do besides for grab coffee.<br/><br/>In the 21st century, it&#x27;s all about hiring a &quot;<strong>Pangean</strong>&quot; for a project. <br/>‍</p><a href="about.html" data-w-id="3f930582-56a9-6339-a97f-e046da59482e" target="_blank" className="button-7 w-button">More About Us</a></div>
                    <div className="w-col w-col-6"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d748d2a875068aca9f8a2_Skilled%20Person.jpg" alt="Skilled Pangean" className="image-13"/></div>
                    </div>
                </div>
                <div id="Tour" className="section-content-blue">
                    <div className="container-flex-vert">
                    <h3 className="h2-c-w-2">What we bring to the table</h3>
                    <div data-duration-in="300" data-duration-out="700" className="tabs w-tabs">
                        <div className="tabs-menu-2 w-tab-menu">
                        <a data-w-tab="Tab 1" className="tab-on-2 w-inline-block w-tab-link">
                            <div className="feature-heading-2">Direct Access to Students</div>
                            <p className="paragraph-2-w-2">Post a project on our marketplace or reach out to students directly, without having to work through college career centers.</p>
                        </a>
                        <a data-w-tab="Tab 2" className="tab-on-2 w-inline-block w-tab-link">
                            <div className="feature-heading-2">No Upfront Fee!</div>
                            <p className="paragraph-2-w-2">Post your first projects for free, and reach out to several students without paying a subscription. We charge a 13% processing fee when you pay through our 1099 compliant payment gateway. </p>
                        </a>
                        <a data-w-tab="Tab 3" className="tab-on-copy w-inline-block w-tab-link w--current">
                            <div className="feature-heading-2"><strong>Ratings and reviews</strong></div>
                            <p className="paragraph-2-w-2">Choose a Pangean based off their ratings and reviews, and then add your own to help them improve and support the network. </p>
                        </a>
                        </div>
                        <div className="w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5dbd0ea73623a32db39b80_Main%20Feed.png" alt="" className="image-15"/></div>
                        <div data-w-tab="Tab 2" className="w-tab-pane"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d78624bb5418a5bd4ef3f_Messenger.png" alt="" className="image-15"/></div>
                        <div data-w-tab="Tab 3" className="w-tab-pane w--tab-active"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d7bbdb1f1ca1230c9531d_Profile.png" alt="" className="image-15"/></div>
                        </div>
                    </div>
                    </div>
                    <div className="div-block-8"><a href="download.html" data-w-id="208bf753-6fda-fc58-92c2-a578aeb1f541" className="button-6-copy w-button">Download now and post your project</a></div>
                </div>
                <div className="section-content-grey">
                    <div className="columns-5 w-row">
                    <div className="column-2 w-col w-col-6 w-col-stack">
                        <h3 className="h2-2">Hire a Pangean</h3>
                        <p className="paragraph-4 space-btm">Find the right Pangean for your project &amp; get started today!</p><a href="download.html" className="button-7-copy w-button">Try it out</a><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d80ad3e9e5a3fcd03a6ea_Linked.png" width="399" alt="" className="image-12"/></div>
                    <div className="column w-col w-col-6 w-col-stack">
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">1099-Compliant</h3>
                            <p className="paragraph-2-copy">Every transaction made through our platform conforms to US tax laws, ensuring a headache-free April for you.</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">NDA Coverage</h3>
                            <p className="paragraph-2-copy">All of our Pangeans are bound by an NDA, so your business secrets are safe with them.</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">Dispute resolution</h3>
                            <p className="paragraph-2-copy">If you aren&#x27;t happy, or the Pangean doesn&#x27;t meet you standard, we can issue full or partial refunds</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">We are Pangeans</h3>
                            <p className="paragraph-2-copy">We&#x27;re always a click away. If you&#x27;re running into a wall, just send a message to us and our team will tear it down.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div><a href="download.html" data-w-id="7362d35b-c5d7-4b11-9d32-c8a1362e4581" className="button-7 w-button">Try it out</a>
                <div className="section-content-2">
                    <div className="container-flex-vert-wide">
                    <h3 className="h2 centre testimonial-white">Hear it from our best spokespeople</h3>
                    <div className="testimonials-wrap">
                        <div data-animation="slide" data-duration="500" data-infinite="1" className="slider w-slider">
                        <div className="w-slider-mask">
                            <div className="slide w-slide">
                            <div className="div-block-11">
                                <div style={{paddingTop:'56.17021276595745%'}} id="w-node-8e8186f5d65c-cefec9cb" className="w-embed-youtubevideo"><iframe src="https://www.youtube.com/embed/-pzxoKQQ0Sg?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameborder="0" style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',pointerEvents:'auto'}} allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div>
                            </div>
                            </div>
                            <div className="slide w-slide">
                            <div className="div-block-11">
                                <div style={{paddingTop:'56.17021276595745%'}} id="w-node-e0af5bacebcf-cefec9cb" className="w-embed-youtubevideo"><iframe src="https://www.youtube.com/embed/z_gJ7kBzQvI?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameborder="0" style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',pointerEvents:'auto'}} allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div>
                            </div>
                            </div>
                        </div>
                        <div className="left-arrow w-slider-arrow-left">
                            <div className="icon-2 w-icon-slider-left"></div>
                        </div>
                        <div className="right-arrow w-slider-arrow-right">
                            <div className="icon-2 w-icon-slider-right"></div>
                        </div>
                        <div className="slide-nav w-slider-nav w-round"></div>
                        </div>
                    </div>
                    </div>
                </div>
                {/* <div className="section-blog">
                    <div className="container-flex-vert">
                    <h3 className="h2-c-copy">Peruse our Medium</h3>
                    </div>
                    <div className="w-dyn-list">
                    <div className="w-dyn-items w-row">
                        <div className="collection-item-2 w-dyn-item w-col w-col-4">
                        <div className="div-block-9">
                            <div className="div-block-10"></div>
                            <div className="text-block-16"></div><a href="#" className="button-8 w-button">Read Article</a>
                            <div className="text-block-15"></div>
                        </div>
                        </div>
                    </div>
                    <div className="w-dyn-empty">
                        <div>No items found.</div>
                    </div>
                    <div className="w-pagination-wrapper"><a href="#" className="w-pagination-previous"><svg className="w-pagination-previous-icon" height="12px" width="12px" xmlns="http://www.w3.org/2000/svg" viewbox="0 0 12 12" transform="translate(0, 1)"><path fill="none" stroke="currentColor" fill-rule="evenodd" d="M8 10L4 6l4-4"></path></svg><div className="w-inline-block">Previous</div></a>
                        <a href="#" className="w-pagination-next next">
                        <div className="text-block-17 w-inline-block">More -&gt;</div>
                        </a>
                    </div>
                    </div>
                </div> */}
                <div className="section-footer">
                    <div className="footer-wrap">
                    <div className="footer-logo-top">
                        <div><a href="#" className="w-nav-brand"><img src="images/Wordmark-White--2x.png" width="204" alt="logo" srcset="images/Wordmark-White--2x-p-500.png 500w, images/Wordmark-White--2x-p-800.png 800w, images/Wordmark-White--2x-p-1080.png 1080w, images/Wordmark-White--2x-p-1600.png 1600w, images/Wordmark-White--2x.png 1768w" sizes="(max-width: 479px) 204px, (max-width: 767px) 40vw, 204px"/></a></div>
                        <div className="div-block-5 w-hidden-tiny">
                        <div className="sml-caption">Want to get in touch?</div><a href="mailto:Adam@Pangea.app" className="button-5 w-button">Contact Us</a></div>
                    </div>
                    <div className="columns-3 w-row">
                        <div className="footer-col w-col w-col-2 w-col-small-small-stack">
                        <div className="text-block-footer">Pangea.app</div><a href="index.html" className="link-3 w--current">Home</a><a href="download.html" className="link-3">Download</a><a href="about.html" className="link-3">About</a></div>
                        <div className="footer-col w-col w-col-2 w-col-small-small-stack">
                        <div className="text-block-footer">Social Media</div><a href="https://www.instagram.com/pangea.app/?hl=en" target="_blank" rel="noopener noreferrer" className="link-3">Instagram</a><a href="https://www.linkedin.com/company/app-pangea/" target="_blank" rel="noopener noreferrer"className="link-3">Linkedin</a><a href="https://medium.com/pangea-app" target="_blank" rel="noopener noreferrer" className="link-3">Medium</a></div>
                        <div className="footer-col w-col w-col-2 w-col-small-small-stack">
                        <div className="text-block-footer">Legal</div><a href="terms-of-use.html" className="link-3">Terms of Service</a><a href="privacy-policy.html" className="link-3">Privacy Policy</a></div>
                        <div className="footer-col w-col w-col-2 w-col-small-small-stack"></div>
                        <div className="footer-col-right w-col w-col-4 w-col-small-small-stack">
                        <div className="text-block-footer">Address</div><a href="https://goo.gl/maps/Z7Yegsv361un6UVF6" target="_blank" rel="noopener noreferrer" className="link-3-copy">225 Dyer St, 2nd Floor<br/>Providence, RI 02903</a></div>
                    </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {
    };
};

export default connect(
    mapStateToProps,
    {}
)(IndexScreen);
