import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Grid, Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

import { CustomContainer } from '../common';
import { addProfilePhotoInfo } from '../../ducks/OnboardingDuck';

import Colors from '../../constants/Colors';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    paper1: {
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper5: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flex: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper2: {
        marginLeft: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex',
        flex: 2,
        justifyContent: 'center',
        alignItems: 'center',
    },

    grid8Container: {
        textAlign: 'center',
        backgroundColor: theme.palette.text.secondary,
        flexDirection: 'row',
        display: 'flex',
        flex: 1,
    },

    grid4Container: {
        backgroundColor: Colors.pangeaGreen,
    },

    fileUpload: {
        height: 40,
        width: 100,
        backgroundColor: Colors.pangeaBlue,
    },
});

class HomeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                <h2>HomeScreen</h2>
                <CustomContainer>
                    <Grid container spacing={2}>
                        <Grid item md={8} className={classes.grid8Container}>
                            <Paper className={classes.paper1}>m=1</Paper>
                            <Paper className={classes.paper5}>m=5</Paper>
                            <Paper className={classes.paper2}>m=2</Paper>
                        </Grid>

                        <Grid item md={4} className={classes.grid4Container}>
                            <Paper className={classes.paper}>m=4</Paper>
                        </Grid>
                    </Grid>
                </CustomContainer>

                {/* <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        paddingTop: 20,
                        paddingBottom: 20,
                    }}
                > */}
                <Typography variant="h1" component="h2" gutterBottom>
                    h1. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="h2" gutterBottom>
                    h2. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="h3" gutterBottom>
                    h3. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="h4" gutterBottom>
                    h4. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="h5" gutterBottom>
                    h5. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="h6" gutterBottom>
                    h6. Heading Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                    subtitle1. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur
                </Typography>
                <Typography variant="subtitle2" gutterBottom>
                    subtitle2. Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur
                </Typography>
                <Typography variant="body1" gutterBottom>
                    body1. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit. Quos blanditiis tenetur unde suscipit, quam beatae
                    rerum inventore consectetur, neque doloribus, cupiditate
                    numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    body2. Lorem ipsum dolor sit amet, consectetur adipisicing
                    elit. Quos blanditiis tenetur unde suscipit, quam beatae
                    rerum inventore consectetur, neque doloribus, cupiditate
                    numquam dignissimos laborum fugiat deleniti? Eum quasi
                    quidem quibusdam.
                </Typography>
                <Typography variant="button" display="block" gutterBottom>
                    button text Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    caption text Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                <Typography variant="overline" display="block" gutterBottom>
                    overline text Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Quos blanditiis tenetur unde suscipit,
                    quam beatae rerum inventore consectetur, neque doloribus,
                    cupiditate numquam dignissimos laborum fugiat deleniti? Eum
                    quasi quidem quibusdam.
                </Typography>
                {/* <FileUpload
                        mode="basic"
                        chooseLabel="Upload Profile Picture"
                        uploadLabel="upload"
                        style={styles.fileUpload}
                        accept="image/*"
                        multiple={false}
                        url=""
                        maxFileSize={2000000}
                        onSelect={this.fileSelectedHandler}
                        onClear={this.cancelFileHandler}
                    />

                    {this.state.selectedPictureUrl ? (
                        <img
                            src={this.state.selectedPictureUrl}
                            alt={addPhoto}
                            height="140"
                            width="140"
                            style={{
                                marginTop: 20,
                                borderRadius: 70,
                            }}
                        />
                    ) : (
                        <img
                            src={addPhoto}
                            alt=""
                            height="140"
                            width="140"
                            style={{
                                marginTop: 20,
                                // borderRadius: 70,
                            }}
                        />
                    )} */}
                {/* </div> */}
            </div>
        );
    }

    fileSelectedHandler = ({ event, files }) => {
        console.log(files[0]);
        this.setState({
            selectedPictureUrl: files[0].objectURL,
        });
        this.setState({
            selectedPicture: files[0],
        });
        this.props.addProfilePhotoInfo({
            profilePhotoInfo: {
                image: files[0].objectURL,
                profile_pic_name: Date.now(),
            },
        });
    };

    cancelFileHandler = () => {
        this.setState({
            selectedPictureUrl: '',
            selectedPicture: null,
        });

        this.props.addProfilePhotoInfo({
            profilePhotoInfo: null,
        });
    };
}

const mapStateToProps = ({ userSession, onboarding }) => {
    const { user } = userSession;

    const { profilePhotoInfo } = onboarding;

    return {
        user,
        image: profilePhotoInfo ? profilePhotoInfo.image : null,
        profile_pic_name: profilePhotoInfo
            ? profilePhotoInfo.profile_pic_name
            : null,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, { addProfilePhotoInfo })(HomeScreen)
);
