import React, { Component } from 'react';
import { connect } from 'react-redux';

import { List, ListItem, Typography, Divider } from '@material-ui/core';

import {
    resetTypeaheadSearchTags,
    searchTagsTypeahead,
} from '../../ducks/SearchTagsDuck';

import {
    saveClickedPangean,
    updatePangeanFilter,
    updateProjectFilter,
} from '../../ducks/MainFeedDuck';

import { withStyles } from '@material-ui/core/styles';

// import { store } from '../..';
// import { push } from 'connected-react-router';

const styles = theme => ({
    rootContainer: {
        height: 500,
        width: 'calc(100% - 40px)',
        minWidth: 'calc(100% - 40px)',
        // display: 'flex',
        // flexDirection: 'column',
        // paddingTop: 10,
        border: 'solid 1px #979797',
        backgroundColor: 'white',
        overflowY: 'scroll',
    },

    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
});

class SearchMobileDropdownView extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const { classes } = this.props;
        return (
            <div>
                {this.props.typeaheadSearchTags.length > 0 && (
                    <List className={classes.rootContainer}>
                        {this.props.typeaheadSearchTags.map(item => {
                            return (
                                <React.Fragment key={item.id}>
                                    {this._renderSuggestedTag(item)}
                                    <Divider />
                                    {/* {index !==
                                        this.props.typeaheadSearchTags - 1 && (
                                        <Divider />
                                    )} */}
                                </React.Fragment>
                            );
                        })}
                    </List>
                )}
            </div>
        );
    }

    _renderSuggestedTag = item => {
        const { classes } = this.props;

        return (
            <ListItem
                className={classes.listItem}
                disableGutters
                onClick={() => {
                    if (
                        item.rethink_type === 'school_name' ||
                        item.rethink_type === 'skill'
                    ) {
                        let selectedTagsArray = [
                            ...this.props.selectedSearchTags,
                        ];
                        selectedTagsArray.unshift(item.item);
                        this.props.resetTypeaheadSearchTags();
                        this._updateTags(selectedTagsArray);
                        // this.props.navigation.goBack(null);
                    } else if (item.rethink_type === 'student_name') {
                        // if (item.item.id === this.props.user.id) {
                        //     this.props.navigation.navigate({
                        //         routeName: 'MyProfileStudent',
                        //         key: 'MyProfileStudent',
                        //     });
                        // } else {
                        //     this.props.saveClickedPangean({
                        //         owner_id: item.item.id,
                        //         responder_id: this.props.user.id,
                        //         pangean: item.item,
                        //     });
                        //     this.props.navigation.navigate({
                        //         routeName: 'DetailedProfile',
                        //         key: 'DetailedProfile',
                        //     });
                        // }
                    } else if (item.rethink_type === 'company_name') {
                        // this.props.saveClickedCompany({
                        //     company_id: item.item.id,
                        // });
                        // this.props.navigation.navigate({
                        //     routeName: 'DetailedCompany',
                        //     key: 'DetailedCompany',
                        // });
                    }
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flex: 1,
                        height: 44,
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginLeft: 20,
                        marginRight: 20,
                    }}
                >
                    {item.picture_url ? (
                        <img
                            resizeMode="cover"
                            src={item.picture_url}
                            style={{
                                width: 34,
                                height: 34,
                                borderBottomWidth: 17,
                                marginRight: 12,
                            }}
                            borderRadius={17}
                            alt=""
                        />
                    ) : (
                        <div
                            style={{
                                height: 38,
                                width: 38,
                                marginRight: 12,
                            }}
                        />
                    )}
                    <Typography style={styles.itemText}>
                        {item.rethink_type === 'skill'
                            ? `#${item.tagName}`
                            : `${item.tagName}`}
                    </Typography>
                </div>
            </ListItem>
        );
    };

    _updateTags = tags => {
        this.props.updatePangeanFilter({
            pangeanDistanceSliderValue: this.props.pangeanDistanceSliderValue,
            yog2019Selected: this.props.yog2019Selected,
            yog2020Selected: this.props.yog2020Selected,
            yog2021Selected: this.props.yog2021Selected,
            yog2022Selected: this.props.yog2022Selected,
            yog2023Selected: this.props.yog2023Selected,
            recentlyActiveSelected: this.props.recentlyActiveSelected,
            coordinates: this.props.coordinates,
            selectedSearchTags: tags,
            pangeanSort: this.props.pangeanSort,
        });

        this.props.updateProjectFilter({
            projectDistanceSliderValue: this.props.projectDistanceSliderValue,
            projectOccurenceRecurringSelected: this.props
                .projectOccurenceRecurringSelected,
            projectOccurenceOneTimeSelected: this.props
                .projectOccurenceOneTimeSelected,
            projectPaymentTypeHourlySelected: this.props
                .projectPaymentTypeHourlySelected,
            projectPaymentTypeFixedSelected: this.props
                .projectPaymentTypeFixedSelected,
            projectPaymentTypeUnpaidSeleceted: this.props
                .projectPaymentTypeUnpaidSeleceted,
            projectPreferLocalSelected: this.props.projectPreferLocalSelected,
            coordinates: this.props.coordinates,
            selectedSearchTags: tags,
            projectSort: this.props.projectSort,
        });
    };
}

const mapStateToProps = ({ userSession, searchTags, mainFeed }) => {
    return {
        user: userSession.user,
        typeaheadSearchTags: searchTags.typeaheadSearchTags
            ? searchTags.typeaheadSearchTags
            : [],
        pangeanDistanceSliderValue: mainFeed.pangeanDistanceSliderValue,
        yog2019Selected: mainFeed.yog2019Selected,
        yog2020Selected: mainFeed.yog2020Selected,
        yog2021Selected: mainFeed.yog2021Selected,
        yog2022Selected: mainFeed.yog2022Selected,
        yog2023Selected: mainFeed.yog2023Selected,
        recentlyActiveSelected: mainFeed.recentlyActiveSelected,
        projectDistanceSliderValue: mainFeed.projectDistanceSliderValue,
        projectOneTimeProjectSelected: mainFeed.projectOneTimeProjectSelected,
        projectInternshipSelected: mainFeed.projectInternshipSelected,
        projectFullTimeSelected: mainFeed.projectFullTimeSelected,
        projectPaidSelected: mainFeed.projectPaidSelected,
        projectUnpaidSelected: mainFeed.projectUnpaidSelected,
        selectedSearchTags: mainFeed.selectedSearchTags,
        pangeansFeed: mainFeed.pangeansFeed,
        projectsFeed: mainFeed.projectsFeed,
        pangeanSort: mainFeed.pangeanSort,
        projectSort: mainFeed.projectSort,
        pangeanSortSetting: mainFeed.pangeanSortSetting,
        projectSortSetting: mainFeed.projectSortSetting,
        pangeanQuery: mainFeed.pangeanQuery,
        projectQuery: mainFeed.projectQuery,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        resetTypeaheadSearchTags,
        searchTagsTypeahead,
        saveClickedPangean,
        updatePangeanFilter,
        updateProjectFilter,
    })(SearchMobileDropdownView)
);
