import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';

import { SkillCard } from '../../common';

import { employerSoftSkillsArr } from '../../../constants/SoftSkills';

import Colors from '../../../constants/Colors';

const skillCardStyles = {
    rootContainer: {
        marginTop: 20,
        marginBottom: 20,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 20,

        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
    },

    sectionTitle: {
        display: 'block',
        marginBottom: 20,
    },

    ratingContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxWidth: 125,
    },

    ratingStar: {
        width: 125,
        height: 125,
        backgroundColor: 'gold',
    },

    skillsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    skillRatingRow: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },

    skillStarsContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        backgroundColor: 'gold',
    },

    seeMoreLinkContainer: {
        display: 'flex',
        flex: 1,
    },

    seeMoreLink: {
        textDecoration: 'none',
        textAlign: 'center',
        width: '100%',
    },

    starDiv: {
        height: 120,
        width: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('images/star.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },

    starDivGray: {
        height: 120,
        width: 120,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('images/star-gray.svg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },
};

class ProfileSkillsAndRatingsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: null,
        };
    }

    render() {
        // boolean logic, if it's not a profile, it's a company.
        const isProfile =
            this.props.type === 'detailed_profile' ||
            this.props.type === 'my_profile';
        const isOwner =
            this.props.type === 'my_profile' ||
            this.props.type === 'my_company';
        const isStudentProfile =
            isProfile && this.props.user && this.props.user_type === 'student';

        let hasRating = false;
        let overallRating = null;
        if (
            this.props.type === 'detailed_profile' ||
            (this.props.type === 'my_profile' && this.props.user)
        ) {
            if (this.props.user.num_overall_rating > 0) {
                hasRating = true;
                overallRating = this.props.user.overall_rating;
            }
        } else if (this.props.company) {
            if (this.props.company.num_overall_rating > 0) {
                hasRating = true;
                overallRating = this.props.company.overall_rating;
            }
        }

        let skills = null;
        if (hasRating && isProfile && isStudentProfile) {
            skills = this.props.user.skills;
            // load full
        } else if (!hasRating && isProfile && isStudentProfile) {
            skills = this.props.user.skills;
            // load just skills no ratings
        } else if (hasRating && !isProfile) {
            skills = this.props.company.skills;
            // load company ratings
        } else if (!hasRating && !isProfile) {
            // load empty company ratings
            skills = employerSoftSkillsArr;
        }

        let skillsRatingInfo = [];
        let softSkillsRatingInfo = [];
        let hardSkillsRatingInfo = [];

        if (isProfile) {
            if (this.props.user) {
                for (let skillId of Object.keys(
                    this.props.user.hard_skill_ratings
                )) {
                    hardSkillsRatingInfo.push({
                        name: this.props.user.hard_skill_names[skillId],
                        key: skillId,
                        rating: this.props.user.hard_skill_ratings[skillId],
                        numRatings: this.props.user.hard_skill_num_ratings[
                            skillId
                        ],
                        type: 'hard',
                    });
                }

                for (let skill of this.props.pangeanSoftSkills) {
                    if (
                        Object.keys(
                            this.props.user.soft_skill_ratings
                        ).includes(skill.id)
                    ) {
                        softSkillsRatingInfo.push({
                            name: skill.name,
                            key: skill.id,
                            rating: this.props.user.soft_skill_ratings[
                                skill.id
                            ],
                            numRatings: this.props.user.soft_skill_num_ratings[
                                skill.id
                            ],
                            type: 'soft',
                        });
                    }
                }
            }
        } else {
            if (hasRating) {
                for (let skill of employerSoftSkillsArr) {
                    if (
                        Object.keys(
                            this.props.company.soft_skill_ratings
                        ).includes(skill.id)
                    ) {
                        skillsRatingInfo.push({
                            name: skill.name,
                            key: skill.id,
                            rating: this.props.company.soft_skill_ratings[
                                skill.id
                            ],
                            numRatings: this.props.company
                                .soft_skill_num_ratings[skill.id],
                            type: 'soft',
                        });
                    }
                }
            } else {
                for (let skill of employerSoftSkillsArr) {
                    skillsRatingInfo.push({
                        name: skill.name,
                        key: skill.id,
                        rating: 0,
                        numRatings: 0,
                        type: 'soft',
                    });
                }
            }
            skills = skillsRatingInfo;
        }

        return (
            <div style={skillCardStyles.rootContainer}>
                <Typography variant="h4" gutterBottom>
                    {isProfile ? 'Skills and Ratings' : 'Ratings'}
                </Typography>

                <Grid container spacing={2}>
                    <Grid item md={5} sm={12} direction="column">
                        <Typography gutterBottom>Overall Rating</Typography>
                        <div style={skillCardStyles.ratingContainer}>
                            {hasRating ? (
                                <div style={skillCardStyles.starDiv}>
                                    <Typography
                                        style={{
                                            color: Colors.white,
                                        }}
                                        variant="h2"
                                    >
                                        {`${overallRating}`}
                                    </Typography>
                                </div>
                            ) : (
                                <div style={skillCardStyles.starDivGray} />
                            )}
                            {hasRating ? (
                                <Typography
                                    variant="body2"
                                    style={{ fontWeight: 700 }}
                                >
                                    Excellent
                                </Typography>
                            ) : (
                                <Typography
                                    variant="body2"
                                    style={{ fontWeight: 700 }}
                                >
                                    No Rating
                                </Typography>
                            )}
                        </div>
                    </Grid>

                    <Grid item md={7} sm={12} direction="column">
                        <Typography gutterBottom>Rating By Skills</Typography>
                        <div style={skillCardStyles.skillsContainer}>
                            {skills.map((skill, index) => (
                                <div style={skillCardStyles.skillRatingRow}>
                                    <div style={{ flex: 0.5 }}>
                                        <SkillCard
                                            backgroundColor={Colors.white}
                                            skillName={skill.name}
                                            isDeletable={false}
                                            isAddable={false}
                                            fontColor={'blue'}
                                        />
                                    </div>

                                    <div style={{ flex: 0.5 }}>
                                        <Rating
                                            style={{
                                                color: Colors.ratingOrange,
                                            }}
                                            value={skill.rating}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

ProfileSkillsAndRatingsCard.propTypes = {
    type: PropTypes.oneOf([
        'detailed_profile',
        'detailed_company',
        'my_profile',
        'my_company',
    ]),
    user: PropTypes.object,
    company: PropTypes.object,
};

export default ProfileSkillsAndRatingsCard;
