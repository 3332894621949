import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography, TextField } from '@material-ui/core';
// import MaskedInput from 'react-text-mask';

import OnboardingContainerView from './OnboardingContainerView';
import {
    updatePhoneNumber,
    addPhoneNumber,
} from '../../../ducks/OnboardingDuck';
import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

const styles = {
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    phoneNumberInput: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        flex: 1,
        fontSize: 18,
    },
    continueButton: {
        borderRadius: 20,
        fontSize: 16,
        fontWeight: '400',
    },
};

// function PhoneTextMask(props) {
//     const { inputRef, ...other } = props;

//     return (
//         <MaskedInput
//             {...other}
//             ref={ref => {
//                 inputRef(ref ? ref.inputElement : null);
//             }}
//             mask={[
//                 '(',
//                 /[1-9]/,
//                 /\d/,
//                 /\d/,
//                 ')',
//                 ' ',
//                 /\d/,
//                 /\d/,
//                 /\d/,
//                 '-',
//                 /\d/,
//                 /\d/,
//                 /\d/,
//                 /\d/,
//             ]}
//             placeholderChar={'\u2000'}
//             showMask
//         />
//     );
// }

class AddPhoneNumberScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            phonNumberError: false,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.phoneNumber !== null &&
            nextProps.phoneNumber !== undefined &&
            nextProps.phoneNumber.toString().length === 10 &&
            !nextProps.phoneNumberError
        ) {
            return { phoneNumberError: false };
        }
        return {};
    }

    _isPhoneNumberInputValid = () => {
        // unformatted phoneNumber must be 10 characters long
        return (
            this.props.phoneNumber !== null &&
            this.props.phoneNumber !== undefined &&
            this.props.phoneNumber.toString().length === 10
        );
    };

    _onPhoneNumberChanged = text => {
        // sanitize input - only accept numbers - does not include ( or - from formatting
        text = text.replace(/[^0-9]/g, '');

        // initialize formattedNum to text so that if
        // text.length < 4, it has the proper value
        let formattedNum = text;

        // if input phone number is longer than 4 numbers and less than 7,
        // format to add (###)-
        if (text.length >= 4 && text.length < 7) {
            formattedNum =
                '(' + text.substring(0, 3) + ')-' + text.substring(3);
        } else if (text.length >= 7) {
            // if longer than 7, format as (###)-###-####
            formattedNum =
                '(' +
                text.substring(0, 3) +
                ')-' +
                text.substring(3, 6) +
                '-' +
                text.substring(6);
        }
        // update phoneNumber as just numbers and
        // formattedPhoneNumber as (###)-###-####
        this.props.updatePhoneNumber({
            phoneNumber: text,
            formattedPhoneNumber: formattedNum,
        });
    };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography variant="h4" gutterBottom>
                    Alright {this.props.firstName}! Let's get you verified.
                </Typography>
                <Typography variant="h2">
                    Please enter your phone number.
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <form
                className={styles.container}
                onSubmit={event => {
                    event.preventDefault();
                    if (!this._isPhoneNumberInputValid()) {
                        this.setState({
                            phoneNumberError: true,
                        });
                        return;
                    }

                    this.setState({ phoneNumberError: false });
                    // check that phoneNumber is unused to send Twilio verification
                    // or render error
                    this.props.addPhoneNumber({
                        phoneNumber: this.props.phoneNumber,
                    });
                }}
                error={!!this.props.error}
                loading={this.props.loading}
            >
                {/* {this.props.error ? (
                    <Message error content={this.props.error} />
                ) : null} */}
                <TextField
                    name="phoneNumber"
                    id="phoneNumber"
                    label="Phone Number"
                    variant="outlined"
                    autoFocus
                    value={this.props.formattedPhoneNumber}
                    error={this.state.phoneNumberError}
                    helperText={
                        this.state.firstNameError
                            ? 'Please enter a valid phone number'
                            : ''
                    }
                    onChange={event => {
                        this._onPhoneNumberChanged(event.target.value);
                    }}
                    fullWidth
                />
                <div style={{ height: 20 }} />
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={styles.continueButton}
                >
                    Continue
                </Button>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader hasHomeLink />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const {
        personalInfo,
        phoneNumber,
        formattedPhoneNumber,
        loading,
        error,
    } = onboarding;

    return {
        // only numbers stored as a string
        firstName: personalInfo ? personalInfo.firstName : '',
        phoneNumber: phoneNumber ? phoneNumber : '',
        // formatted numbers as (###)-###-####
        formattedPhoneNumber: formattedPhoneNumber ? formattedPhoneNumber : '',
        loading,
        error,
    };
};

export default connect(mapStateToProps, {
    updatePhoneNumber,
    addPhoneNumber,
})(AddPhoneNumberScreen);
