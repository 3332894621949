import React from 'react';
import { SearchMobileDropdownView } from '../common';
import { TextField, ClickAwayListener, Popper } from '@material-ui/core';

const styles = {
    textInput: {
        display: 'flex',
        borderRadius: 20,
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        border: 'solid 1px #8e8e93',
        flex: 1,
        fontSize: 16,
    },
};

const SearchBar = props => {
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    const [typedSearchTag, setTypedSearchTag] = React.useState('');
    // const [windowWidth, setWindowWidth] = React.useState(0);

    const searchOpen =
        Boolean(searchAnchorEl) &&
        props.typeaheadSearchTags &&
        props.typeaheadSearchTags.length > 0;

    const handleSearchFocus = event => {
        setSearchAnchorEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        setSearchAnchorEl(null);
    };

    // React.useEffect(() => {
    //     updateWindowDimensions();
    //     window.addEventListener('resize', updateWindowDimensions);
    // });

    // const updateWindowDimensions = () => {
    //     setWindowWidth(window.innerWidth);
    // };

    return (
        <ClickAwayListener onClickAway={handleSearchClose}>
            <div>
                <TextField
                    type="search"
                    name="search-field"
                    placeholder="Search"
                    value={typedSearchTag}
                    fullWidth
                    autoComplete="off"
                    onChange={event => {
                        setTypedSearchTag(event.target.value);
                        props.searchTagsTypeahead({
                            text: event.target.value,
                            size: 100,
                        });
                    }}
                    onFocus={event => {
                        handleSearchFocus(event);
                    }}
                    InputProps={{
                        disableUnderline: true,
                        style: {
                            ...styles.textInput,
                        },
                    }}
                />
                <Popper
                    id={'search-popper'}
                    open={searchOpen}
                    placement="bottom-start"
                    anchorEl={searchAnchorEl}
                    onClose={handleSearchClose}
                    autoFocus={false}
                >
                    <SearchMobileDropdownView />
                </Popper>
            </div>
        </ClickAwayListener>
    );
};

export default SearchBar;
