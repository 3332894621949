import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';
import { Grid, Typography, Button, List, ListItem } from '@material-ui/core';

import {
    Header,
    PangeanItem,
    FooterMini,
    GoogleAd,
    SearchBar,
    CustomContainer,
} from '../common';
import Colors from '../../constants/Colors';

import {
    pangeansFeedFetch,
    saveClickedPangean,
    updatePangeanQuery,
    updatePangeanSort,
    updatePangeanFilter,
} from '../../ducks/MainFeedDuck';

import { searchTagsTypeahead } from '../../ducks/SearchTagsDuck';

import { push } from 'connected-react-router';
import { store } from '../..';

const styles = {
    rootContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 40,
        marginLeft: '10%',
        marginRight: '10%',
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff',
        paddingTop: 21,
        paddingLeft: 42,
        paddingRight: 38,
        paddingBottom: 16,
        // paddingLeft: 42,
        // paddingRight: 42,
        // paddingTop: 25,
        // paddingBottom: 51,
    },

    headerContainer: {
        height: 235,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('images/darkbg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },

    headerTitle: {
        marginBottom: 40,
        color: Colors.white,
    },

    searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '75%',
    },

    textInput: {
        display: 'flex',
        borderRadius: 20,
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        border: 'solid 1px #8e8e93',
        flex: 1,
        fontSize: 16,
    },

    searchBtn: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        fontSize: 16,
        fontWeight: '600',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 45,
        paddingRight: 45,
    },

    sectionTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 15,
    },

    sectionTitle: {
        fontFamily: 'Inter',
        fontSize: 21,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#1d252d',
    },

    seeMoreLink: {
        color: '#1d252d',
    },

    skillsContainer: {
        display: 'flex',
        marginBottom: 30,
    },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },

    gridContainer: {
        marginTop: 20,
        marginBottom: 30,
        display: 'flex',
        flexDirection: 'column',
    },
};

class PangeansMobileScreen extends Component {
    componentDidMount() {
        this.props.pangeansFeedFetch({
            elasticSearchQuery: this.props.pangeanQuery,
            sort: this.props.pangeanSort,
            skipVal: 0,
            isPullUpReload: false,
        });
    }

    _onPangeanItemPress = item => {
        if (item.id === this.props.user.id) {
            store.dispatch(push('/myProfile'));
        } else {
            this.props.saveClickedPangean({
                owner_id: item.id,
                responder_id: this.props.user.id,
                pangean: item,
            });
            store.dispatch(push('/detailedProfile'));
        }
    };

    render() {
        return (
            <div style={{ backgroundColor: '#f2f2f7' }}>
                <Header
                    hasSearch={false}
                    activeLinkOption={'Pangeans'}
                    user={this.props.user}
                />
                <CustomContainer>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            md={4}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'fixed',
                            }}
                        >
                            <div style={{ height: 20 }} />
                            <Typography variant="h3" gutterBottom>
                                Pangeans
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Discover student talent, exclusively at
                                Pangea.app
                            </Typography>
                            <SearchBar
                                searchTagsTypeahead={
                                    this.props.searchTagsTypeahead
                                }
                                typeaheadSearchTags={
                                    this.props.typeaheadSearchTags
                                }
                            />
                            <GoogleAd />
                            {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    store.dispatch(
                                        push('/postChooseProjectType')
                                    );
                                }}
                            >
                                Post a new project
                            </Button> */}
                            <FooterMini light />
                        </Grid>
                        <Grid item md={4} style={{ display: 'flex' }} />
                        <Grid item md={1} style={{ display: 'flex' }}></Grid>
                        <Grid item md={6}>
                            <List
                                // container
                                // spacing={2}
                                style={styles.gridContainer}
                                // cols={1}
                                // cellHeight={'auto'}
                            >
                                {this.props.pangeansFeed.map(
                                    (pangean, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem key={index}>
                                                <PangeanItem
                                                    pangean={pangean}
                                                    onPress={() =>
                                                        this._onPangeanItemPress(
                                                            pangean
                                                        )
                                                    }
                                                />
                                            </ListItem>
                                            {index ===
                                                this.props.pangeansFeed.length -
                                                    2 && (
                                                <Waypoint
                                                    onEnter={() => {
                                                        if (
                                                            !this.props
                                                                .isEndReachedPangeanFeed
                                                        ) {
                                                            this.props.pangeansFeedFetch(
                                                                {
                                                                    elasticSearchQuery: this
                                                                        .props
                                                                        .pangeanQuery,
                                                                    sort: this
                                                                        .props
                                                                        .pangeanSort,
                                                                    skipVal: this
                                                                        .props
                                                                        .pangeansFeed
                                                                        .length,
                                                                    isPullUpReload: false,
                                                                }
                                                            );
                                                        }
                                                    }}
                                                />
                                            )}
                                        </React.Fragment>
                                    )
                                )}
                            </List>
                        </Grid>
                        <Grid item md={1} style={{ display: 'flex' }}></Grid>
                    </Grid>
                </CustomContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ mainFeed, userSession, searchTags }) => {
    const {
        loading,
        pangeansFeed,
        isEndReachedPangeanFeed,
        pangeanSortSetting,
        selectedSearchTags,
        pangeanQuery,
        pangeanSort,
        // filter
        pangeanDistanceSliderValue,
        yog2019Selected,
        yog2020Selected,
        yog2021Selected,
        yog2022Selected,
        yog2023Selected,
        recentlyActiveSelected,
    } = mainFeed;

    const { user, wasReactivated } = userSession;

    return {
        loading,
        user,
        wasReactivated,

        pangeansFeed,
        isEndReachedPangeanFeed,
        selectedSearchTags,
        pangeanQuery,
        pangeanSort,
        pangeanSortSetting,

        // filter
        pangeanDistanceSliderValue,
        yog2019Selected,
        yog2020Selected,
        yog2021Selected,
        yog2022Selected,
        yog2023Selected,
        recentlyActiveSelected,

        // searchtag
        typeaheadSearchTags: searchTags.typeaheadSearchTags
            ? searchTags.typeaheadSearchTags
            : [],
    };
};

export default connect(mapStateToProps, {
    pangeansFeedFetch,
    saveClickedPangean,
    updatePangeanQuery,
    updatePangeanSort,
    updatePangeanFilter,
    searchTagsTypeahead,
})(PangeansMobileScreen);
