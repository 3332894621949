import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from '@material-ui/core';

import { Form } from 'react-final-form';

import {
    projectChooseProjectType,
    projectAddPhotos,
    projectRemovePhoto,
    projectPreferLocal,
    projectDiscard,
    postCreate,
    resetPostCreate,
    updateProjectInCreate,
    projectAddAddress,
    projectAddGeoId,
} from '../../../ducks/CreatePostDuck';

import {
    postResubmit,
    postDiscard,
    updateProjectInEdit,
    updateNewPhotos,
} from '../../../ducks/MyPostsDuck';

import moment from 'moment';

import { createProposedSkill } from '../../../ducks/ProposedSkillDuck';

import Environment from '../../../constants/Environment';

import ProjectTypeForm from './ProjectTypeForm';
import BasicDetailsForm from './BasicDetailsForm';
import SkillsForm from './SkillsForm';
import CompensationForm from './CompensationForm';
import DurationTimeCommitmentForm from './DurationTimeCommitmentForm';
import AvailabilityForm from './AvailabilityForm';
import LocationPreferenceForm from './LocationPreferenceForm';

const projectTypes = ['one_time', 'internship', 'full_time'];

class PostOverview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeOfProject: projectTypes[0],
            compensationRate: 'hour',
            selectedPaymentIndex: 1,
            selectedProjectTypeIndex: 1,
            selectedLocationPickerIndex: 0,
        };
    }

    async componentDidMount() {}

    static getDerivedStateFromProps(nextProps, prevState) {
        let selectedPaymentIndex = 0;
        let selectedProjectTypeIndex = 0;

        if (nextProps.project_type === 'full_time') {
            if (nextProps.payment_frequency === 'year') {
                selectedPaymentIndex = 0;
            } else if (nextProps.payment_frequency === 'hour') {
                selectedPaymentIndex = 1;
            } else if (nextProps.payment_frequency === 'negotiable') {
                selectedPaymentIndex = 2;
            }
        } else {
            if (nextProps.payment_frequency === 'negotiable') {
                selectedPaymentIndex = 1;
            } else {
                if (nextProps.is_paid) {
                    selectedPaymentIndex = 0;
                } else {
                    selectedPaymentIndex = 2;
                }
            }
        }

        if (nextProps.project_type === 'internship') {
            selectedProjectTypeIndex = 1;
        } else if (nextProps.project_type === 'full_time') {
            selectedProjectTypeIndex = 2;
        }

        let selectedLocalIndex = 0;

        if (nextProps.prefer_local) {
            selectedLocalIndex = 0;
        } else {
            selectedLocalIndex = 1;
        }

        let selectedLocationPickerIndex = 0;
        let addressOptions = [];
        if (nextProps.owner) {
            const userAddress =
                nextProps.owner &&
                nextProps.owner.loc &&
                nextProps.owner.loc.address &&
                nextProps.owner.loc.address.formatted_name
                    ? nextProps.owner.loc.address.formatted_name
                    : 'Some Address';
            addressOptions = [
                {
                    label: userAddress,
                    value: userAddress,
                },
                {
                    label: 'Remote',
                    value: 'Remote',
                },
                {
                    label: 'Other Address',
                    value: 'Other Address',
                },
            ];
        }

        if (nextProps.clickedPost) {
            if (!nextProps.clickedPost.prefer_local) {
                // Remote
                selectedLocationPickerIndex = 1;
            } else if (
                (nextProps.clickedPost.geoId &&
                    nextProps.clickedPost.geoId === nextProps.owner.geo_id) ||
                nextProps.clickedPost.geo_id === nextProps.owner.geo_id
            ) {
                // Default Address
                selectedLocationPickerIndex = 0;
            } else {
                // Other Address
                selectedLocationPickerIndex = 2;
            }
        }

        // let newSpecificDateSelected = false;

        // if (nextProps.futureDateSelected) {
        //     // futureDateSelected
        //     if (prevState.specificDateSelected) {
        //     }
        // } else {
        //     // futureDateSelectedIsFalse
        //     if (prevState.specificDateSelected) {
        //     }
        // }

        return {
            selectedPaymentIndex,
            selectedProjectTypeIndex,
            selectedLocalIndex,
            selectedLocationPickerIndex,
            addressOptions,
            // specificDateSelected: newSpecificDateSelected,
        };
    }

    _areSkillInputsValid = () => {
        if (this.props.proposed_skills) {
            if (this.props.skills) {
                return this.props.skills.length +
                    this.props.proposed_skills.length >=
                    2
                    ? undefined
                    : 'Please select at least two skills';
            } else {
                return this.props.proposed_skills.length >= 2
                    ? undefined
                    : 'Please select at least two skills';
            }
        } else {
            return this.props.skills &&
                this.props.skills !== undefined &&
                this.props.skills.length >= 2
                ? undefined
                : 'Please select at least two skills';
        }
    };

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    overflowY: 'scroll',
                    // height: 'calc(100vh - 200px)',
                }}
            >
                <Form
                    onSubmit={() => {
                        console.log('onSubmit');
                        // this.props.onSubmit
                    }}
                    validate={values => {
                        const errors = {};

                        errors.searchSkills = this._areSkillInputsValid();

                        if (!values.isDurationSkipped && !values.duration) {
                            errors.duration = 'Required';
                        }

                        if (
                            !values.isTimeCommitmentSkipped &&
                            !values.timeCommitment
                        ) {
                            errors.timeCommitment = 'Required';
                        }

                        if (!values.availability) {
                            errors.availability = 'Please Select an Option';
                        } else if (
                            values.availability &&
                            values.availability === 'specific_date' &&
                            !values.startDate
                        ) {
                            errors.availability = 'Please Select a Start Date';
                        }

                        if (!values.address) {
                            errors.address = 'Please Select an Option';
                        } else if (
                            values.address === 'Other Address' &&
                            !values.otherAddress
                        ) {
                            errors.otherAddress = 'Please Enter an Address';
                        }

                        return errors;
                    }}
                >
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <ProjectTypeForm values={values} />
                            <div style={{ height: 20 }} />
                            <BasicDetailsForm values={values} />
                            <div style={{ height: 20 }} />

                            <SkillsForm
                                selectedSkills={this.props.skills}
                                proposedSkills={this.props.proposed_skills}
                                _updatePost={this._updatePost}
                                values={values}
                            />
                            <div style={{ height: 20 }} />

                            <CompensationForm
                                {...this.props}
                                project_type={values.projectType}
                                selectedPaymentIndex={
                                    this.state.selectedPaymentIndex
                                }
                                _onChangePaymentType={this._onChangePaymentType}
                                values={values}
                            />
                            <div style={{ height: 20 }} />

                            {values.projectType !== 'full_time' && (
                                <DurationTimeCommitmentForm
                                    isTimeCommitmentSkipped={
                                        this.props.isTimeCommitmentSkipped
                                    }
                                    isDurationSkipped={values.isDurationSkipped}
                                    duration={this.props.duration}
                                    type={this.props.type}
                                    _updatePost={this.props._updatePost}
                                    time_commitment={this.props.time_commitment}
                                    values={{ ...values }}
                                />
                            )}
                            <div style={{ height: 20 }} />

                            <AvailabilityForm
                                futureDateSelected
                                specificDateSelected
                                start_date
                                startDate
                                values={values}
                            />
                            <div style={{ height: 20 }} />

                            <LocationPreferenceForm
                                addressOptions={this.state.addressOptions}
                                selectedLocationPickerIndex={
                                    this.state.selectedLocationPickerIndex
                                }
                                owner={this.props.owner}
                                address={this.props.address}
                                _updatePost={this.props._updatePost}
                                addressError={this.props.addressError}
                                values={values}
                            />
                            <div style={{ height: 20 }} />

                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={submitting}
                                style={{ marginBottom: 20 }}
                            >
                                Review Project
                            </Button>
                            <pre>{JSON.stringify(values, undefined, 2)}</pre>
                        </form>
                    )}
                </Form>
            </div>
        );
    }

    _onChangePaymentType = value => {
        let index;
        if (this.props.project_type === 'full_time') {
            if (value === 'Salaried') {
                this._updatePost({
                    is_paid: true,
                    payment_frequency: 'year',
                    compensation: 40000,
                });
                index = 0;
            } else if (value === 'Hourly') {
                this._updatePost({
                    is_paid: true,
                    payment_frequency: 'hour',
                    compensation: 15,
                });
                index = 1;
            } else if (value === 'Negotiable') {
                this._updatePost({
                    is_paid: true,
                    payment_frequency: 'negotiable',
                    compensation: 0,
                });
                index = 2;
            }
        } else {
            if (value === 'Paid') {
                this._updatePost({
                    is_paid: true,
                    payment_frequency: 'hour',
                });
                index = 0;
            } else if (value === 'Negotiable') {
                this._updatePost({
                    is_paid: true,
                    payment_frequency: 'negotiable',
                    compensation: 0,
                });
                index = 1;
            } else if (value === 'Unpaid') {
                this._updatePost({
                    is_paid: false,
                    payment_frequency: 'never',
                    compensation: 0,
                });
                index = 2;
            }
        }
        this.setState({ selectedPaymentIndex: index });
    };

    _handleProjectTypeChange = project_type => {
        switch (project_type) {
            case 'one_time':
                this._onPressOneTime();
                break;
            case 'internship':
                this._onPressInternship();
                break;
            case 'full_time':
                this._onPressFullTime();
                break;
            default:
                break;
        }
    };

    _onPressOneTime = () => {
        this.props.projectChooseProjectType({
            project_type: 'one_time',
            is_paid: true,
            compensation: 500,
            formattedCompensation: '500',
            payment_frequency: 'project',
        });
    };

    _onPressInternship = () => {
        this.props.projectChooseProjectType({
            project_type: 'internship',
            is_paid: true,
            compensation: 15,
            formattedCompensation: '15',
            payment_frequency: 'hour',
        });
    };

    _onPressFullTime = () => {
        this.props.projectChooseProjectType({
            project_type: 'full_time',
            is_paid: true,
            compensation: 40000,
            formattedCompensation: '40,000',
            payment_frequency: 'year',
        });
    };

    _updatePost = propertiesToBeUpdated => {
        let newPost = Object.assign({}, this.props.clickedPost);

        for (let prop in propertiesToBeUpdated) {
            newPost[prop] = propertiesToBeUpdated[prop];
        }

        if (this.props.type === 'create') {
            this.props.updateProjectInCreate({
                post: newPost,
            });
        } else if (this.props.type === 'edit') {
            this.props.updateProjectInEdit({
                post: newPost,
            });
        } else if (this.props.type === 'admin') {
            this.props.updateProjectInReview({
                updatedPost: newPost,
            });
        }
    };
}

const mapStateToProps = (
    { createPost, myPosts, admin, userSession },
    ownProps
) => {
    if (ownProps.type === 'create') {
        const {
            project_type,
            skills,
            proposed_skills,
            title,
            description,
            paymentType,
            compensation,
            payment_frequency,
            is_paid,
            duration,
            isDurationSkipped,
            time_commitment,
            isTimeCommitmentSkipped,
            start_date,
            images,
            photo_names,
            prefer_local,
            address,
        } = createPost.project;

        const { user } = userSession;

        let futureDateSelected = false;
        if (start_date) {
            if (moment(Date.now()).isBefore(moment(start_date))) {
                futureDateSelected = true;
            }
        }

        if (
            images &&
            images[images.length - 1] !== 'add-photo' &&
            images.length < 4
        ) {
            images.push('add-photo');
        }

        let compensationText = compensation ? `${compensation}` : '';
        compensationText = compensationText.replace(/[^0-9]/g, '');
        let formattedCompensation;

        if (compensationText.length < 4) {
            formattedCompensation = compensationText;
        } else if (compensationText.length === 4) {
            formattedCompensation =
                compensationText.substring(0, 1) +
                ',' +
                compensationText.substring(1);
        } else if (compensationText.length === 5) {
            formattedCompensation =
                compensationText.substring(0, 2) +
                ',' +
                compensationText.substring(2);
        } else if (compensationText.length === 6) {
            formattedCompensation =
                compensationText.substring(0, 3) +
                ',' +
                compensationText.substring(3);
        }

        return {
            owner: user,
            user,
            project_type,
            skills,
            proposed_skills,
            title,
            description,
            paymentType: paymentType,
            compensation,
            formattedCompensation,
            payment_frequency,
            is_paid,
            duration: duration ? duration : '',
            isDurationSkipped,
            time_commitment: time_commitment ? time_commitment : '',
            isTimeCommitmentSkipped,
            start_date: start_date
                ? moment(start_date).format('MM-DD-YYYY')
                : '',
            images: images ? images : [],
            photo_names: photo_names ? photo_names : [],
            prefer_local,
            postCreateSuccess: createPost.postCreateSuccess
                ? createPost.postCreateSuccess
                : false,
            clickedPost: createPost.project,
            address,
            futureDateSelected,
        };
    } else if (ownProps.type === 'edit') {
        const { user } = userSession;

        const { loading, clickedPost, newPhotoNames, newImages } = myPosts;

        let futureDateSelected = false;
        if (clickedPost && clickedPost.start_date) {
            if (moment(Date.now()).isBefore(moment(clickedPost.start_date))) {
                futureDateSelected = true;
            }
        }

        let oldImages = [];
        let oldPhotoNames = [];
        if (clickedPost && clickedPost.photos) {
            for (let i = 0; i < clickedPost.photos.length; i++) {
                oldImages.push(
                    `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Posts/${clickedPost.id}/${clickedPost.photos[i]}`
                );
            }

            oldPhotoNames = Array.from(clickedPost.photos);
        }

        let combinedImages = Array.from(oldImages);
        let combinedPhotoNames = Array.from(oldPhotoNames);

        if (newImages && newImages !== undefined) {
            combinedImages = combinedImages.concat(Array.from(newImages));
        }

        if (newPhotoNames && newPhotoNames !== undefined) {
            combinedPhotoNames = combinedPhotoNames.concat(
                Array.from(newPhotoNames)
            );
        }

        if (
            combinedImages[combinedImages.length - 1] !== 'add-photo' &&
            combinedImages.length < 4
        ) {
            combinedImages.push('add-photo');
        }

        let compensationText =
            clickedPost && clickedPost.compensation
                ? `${clickedPost.compensation}`
                : '';
        compensationText = compensationText.replace(/[^0-9]/g, '');
        let formattedCompensation;

        if (compensationText.length < 4) {
            formattedCompensation = compensationText;
        } else if (compensationText.length === 4) {
            formattedCompensation =
                compensationText.substring(0, 1) +
                ',' +
                compensationText.substring(1);
        } else if (compensationText.length === 5) {
            formattedCompensation =
                compensationText.substring(0, 2) +
                ',' +
                compensationText.substring(2);
        } else if (compensationText.length === 6) {
            formattedCompensation =
                compensationText.substring(0, 3) +
                ',' +
                compensationText.substring(3);
        }

        return {
            owner: user,
            loading,
            user,
            clickedPost,
            project_type: clickedPost ? clickedPost.project_type : '',
            skills: clickedPost ? clickedPost.skills : [],
            proposed_skills: clickedPost ? clickedPost.proposed_skills : [],
            title: clickedPost ? clickedPost.title : '',
            description: clickedPost ? clickedPost.description : '',
            is_paid: clickedPost ? clickedPost.is_paid : null,
            compensation:
                clickedPost && clickedPost.compensation
                    ? clickedPost.compensation
                    : null,
            formattedCompensation,
            payment_frequency:
                clickedPost && clickedPost.payment_frequency
                    ? clickedPost.payment_frequency
                    : '',
            duration:
                clickedPost && clickedPost.duration
                    ? clickedPost.duration.toString()
                    : '',
            isDurationSkipped: !(clickedPost && clickedPost.duration),
            time_commitment:
                clickedPost && clickedPost.time_commitment
                    ? clickedPost.time_commitment.toString()
                    : '',
            isTimeCommitmentSkipped: !(
                clickedPost && clickedPost.time_commitment
            ),
            start_date:
                clickedPost && clickedPost.start_date
                    ? moment(clickedPost.start_date).format('MM-DD-YYYY')
                    : '',
            oldImages,
            oldPhotoNames,
            newPhotoNames,
            newImages,
            images: combinedImages,
            combinedImages,
            combinedPhotoNames,
            prefer_local: clickedPost ? clickedPost.prefer_local : null,
            address:
                clickedPost && clickedPost.address
                    ? clickedPost.address
                    : clickedPost && clickedPost.loc && clickedPost.loc.address
                    ? clickedPost.loc.address.formatted_name
                    : null,
            futureDateSelected,
        };
    } else if (ownProps.type === 'admin') {
        const {
            clickedPost,
            pressedReject,
            rejectReason,
            pressedApprove,
            suggestions,
        } = admin;

        const { user } = userSession;

        let futureDateSelected = false;
        if (clickedPost && clickedPost.start_date) {
            if (moment(Date.now()).isBefore(moment(clickedPost.start_date))) {
                futureDateSelected = true;
            }
        }

        let compensationText =
            clickedPost && clickedPost.compensation
                ? `${clickedPost.compensation}`
                : '';
        compensationText = compensationText.replace(/[^0-9]/g, '');
        let formattedCompensation;

        if (compensationText.length < 4) {
            formattedCompensation = compensationText;
        } else if (compensationText.length === 4) {
            formattedCompensation =
                compensationText.substring(0, 1) +
                ',' +
                compensationText.substring(1);
        } else if (compensationText.length === 5) {
            formattedCompensation =
                compensationText.substring(0, 2) +
                ',' +
                compensationText.substring(2);
        } else if (compensationText.length === 6) {
            formattedCompensation =
                compensationText.substring(0, 3) +
                ',' +
                compensationText.substring(3);
        }

        let images = [];
        if (clickedPost && clickedPost.photos) {
            for (let i = 0; i < clickedPost.photos.length; i++) {
                images.push(
                    `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Posts/${clickedPost.id}/${clickedPost.photos[i]}`
                );
            }
        }

        return {
            owner: clickedPost ? clickedPost.owner : null,
            user,
            clickedPost,
            project_type: clickedPost ? clickedPost.project_type : '',
            skills: clickedPost ? clickedPost.skills : [],
            proposed_skills: clickedPost ? clickedPost.proposed_skills : [],
            title: clickedPost ? clickedPost.title : '',
            description: clickedPost ? clickedPost.description : '',
            is_paid: clickedPost ? clickedPost.is_paid : true,
            compensation:
                clickedPost && clickedPost.compensation
                    ? clickedPost.compensation
                    : null,
            formattedCompensation,
            payment_frequency:
                clickedPost && clickedPost.payment_frequency
                    ? clickedPost.payment_frequency
                    : '',
            duration:
                clickedPost && clickedPost.duration
                    ? `${clickedPost.duration}`
                    : '',
            isDurationSkipped:
                clickedPost && clickedPost.duration ? false : true,
            time_commitment:
                clickedPost && clickedPost.time_commitment
                    ? `${clickedPost.time_commitment}`
                    : '',
            isTimeCommitmentSkipped:
                clickedPost && clickedPost.time_commitment ? false : true,
            start_date:
                clickedPost && clickedPost.start_date
                    ? moment(clickedPost.start_date).format('MM-DD-YYYY')
                    : '',
            prefer_local: clickedPost ? clickedPost.prefer_local : null,
            address:
                clickedPost && clickedPost.address
                    ? clickedPost.address
                    : clickedPost && clickedPost.loc && clickedPost.loc.address
                    ? clickedPost.loc.address.formatted_name
                    : null,
            pressedReject,
            rejectReason,
            pressedApprove,
            suggestions,
            images,
            futureDateSelected,
        };
    }
};

export default connect(mapStateToProps, {
    projectChooseProjectType,
    projectAddPhotos,
    projectRemovePhoto,
    projectPreferLocal,
    projectDiscard,
    postCreate,
    resetPostCreate,
    updateProjectInCreate,
    projectAddAddress,
    projectAddGeoId,
    postResubmit,
    postDiscard,
    updateProjectInEdit,
    updateNewPhotos,
    createProposedSkill,
})(PostOverview);
