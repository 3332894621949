/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { authenticate } from '../../ducks/UserSessionDuck';
import Colors from '../../constants/Colors';

import {Footer, WelcomeHeader, WhiteButton, WhiteReverseButton} from '../common'

import { store } from '../..';
import { Typography, Button } from '@material-ui/core';

import { push } from 'connected-react-router';
import { fetchSoftSkills } from '../../ducks/SkillsDuck';

class LoadingScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    componentDidMount() {
        this.checkLoggedIn();
    }

    checkLoggedIn() {
        const token = localStorage.getItem('auth_token');

        if (token) {
            console.log('found token, try authing');
            this.props.authenticate({ token: token });
        } else {
            console.log("didn't find token, send to welcome screen");
            this.setState({ loading: false });
            // store.dispatch(push('/welcome'));
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.authenticated) {
            console.log(
                'dispatching myProfile from componentDidUpdate, this.props.authenticated'
            );
            if (this.props.user && this.props.user.user_type === 'student') {
                store.dispatch(push('/projects'));
            } else {
                store.dispatch(push('/pangeans'));
            }
            this.props.fetchSoftSkills();
        }
    }

    render() {
        return (
            <div>
                {this.state.loading ? (
                    <div
                        style={{
                            width: '100vw',
                            height: '100vh',
                            backgroundImage:
                                "url('images/pangea/splash-logo-high-res.png')",
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundColor: Colors.pangeaBlue,
                        }}
                    />
                ) : (
                    <div>
                        <WelcomeHeader activeLink='index'/>
                {/* <div data-collapse="medium" data-animation="default" data-duration="400" className="navbar w-nav"></div> */}
                <div className="section-hero">
                    <div className="container">
                    <div className="container-hero"></div>
                    </div>
                    <div className="container-hero-2">
                    <h3 className="h1-copy-2">College talent, <br/>on-demand.</h3>
                    <p className="p1-2">Pangea.app gives you immediate, direct access to enthusiastic and talented students (a.k.a Pangeans) ready to undertake any project.</p>
                    <div style={{display: 'flex'}}>
                        <WhiteButton href='#Tour'>
                            Take a Tour
                        </WhiteButton>
                        <div style={{width: 20}}/>
                        <WhiteReverseButton href='/download'>
                            Download
                        </WhiteReverseButton>
                    </div>
                    </div>
                </div>
                <div className="section-content">
                    <div className="columns-4 w-row">
                    <div className="w-col w-col-6">
                        <h3 className="h2-2">Projects-based work</h3>
                        <p className="paragraph-4">Freelance projects present a <strong>low commitment</strong> opportunity to access college talent affordably and efficiently.  <br/><br/>Projects can be <strong>short-term </strong>and centered around <strong>real work</strong>, allowing you to test to see if the candidate is a good fit for larger roles.<br/><br/>No more having an &quot;intern&quot; sitting around from 9-5 for 3 months with nothing to do besides for grab coffee.<br/><br/>In the 21st century, it&#x27;s all about hiring a &quot;<strong>Pangean</strong>&quot; for a project. <br/>‍</p><a href="about.html" data-w-id="3f930582-56a9-6339-a97f-e046da59482e" target="_blank" className="button-7 w-button">More About Us</a></div>
                    <div className="w-col w-col-6"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d748d2a875068aca9f8a2_Skilled%20Person.jpg" alt="Skilled Pangean" className="image-13"/></div>
                    </div>
                </div>
                <div id="Tour" className="section-content-blue">
                    <div className="container-flex-vert">
                    <h3 className="h2-c-w-2">What we bring to the table</h3>
                    <div data-duration-in="300" data-duration-out="700" className="tabs w-tabs">
                        <div className="tabs-menu-2 w-tab-menu">
                        <a data-w-tab="Tab 1" className="tab-on-2 w-inline-block w-tab-link">
                            <div className="feature-heading-2">Direct Access to Students</div>
                            <p className="paragraph-2-w-2">Post a project on our marketplace or reach out to students directly, without having to work through college career centers.</p>
                        </a>
                        <a data-w-tab="Tab 2" className="tab-on-2 w-inline-block w-tab-link">
                            <div className="feature-heading-2">No Upfront Fee!</div>
                            <p className="paragraph-2-w-2">Post your first projects for free, and reach out to several students without paying a subscription. We charge a 13% processing fee when you pay through our 1099 compliant payment gateway. </p>
                        </a>
                        <a data-w-tab="Tab 3" className="tab-on-copy w-inline-block w-tab-link w--current">
                            <div className="feature-heading-2"><strong>Ratings and reviews</strong></div>
                            <p className="paragraph-2-w-2">Choose a Pangean based off their ratings and reviews, and then add your own to help them improve and support the network. </p>
                        </a>
                        </div>
                        <div className="w-tab-content">
                        <div data-w-tab="Tab 1" className="w-tab-pane"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5dbd0ea73623a32db39b80_Main%20Feed.png" alt="" className="image-15"/></div>
                        <div data-w-tab="Tab 2" className="w-tab-pane"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d78624bb5418a5bd4ef3f_Messenger.png" alt="" className="image-15"/></div>
                        <div data-w-tab="Tab 3" className="w-tab-pane w--tab-active"><img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d7bbdb1f1ca1230c9531d_Profile.png" alt="" className="image-15"/></div>
                        </div>
                    </div>
                    </div>
                    <div className="div-block-8">
                    <WhiteReverseButton href='/download'>
                    Download and post your project
                        </WhiteReverseButton>
                        {/* <a href="download.html" data-w-id="208bf753-6fda-fc58-92c2-a578aeb1f541" className="button-6-copy w-button">Download now and post your project</a> */}
                    </div>
                </div>
                <div className="section-content-grey">
                    <div className="columns-5 w-row">
                    <div className="column-2 w-col w-col-6 w-col-stack">
                        <h3 className="h2-2">Hire a Pangean</h3>
                        <p className="paragraph-4 space-btm">Find the right Pangean for your project &amp; get started today!</p>
                        <div>
                        <Button variant='contained' color='primary' href='/download'>
                            Try it out
                        </Button>
                        </div>
                        <img src="https://uploads-ssl.webflow.com/5d5d5ec3f8a21b2038f6b5ff/5d5d80ad3e9e5a3fcd03a6ea_Linked.png" width="399" alt="" className="image-12"/></div>
                    <div className="column w-col w-col-6 w-col-stack">
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">1099-Compliant</h3>
                            <p className="paragraph-2-copy">Every transaction made through our platform conforms to US tax laws, ensuring a headache-free April for you.</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">NDA Coverage</h3>
                            <p className="paragraph-2-copy">All of our Pangeans are bound by an NDA, so your business secrets are safe with them.</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">Dispute resolution</h3>
                            <p className="paragraph-2-copy">If you aren&#x27;t happy, or the Pangean doesn&#x27;t meet you standard, we can issue full or partial refunds</p>
                        </div>
                        </div>
                        <div className="feature-wrap-2">
                        <div className="feature-wrap-left">
                            <div className="tick-circle"><img src="images/check.svg" width="18" alt=""/></div>
                        </div>
                        <div className="feature-wrap-right">
                            <h3 className="h4-3">We are Pangeans</h3>
                            <p className="paragraph-2-copy">We&#x27;re always a click away. If you&#x27;re running into a wall, just send a message to us and our team will tear it down.</p>
                        </div>
                        </div>
                    </div>
                    </div>
                </div><a href="download.html" data-w-id="7362d35b-c5d7-4b11-9d32-c8a1362e4581" className="button-7 w-button">Try it out</a>
                <div className="section-content-2">
                    <div className="container-flex-vert-wide">
                    <h3 className="h2 centre testimonial-white">Hear it from our best spokespeople</h3>
                    <div className="testimonials-wrap">
                        <div data-animation="slide" data-duration="500" data-infinite="1" className="slider w-slider">
                        <div className="w-slider-mask">
                            <div className="slide w-slide">
                            <div className="div-block-11">
                                <div style={{paddingTop:'56.17021276595745%'}} id="w-node-8e8186f5d65c-cefec9cb" className="w-embed-youtubevideo"><iframe src="https://www.youtube.com/embed/-pzxoKQQ0Sg?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameborder="0" style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',pointerEvents:'auto'}} allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div>
                            </div>
                            </div>
                            <div className="slide w-slide">
                            <div className="div-block-11">
                                <div style={{paddingTop:'56.17021276595745%'}} id="w-node-e0af5bacebcf-cefec9cb" className="w-embed-youtubevideo"><iframe src="https://www.youtube.com/embed/z_gJ7kBzQvI?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0" frameborder="0" style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',pointerEvents:'auto'}} allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div>
                            </div>
                            </div>
                        </div>
                        <div className="left-arrow w-slider-arrow-left">
                            <div className="icon-2 w-icon-slider-left"></div>
                        </div>
                        <div className="right-arrow w-slider-arrow-right">
                            <div className="icon-2 w-icon-slider-right"></div>
                        </div>
                        <div className="slide-nav w-slider-nav w-round"></div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer/>
            </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user, authenticated } = userSession;

    return {
        authenticated,
        user,
    };
};

export default connect(mapStateToProps, {
    authenticate,
    fetchSoftSkills,
})(LoadingScreen);
