// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    INBOX_FETCH: 'inbox_fetch',
    INBOX_RELOAD: 'inbox_reload',
    INBOX_FETCH_SUCCESS: 'inbox_fetch_success',
    INBOX_FETCH_FAIL: 'inbox_fetch_fail',
    INBOX_UPDATE_HAS_UNREAD_MESSAGES: 'inbox_update_has_unread_messages',

    INBOX_SUBSCRIBE: 'inbox_subscribe',
    INBOX_SUBSCRIBE_SUCCESS: 'inbox_subscribe_success',
    INBOX_SUBSCRIBE_FAIL: 'inbox_subscribe_fail',

    ROOM_UPDATE: 'room_update',
    ROOM_UPDATE_SUCCESS: 'room_update_success',
    ROOM_UPDATE_FAIL: 'room_update_fail',

    // GET_MESSENGER_SCREEN_DATA: 'get_messenger_screen_data',
    // GET_MESSENGER_SCREEN_DATA_SUCCESS: 'get_messenger_screen_data_sucess',
    // GET_MESSENGER_SCREEN_DATA_FAIL: 'get_messenger_screen_data_fail',

    // SAVE_CLICKED_ROOM: 'save_room',
    // SAVE_CLICKED_ROOM_SUCCESS: 'save_room_success',
};

const INITIAL_STATE = {
    loading: false,
    roomsArray: [],
    // clickedRoom: null,x
    error: '',
    hasUnreadMessages: false,
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // fetch all rooms that a user is a part of
        case types.INBOX_FETCH:
            return { ...state, loading: true };
        case types.INBOX_RELOAD:
            return { ...state, loading: true, roomsArray: [] };
        case types.INBOX_FETCH_SUCCESS:
            return { ...state, loading: false, roomsArray: action.payload };
        case types.INBOX_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.INBOX_UPDATE_HAS_UNREAD_MESSAGES:
            return { ...state, hasUnreadMessages: action.payload };

        case types.INBOX_SUBSCRIBE:
            return { ...state, loading: true, roomsArray: [] };
        case types.INBOX_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
                roomsArray: action.payload,
                // hasUnreadMessages: action.payload
            };
        case types.INBOX_SUBSCRIBE_FAIL:
            return { ...state, loading: false, error: action.payload };

        // // update room
        case types.ROOM_UPDATE:
            return { ...state, loading: false };
        case types.ROOM_UPDATE_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };
        case types.ROOM_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        // save clicked room
        case types.SAVE_CLICKED_ROOM:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_ROOM_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };

        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const inboxFetch = ({ user, isPullDownReload }) => {
    return dispatch => {
        if (!isPullDownReload) {
            dispatch({ type: types.INBOX_FETCH, payload: { user } });
        } else {
            dispatch({ type: types.INBOX_RELOAD, payload: { user } });
        }

        WS.socket.emit('inbox:fetch', { user_id: user.id });
        WS.socket.once('inbox:fetch', res => {
            if (res.success) {
                // inboxFetchSuccess(dispatch, res.data);
                inboxFetchSuccess(dispatch, res);
            } else {
                inboxFetchFail(dispatch, res.message);
            }
        });
    };
};

const inboxFetchSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Inbox Fetch Success', {});
    dispatch({
        type: types.INBOX_FETCH_SUCCESS,
        payload: res.data.inbox,
    });
    dispatch({
        type: types.INBOX_UPDATE_HAS_UNREAD_MESSAGES,
        payload: res.data.has_unread_messages,
    });
};

const inboxFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('inboxFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.INBOX_FETCH_FAIL,
        payload: msg,
    });
};

export const inboxSubscribe = ({ user }) => {
    return dispatch => {
        let hasListener = WS.socket.hasListeners('inbox:change');

        if (hasListener) {
        } else {
            dispatch({ type: types.INBOX_SUBSCRIBE });

            WS.socket.emit('inbox:subscribe', { user_id: user.id });
            WS.socket.once('inbox:subscribe', res => {
                if (res.success) {
                    WS.socket.on('inbox:change', res => {
                        inboxSubscribeSuccess(dispatch, res);
                    });
                } else {
                    console.log('error subscribing to inbox');
                }
            });
        }
    };
};

const inboxSubscribeSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Inbox Subscribe Success', res);
    dispatch({
        type: types.INBOX_SUBSCRIBE_SUCCESS,
        payload: res,
    });
};

export const roomUpdate = ({ room, user }) => {
    return dispatch => {
        dispatch({ type: types.ROOM_UPDATE });

        WS.socket.emit('room:update', { room });
        WS.socket.once('room:update', res => {
            if (res.success) {
                roomUpdateSuccess(dispatch, res.data[0], user);
            } else {
                roomUpdateFail(dispatch, res.msg);
            }
        });
    };
};

const roomUpdateSuccess = (dispatch, room, user) => {
    // AmplitudeAnalytics.track('Room Update Success', {});
    dispatch({
        type: types.ROOM_UPDATE_SUCCESS,
        payload: room,
    });
};

const roomUpdateFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomUpdateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.ROOM_UPDATE_FAIL,
        payload: msg,
    });
};
