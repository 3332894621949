import io from 'socket.io-client';
import Environment from '../constants/Environment';
// import { AsyncStorage } from 'react-native';
import { store } from '../index';

const socketURL = `https://${Environment.SOCKETAPI}`;

export default class WS {
    static init() {
        this.socket = io(socketURL, {
            transports: ['websocket'],
        });
        this.socket.on('connect', () => {
            console.log('connected to server!!!');
            const token = localStorage.getItem('auth_token');
            if (token) {
                console.log('got token:');
                console.log(token);
                this.socket.emit('authenticate', { token });

                store.dispatch({ type: 'authenticate' });

                this.socket.once('authenticated', res => {
                    console.log('authenticated!!!');
                    if (res.data && res.data.token) {
                        localStorage.setItem('auth_token', res.data.token);
                        store.dispatch({
                            type: 'authenticate_success',
                            payload: res.data.user,
                        });
                    } else {
                        store.dispatch({
                            type: 'authenticate_fail',
                            payload: res.status,
                        });
                    }
                });
            }
        });
        this.socket.on('disconnect', () => {
            console.log('disconnected from server');

            this.socket = io(socketURL, {
                transports: ['websocket'],
            });
        });
    }
}
