import Colors from './Colors';

export default {
    textInput: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        flex: 1,
        fontSize: 18,
    },
    button: {
        borderRadius: 20,
        fontSize: 16,
        fontWeight: '400',
    },
    fileUpload: {
        height: 40,
        width: 100,
        backgroundColor: Colors.pangeaBlue,
    },
    textArea: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingTop: 10,
        paddingBottom: 10,
        paddingRight: 10,
        paddingLeft: 10,
        marginBottom: 20,
        // minHeight: 85,
        flex: 1,
        fontSize: 18,
    },
    buttonProgress: {
        color: Colors.pangeaBlue,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    marginBottom20: {
        marginBottom: 20,
    },
    marginBottom10: {
        marginBottom: 10,
    },
    marginBottom0: {
        marginBottom: 0,
    },
};

/*
Inter
Name	            Weight class
Thin	            100
Thin Italic	        100
Extra Light	        200
Extra Light Italic	200
Light	            300
Light Italic	    300
Regular	            400
Italic	            400
Medium	            500
Medium Italic	    500
Semi Bold	        600
Semi Bold Italic	600
Bold	            700
Bold Italic	        700
Extra Bold	        800
Extra Bold Italic	800
Black	            900
Black Italic	    900
*/
