import React from 'react';
import Colors from '../../constants/Colors';

import PropTypes from 'prop-types';

import { Dialog, Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { push } from 'connected-react-router';
import { store } from '../..';

const ReonboardPromptOverlay = props => {
    return (
        <Dialog
            onClose={() => {
                console.log('onClose');
            }}
            open={true}
        >
            <div
                style={{
                    margin: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Complete Your Profile
                </Typography>
                <Typography gutterBottom>
                    Complete your profile to view or make posts!
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (props.user.user_type === 'student') {
                            // conditional for studentAddProfessionalInfo
                            store.dispatch(push('/studentAddProfileInfo'));
                        } else {
                            if (props.user.company_id) {
                                store.dispatch(push('/businessAddCompanyInfo'));
                            } else {
                                store.dispatch(push('/businessAddProfileInfo'));
                            }
                        }
                    }}
                >
                    Okay
                </Button>
                <Button
                    style={{ position: 'absolute', bottom: 0, right: 0 }}
                    onClick={e => {
                        e.preventDefault();
                        props.logoutUser();
                    }}
                >
                    log out
                </Button>
            </div>
        </Dialog>
    );
};

ReonboardPromptOverlay.propTypes = {
    user: PropTypes.object.isRequired,
    logoutUser: PropTypes.func.isRequired,
};

export default withRouter(ReonboardPromptOverlay);
