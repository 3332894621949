import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import {
    Dialog,
    TextField,
    Button,
    Typography,
    FormControl,
    List,
    ListItem,
    Divider,
    MenuItem,
    Select,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { saveStripeCardToken } from '../../../ducks/SubscriptionsDuck';
import PlacesAutocomplete from 'react-places-autocomplete';
import { CustomInputLabel, CustomInputBase } from '..';

// props:
/*
close
isVisible
onSubmit
*/

const searchOptions = {
    types: ['geocode'],
};

const numOfEmployees = ['1', '2-10', '11-50', '51-100', '101+'];

class EditBusinessCompanyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const required = value => (value ? undefined : 'Required');

        const { classes } = this.props;

        return (
            <Dialog
                onClose={() => {
                    console.log('onClose');
                    this.props.close();
                }}
                open={this.props.isVisible}
            >
                <Form onSubmit={this.props.onSubmit}>
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    flex: 1,
                                    padding: 20,
                                    minWidth: '33%',
                                }}
                            >
                                <Typography variant="h4" gutterBottom>
                                    Edit Company Info
                                </Typography>
                                <Field name="companyName" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            variant="outlined"
                                            label="Company Name"
                                            fullWidth
                                            style={{
                                                marginBottom: 20,
                                                marginRight: 10,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field name="bio" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            placeholder={
                                                'For example “I am a RISD undergraduate with a passion for ”'
                                            }
                                            variant="outlined"
                                            label="Description"
                                            fullWidth
                                            maxLength={5000}
                                            rows="6"
                                            multiline
                                            error={meta.error && meta.touched}
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field
                                    name="numOfEmployees"
                                    validate={required}
                                >
                                    {({ input, meta }) => (
                                        <FormControl
                                            style={{
                                                minWidth: '50%',
                                                marginBottom: 20,
                                            }}
                                            error={meta.error && meta.touched}
                                        >
                                            <CustomInputLabel variant="outlined">
                                                Number of Employees
                                            </CustomInputLabel>
                                            <Select
                                                variant="outlined"
                                                {...input}
                                                onChange={e => {
                                                    input.onChange(
                                                        e.target.value
                                                    );
                                                }}
                                                labelWidth={'100%'}
                                                input={
                                                    <CustomInputBase
                                                        error={
                                                            meta.error &&
                                                            meta.touched
                                                        }
                                                    />
                                                }
                                            >
                                                <MenuItem value="" disabled>
                                                    Number of Employees
                                                </MenuItem>
                                                {numOfEmployees.map(
                                                    (
                                                        numOfEmployeesItem,
                                                        index
                                                    ) => {
                                                        return (
                                                            <MenuItem
                                                                value={
                                                                    numOfEmployeesItem
                                                                }
                                                            >
                                                                {
                                                                    numOfEmployeesItem
                                                                }
                                                            </MenuItem>
                                                        );
                                                    }
                                                )}
                                            </Select>
                                        </FormControl>
                                    )}
                                </Field>
                                <Field name="address">
                                    {({ input, meta }) => (
                                        <PlacesAutocomplete
                                            onChange={address => {
                                                // this.setState({ address });
                                                input.onChange(address);
                                            }}
                                            onSelect={address => {
                                                // this.setState({ address });
                                                // props._updatePost({ address });
                                                input.onChange(address);
                                            }}
                                            searchOptions={searchOptions}
                                            {...input}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <div>
                                                    <TextField
                                                        label="Address"
                                                        variant="outlined"
                                                        error={
                                                            meta.error &&
                                                            meta.touched
                                                        }
                                                        {...input}
                                                        inputProps={{
                                                            ...getInputProps({
                                                                // placeholder: 'Address',
                                                                className:
                                                                    'location-search-input',
                                                            }),
                                                        }}
                                                        style={{
                                                            minWidth: '100%',
                                                        }}
                                                    />
                                                    <div className="autocomplete-dropdown-container">
                                                        {loading && (
                                                            <Typography>
                                                                Loading...
                                                            </Typography>
                                                        )}

                                                        <List>
                                                            {suggestions.map(
                                                                suggestion => {
                                                                    return (
                                                                        <ListItem
                                                                            {...getSuggestionItemProps(
                                                                                suggestion
                                                                            )}
                                                                            className={
                                                                                classes.listItem
                                                                            }
                                                                        >
                                                                            <Typography>
                                                                                {
                                                                                    suggestion.description
                                                                                }
                                                                            </Typography>
                                                                            <Divider />
                                                                        </ListItem>
                                                                    );
                                                                }
                                                            )}
                                                        </List>
                                                    </div>
                                                </div>
                                            )}
                                        </PlacesAutocomplete>
                                    )}
                                </Field>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting}
                                    style={{ marginBottom: 20 }}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )}
                </Form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ userSession, subscriptions }) => {
    const { user } = userSession;
    const {
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    } = subscriptions;
    return {
        user,
        paymentLoading,
        paidSubscription,
        error,
        selectedSubscription,
    };
};

export default connect(mapStateToProps, {
    saveStripeCardToken,
})(EditBusinessCompanyForm);
