import React from 'react';
import Colors from '../../constants/Colors';

import PropTypes from 'prop-types';

import {
    Menu,
    AppBar,
    Toolbar,
    TextField,
    withStyles,
    ClickAwayListener,
    Popper,
    Link,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter } from 'react-router-dom';

import HeaderDropdown from './header/HeaderDropdown';

import globe from '../../assets/images/pangea/globe.png';
import { SearchDropdownView, CustomContainer } from './';

import { push } from 'connected-react-router';
import { store } from '../..';

/*
 * This file contains a common component for header which allows text, a left icon,
 * a right icon, or any combination of the three to be passed in such that they
 * will all be correctly aligned. Left and right icons must have width less than
 * 65px and must already have a onPress method attached to them. See end of document
 * and ProfileScreen.js for implementation guidelines.
 */
const styles = {
    container: {
        backgroundColor: '#006C9E',
        // paddingLeft: 42,
        // paddingRight: 42,
        display: 'flex',
        justifyContent: 'space-between',
    },

    leftContainer: {
        display: 'flex',
        flex: 1.0,
        alignItems: 'center',
    },

    logoImg: {
        marginLeft: 15,
        marginRight: 15,
    },

    textInput: {
        display: 'flex',
        borderRadius: 20,
        // border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        // paddingTop: 3,
        // paddingBottom: 3,
        flex: 1,
        fontSize: 16,
    },

    linksContainer: {
        flex: 1.0,
        display: 'flex',
        flexDirection: 'row-reverse',
    },

    activeLink: {
        color: 'white',
        // textDecoration: 'none',
        marginLeft: 30,
        fontSize: 16,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '700',
    },

    link: {
        color: 'white',
        // textDecoration: 'none',
        marginLeft: 30,
        fontSize: 16,
        fontFamily: 'Inter',
        fontColor: Colors.fontBlack,
        fontWeight: '400',
    },
};

const ProfileMenu = withStyles({
    paper: {
        border: '1px solid #979797',
        borderRadius: 0,
        marginTop: 30,
    },
})(props => (
    <Menu
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));

const Header = ({ hasSearch, activeLinkOption, user, history }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [searchAnchorEl, setSearchAnchorEl] = React.useState(null);
    const [searchQuery, setSearchQuery] = React.useState('');
    const [windowWidth, setWindowWidth] = React.useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

    const meMenuOpen = Boolean(anchorEl);
    const searchOpen = Boolean(searchAnchorEl);

    const handleMeMenu = event => {
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleMeMenuClose = () => {
        setAnchorEl(null);
    };

    const handleSearchFocus = event => {
        setSearchAnchorEl(event.currentTarget);
    };

    const handleSearchClose = () => {
        setSearchAnchorEl(null);
    };

    const handleMenuIconClick = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    React.useEffect(() => {
        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);
    });

    const updateWindowDimensions = () => {
        setWindowWidth(window.innerWidth);
    };

    const sideDrawerContent = history => (
        <div
            className={{ width: 'auto' }}
            role="presentation"
            onClick={handleMenuIconClick}
            onKeyDown={handleMenuIconClick}
        >
            <List>
                {[
                    { text: 'Pangeans', route: '/pangeans' },
                    { text: 'Projects', route: '/projects' },
                    // { text: 'Messaging', route: '/messenger' },
                    { text: 'Me', route: '/myProfile' },
                ].map(linkObj => (
                    <ListItem
                        button
                        key={linkObj.text}
                        onClick={event => {
                            event.preventDefault();
                            store.dispatch(push(linkObj.route));
                        }}
                    >
                        <ListItemText primary={linkObj.text} />
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <AppBar position="sticky">
            <CustomContainer>
                <Toolbar
                    style={styles.container}
                    variant="dense"
                    disableGutters={true}
                >
                    <ClickAwayListener onClickAway={handleSearchClose}>
                        <div style={styles.leftContainer}>
                            <Link href="/">
                                <img
                                    src={globe}
                                    height={40}
                                    width={40}
                                    alt="Pangea logo"
                                    style={styles.logoImg}
                                ></img>
                            </Link>

                            {hasSearch ? (
                                <TextField
                                    type="search"
                                    name="search-field"
                                    placeholder="Search"
                                    value={searchQuery}
                                    onChange={event => {
                                        setSearchQuery(event.target.value);
                                    }}
                                    onFocus={handleSearchFocus}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: {
                                            ...styles.textInput,
                                        },
                                    }}
                                    autoComplete="off"
                                />
                            ) : null}

                            <Popper
                                id={'search-popper'}
                                open={searchOpen}
                                placement="bottom-start"
                                anchorEl={searchAnchorEl}
                                onClose={handleSearchClose}
                                autoFocus={false}
                                // style={{ marginTop: 15 }}
                            >
                                <SearchDropdownView />
                            </Popper>
                        </div>
                    </ClickAwayListener>

                    {windowWidth > 600 ? (
                        <div style={styles.linksContainer}>
                            <Link
                                color="inherit"
                                href="/"
                                style={
                                    activeLinkOption === 'Me'
                                        ? styles.activeLink
                                        : styles.link
                                }
                                onClick={handleMeMenu}
                            >
                                Me
                            </Link>
                            {/* <Link
                                color="inherit"
                                href="/"
                                style={
                                    activeLinkOption === 'Me'
                                        ? styles.activeLink
                                        : styles.link
                                }
                                onClick={event => {
                                    event.preventDefault();
                                    store.dispatch(push('/messenger'));
                                }}
                            >
                                Messaging
                            </Link> */}
                            <Link
                                color="inherit"
                                href="/projects"
                                style={
                                    activeLinkOption === 'Me'
                                        ? styles.activeLink
                                        : styles.link
                                }
                            >
                                Projects
                            </Link>
                            <Link
                                color="inherit"
                                href="/pangeans"
                                style={
                                    activeLinkOption === 'Me'
                                        ? styles.activeLink
                                        : styles.link
                                }
                            >
                                Pangeans
                            </Link>
                            <ProfileMenu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={meMenuOpen}
                                onClose={handleMeMenuClose}
                            >
                                <HeaderDropdown user={user} />
                            </ProfileMenu>
                        </div>
                    ) : (
                        <div style={styles.linksContainer}>
                            <IconButton onClick={handleMenuIconClick}>
                                <MenuIcon
                                    style={{
                                        color: 'white',
                                    }}
                                />
                            </IconButton>
                        </div>
                    )}
                </Toolbar>
            </CustomContainer>

            <Drawer
                anchor="right"
                open={isDrawerOpen}
                onClose={() => {
                    setIsDrawerOpen(false);
                }}
            >
                {sideDrawerContent(history)}
            </Drawer>
        </AppBar>
    );
};

Header.propTypes = {
    user: PropTypes.object.isRequired,
};

export default withRouter(Header);
