import React, { Component } from 'react';
import Downshift from 'downshift';
// import deburr from 'lodash/deburr';
import {
    TextField,
    Paper,
    Button,
    MenuItem,
    List,
    Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import Colors from '../../constants/Colors';
import { SkillCard } from '.';
import {
    skillsTypeahead,
    skillsComputeSuggestedSkills,
    resetTypeaheadSkills,
} from '../../ducks/SkillsDuck';

const styles = theme => ({
    root: {
        flexGrow: 1,
        height: 250,
    },
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        zIndex: 2,
        left: 0,
        right: 0,
    },
    paperWithBorder: {
        position: 'absolute',
        zIndex: 2,
        border: 'solid 1px #c7c7cc',
        left: 0,
        right: 0,
    },
    chip: {
        margin: theme.spacing(0.5, 0.25),
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        width: 'auto',
        flexGrow: 1,
    },
    divider: {
        height: theme.spacing(2),
    },
});

/*
Props:
    backgroundColor
    hasProposedSkills
    hasSuggestedSkills
    defaultSuggestedSkills
    ownSuggestedSkills
    disableProposeSkill
    selectedSkills
    proposedSkills
    autoFocus
    updateSuggestedSkills
    updateSelectedSkills
    updateProposedSkills
    autocompleteMargin
*/

class SkillAutocompleteView extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedSkillInitial: '',
            typedSkill: '',
            suggestedSkills: [],
            selectedSkills: [],
            proposedSkills: [],
            typeaheadSkills: [],
            color: null,
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            color: nextProps.color,
            selectedSkills: nextProps.selectedSkills
                ? nextProps.selectedSkills
                : [],
            proposedSkills: nextProps.proposedSkills
                ? nextProps.proposedSkills
                : [],
            typeaheadSkills: nextProps.typeaheadSkills,
            suggestedSkills:
                nextProps.suggestedSkills &&
                nextProps.suggestedSkills.length > 0
                    ? nextProps.suggestedSkills
                    : nextProps.defaultSuggestedSkills &&
                      nextProps.defaultSuggestedSkills.length > 0
                    ? nextProps.defaultSuggestedSkills
                    : [],
        };
    }

    _renderSkill = item => {
        if (item.name === 'suggest-skill') {
            if (
                item.typedSkill.length >= 2 &&
                !this.props.disableProposeSkill
            ) {
                return (
                    <Button
                        style={{ zIndex: 5 }}
                        onPress={() => {
                            let proposedSkillsArray = [
                                ...this.state.proposedSkills,
                            ];
                            proposedSkillsArray.unshift({
                                name: item.typedSkill,
                            });
                            this.setState({ typedSkill: '' });
                            this.props.updateProposedSkills(
                                proposedSkillsArray
                            );
                            this.props.resetTypeaheadSkills();
                        }}
                    >
                        <div
                            style={{
                                height: 30,
                                jusitfyContent: 'center',
                                alignItems: 'flex-start',
                                flex: 1,
                                zIndex: 5,
                            }}
                        >
                            <Typography
                                variant="body2"
                                style={[
                                    styles.suggestText,
                                    { color: this.state.color },
                                ]}
                            >
                                {`+ Propose Skill: "${item.typedSkill}"`}
                            </Typography>
                        </div>
                    </Button>
                );
            } else {
                return null;
            }
        } else {
            return (
                <Button
                    style={{ zIndex: 5 }}
                    onPress={() => {
                        let selectedSkillsArray = this.state.selectedSkills
                            ? Array.from(this.state.selectedSkills)
                            : [];

                        if (
                            !this._isDuplicateSkill(selectedSkillsArray, item)
                        ) {
                            // add just the skill's id and name
                            selectedSkillsArray.unshift({
                                id: item.id,
                                name: item.name,
                            });
                            this.setState({ typedSkill: '' });
                            this.props.updateSelectedSkills(
                                selectedSkillsArray
                            );
                            this.props.skillsComputeSuggestedSkills(
                                selectedSkillsArray
                            );

                            this.props.resetTypeaheadSkills();
                        }
                    }}
                >
                    <div
                        style={{
                            height: 30,
                            jusitfyContent: 'center',
                            alignItems: 'flex-start',
                            flex: 1,
                            zIndex: 5,
                        }}
                    >
                        <Typography variant="body2" style={styles.itemText}>
                            {item.name}
                        </Typography>
                    </div>
                </Button>
            );
        }
    };

    _isDuplicateSkill(selectedSkills, item) {
        for (let i = 0; i < selectedSkills.length; i++) {
            if (selectedSkills[i].name === item.name) {
                return true;
            }
        }

        return false;
    }

    _renderSuggestedSkillItem = (skill, index) => {
        return (
            <SkillCard
                backgroundColor={
                    this.props.white
                        ? Colors.white
                        : this.state.color
                        ? this.state.color
                        : Colors.pangeaGreen
                }
                skillName={skill.name}
                isDeletable={false}
                isAddable={true}
                onAddPress={() => {
                    let selectedSkillsArray = Array.from(
                        this.state.selectedSkills
                    );
                    // add just the skill's id and name
                    selectedSkillsArray.unshift({
                        id: skill.id,
                        name: skill.name,
                    });
                    this.props.updateSelectedSkills(selectedSkillsArray);
                    this.props.skillsComputeSuggestedSkills(
                        selectedSkillsArray
                    );
                }}
                fontColor={this.props.white ? 'blue' : 'white'}
            />
        );
    };

    _renderSelectedSkillItem = (item, index) => {
        return (
            <SkillCard
                backgroundColor={
                    this.props.white
                        ? Colors.white
                        : this.state.color
                        ? this.state.color
                        : Colors.pangeaGreen
                }
                skillName={item.name}
                isDeletable={true}
                onDeletePress={() => {
                    let newSelectedSkills = this.state.selectedSkills
                        ? Array.from(this.state.selectedSkills)
                        : [];
                    newSelectedSkills.splice(index, 1);
                    this.props.updateSelectedSkills(newSelectedSkills);
                    this.props.skillsComputeSuggestedSkills(newSelectedSkills);
                }}
                fontColor={this.props.white ? 'blue' : 'white'}
            />
        );
    };

    _renderProposedSkillItem = (skill, index) => {
        return (
            <SkillCard
                backgroundColor={
                    this.props.white
                        ? Colors.white
                        : this.state.color
                        ? this.state.color
                        : Colors.pangeaGreen
                }
                skillName={skill.name}
                isDeletable={true}
                onDeletePress={() => {
                    let newProposedSkills = this.state.proposedSkills
                        ? Array.from(this.state.proposedSkills)
                        : [];
                    newProposedSkills.splice(index, 1);
                    this.props.updateProposedSkills(newProposedSkills);
                }}
                fontColor={this.props.white ? 'blue' : 'white'}
            />
        );
    };

    _renderInput = inputProps => {
        const {
            InputProps,
            classes,
            ref,
            error,
            helperText,
            ...other
        } = inputProps;

        return (
            <TextField
                InputProps={{
                    inputRef: ref,
                    classes: {
                        root: classes.inputRoot,
                        input: classes.inputInput,
                    },
                    ...InputProps,
                    style: {
                        paddingTop: 5,
                    },
                }}
                {...other}
                error={error ? error : false}
                helperText={helperText}
            />
        );
    };

    _renderTypeaheadSkills = typeaheadSkillsProps => {
        const {
            skill,
            index,
            itemProps,
            highlightedIndex,
            disableProposeSkill,
            typedSkill,
        } = typeaheadSkillsProps;
        const isHighlighted = highlightedIndex === index;

        if (skill.name === 'suggest-skill') {
            if (typedSkill.length >= 2 && !disableProposeSkill) {
                return (
                    <MenuItem
                        {...itemProps}
                        key={skill.id}
                        selected={isHighlighted}
                        component="div"
                        style={{
                            fontWeight: 400,
                            color: this.state.color,
                            zIndex: 3,
                        }}
                    >
                        {`+ Propose Skill: "${typedSkill}"`}
                    </MenuItem>
                );
            } else {
                return null;
            }
        }

        return (
            <MenuItem
                {...itemProps}
                key={skill.id}
                selected={isHighlighted}
                component="div"
                style={{
                    fontWeight: 400,
                    zIndex: 3,
                }}
            >
                {skill.name}
            </MenuItem>
        );
    };

    render() {
        console.log('skillautocomplete props');
        console.log(this.props);
        const { classes } = this.props;

        let newArr = this.state.typeaheadSkills
            ? Array.from(this.state.typeaheadSkills)
            : [];

        if (this.state.typedSkill && this.state.typedSkill.length > 2) {
            newArr.push({
                name: 'suggest-skill',
                typedSkill: this.state.typedSkill,
            });
        }
        return (
            <div>
                {/* <p>Skill Autocomplete</p> */}
                {this._renderSkillAutocomplete(newArr, classes)}
                {this.props.hasSuggestedSkills &&
                this.state.suggestedSkills.length > 0 ? (
                    <div style={{ maxWidth: 590, marginTop: 20 }}>
                        <Typography variant="body2">
                            Suggested Skills:
                        </Typography>
                        <List
                            style={{
                                height: 50,
                                overflowX: 'scroll',
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                            alignItems="flex-start"
                        >
                            {this.state.suggestedSkills &&
                            this.state.suggestedSkills.length > 0
                                ? this._orderSuggestedSkills().map(
                                      (skill, index) =>
                                          this._renderSuggestedSkillItem(
                                              skill,
                                              index
                                          )
                                  )
                                : this.props.defaultSuggestedSkills.map(
                                      (skill, index) =>
                                          this._renderSuggestedSkillItem(
                                              skill,
                                              index
                                          )
                                  )}
                        </List>
                    </div>
                ) : null}
                {this.state.selectedSkills.length > 0 ? (
                    <div style={{ maxWidth: 590 }}>
                        <Typography variant="body2">
                            Selected Skills:
                        </Typography>
                        <List
                            style={{
                                height: 50,
                                overflowX: 'scroll',
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {this.state.selectedSkills &&
                            this.state.selectedSkills.length > 0
                                ? this.state.selectedSkills.map(
                                      (skill, index) =>
                                          this._renderSelectedSkillItem(
                                              skill,
                                              index
                                          )
                                  )
                                : null}
                        </List>
                    </div>
                ) : null}
                {this.state.proposedSkills.length > 0 ? (
                    <div style={{ maxWidth: 590 }}>
                        <Typography variant="body2">
                            Proposed Skills:
                        </Typography>
                        <List
                            style={{
                                height: 50,
                                overflowX: 'scroll',
                                padding: 0,
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {this.state.proposedSkills &&
                            this.state.proposedSkills.length > 0
                                ? this.state.proposedSkills.map(
                                      (skill, index) =>
                                          this._renderProposedSkillItem(
                                              skill,
                                              index
                                          )
                                  )
                                : null}
                        </List>
                    </div>
                ) : null}
            </div>
        );
    }

    // put any skills that appear in default skills and suggested skills at the front of the list
    _orderSuggestedSkills = () => {
        const { suggestedSkills, selectedSkills } = this.state;
        const { defaultSuggestedSkills } = this.props;

        if (defaultSuggestedSkills && defaultSuggestedSkills.length > 0) {
            let defaultMinusSelected = defaultSuggestedSkills.filter(
                value => !this.contains(selectedSkills, value)
            );
            let suggestedMinusDefaultMinusSelected = suggestedSkills
                .filter(value => !this.contains(defaultSuggestedSkills, value))
                .filter(value => !this.contains(selectedSkills, value));

            let prioritySkills = defaultMinusSelected.concat(
                suggestedMinusDefaultMinusSelected
            );

            return prioritySkills;
        } else {
            return suggestedSkills;
        }
    };

    contains = (arr, val) => {
        for (let a of arr) {
            if (a.name === val.name) {
                return true;
            }
        }
        return false;
    };

    _renderSkillAutocomplete(newArr, classes) {
        return (
            <Downshift
                onSelect={(selectedItem, stateAndHelpers) => {
                    if (selectedItem && selectedItem.name === 'suggest-skill') {
                        let proposedSkillsArray = [
                            ...this.state.proposedSkills,
                        ];
                        proposedSkillsArray.unshift({
                            name: this.state.typedSkill,
                        });
                        this.setState({ typedSkill: '' });
                        this.props.updateProposedSkills(proposedSkillsArray);
                        this.props.resetTypeaheadSkills();
                    } else if (selectedItem && selectedItem.name) {
                        let selectedSkillsArray = this.state.selectedSkills
                            ? Array.from(this.state.selectedSkills)
                            : [];

                        if (
                            !this._isDuplicateSkill(
                                selectedSkillsArray,
                                selectedItem
                            )
                        ) {
                            // add just the skill's id and name
                            selectedSkillsArray.unshift({
                                id: selectedItem.id,
                                name: selectedItem.name,
                            });
                            this.setState({ typedSkill: '' });
                            this.props.updateSelectedSkills(
                                selectedSkillsArray
                            );
                            this.props.skillsComputeSuggestedSkills(
                                selectedSkillsArray
                            );

                            this.props.resetTypeaheadSkills();
                        }
                    }
                }}
                id="downshift-options"
                inputValue={this.state.typedSkill}
                itemToString={item => (item ? item.name : '')}
            >
                {({
                    clearSelection,
                    getInputProps,
                    getItemProps,
                    getLabelProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    openMenu,
                    selectedItem,
                }) => {
                    const {
                        onBlur,
                        onChange,
                        onFocus,
                        ...inputProps
                    } = getInputProps({
                        onChange: event => {
                            this.setState({ typedSkill: event.target.value });
                            this.props.skillsTypeahead({
                                text: event.target.value,
                                size: 8,
                            });

                            if (event.target.value === '') {
                                clearSelection();
                            }
                            if (this.props.fieldInput) {
                                this.props.fieldInput.onChange(
                                    event.target.value
                                );
                            }
                        },
                        onFocus: () => {
                            openMenu();
                            if (this.props.fieldInput) {
                                this.props.fieldInput.onFocus();
                            }
                        },
                        onBlur: () => {
                            if (this.props.fieldInput) {
                                this.props.fieldInput.onBlur();
                            }
                        },
                        placeholder:
                            'Web Development, Marketing, Graphic Design',
                    });

                    return (
                        <div className={classes.container}>
                            {this._renderInput({
                                fullWidth: true,
                                classes,
                                label: 'Skills',
                                InputLabelProps: getLabelProps({
                                    shrink: true,
                                    style: {
                                        fontSize: 21,
                                        fontFamily: 'Inter',
                                    },
                                }),
                                InputProps: { onBlur, onChange, onFocus },
                                inputProps,
                                error: this.props.error
                                    ? this.props.error
                                    : false,
                                helperText: this.props.helperText
                                    ? this.props.helperText
                                    : '',
                            })}

                            <div {...getMenuProps()}>
                                {isOpen ? (
                                    <Paper
                                        className={
                                            newArr && newArr.length > 0
                                                ? classes.paperWithBorder
                                                : classes.paper
                                        }
                                        square
                                    >
                                        {newArr.map((skill, index) =>
                                            this._renderTypeaheadSkills({
                                                skill,
                                                index,
                                                itemProps: getItemProps({
                                                    item: {
                                                        id: skill.id,
                                                        name: skill.name,
                                                    },
                                                }),
                                                highlightedIndex,
                                                selectedItem,
                                                disableProposeSkill: this.props
                                                    .disableProposeSkill,
                                                typedSkill: this.state
                                                    .typedSkill,
                                            })
                                        )}
                                    </Paper>
                                ) : null}
                            </div>
                        </div>
                    );
                }}
            </Downshift>
        );
    }
}

const mapStateToProps = ({ skills }) => {
    const { typeaheadSkills, suggestedSkills } = skills;

    return {
        typeaheadSkills: typeaheadSkills ? typeaheadSkills : [],
        suggestedSkills,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        skillsTypeahead,
        skillsComputeSuggestedSkills,
        resetTypeaheadSkills,
    })(SkillAutocompleteView)
);

// const styles = {
//     autocompleteContainer: {
//         borderWidth: 0,
//     },
//     autocompleteInputContainer: {
//         borderWidth: 0,
//         borderBottomWidth: 1,
//     },
//     autocompleteListContainer: {},
//     autocompleteList: {
//         position: 'relative',
//         borderWidth: 1,
//     },
//     autocompleteTextInput: {
//         fontSize: 13,
//         fontFamily: 'InterRegular',
//         alignSelf: 'stretch',
//     },
//     flatListContainer: {
//         paddingVertical: 8,
//         paddingHorizontal: 8,
//     },
//     suggestedSkillsContainer: {
//         marginVertical: 0,
//         marginTop: 5,
//     },
//     selectedSkillsContainer: {
//         marginVertical: 0,
//     },
//     proposedSkillsContainer: {
//         marginVertical: 0,
//     },
//     skillHeaders: {
//         fontFamily: 'InterSemiBold',
//         fontSize: 16,
//         color: Colors.fontGray,
//     },
//     addASkill: {
//         fontSize: 14,
//         fontFamily: 'InterRegular',
//         alignSelf: 'stretch',
//         color: Colors.fontGray,
//         marginTop: 13,
//     },
//     suggestText: {
//         height: 17,
//         fontSize: 14,
//         fontFamily: 'InterSemiBold',
//         marginVertical: 7.5,
//         marginLeft: 5,
//     },
//     itemText: {
//         height: 17,
//         fontSize: 14,
//         fontFamily: 'InterMedium',
//         color: Colors.fontGray,
//         marginVertical: 7.5,
//         marginLeft: 5,
//     },
// };
