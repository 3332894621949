import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Footer } from '../common';

import { Grid } from '@material-ui/core';

import {
    ProfileMainCard,
    ProfileSkillsAndRatingsCard,
    ProfileExperiencesCard,
    ProfileReferralsCard,
    ProfileAboutCard,
    ProfileAdCard,
    ProfileSuggestionsCard,
    CustomContainer,
} from '../common';

import {
    getMessengerScreenData,
    initializeRoom,
    clearClickedRoom,
} from '../../ducks/TransactionMessengerDuck';
import { roomUpdate } from '../../ducks/MyRoomsDuck';
import { saveClickedPost, clearClickedPangean } from '../../ducks/MainFeedDuck';

class DetailedProfileScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {}

    render() {
        if (this.props.loading) {
            return <div />;
        } else {
            return (
                <div style={{ backgroundColor: '#f2f2f7' }}>
                    <Header hasSearch user={this.props.user} />
                    <CustomContainer>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <ProfileMainCard
                                    type={'detailed_profile'}
                                    user={this.props.clickedPangean}
                                />
                                {this.props.clickedPangean &&
                                this.props.clickedPangean.user_type ===
                                    'student' ? (
                                    <div>
                                        <ProfileSkillsAndRatingsCard
                                            type="student"
                                            user={this.props.clickedPangean}
                                        />

                                        {/* <ProfileExperiencesCard
                                            user={this.props.clickedPangean}
                                        /> */}

                                        {/* <ProfileReferralsCard
                                            user={this.props.clickedPangean}
                                        /> */}
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </Grid>

                            <Grid item md={4}>
                                {this.props.clickedPangean.user_type ===
                                'business' ? (
                                    <div>
                                        <ProfileAboutCard
                                            type="company"
                                            company={
                                                this.props.clickedPangean
                                                    ? this.props.clickedPangean
                                                          .company
                                                    : null
                                            }
                                            user={this.props.clickedPangean}
                                        />
                                        <ProfileAdCard />
                                    </div>
                                ) : this.props.clickedPangean.user_type ===
                                  'student' ? (
                                    <div>
                                        <ProfileAboutCard
                                            type="school"
                                            school={
                                                this.props.clickedPangean.school
                                            }
                                            user={this.props.clickedPangean}
                                        />

                                        <ProfileAdCard />

                                        {/* <ProfileSuggestionsCard /> */}
                                    </div>
                                ) : null}
                            </Grid>
                        </Grid>
                    </CustomContainer>
                    <Footer light />
                </div>
            );
        }
    }
}

const mapStateToProps = ({
    userSession,
    mainFeed,
    transactionMessenger,
    skills,
}) => {
    const { user } = userSession;
    const { loading, clickedPangean } = mainFeed;
    const { clickedRoom } = transactionMessenger;
    const { pangeanSoftSkills } = skills;

    return {
        user,
        loading,
        clickedPangean,
        clickedRoom,
        pangeanSoftSkills,
    };
};

export default connect(mapStateToProps, {
    getMessengerScreenData,
    initializeRoom,
    roomUpdate,
    saveClickedPost,
    clearClickedPangean,
    clearClickedRoom,
})(DetailedProfileScreen);
