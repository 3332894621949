import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, Footer } from '../common';

import { Grid } from '@material-ui/core';

import {
    ProfileMainCard,
    ProfileSkillsAndRatingsCard,
    ProfileReviewsCard,
    ProfileExperiencesCard,
    ProfileReferralsCard,
    ProfileAboutCard,
    ProfileAdCard,
    ProfileSuggestionsCard,
    CustomContainer,
} from '../common';

const styles = {
    rootContainer: {
        // paddingLeft: 42,
        // paddingRight: 42,
        paddingBottom: 40,
        backgroundColor: '#f2f2f7',
    },
};

class MyCompanyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    async componentDidMount() {}
    // padding 100: 1080
    // padding 150 : 980
    render() {
        return (
            <div style={{ backgroundColor: '#f2f2f7' }}>
                <Header hasSearch user={this.props.user} />
                <CustomContainer>
                    <div style={styles.rootContainer}>
                        <Grid container spacing={2}>
                            <Grid item md={8}>
                                <ProfileMainCard
                                    type={'my_company'}
                                    user={this.props.user}
                                    company={this.props.user.company}
                                />
                                <ProfileSkillsAndRatingsCard
                                    type="my_company"
                                    company={this.props.user.company}
                                />
                                <ProfileReviewsCard
                                    reviews={this.props.user.company.reviews}
                                />
                            </Grid>

                            <Grid item md={4}>
                                <div style={{height: 20}}/>
                                <ProfileAdCard />
                            </Grid>
                        </Grid>
                    </div>
                </CustomContainer>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(MyCompanyScreen);
