import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/app/App';
import configureStore, { history } from './configureStore';
import { PersistGate } from 'redux-persist/integration/react';

export const { store, persistor } = configureStore();

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App history={history} />
                </PersistGate>
            </Provider>
        </AppContainer>,
        document.getElementById('root')
    );
};

render();

// Hot reloading
if (module.hot) {
    // Reload components
    module.hot.accept('./components/app/App', () => {
        render();
    });
}

// import { render } from 'react-dom';
// import { Provider } from 'react-redux';
// import { browserHistory } from 'react-router';
// import { applyMiddleware, createStore } from 'redux';
// import { routerMiddleware, push } from 'react-router-redux';

// import thunk from 'redux-thunk';
// import rootReducer from './rootReducer';
// import Environment from './constants/Environment';

// // Logger with default options
// import logger from 'redux-logger';

// const routerReduxMiddleware = routerMiddleware(browserHistory);

// let applyMiddlewareObj;

// if (Environment.LOGGER) {
//     applyMiddlewareObj = applyMiddleware(thunk, routerReduxMiddleware, logger);
// } else {
//     applyMiddlewareObj = applyMiddleware(thunk, routerReduxMiddleware);
// }

// const store = createStore(rootReducer, applyMiddlewareObj);

// render(
//     <Provider store={store}>
//         <App />
//     </Provider>,
//     document.getElementById('root')
// );

// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import * as serviceWorker from './serviceWorker';

// ReactDOM.render(<App />, document.getElementById('root'));

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
