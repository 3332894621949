import React, { Component } from 'react';
import Compose from './Compose';
import Message from './Message';
import moment from 'moment';

import {
    messageCreate,
    messageReceive,
    addMessengerScreenData,
    changeMessageToReceived,
    clearClickedRoom,
} from '../../../ducks/TransactionMessengerDuck';

import { connect } from 'react-redux';

const styles = {
    messageListContainer: {
        padding: 10,
        paddingBottom: 70,
    },
};

class MessageRoom extends Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        let clickedRoom = nextProps.clickedRoom;
        if (
            nextProps.clickedRoom !== null &&
            nextProps.clickedRoom !== undefined
        ) {
            let isOwner = clickedRoom.owner_id === nextProps.user.id;
            let isBuyer = clickedRoom.buyer_id === nextProps.user.id;

            let buyerId = clickedRoom.buyer_id;
            let sellerId = clickedRoom.seller_id;

            let otherUser = null;
            let avatarPic = null;

            if (isOwner) {
                otherUser = clickedRoom.responder;
                avatarPic = clickedRoom.responder_profile_pic;
            } else {
                otherUser = clickedRoom.owner;
                avatarPic = clickedRoom.owner_profile_pic;
            }

            return {
                clickedRoom,
                isOwner,
                isBuyer,
                buyerId,
                sellerId,
                otherUser,
                avatarPic,
                messagesArray: clickedRoom.messages,
                user: nextProps.user,
            };
        } else {
            return {};
        }
    }

    _renderMessages = () => {
        let i = 0;
        let messageCount = this.props.messages.length;
        let tempMessages = [];

        while (i < messageCount) {
            let previous = this.props.messages[i - 1];
            let current = this.props.messages[i];
            let next = this.props.messages[i + 1];
            let isMine = current.user._id === this.props.user.id;
            let currentMoment = moment(current.timestamp);
            let prevBySameAuthor = false;
            let nextBySameAuthor = false;
            let startsSequence = true;
            let endsSequence = true;
            let showTimestamp = true;

            if (previous) {
                let previousMoment = moment(previous.timestamp);
                let previousDuration = moment.duration(
                    currentMoment.diff(previousMoment)
                );
                prevBySameAuthor = previous.author === current.author;

                if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                    startsSequence = false;
                }

                if (previousDuration.as('hours') < 1) {
                    showTimestamp = false;
                }
            }

            if (next) {
                let nextMoment = moment(next.timestamp);
                let nextDuration = moment.duration(
                    nextMoment.diff(currentMoment)
                );
                nextBySameAuthor = next.author === current.author;

                if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                    endsSequence = false;
                }
            }

            tempMessages.push(
                <Message
                    key={i}
                    isMine={isMine}
                    startsSequence={startsSequence}
                    endsSequence={endsSequence}
                    showTimestamp={showTimestamp}
                    data={current}
                />
            );

            // Proceed to the next message.
            i += 1;
        }

        return tempMessages.reverse();
    };

    _onSend(message) {
        let msg = {
            _id: null,
            createdAt: Date.now(),
            from: this.props.user.id,
            to:
                this.state.clickedRoom.owner_id === this.props.user.id
                    ? this.state.clickedRoom.responder_id
                    : this.state.clickedRoom.owner_id,
            user: {
                _id: this.props.user.id,
                name: this.props.user.full_name,
            },
            room_id: this.state.clickedRoom.id,
            text: message,
            sent: false,
            received: false,
        };

        let transaction = {
            seller_id: this.state.sellerId,
            buyer_id: this.state.buyerId,
            owner: this.state.isBuyer ? 'buyer' : 'seller',
            room_id: this.state.clickedRoom.id,
        };

        let user = { id: this.props.user.id };

        if (this.state.clickedRoom.messages.length === 0) {
            this.props.addMessengerScreenData({
                room: this.state.clickedRoom,
                firstMessage: msg,
                transaction,
                user,
            });
        } else {
            this.props.messageCreate(msg, user);
        }
    }

    render() {
        return (
            <div
                style={{
                    display: 'flex',
                    // flex: 1,
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    // padding: 10,
                    // paddingBottom: 70,
                    height: '100vh',
                }}
            >
                <div style={styles.messageListContainer}>
                    {this._renderMessages()}
                </div>
                {this.props.clickedRoom ? (
                    <Compose onSend={message => this._onSend(message)} />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = ({ userSession, transactionMessenger, mainFeed }) => {
    const { user } = userSession;

    const {
        clickedRoom,
        loading,
        requestPaymentLoading,
    } = transactionMessenger;
    const { filter } = mainFeed;

    return {
        user,
        clickedRoom,
        loading,
        requestPaymentLoading,
        filter,
    };
};

export default connect(mapStateToProps, {
    messageCreate,
    messageReceive,
    changeMessageToReceived,
    addMessengerScreenData,
    clearClickedRoom,
})(MessageRoom);
