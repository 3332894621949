import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-credit-cards/es/styles-compiled.css';
import { Dialog, TextField, Button, Typography } from '@material-ui/core';
import { Form, Field } from 'react-final-form';

// props:
/*
close
isVisible
onSubmit
*/

class EditBusinessProfileForm extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        const required = value => (value ? undefined : 'Required');

        return (
            <Dialog
                onClose={() => {
                    console.log('onClose');
                    this.props.close();
                }}
                open={this.props.isVisible}
            >
                <Form onSubmit={this.props.onSubmit}>
                    {({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'stretch',
                                    flex: 1,
                                    padding: 20,
                                    minWidth: '33%',
                                }}
                            >
                                <Typography variant="h4" gutterBottom>
                                    Edit Business Profile
                                </Typography>
                                <div style={{ display: 'flex' }}>
                                    <Field name="firstName" validate={required}>
                                        {({ input, meta }) => (
                                            <TextField
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                variant="outlined"
                                                label="First Name"
                                                fullWidth
                                                style={{
                                                    marginBottom: 20,
                                                    marginRight: 10,
                                                }}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                    <Field name="lastName" validate={required}>
                                        {({ input, meta }) => (
                                            <TextField
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                variant="outlined"
                                                label="Last Name"
                                                fullWidth
                                                style={{
                                                    marginBottom: 20,
                                                    marginLeft: 10,
                                                }}
                                                {...input}
                                            />
                                        )}
                                    </Field>
                                </div>
                                <Field name="bio" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            placeholder={
                                                'For example “I am a passionate entrepreneur looking to bring value to the world specifically in the medical field.”'
                                            }
                                            variant="outlined"
                                            label="Bio"
                                            fullWidth
                                            maxLength={5000}
                                            rows="6"
                                            multiline
                                            error={meta.error && meta.touched}
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Field name="yourTitle" validate={required}>
                                    {({ input, meta }) => (
                                        <TextField
                                            error={meta.error && meta.touched}
                                            variant="outlined"
                                            label="Your Title"
                                            fullWidth
                                            style={{
                                                marginBottom: 20,
                                            }}
                                            {...input}
                                        />
                                    )}
                                </Field>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting}
                                    style={{ marginBottom: 20 }}
                                >
                                    Save
                                </Button>
                            </div>
                        </form>
                    )}
                </Form>
            </Dialog>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;
    return {
        user,
    };
};

export default connect(mapStateToProps, {})(EditBusinessProfileForm);
