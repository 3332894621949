// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

import { simpleDispatch } from './common/duckHelper';

import moment from 'moment';

const distanceRange = [5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 40, 50, 'no limit'];

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SAVE_CLICKED_POST: 'save_clicked_post',
    SAVE_CLICKED_POST_SUCCESS: 'save_clicked_post_success',
    SAVE_CLICKED_POST_FAIL: 'save_clicked_post_fail',

    SAVE_CLICKED_PANGEAN: 'save_clicked_pangean',
    SAVE_CLICKED_PANGEAN_SUCCESS: 'save_clicked_pangean_success',
    SAVE_CLICKED_PANGEAN_FAIL: 'save_clicked_pangean_fail',

    CLEAR_CLICKED_PANGEAN: 'clear_clicked_pangean',
    CLEAR_CLICKED_POST: 'CLEAR_CLICKED_POST',

    PANGEANS_FEED_FETCH: 'pangeans_feed_fetch',
    PANGEANS_FEED_RELOAD: 'pangeans_feed_reload',
    PANGEANS_FEED_FETCH_SUCCESS: 'pangeans_feed_fetch_success',
    PANGEANS_FEED_FETCH_FAIL: 'pangeans_feed_fetch_fail',

    PROJECTS_FEED_FETCH: 'projects_feed_fetch',
    PROJECTS_FEED_RELOAD: 'projects_feed_reload',
    PROJECTS_FEED_FETCH_SUCCESS: 'projects_feed_fetch_success',
    PROJECTS_FEED_FETCH_FAIL: 'projects_feed_fetch_fail',

    SERVICES_FEED_FETCH: 'services_feed_fetch',
    SERVICES_FEED_RELOAD: 'services_feed_reload',
    SERVICES_FEED_FETCH_SUCCESS: 'services_feed_fetch_success',
    SERVICES_FEED_FETCH_FAIL: 'services_feed_fetch_fail',

    SAVE_RELATED_ROOM: 'save_related_room',
    SAVE_RELATED_ROOM_SUCCESS: 'save_related_room_success',
    SAVE_RELATED_ROOM_FAIL: 'save_related_room_success_fail',

    UPDATE_PANGEAN_FILTER: 'update_pangean_filter',
    UPDATE_PROJECT_FILTER: 'update_project_filter',
    UPDATE_SERVICE_FILTER: 'update_service_filter',

    UPDATE_PANGEAN_QUERY: 'update_pangean_query',
    UPDATE_PROJECT_QUERY: 'update_project_query',
    UPDATE_SERVICE_QUERY: 'update_service_query',

    UPDATE_PANGEAN_SORT_SETTING: 'update_pangean_sort_setting',
    UPDATE_PROJECT_SORT_SETTING: 'update_project_sort_setting',
    UPDATE_SERVICE_SORT_SETTING: 'update_service_sort_setting',

    UPDATE_PANGEAN_SORT: 'update_pangean_sort',
    UPDATE_PROJECT_SORT: 'update_project_sort',
    UPDATE_SERVICE_SORT: 'update_service_sort',

    UPDATE_PROJECT_DISTANCE_FROM_FIELD: 'update_project_distance_from_field',
    UPDATE_PROJECT_DISTANCE_FROM_ADDRESS:
        'update_project_distance_from_address',
    UPDATE_PANGEAN_DISTANCE_FROM_FIELD: 'update_pangean_distance_from_field',
    UPDATE_PANGEAN_DISTANCE_FROM_ADDRESS:
        'update_pangean_distance_from_address',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: true,

    clickedPost: null,
    clickedPostOwner: null,
    clickedPangean: null,

    pangeansFeed: [],
    projectsFeed: [],
    servicesFeed: [],

    isEndReachedPangeanFeed: null,
    isEndReachedProjectFeed: null,
    isEndReachedServiceFeed: null,

    selectedSearchTags: [],

    pangeanQuery: {
        constant_score: {
            filter: {
                bool: {
                    must: [
                        // {"geo_distance": {
                        //     "distance": "50km", "location": [this.state.user.loc.location.coordinates[0], this.state.user.loc.location.coordinates[1]]
                        // }},
                        // {"match": {"user_type": "student"}},
                    ],
                },
            },
        },
        // "bool": {
        //     "multi-match": {
        //         "query": "kristen mashikian",
        //         "fields": ["full_name^2", "school", "skills^2"]
        //     }
        // }
    },
    projectQuery: {
        constant_score: {
            filter: {
                bool: {
                    must: [
                        { match: { status: 'active' } },
                        // {"match": {"post_type": "project"}}
                    ],
                },
            },
        },
    },
    serviceQuery: {
        constant_score: {
            filter: {
                bool: {
                    must: [
                        { match: { status: 'active' } },
                        { match: { post_type: 'service' } },
                    ],
                },
            },
        },
    },

    // FILTER SETTINGS
    // PANGEAN
    pangeanDistanceSliderValue: [12],
    pangeanDistanceFromField: null,
    pangeanDistanceFromAddress: null,
    yog2019Selected: true,
    yog2020Selected: true,
    yog2021Selected: true,
    yog2022Selected: true,
    yog2023Selected: true,
    recentlyActiveSelected: false,
    // PROJECT
    projectDistanceSliderValue: [12],
    projectDistanceFromField: null,
    projectDistanceFromAddress: null,
    projectOneTimeProjectSelected: true,
    projectInternshipSelected: true,
    projectFullTimeSelected: true,
    projectPaidSelected: true,
    projectUnpaidSelected: true,
    // SERVICE
    serviceDistanceSliderValue: [12],
    serviceOccurenceRecurringSelected: true,
    serviceOccurenceOneTimeSelected: true,
    servicePaymentTypeHourlySelected: true,
    servicePaymentTypeFixedSelected: true,
    servicePaymentTypeUnpaidSeleceted: true,
    servicePreferLocalSelected: false,

    // SORT SETTING
    pangeanSortSetting: 'recommended',
    projectSortSetting: 'recommended',
    serviceSortSetting: 'recommended',

    pangeanSort: [{ last_active: { order: 'desc' } }],
    projectSort: [{ created_at: { order: 'desc' } }],
    serviceSort: [{ created_at: { order: 'desc' } }],

    // offeringsArray: [],
    // requestsArray: [],
    // isEndReachedRequest: null,
    // isEndReachedOffering: null,
    // filter: {
    //     post_type: 'offering'
    // },
    // filterState: {
    //     postBorderColor: null,
    //     categoryDictionary: {"all_service": true, "no_service": false,
    //     "cuisine": false, "delivery": false, "design": false, "events": false,
    //     "instruction": false, "moving": false, "photography": false, "other_service": false,
    //     "all_good": true, "no_good": false, "apparel": false,
    //     "arts": false, "books": false, "electronics": false,
    //     "furniture": false, "other_good": false},
    //     scrollEnabled: true,
    //     priceMultiSliderValue: [0, 11],
    //     distanceSliderValue: [10],
    //     categories: [],
    //     includeFollowingSwitchValue: false,
    //     rating_min: 1,
    //     price_min: null,
    //     price_max: null,
    //     distance_max: null,
    //     error: '',
    //     isServiceClicked: true,
    //     isGoodClicked: true,
    // },
};

export default (state = INITIAL_STATE, action) => {
    // let newFilter;
    switch (action.type) {
        case types.SAVE_CLICKED_POST:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_POST_SUCCESS:
            return { ...state, loading: false, clickedPost: action.payload };
        case types.SAVE_CLICKED_POST_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.SAVE_CLICKED_PANGEAN:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_PANGEAN_SUCCESS:
            return { ...state, loading: false, clickedPangean: action.payload };
        case types.CLEAR_CLICKED_PANGEAN:
            return { ...state, clickedPangean: null };
        case types.CLEAR_CLICKED_POST:
            return { ...state, clickedPost: null };

        case types.PANGEANS_FEED_FETCH:
            return { ...state, loading: action.payload };
        case types.PANGEANS_FEED_RELOAD:
            return { ...state, loading: action.payload, pangeansFeed: [] };
        case types.PANGEANS_FEED_FETCH_SUCCESS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    loading: false,
                    isEndReachedPangeanFeed: true,
                };
            } else {
                if (containsSame(state.pangeansFeed, action.payload)) {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedPangeanFeed: false,
                    };
                } else {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedPangeanFeed: false,
                        pangeansFeed: [
                            ...state.pangeansFeed,
                            ...action.payload,
                        ],
                    };
                }
            }
        case types.PANGEANS_FEED_FETCH_FAIL:
            return { ...state, loading: false };

        case types.PROJECTS_FEED_FETCH:
            return { ...state, loading: action.payload };
        case types.PROJECTS_FEED_RELOAD:
            return { ...state, loading: action.payload, projectsFeed: [] };
        case types.PROJECTS_FEED_FETCH_SUCCESS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    loading: false,
                    isEndReachedProjectFeed: true,
                };
            } else {
                if (containsSame(state.projectsFeed, action.payload)) {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedProjectFeed: false,
                    };
                } else {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedProjectFeed: false,
                        projectsFeed: [
                            ...state.projectsFeed,
                            ...action.payload,
                        ],
                    };
                }
            }
        case types.PROJECTS_FEED_FETCH_FAIL:
            return { ...state, loading: false };

        case types.SERVICES_FEED_FETCH:
            return { ...state, loading: action.payload };
        case types.SERVICES_FEED_RELOAD:
            return { ...state, loading: action.payload, servicesFeed: [] };
        case types.SERVICES_FEED_FETCH_SUCCESS:
            if (action.payload.length === 0) {
                return {
                    ...state,
                    loading: false,
                    isEndReachedServiceFeed: true,
                };
            } else {
                if (containsSame(state.projectsFeed, action.payload)) {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedServiceFeed: false,
                    };
                } else {
                    return {
                        ...state,
                        loading: false,
                        isEndReachedServiceFeed: false,
                        servicesFeed: [
                            ...state.servicesFeed,
                            ...action.payload,
                        ],
                    };
                }
            }
        case types.SERVICES_FEED_FETCH_FAIL:
            return { ...state, loading: false };

        case types.UPDATE_PANGEAN_SORT:
            return { ...state, pangeanSort: action.payload };
        case types.UPDATE_PROJECT_SORT:
            return { ...state, projectSort: action.payload };
        case types.UPDATE_SERVICE_SORT:
            return { ...state, serviceSort: action.payload };

        case types.UPDATE_PANGEAN_SORT_SETTING:
            return { ...state, pangeanSortSetting: action.payload };
        case types.UPDATE_PROJECT_SORT_SETTING:
            return { ...state, projectSortSetting: action.payload };
        case types.UPDATE_SERVICE_SORT_SETTING:
            return { ...state, serviceSortSetting: action.payload };

        case types.UPDATE_PANGEAN_FILTER:
            return {
                ...state,
                pangeanDistanceSliderValue:
                    action.payload.pangeanDistanceSliderValue,
                yog2019Selected: action.payload.yog2019Selected,
                yog2020Selected: action.payload.yog2020Selected,
                yog2021Selected: action.payload.yog2021Selected,
                yog2022Selected: action.payload.yog2022Selected,
                yog2023Selected: action.payload.yog2023Selected,
                recentlyActiveSelected: action.payload.recentlyActiveSelected,
                selectedSearchTags: action.payload.selectedSearchTags,
            };
        case types.UPDATE_PROJECT_FILTER:
            return {
                ...state,
                projectDistanceSliderValue:
                    action.payload.projectDistanceSliderValue,
                projectOneTimeProjectSelected:
                    action.payload.projectOneTimeProjectSelected,
                projectInternshipSelected:
                    action.payload.projectInternshipSelected,
                projectFullTimeSelected: action.payload.projectFullTimeSelected,
                projectPaidSelected: action.payload.projectPaidSelected,
                projectUnpaidSelected: action.payload.projectUnpaidSelected,
                selectedSearchTags: action.payload.selectedSearchTags,
            };
        case types.UPDATE_SERVICE_FILTER:
            return {
                ...state,
                serviceOccurenceRecurringSelected:
                    action.payload.serviceOccurenceRecurringSelected,
                serviceOccurenceOneTimeSelected:
                    action.payload.serviceOccurenceOneTimeSelected,
                servicePaymentTypeHourlySelected:
                    action.payload.servicePaymentTypeHourlySelected,
                servicePaymentTypeFixedSelected:
                    action.payload.servicePaymentTypeFixedSelected,
                servicePaymentTypeUnpaidSeleceted:
                    action.payload.servicePaymentTypeUnpaidSeleceted,
                servicePreferLocalSelected:
                    action.payload.servicePreferLocalSelected,
                serviceDistanceSliderValue:
                    action.payload.serviceDistanceSliderValue,
                selectedSearchTags: action.payload.selectedSearchTags,
            };

        case types.UPDATE_PANGEAN_QUERY:
            return { ...state, pangeanQuery: action.payload };
        case types.UPDATE_PROJECT_QUERY:
            return { ...state, projectQuery: action.payload };
        case types.UPDATE_SERVICE_QUERY:
            return { ...state, serviceQuery: action.payload };

        case types.UPDATE_PROJECT_DISTANCE_FROM_FIELD:
            return { ...state, projectDistanceFromField: action.payload };
        case types.UPDATE_PROJECT_DISTANCE_FROM_ADDRESS:
            return { ...state, projectDistanceFromAddress: action.payload };
        case types.UPDATE_PANGEAN_DISTANCE_FROM_FIELD:
            return { ...state, pangeanDistanceFromField: action.payload };
        case types.UPDATE_PANGEAN_DISTANCE_FROM_ADDRESS:
            return { ...state, pangeanDistanceFromAddress: action.payload };

        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const saveClickedPost = ({ post, owner_id, responder_id }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_POST });

        WS.socket.emit('post:embed', { post_id: post.id });
        WS.socket.once('post:embed', res => {
            if (res.success) {
                simpleDispatch(
                    dispatch,
                    types.SAVE_CLICKED_POST_SUCCESS,
                    res.data
                );
            } else {
                simpleDispatch(
                    dispatch,
                    types.SAVE_CLICKED_POST_FAIL,
                    res.message
                );
            }
        });

        dispatch({ type: types.SAVE_RELATED_ROOM });

        WS.socket.emit('room:findRoomFromPost', {
            owner_id,
            responder_id,
            post_id: post.id,
        });
        WS.socket.once('room:findRoomFromPost', res => {
            if (res.success) {
                saveRelatedRoomSuccess(dispatch, res.data);
            } else {
                saveRelatedRoomFail(dispatch, res.message);
            }
        });
    };
};
const saveRelatedRoomSuccess = (dispatch, res) => {
    let room = null;
    if (res && res.length > 0) {
        room = res[0];
    }

    dispatch({
        type: types.SAVE_RELATED_ROOM_SUCCESS,
        payload: room,
    });
};
const saveRelatedRoomFail = (dispatch, res) => {
    dispatch({
        type: types.SAVE_RELATED_ROOM_FAIL,
        payload: res,
    });
};

export const saveClickedPangean = ({ pangean, owner_id, responder_id }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_PANGEAN });

        WS.socket.emit('user:embedOtherUser', { user: { id: pangean.id } });
        WS.socket.once('user:embedOtherUser', res => {
            if (res.success) {
                // AmplitudeAnalytics.track('Save Clicked Pangean Success', res);
                dispatch({
                    type: types.SAVE_CLICKED_PANGEAN_SUCCESS,
                    payload: res.data,
                });

                dispatch({ type: types.SAVE_RELATED_ROOM });
                WS.socket.emit('room:findRoomFromProfile', {
                    owner_id,
                    responder_id,
                    profile_id: pangean.id,
                });
                WS.socket.once('room:findRoomFromProfile', res => {
                    if (res.success) {
                        saveRelatedRoomSuccess(dispatch, res.data);
                    } else {
                        saveRelatedRoomFail(dispatch, res.message);
                    }
                });
            } else {
                dispatch({
                    type: types.SAVE_CLICKED_PANGEAN_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const clearClickedPangean = () => {
    return dispatch => {
        dispatch({ type: types.CLEAR_CLICKED_PANGEAN });
    };
};

export const clearClickedPost = () => {
    return dispatch => {
        dispatch({ type: types.CLEAR_CLICKED_POST });
    };
};

const containsSame = (oldList, newList) => {
    for (let i = 0; i < oldList.length; i++) {
        for (let k = 0; k < newList.length; k++) {
            if (newList[k].id === oldList[i].id) {
                return true;
            }
        }
    }

    return false;
};

export const pangeansFeedFetch = ({
    elasticSearchQuery,
    sort,
    skipVal,
    isPullUpReload,
}) => {
    return dispatch => {
        if (isPullUpReload) {
            dispatch({ type: types.PANGEANS_FEED_RELOAD });
        } else {
            dispatch({ type: types.PANGEANS_FEED_FETCH, payload: false });
        }

        WS.socket.emit('feed:pangeansFetch', {
            query: elasticSearchQuery,
            sort,
            skip_val: skipVal,
        });
        WS.socket.once('feed:pangeansFetch', res => {
            if (res.success) {
                pangeansFeedFetchSuccess(dispatch, res.data);
            } else {
                pangeansFeedFetchFail(dispatch, res.message);
            }
        });
    };
};
const pangeansFeedFetchSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Pangeans Feed Fetch Success', {});

    dispatch({
        type: types.PANGEANS_FEED_FETCH_SUCCESS,
        payload: data.values,
    });
};
const pangeansFeedFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('feedFail', {
    //     level: 'warning',
    // });

    dispatch({
        type: types.PANGEANS_FEED_FETCH_FAIL,
        payload: msg,
    });
};

export const projectsFeedFetch = ({
    elasticSearchQuery,
    sort,
    skipVal,
    isPullUpReload,
}) => {
    return dispatch => {
        if (isPullUpReload) {
            dispatch({ type: types.PROJECTS_FEED_RELOAD });
        } else {
            dispatch({ type: types.PROJECTS_FEED_FETCH, payload: false });
        }

        WS.socket.emit('feed:projectsFetch', {
            query: elasticSearchQuery,
            sort: sort,
            skip_val: skipVal,
        });
        WS.socket.once('feed:projectsFetch', res => {
            if (res.success) {
                projectsFeedFetchSuccess(dispatch, res.data);
            } else {
                projectsFeedFetchFail(dispatch, res.message);
            }
        });
    };
};
const projectsFeedFetchSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Projects Feed Fetch Success', {});

    dispatch({
        type: types.PROJECTS_FEED_FETCH_SUCCESS,
        payload: data.values,
    });
};
const projectsFeedFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('feedFail', {
    //     level: 'warning',
    // });

    dispatch({
        type: types.PROJECTS_FEED_FETCH_FAIL,
        payload: msg,
    });
};

export const servicesFeedFetch = ({
    elasticSearchQuery,
    skipVal,
    isPullUpReload,
}) => {
    return dispatch => {
        if (isPullUpReload) {
            dispatch({ type: types.SERVICES_FEED_RELOAD });
        } else {
            dispatch({ type: types.SERVICES_FEED_FETCH, payload: false });
        }

        WS.socket.emit('feed:servicesFetch', {
            query: elasticSearchQuery,
            skip_val: skipVal,
        });
        WS.socket.once('feed:servicesFetch', res => {
            if (res.success) {
                servicesFeedFetchSuccess(dispatch, res.data);
            } else {
                servicesFeedFetchFail(dispatch, res.message);
            }
        });
    };
};
const servicesFeedFetchSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Services Feed Fetch Success', {});

    dispatch({
        type: types.SERVICES_FEED_FETCH_SUCCESS,
        payload: data.values,
    });
};
const servicesFeedFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('feedFail', {
    //     level: 'warning',
    // });

    dispatch({
        type: types.SERVICES_FEED_FETCH_FAIL,
        payload: msg,
    });
};

export const updatePangeanSort = ({ pangeanSortSetting, pangeanQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PANGEAN_SORT_SETTING,
            payload: pangeanSortSetting,
        });

        let newSort = [];

        if (pangeanSortSetting === 'recommended') {
            newSort = [{ last_active: { order: 'desc' } }];
        } else if (pangeanSortSetting === 'rating') {
            newSort = [{ overall_rating: { order: 'desc' } }];
        } else if (pangeanSortSetting === 'price_high') {
            newSort = [{ base_hourly_wage: { order: 'desc' } }];
        } else {
            // price_low
            newSort = [{ base_hourly_wage: { order: 'asc' } }];
        }

        dispatch({ type: types.UPDATE_PANGEAN_SORT, payload: newSort });

        dispatch(
            pangeansFeedFetch({
                elasticSearchQuery: pangeanQuery,
                sort: newSort,
                skip_val: 0,
                isPullUpReload: true,
            })
        );
    };
};

export const updateProjectSort = ({ projectSortSetting, projectQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_SORT_SETTING,
            payload: projectSortSetting,
        });

        let newSort = [];

        if (projectSortSetting === 'recommended') {
            newSort = [{ created_at: { order: 'desc' } }];
        } else if (projectSortSetting === 'starting_soonest') {
            newSort = [{ start_date: { order: 'desc' } }];
        } else if (projectSortSetting === 'compensation_high') {
            newSort = [{ compensation: { order: 'desc' } }];
        } else if (projectSortSetting === 'compensation_low') {
            newSort = [{ compensation: { order: 'asc' } }];
        } else {
            // posted_recently
            newSort = [{ created_at: { order: 'desc' } }];
        }

        dispatch({ type: types.UPDATE_PROJECT_SORT, payload: newSort });

        dispatch(
            projectsFeedFetch({
                elasticSearchQuery: projectQuery,
                sort: newSort,
                skip_val: 0,
                isPullUpReload: true,
            })
        );
    };
};

export const updateServiceSort = ({ serviceSortSetting, serviceQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SERVICE_SORT_SETTING,
            payload: serviceSortSetting,
        });

        // THIS IS NOT UP TO DATE - not sure of sort settings for services

        let newSort = [];

        if (serviceSortSetting === 'recommended') {
            newSort = [{ last_active: { order: 'desc' } }];
        } else if (serviceSortSetting === 'rating') {
            newSort = [{ overall_rating: { order: 'desc' } }];
        } else if (serviceSortSetting === 'price_high') {
            newSort = [{ cost: { order: 'desc' } }];
        } else if (serviceSortSetting === 'price_low') {
            newSort = [{ cost: { order: 'asc' } }];
        } else {
            // expiring soonest
            newSort = [{ expiration_date: { order: 'asc' } }];
        }

        dispatch({ type: types.UPDATE_SERVICE_SORT, payload: newSort });

        dispatch(
            servicesFeedFetch({
                elasticSearchQuery: serviceQuery,
                sort: newSort,
                skip_val: 0,
                isPullUpReload: true,
            })
        );
    };
};

export const updatePangeanFilter = ({
    pangeanDistanceSliderValue,
    yog2019Selected,
    yog2020Selected,
    yog2021Selected,
    yog2022Selected,
    yog2023Selected,
    recentlyActiveSelected,
    coordinates,
    selectedSearchTags,
    pangeanSort,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PANGEAN_FILTER,
            payload: {
                pangeanDistanceSliderValue,
                yog2019Selected,
                yog2020Selected,
                yog2021Selected,
                yog2022Selected,
                yog2023Selected,
                recentlyActiveSelected,
                selectedSearchTags,
            },
        });

        let mustArray = []; // [{"match": {"user_type": "student"}}, {"match": {"has_reonboarded": true}}];
        let shouldArray = [];

        let searchTagsString = '';

        if (selectedSearchTags) {
            selectedSearchTags.forEach(tag => {
                // if (tag.rethink_type === 'school_name') {
                //     mustArray.push({ term: { school_name: tag.tagName } });
                // } else {
                searchTagsString += tag.tagName;
                searchTagsString += ' ';
                // }
            });
            // what to do if someone enters more than one school name? should we allow this?
        }

        // Remove common words that will confuse search tags
        searchTagsString = searchTagsString.replace(
            /University|College|of|the|School/gi,
            ''
        );

        let elasticSearchQuery = {};

        if (typeof distanceRange[pangeanDistanceSliderValue[0]] === 'number') {
            mustArray.push({
                geo_distance: {
                    distance:
                        distanceRange[pangeanDistanceSliderValue[0]] + 'mi',
                    location: [coordinates[0], coordinates[1]],
                },
            });
        }

        if (yog2019Selected) {
            shouldArray.push({ match: { expected_yog: '2019' } });
        }

        if (yog2020Selected) {
            shouldArray.push({ match: { expected_yog: '2020' } });
        }

        if (yog2021Selected) {
            shouldArray.push({ match: { expected_yog: '2021' } });
        }

        if (yog2022Selected) {
            shouldArray.push({ match: { expected_yog: '2022' } });
        }

        if (yog2023Selected) {
            shouldArray.push({ match: { expected_yog: '2023' } });
        }

        if (selectedSearchTags && selectedSearchTags.length > 0) {
            mustArray.push({ match: { _all_search: searchTagsString } });
        }

        let recentlyActive = moment(new Date(Date.now()))
            .subtract(2, 'weeks')
            .format('YYYY-MM-DD');

        if (recentlyActiveSelected) {
            mustArray.push({ range: { last_active: { gte: recentlyActive } } });
        }

        elasticSearchQuery = {
            constant_score: {
                filter: {
                    bool: {
                        must: mustArray,
                        should: shouldArray,
                    },
                },
            },
        };

        dispatch(updatePangeanQuery({ pangeanQuery: elasticSearchQuery }));
        dispatch(
            pangeansFeedFetch({
                elasticSearchQuery,
                sort: pangeanSort,
                skip_val: 0,
                isPullUpReload: true,
            })
        );
    };
};

export const updateProjectFilter = ({
    projectDistanceSliderValue,
    projectOneTimeProjectSelected,
    projectInternshipSelected,
    projectFullTimeSelected,
    projectPaidSelected,
    projectUnpaidSelected,
    coordinates,
    selectedSearchTags,
    projectSort,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_FILTER,
            payload: {
                projectDistanceSliderValue,
                projectOneTimeProjectSelected,
                projectInternshipSelected,
                projectFullTimeSelected,
                projectPaidSelected,
                projectUnpaidSelected,
                selectedSearchTags,
            },
        });

        let searchTagsString = '';

        if (selectedSearchTags) {
            selectedSearchTags.forEach(tag => {
                searchTagsString += tag.tagName;
                searchTagsString += ' ';
            });

            // might need to take off trailing space
        }

        let elasticSearchQuery = {};

        let mustArray = [{ match: { status: 'active' } }];
        let shouldArray = [];

        if (typeof distanceRange[projectDistanceSliderValue[0]] === 'number') {
            mustArray.push({
                geo_distance: {
                    distance:
                        distanceRange[projectDistanceSliderValue[0]] + 'mi',
                    location: [coordinates[0], coordinates[1]],
                },
            });
        }

        if (projectOneTimeProjectSelected) {
            shouldArray.push({ match: { project_type: 'one_time' } });
        }

        if (projectInternshipSelected) {
            shouldArray.push({ match: { project_type: 'internship' } });
        }

        if (projectFullTimeSelected) {
            shouldArray.push({ match: { project_type: 'full_time' } });
        }

        if (projectPaidSelected) {
            if (!projectUnpaidSelected) {
                mustArray.push({ match: { is_paid: true } });
            }
        }

        if (projectUnpaidSelected) {
            if (!projectPaidSelected) {
                mustArray.push({ match: { is_paid: false } });
            }
        }

        if (selectedSearchTags && selectedSearchTags.length > 0) {
            mustArray.push({ match: { _all_search: searchTagsString } });
        }

        elasticSearchQuery = {
            constant_score: {
                filter: {
                    bool: {
                        must: mustArray,
                        should: shouldArray,
                    },
                },
            },
        };

        dispatch(updateProjectQuery({ projectQuery: elasticSearchQuery }));
        dispatch(
            projectsFeedFetch({
                elasticSearchQuery,
                sort: projectSort,
                skip_val: 0,
                isPullUpReload: true,
            })
        );
    };
};

export const updateServiceFilter = ({
    serviceOccurenceRecurringSelected,
    serviceOccurenceOneTimeSelected,
    servicePaymentTypeHourlySelected,
    servicePaymentTypeFixedSelected,
    servicePaymentTypeUnpaidSeleceted,
    servicePreferLocalSelected,
    serviceDistanceSliderValue,
    selectedSearchTags,
}) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SERVICE_FILTER,
            payload: {
                serviceOccurenceRecurringSelected,
                serviceOccurenceOneTimeSelected,
                servicePaymentTypeHourlySelected,
                servicePaymentTypeFixedSelected,
                servicePaymentTypeUnpaidSeleceted,
                servicePreferLocalSelected,
                serviceDistanceSliderValue,
                selectedSearchTags,
            },
        });
    };
};

export const updatePangeanQuery = ({ pangeanQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PANGEAN_QUERY,
            payload: pangeanQuery,
        });
    };
};

export const updateProjectQuery = ({ projectQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_QUERY,
            payload: projectQuery,
        });
    };
};

export const updateServiceQuery = ({ serviceQuery }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_SERVICE_QUERY,
            payload: serviceQuery,
        });
    };
};

export const updateProjectDistanceFromField = ({ distanceFromField }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_DISTANCE_FROM_FIELD,
            payload: distanceFromField,
        });
    };
};

export const updateProjectDistanceFromAddress = ({ address }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROJECT_DISTANCE_FROM_ADDRESS,
            payload: address,
        });
    };
};

export const updatePangeanDistanceFromField = ({ distanceFromField }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PANGEAN_DISTANCE_FROM_FIELD,
            payload: distanceFromField,
        });
    };
};

export const updatePangeanDistanceFromAddress = ({ address }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PANGEAN_DISTANCE_FROM_ADDRESS,
            payload: address,
        });
    };
};
