import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Waypoint } from 'react-waypoint';

import { Grid, Typography, List, ListItem } from '@material-ui/core';

import Colors from '../../constants/Colors';
import { push } from 'connected-react-router';
import { store } from '../..';

import {
    Header,
    ProjectItem,
    SearchBar,
    FooterMini,
    GoogleAd,
    CustomContainer,
} from '../common';

import {
    projectsFeedFetch,
    saveClickedPost,
    updateProjectQuery,
    updateProjectSort,
    updateProjectFilter,
} from '../../ducks/MainFeedDuck';

import { searchTagsTypeahead } from '../../ducks/SearchTagsDuck';

import '../common/Paginate.css';

const styles = {
    rootContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 40,
        // marginLeft: 208,
        // marginRight: 208,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff',
        paddingTop: 21,
        paddingLeft: 42,
        paddingRight: 38,
        paddingBottom: 16,
    },

    currentProjectsCardContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff',
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
    },

    projectCardContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 20,
        marginBottom: 20,
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        backgroundColor: '#ffffff',
    },

    headerContainer: {
        height: 280,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: "url('images/darkbg.jpg')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    },

    headerTitle: {
        marginBottom: 30,
        color: Colors.white,
    },

    searchContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '75%',
        marginBottom: 40,
    },

    textInput: {
        display: 'flex',
        borderRadius: 20,
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        border: 'solid 1px #8e8e93',
        flex: 1,
        fontSize: 16,
    },

    searchBtn: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        fontSize: 16,
        fontWeight: '600',
        paddingTop: 5,
        paddingBottom: 5,
        paddingLeft: 45,
        paddingRight: 45,
    },

    postProjectContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    postProjectText: {
        marginRight: 60,
        color: Colors.white,
    },

    postProjectButton: {
        borderRadius: 40,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 2px #ffffff',
        // backgroundColor: 'transparent',
        // textDecoration: 'none',
        paddingLeft: 40,
        paddingRight: 40,

        color: Colors.white,
    },

    sectionTitleContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        marginBottom: 15,
    },

    sectionTitle: {
        fontFamily: 'Inter',
        fontSize: 21,
        fontWeight: 'bold',
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        color: '#1d252d',
        padding: 0,
        margin: 0,
    },

    seeMoreLink: {
        color: '#1d252d',
    },

    gridContainer: {
        marginTop: 20,
        marginBottom: 30,
    },

    skillsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: 37,
    },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
        marginBottom: 10,
    },
};

class ProjectsMobileScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: '',
            searchAnchorElement: null,
        };

        this.setSearchAnchorElement = this.setSearchAnchorElement.bind(this);
        this.handleSearchClose = this.handleSearchClose.bind(this);
    }

    setSearchQuery(searchQuery) {
        this.setState({
            searchQuery,
        });
    }

    setSearchAnchorElement(event) {
        this.setState({
            searchAnchorElement: event.currentTarget,
        });
    }

    handleSearchClose() {
        this.setState({
            searchAnchorElement: null,
        });
    }

    componentDidMount() {
        this.props.projectsFeedFetch({
            elasticSearchQuery: this.props.projectQuery,
            sort: this.props.projectSort,
            skipVal: 0,
            isPullUpReload: false,
        });
    }

    _onPostItemPress = item => {
        this.props.saveClickedPost({
            post: item,
            owner_id: item.owner_id,
            responder_id: this.props.user.id,
        }); // doesn't matter who is owner and who is responder
        store.dispatch(push('/detailedPost'));
    };

    render() {
        return (
            <div style={{ backgroundColor: '#f2f2f7' }}>
                <Header
                    hasSearch={false}
                    activeLinkOption={'Pangeans'}
                    user={this.props.user}
                />
                <CustomContainer>
                    <Grid container spacing={2}>
                        <Grid
                            item
                            md={4}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'stretch',
                                position: 'fixed',
                            }}
                        >
                            <div style={{ height: 20 }} />
                            <Typography variant="h3" gutterBottom>
                                Projects
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                Discover amazing projects curated just for you.
                            </Typography>
                            <SearchBar
                                searchTagsTypeahead={
                                    this.props.searchTagsTypeahead
                                }
                                typeaheadSearchTags={
                                    this.props.typeaheadSearchTags
                                }
                            />
                            <GoogleAd />
                            <FooterMini light />
                        </Grid>
                        <Grid item md={4} style={{ display: 'flex' }} />
                        <Grid item md={1} style={{ display: 'flex' }}></Grid>
                        <Grid item md={6}>
                            <List style={styles.gridContainer}>
                                {this.props.projectsFeed.map(
                                    (project, index) => (
                                        <React.Fragment>
                                            <ListItem key={index}>
                                                <ProjectItem
                                                    project={project}
                                                    onPress={() =>
                                                        this._onPostItemPress(
                                                            project
                                                        )
                                                    }
                                                />
                                                {index ===
                                                    this.props.projectsFeed
                                                        .length -
                                                        2 && (
                                                    <Waypoint
                                                        onEnter={() => {
                                                            if (
                                                                !this.props
                                                                    .isEndReachedProjectFeed
                                                            ) {
                                                                this.props.projectsFeedFetch(
                                                                    {
                                                                        elasticSearchQuery: this
                                                                            .props
                                                                            .projectQuery,
                                                                        sort: this
                                                                            .props
                                                                            .projectSort,
                                                                        skipVal: this
                                                                            .props
                                                                            .projectsFeed
                                                                            .length,
                                                                        isPullUpReload: false,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </ListItem>
                                        </React.Fragment>
                                    )
                                )}
                            </List>
                        </Grid>
                        <Grid item md={1} style={{ display: 'flex' }}></Grid>
                    </Grid>
                </CustomContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ mainFeed, userSession, searchTags }) => {
    const {
        loading,
        projectsFeed,
        isEndReachedProjectFeed,
        projectSortSetting,
        selectedSearchTags,
        projectQuery,
        projectSort,
        // filter
        projectDistanceSliderValue,
        projectOccurenceRecurringSelected,
        projectOccurenceOneTimeSelected,
        projectPaymentTypeHourlySelected,
        projectPaymentTypeFixedSelected,
        projectPaymentTypeUnpaidSeleceted,
        projectPreferLocalSelected,
    } = mainFeed;
    const { user, wasReactivated } = userSession;

    return {
        loading,
        user,
        wasReactivated,

        projectsFeed,
        isEndReachedProjectFeed,
        selectedSearchTags,
        projectQuery,
        projectSort,
        projectSortSetting,

        // filter
        projectDistanceSliderValue,
        projectOccurenceRecurringSelected,
        projectOccurenceOneTimeSelected,
        projectPaymentTypeHourlySelected,
        projectPaymentTypeFixedSelected,
        projectPaymentTypeUnpaidSeleceted,
        projectPreferLocalSelected,

        // searchtag
        typeaheadSearchTags: searchTags.typeaheadSearchTags
            ? searchTags.typeaheadSearchTags
            : [],
    };
};

export default connect(mapStateToProps, {
    projectsFeedFetch,
    saveClickedPost,
    updateProjectQuery,
    updateProjectSort,
    updateProjectFilter,
    searchTagsTypeahead,
})(ProjectsMobileScreen);
