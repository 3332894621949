export const pangeanSoftSkillsArr = [
    {
        created_at: '2019-07-04T05:53:44.777Z',
        id: '876e2e81-b194-409c-8901-6253fe8f67c8',
        last_modified: '2019-07-04T05:53:44.777Z',
        name: 'Initiative',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.805Z',
        id: 'f052c914-fb06-43f1-a54b-acd9f8a0df3b',
        last_modified: '2019-07-04T05:53:44.805Z',
        name: 'Professionalism',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.761Z',
        id: 'c56c1c42-9267-4348-93ff-230bc226955f',
        last_modified: '2019-07-04T05:53:44.761Z',
        name: 'Reliability',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.798Z',
        id: '60c78041-bae9-40b2-ac21-e9c4500e093e',
        last_modified: '2019-07-04T05:53:44.798Z',
        name: 'Critical Thinking',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.784Z',
        id: 'bed7a04b-568b-424b-a9ac-ded46a1294a6',
        last_modified: '2019-07-04T05:53:44.784Z',
        name: 'Persuasion',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.626Z',
        id: 'd481392f-99e2-4ec9-8b82-92eb0fbcdd79',
        last_modified: '2019-07-04T05:53:44.627Z',
        name: 'Communication',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.769Z',
        id: 'c8dd5d9a-f54d-4d83-a887-276927073d34',
        last_modified: '2019-07-04T05:53:44.769Z',
        name: 'Flexibility',
        type: 'on_pangean',
    },
    {
        created_at: '2019-07-04T05:53:44.791Z',
        id: 'db4004b8-7baa-4555-9737-1d4f1b942320',
        last_modified: '2019-07-04T05:53:44.791Z',
        name: 'Creativity',
        type: 'on_pangean',
    },
];

export const employerSoftSkillsArr = [
    {
        created_at: '2019-07-04T05:53:44.836Z',
        id: '6818c82a-b316-42a1-b7af-974c9e80eb65',
        last_modified: '2019-07-04T05:53:44.836Z',
        name: 'Mentorship',
        type: 'on_employer',
    },
    {
        created_at: '2019-07-04T05:53:44.820Z',
        id: 'd6ebdc51-e02f-462d-8820-2088066b676c',
        last_modified: '2019-07-04T05:53:44.820Z',
        name: 'Reliability',
        type: 'on_employer',
    },
    {
        created_at: '2019-07-04T05:53:44.827Z',
        id: 'cac66cee-d438-4c15-9cb7-80054b6a4b22',
        last_modified: '2019-07-04T05:53:44.827Z',
        name: 'Flexibility',
        type: 'on_employer',
    },
    {
        created_at: '2019-07-04T05:53:44.849Z',
        id: '12b5f0c4-83d2-41cb-8742-4e77905806d1',
        last_modified: '2019-07-04T05:53:44.849Z',
        name: 'Professionalism',
        type: 'on_employer',
    },
    {
        created_at: '2019-07-04T05:53:44.842Z',
        id: '791f4af2-365e-4c92-9841-264582f4d6b6',
        last_modified: '2019-07-04T05:53:44.842Z',
        name: 'Empathy',
        type: 'on_employer',
    },
    {
        created_at: '2019-07-04T05:53:44.813Z',
        id: 'fcc195e1-4a56-4b44-b9a3-9caeb2320bd7',
        last_modified: '2019-07-04T05:53:44.813Z',
        name: 'Communication',
        type: 'on_employer',
    },
];
