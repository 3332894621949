import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SKILL_TYPEAHEAD: 'skill_typeahead',
    SKILL_TYPEAHEAD_SUCCESS: 'skill_typeahead_success',
    SKILL_TYPEAHEAD_FAIL: 'skill_typeahead_fail',
    SKILL_COMPUTE_SUGGESTED_SKILLS: 'skill_compute_suggested_skills',
    SKILL_COMPUTE_SUGGESTED_SKILLS_SUCCESS:
        'skill_compute_suggested_skills_success',
    SKILL_COMPUTE_SUGGESTED_SKILLS_FAIL: 'skill_compute_suggested_skills_fail',
    SKILL_RESET_TYPEAHEAD: 'skill_reset_typeahead',
    FETCH_SOFT_SKILLS: 'FETCH_SOFT_SKILLS',
    FETCH_SOFT_SKILLS_SUCCESS: 'FETCH_SOFT_SKILLS_SUCCESS',
    FETCH_SOFT_SKILLS_FAIL: 'FETCH_SOFT_SKILLS_FAIL',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    typeaheadSkills: null,
    suggestedSkills: null,
    pangeanSoftSkills: null,
    employerSoftSkills: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SKILL_TYPEAHEAD:
            return { ...state, loading: true };
        case types.SKILL_TYPEAHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                typeaheadSkills: action.payload,
            };
        case types.SKILL_TYPEAHEAD_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.SKILL_COMPUTE_SUGGESTED_SKILLS:
            return { ...state, loading: true };
        case types.SKILL_COMPUTE_SUGGESTED_SKILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                suggestedSkills: action.payload,
            };
        case types.SKILL_COMPUTE_SUGGESTED_SKILLS_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.SKILL_RESET_TYPEAHEAD:
            return { ...state, typeaheadSkills: [] };
        case types.FETCH_SOFT_SKILLS:
            return { ...state, loading: true };
        case types.FETCH_SOFT_SKILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                pangeanSoftSkills: action.payload.pangeanSoftSkills,
                employerSoftSkills: action.payload.employerSoftSkills,
            };
        case types.FETCH_SOFT_SKILLS_FAIL:
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const skillsTypeahead = ({ text, size }) => {
    return dispatch => {
        dispatch({ type: types.SKILL_TYPEAHEAD });

        WS.socket.emit('skills:search:typeahead', {
            text,
            size,
        });

        WS.socket.once('skills:search:typeahead', res => {
            if (res.success) {
                skillsTypeaheadSuccess(dispatch, res.data);
            } else {
                skillsTypeaheadFail(dispatch, res.message);
            }
        });
    };
};

const skillsTypeaheadSuccess = (dispatch, res) => {
    dispatch({ type: types.SKILL_TYPEAHEAD_SUCCESS, payload: res });
};
const skillsTypeaheadFail = (dispatch, msg) => {
    dispatch({ type: types.SKILL_TYPEAHEAD_FAIL, payload: msg });
};

export const skillsComputeSuggestedSkills = data => {
    return dispatch => {
        dispatch({ type: types.SKILL_COMPUTE_SUGGESTED_SKILLS, payload: data });

        WS.socket.emit('skill:computeSuggestedSkills', {
            skills: data,
        });
        WS.socket.once('skill:computeSuggestedSkills', res => {
            if (res.success) {
                skillsComputeSuggestedSkillsSuccess(dispatch, res.data);
            } else {
                skillsComputeSuggestedSkillsFail(dispatch, res.message);
            }
        });
    };
};

const skillsComputeSuggestedSkillsSuccess = (dispatch, res) => {
    dispatch({
        type: types.SKILL_COMPUTE_SUGGESTED_SKILLS_SUCCESS,
        payload: res,
    });
};
const skillsComputeSuggestedSkillsFail = (dispatch, msg) => {
    dispatch({ type: types.SKILL_COMPUTE_SUGGESTED_SKILLS_FAIL, payload: msg });
};

export const resetTypeaheadSkills = () => {
    return dispatch => {
        dispatch({ type: types.SKILL_RESET_TYPEAHEAD });
    };
};

export const fetchSoftSkills = () => {
    // AmplitudeAnalytics.track('Fetch Soft Skills');

    return dispatch => {
        dispatch({
            type: types.FETCH_SOFT_SKILLS,
        });

        WS.socket.emit('softSkill:fetch', {});

        WS.socket.once('softSkill:fetch', res => {
            if (res.success) {
                dispatch({
                    type: types.FETCH_SOFT_SKILLS_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.FETCH_SOFT_SKILLS_FAIL,
                    payload: res.error,
                });
            }
        });
    };
};
