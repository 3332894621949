// import Sentry from 'sentry-expo';

// import {NavigationActions} from 'react-navigation';
import { saveClickedPost, saveClickedPangean } from './MainFeedDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SAVE_DEEPLINK_DATA: 'save_deeplink_data',
    RESET_DEEPLINK_DATA: 'reset_deeplink_data',
    SAVE_CLICKED_POST_SUCCESS: 'save_clicked_post_success',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    canonicalIdentifier: null,
    data: null,
    screen: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SAVE_DEEPLINK_DATA:
            return {
                ...state,
                data: action.payload.data,
                canonicalIdentifier: action.payload.canonicalIdentifier,
                screen: action.payload.screen,
            };
        case types.RESET_DEEPLINK_DATA:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const saveDeeplinkData = ({
    data,
    canonicalIdentifier,
    screen,
    user,
}) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_DEEPLINK_DATA,
            payload: { data, canonicalIdentifier, screen },
        });

        try {
            // Sentry.captureMessage('attempt to parse deep link data', {
            //     level: 'info',
            // });

            let parsedData = JSON.parse(data);
            switch (screen) {
                case 'DetailedPost':
                    // Sentry.captureMessage('post data parsed', {
                    //     level: 'info',
                    // });
                    dispatch(
                        saveClickedPost({
                            post: parsedData,
                            owner_id: parsedData.owner.id,
                            responder_id: user,
                        })
                    );

                    // Sentry.captureMessage(`save clicked post dispatched, nav to ${screen}`, {
                    //     level: 'info',
                    // });
                    // dispatch(NavigationActions.navigate({key: screen, routeName: screen}));
                    break;
                case 'DetailedProfile':
                    // Sentry.captureMessage('pangean data parsed', {
                    //     level: 'info',
                    // });
                    dispatch(
                        saveClickedPangean({
                            pangean: parsedData,
                            owner_id: parsedData.id,
                            responder_id: user,
                        })
                    );
                    // Sentry.captureMessage(`save clicked pangean dispatched, nav to ${screen}`, {
                    //     level: 'info',
                    // });
                    // dispatch(NavigationActions.navigate({key: screen, routeName: screen}));
                    break;
                case 'AddPhoneNumber':
                    // Sentry.captureMessage('referral data parsed', {
                    //     level: 'info',
                    // });
                    // Sentry.captureMessage(`save referral data dispatched, nav to ${screen}`, {
                    //     level: 'info',
                    // });
                    // dispatch(NavigationActions.navigate({key: screen, routeName: screen}));
                    break;
                default:
                    break;
            }
        } catch (exception) {
            // Sentry.captureMessage(`failed to parse deep link data ${exception}`, {
            //     level: 'critical',
            // });
        }
    };
};

export const resetDeeplinkData = () => {
    return dispatch => {
        dispatch({ type: types.RESET_DEEPLINK_DATA, payload: true });

        // dispatch(NavigationActions.navigate({key: 'FeedStack', routeName: 'FeedStack'}));
    };
};
