import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import OnboardingStyles from '../../../constants/OnboardingStyles';
import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import { push } from 'connected-react-router';
import { store } from '../../..';
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class AddNewSchoolSuccessScreen extends Component {
    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.subTitleText} gutterBottom>
                    {'Thank you for adding your school!'}
                </Typography>
                <Typography style={OnboardingStyles.titleText}>
                    {
                        'Your account will be fully activated when the team verifies your school.'
                    }
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    store.dispatch(push('/studentAddProfileInfo'));
                }}
            >
                Continue
            </Button>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps, {})(AddNewSchoolSuccessScreen);
