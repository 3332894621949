import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    Grid,
    Typography,
    List,
    ListItem,
    Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Environment from '../../../constants/Environment';

import PlacesAutocomplete from 'react-places-autocomplete';

import OnboardingContainerView from './OnboardingContainerView';
import {
    addStudentSchoolInfo,
    updateStudentProfileInfo,
    addBioInfo,
    addStudentAddressInfo,
    addProfilePhotoInfo,
} from '../../../ducks/OnboardingDuck';

import {
    updateUser,
    uploadUserProfilePic,
} from '../../../ducks/UserSessionDuck';

import {
    OnboardingHeader,
    FooterShort,
    CustomContainer,
    CustomInputLabel,
    CustomInputBase,
} from '../../common';

import { Form, Field } from 'react-final-form';

import FileUpload from '../../common/fileUpload/FileUpload';
import Colors from '../../../constants/Colors';

import addPhoto from '../../../assets/images/common/addPhoto.svg';

import { push } from 'connected-react-router';
import { store } from '../../..';

import OnboardingStyles from '../../../constants/OnboardingStyles';

import GlobalStyles from '../../../constants/GlobalStyles';
const globalStyles = GlobalStyles;

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
const yogOptions = [
    {
        key: '2019',
        text: '2019',
        value: '2019',
    },
    {
        key: '2020',
        text: '2020',
        value: '2020',
    },
    {
        key: '2021',
        text: '2021',
        value: '2021',
    },
    {
        key: '2022',
        text: '2022',
        value: '2022',
    },
    {
        key: '2023',
        text: '2023',
        value: '2023',
    },
];

const searchOptions = {
    types: ['geocode'],
};

const styles = {
    fileUpload: {
        height: 40,
        width: 100,
        backgroundColor: Colors.pangeaBlue,
    },
    textArea: {
        paddingTop: 10,
        paddingBottom: 10,
        marginBottom: 20,
        minHeight: 85,
    },
    button: {
        marginTop: 20,
    },
    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
};

class StudentAddProfileInfoScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            address: '',
            pictures: [],
            selectedPictureUrl: null,
            selectedPicture: null,
        };
    }

    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.subTitleText} gutterBottom>
                    Alright {this.props.firstName}
                </Typography>
                <Typography style={OnboardingStyles.titleText}>
                    Please fill out your profile info:
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        const { classes } = this.props;
        return (
            <Form
                onSubmit={values => {
                    let updatedUser = {
                        id: this.props.user.id,
                        bio: values.bio,
                        intended_major: values.intendedMajor,
                        expected_yog: values.yearOfGraduation,
                    };

                    if (values.address) {
                        updatedUser.address = values.address;
                    }

                    if (
                        this.state.selectedPictureUrl &&
                        this.state.selectedPicture
                    ) {
                        let timeStamp = `${Date.now()}`;

                        updatedUser.profile_pic = timeStamp;

                        this.props.uploadUserProfilePic(
                            this.props.user,
                            this.state.selectedPictureUrl,
                            timeStamp,
                            this.state.selectedPicture
                        );
                    }

                    this.props.updateUser({ user: updatedUser });
                    store.dispatch(push('/studentAddProfessionalInfo'));
                }}
                validate={values => {
                    const errors = {};

                    if (!values.yearOfGraduation) {
                        errors.yearOfGraduation = 'Required';
                    }

                    if (!values.intendedMajor) {
                        errors.intendedMajor = 'Required';
                    }

                    // if we make profile pic required:
                    // if (
                    //     !this.state.selectedPicture ||
                    //     !this.state.selectedPictureUrl
                    // ) {
                    //     errors.profilePic = 'Required';
                    // }

                    if (!values.bio) {
                        errors.bio = 'Required';
                    }

                    return errors;
                }}
            >
                {({ handleSubmit, submitting, values }) => (
                    <form
                        onSubmit={handleSubmit}
                        error={!!this.props.error}
                        loading={this.props.loading}
                        style={{
                            overflowY: 'scroll',
                            height: 'calc(100vh - 170px)',
                        }}
                    >
                        <Typography variant="h5" gutterBottom>
                            Academic Information
                        </Typography>

                        <Typography
                            style={OnboardingStyles.formTitleBold}
                        >{`${this.props.schoolName}`}</Typography>
                        <Field name="yearOfGraduation">
                            {({ input, meta }) => (
                                <FormControl
                                    style={{
                                        minWidth: '75%',
                                        marginBottom: 20,
                                    }}
                                    error={meta.error && meta.touched}
                                >
                                    <CustomInputLabel variant="outlined">
                                        Expected Year of Graduation
                                    </CustomInputLabel>
                                    <Select
                                        id="expected-yog"
                                        variant="outlined"
                                        {...input}
                                        onChange={e => {
                                            input.onChange(e.target.value);
                                        }}
                                        input={
                                            <CustomInputBase
                                                error={
                                                    meta.error && meta.touched
                                                }
                                            />
                                        }
                                        inputProps={{
                                            name: 'yearOfGrad',
                                            id: 'yearOfGrad',
                                        }}
                                    >
                                        <MenuItem value="" disabled>
                                            Expected Year of Graduation
                                        </MenuItem>
                                        {yogOptions.map((yog, index) => {
                                            return (
                                                <MenuItem
                                                    value={yog.value}
                                                    key={index}
                                                >
                                                    {yog.text}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            )}
                        </Field>

                        <Field name="intendedMajor">
                            {({ input, meta }) => (
                                <TextField
                                    label="Intended Major"
                                    variant="outlined"
                                    type="text"
                                    name="intended-major"
                                    id="intended-major"
                                    fullWidth
                                    error={meta.error && meta.touched}
                                    {...input}
                                />
                            )}
                        </Field>

                        <div style={{ height: 20 }} />

                        <div>
                            <Typography variant="h5" gutterBottom>
                                Profile Picture
                            </Typography>
                            <Field name="profilePic">
                                {({ input, meta }) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            paddingBottom: 20,
                                            flex: 1,
                                        }}
                                    >
                                        <Grid item md={4}>
                                            {this.state.selectedPictureUrl ? (
                                                <img
                                                    src={
                                                        this.state
                                                            .selectedPictureUrl
                                                    }
                                                    alt={addPhoto}
                                                    height="140"
                                                    width="140"
                                                    style={{
                                                        marginTop: 20,
                                                        borderRadius: 70,
                                                    }}
                                                />
                                            ) : (
                                                <img
                                                    src={addPhoto}
                                                    alt=""
                                                    height="140"
                                                    width="140"
                                                    style={{
                                                        marginTop: 20,
                                                        // borderRadius: 70,
                                                    }}
                                                />
                                            )}
                                        </Grid>
                                        <Grid
                                            item
                                            md={8}
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <div>
                                                <Typography
                                                    style={
                                                        OnboardingStyles.formTitle
                                                    }
                                                >
                                                    A profile picture helps
                                                    increase the chances of
                                                    employers viewing your
                                                    profile.
                                                </Typography>
                                                {meta.error && meta.touched && (
                                                    <Typography
                                                        variant="subtitle2"
                                                        color="error"
                                                        style={{
                                                            marginTop: 10,
                                                        }}
                                                    >
                                                        Profile Picture Required
                                                    </Typography>
                                                )}
                                            </div>
                                            <FileUpload
                                                mode="basic"
                                                chooseLabel="Upload Profile Picture"
                                                uploadLabel="upload"
                                                style={styles.fileUpload}
                                                accept="image/*"
                                                multiple={false}
                                                url=""
                                                maxFileSize={2000000}
                                                onSelect={
                                                    this.fileSelectedHandler
                                                }
                                                onClear={this.cancelFileHandler}
                                            />
                                        </Grid>
                                    </div>
                                )}
                            </Field>
                        </div>

                        <Typography variant="h5" gutterBottom>
                            Tell potential employers about you
                        </Typography>
                        <Field name="bio">
                            {({ input, meta }) => (
                                <TextField
                                    variant="outlined"
                                    label="Bio"
                                    placeholder={
                                        'For example “I’m a junior at Brown with passion for filmmaking and working with early-stage companies that are mission driven.”'
                                    }
                                    rows="6"
                                    fullWidth
                                    maxLength={5000}
                                    multiline
                                    error={meta.error && meta.touched}
                                    {...input}
                                />
                            )}
                        </Field>

                        <div style={{ height: 20 }} />

                        <Typography variant="h5" gutterBottom>
                            Home Address (optional)
                        </Typography>
                        <Typography gutterBottom>
                            We’ll use your address to match you with the best
                            projects year-round. City and state are sufficient.
                        </Typography>
                        <Field name="address">
                            {({ input, meta }) => (
                                <PlacesAutocomplete
                                    searchOptions={searchOptions}
                                    onChange={address => {
                                        // input.onChange(address);
                                    }}
                                    onSelect={address => {
                                        input.onChange(address);
                                    }}
                                    {...input}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <div style={{ marginTop: 20 }}>
                                            <TextField
                                                id="address-input"
                                                label="Address"
                                                variant="outlined"
                                                error={
                                                    meta.error && meta.touched
                                                }
                                                {...input}
                                                inputProps={{
                                                    ...getInputProps({
                                                        // placeholder: 'Address',
                                                        className:
                                                            'location-search-input',
                                                    }),
                                                }}
                                                style={{ minWidth: '75%' }}
                                            />
                                            <div className="autocomplete-dropdown-container">
                                                {loading && (
                                                    <Typography>
                                                        Loading...
                                                    </Typography>
                                                )}

                                                <List>
                                                    {suggestions.map(
                                                        suggestion => {
                                                            return (
                                                                <ListItem
                                                                    {...getSuggestionItemProps(
                                                                        suggestion
                                                                    )}
                                                                    className={
                                                                        classes.listItem
                                                                    }
                                                                >
                                                                    <Typography>
                                                                        {
                                                                            suggestion.description
                                                                        }
                                                                    </Typography>
                                                                    <Divider />
                                                                </ListItem>
                                                            );
                                                        }
                                                    )}
                                                </List>
                                            </div>
                                        </div>
                                    )}
                                </PlacesAutocomplete>
                            )}
                        </Field>

                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                        >
                            Continue
                        </Button>
                        <div style={{ height: 20 }} />
                    </form>
                )}
            </Form>
        );
    };

    fileSelectedHandler = ({ event, files }) => {
        this.setState({
            selectedPictureUrl: files[0].objectURL,
        });
        this.setState({
            selectedPicture: files[0],
        });
    };

    cancelFileHandler = () => {
        this.setState({
            selectedPictureUrl: '',
            selectedPicture: null,
        });
    };

    onDrop = (pictureFiles, pictureDataURLs) => {
        this.setState({
            pictures: this.state.pictures.concat(pictureFiles),
        });
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, userSession }) => {
    const {
        selectedSchool,
        studentSchoolInfo,
        bio,
        studentHomeAddress,
        personalInfo,
        profilePhotoInfo,
        loading,
        error,
    } = onboarding;

    const { user } = userSession;

    return {
        user,
        yearOfGraduation: studentSchoolInfo
            ? studentSchoolInfo.yearOfGraduation
            : '',
        intendedMajor: studentSchoolInfo ? studentSchoolInfo.intendedMajor : '',
        schoolName: selectedSchool ? selectedSchool.name : '',
        selectedSchool,
        bio: bio ? bio : '',
        studentHomeAddress: studentHomeAddress ? studentHomeAddress : '',
        firstName: personalInfo ? personalInfo.firstName : '',
        image: profilePhotoInfo
            ? profilePhotoInfo.image
            : user.profile_pic
            ? `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${user.id}/profilePic/${user.profile_pic}`
            : null,
        profile_pic_name: profilePhotoInfo
            ? profilePhotoInfo.profile_pic_name
            : user.profile_pic
            ? user.profile_picprofilePhotoInfo
            : null,
        loading,
        error,
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        addStudentSchoolInfo,
        updateStudentProfileInfo,
        addBioInfo,
        addStudentAddressInfo,
        addProfilePhotoInfo,
        updateUser,
        uploadUserProfilePic,
    })(StudentAddProfileInfoScreen)
);
