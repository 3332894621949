import React from 'react';

const peopleSuggestionsStyle = {
    rootContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },

    sectionTitle: {
        marginBottom: 20,
    },

    rule: {
        border: 'solid 1px #979797',
        marginTop: 20,
        marginBottom: 15,
        width: '100%',
    },
};

const pangeanCardStyles = {
    rootContainer: {
        borderRadius: 5,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        padding: 10,
        marginTop: 20,
        marginBottom: 20,
    },

    bioContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    profileImage: {
        borderRadius: 30,
        width: 60,
        height: 60,
        border: 'solid 1px #c7c7cc',
        marginRight: 20,
    },

    bioTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    boldBioText: {
        fontWeight: 'bold',
        display: 'block',
        fontSize: 16,
        marginBottom: 1,
    },

    bioText: {
        fontSize: 14,
        display: 'block',
    },

    skillsContainer: {
        marginTop: 10,
        display: 'flex',
        justifyContent: 'space-between',
    },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
};

const PangeanCard = ({ name, major, school, profilePicSrc, skills }) => {
    let SkillChips = [];

    if (skills && skills.length > 0) {
        SkillChips.push(
            <div style={pangeanCardStyles.skillChip}>{skills[0]}</div>
        );

        if (skills.length > 1) {
            SkillChips.push(
                <div style={pangeanCardStyles.skillChip}>{skills[1]}</div>
            );
        }

        if (skills.length === 3) {
            SkillChips.push(
                <div style={pangeanCardStyles.skillChip}>{skills[2]}</div>
            );
        } else if (skills.length > 3) {
            SkillChips.push(
                <div style={pangeanCardStyles.skillChip}>{`+ ${skills.length -
                    2}`}</div>
            );
        }
    }

    return (
        <div style={pangeanCardStyles.rootContainer}>
            <div style={pangeanCardStyles.bioContainer}>
                <img
                    src={profilePicSrc}
                    style={pangeanCardStyles.profileImage}
                    alt={name}
                />

                <div style={pangeanCardStyles.bioTextContainer}>
                    <span style={pangeanCardStyles.boldBioText}>{name}</span>
                    <span style={pangeanCardStyles.bioText}>{major}</span>
                    <span style={pangeanCardStyles.bioText}>{school}</span>
                </div>
            </div>

            <div style={pangeanCardStyles.skillsContainer}>{SkillChips}</div>
        </div>
    );
};

const ProfileSuggestionsCard = () => {
    return (
        <div style={peopleSuggestionsStyle.rootContainer}>
            <span style={peopleSuggestionsStyle.sectionTitle}>
                People also viewed
            </span>

            <hr style={peopleSuggestionsStyle.rule} />

            <PangeanCard
                name="Brad Hall"
                major="CS"
                school="Brown University"
                skills={['#UI Design', '#UX Design', '#Software']}
            />

            <PangeanCard
                name="Brad Hall"
                major="CS"
                school="Brown University"
                skills={['#UI Design', '#UX Design', '#Software']}
            />

            <PangeanCard
                name="Brad Hall"
                major="CS"
                school="Brown University"
                skills={['#UI Design', '#UX Design', '#Software']}
            />
        </div>
    );
};

export default ProfileSuggestionsCard;
