import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header, FooterShort, PostOverview, CustomContainer } from '../common';

import { Grid, Typography } from '@material-ui/core';

const styles = {
    container: {
        display: 'flex',
        flex: 1,
        marginTop: 50,
        marginBottom: 50,
        marginLeft: 42,
        marginRight: 42,
    },

    pageTitle: {
        marginBottom: 145,
    },
};

const projectTypes = ['One-Time', 'Internship', 'Full-Time'];

class PostNewProjectScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typeOfProject: projectTypes[0],
            compensationRate: 'hour',
        };
    }

    async componentDidMount() {}

    render() {
        return (
            <div
                style={
                    {
                        // backgroundImage: "url('images/pangea-background.jpg')",
                        // backgroundSize: 'contain',
                    }
                }
            >
                <Header />
                <CustomContainer>
                    <Grid
                        container
                        spacing={2}
                        style={styles.container}
                        justifyContent="center"
                    >
                        <Grid
                            item
                            md={5}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'fixed',
                            }}
                        >
                            <Typography
                                variant="h3"
                                gutterBottom
                                style={styles.pageTitle}
                            >
                                Post a New Project
                            </Typography>
                            <Typography
                                variant="body1"
                                gutterBottom
                            >{`Alright ${
                                this.props.user
                                    ? this.props.user.first_name
                                    : 'Juan Pablo'
                            }`}</Typography>
                            <Typography variant="h2">
                                Please enter information
                            </Typography>
                            <Typography variant="h2" gutterBottom>
                                about your project.
                            </Typography>
                        </Grid>
                        <Grid item md={5} style={{ display: 'flex' }} />
                        <Grid item md={1} style={{ display: 'flex' }} />
                        <Grid item md={6} style={{ width: '100%' }}>
                            <PostOverview type={'create'} />
                        </Grid>
                    </Grid>
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(PostNewProjectScreen);
