// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import Environment from '../constants/Environment';
// import { NavigationActions } from 'react-navigation';

import { saveClickedCompany } from './CompanyDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    MY_CANDIDATE_POOLS_FETCH: 'MY_CANDIDATE_POOLS_FETCH',
    MY_CANDIDATE_POOLS_RELOAD: 'MY_CANDIDATE_POOLS_RELOAD',
    MY_CANDIDATE_POOLS_FETCH_SUCCESS: 'MY_CANDIDATE_POOLS_FETCH_SUCCESS',
    MY_CANDIDATE_POOLS_FETCH_FAIL: 'MY_CANDIDATE_POOLS_FETCH_FAIL',

    EDIT_CLICKED_CANDIDATE_POOL: 'EDIT_CLICKED_CANDIDATE_POOL',
    SAVE_CLICKED_CANDIDATE_POOL: 'SAVE_CLICKED_CANDIDATE_POOL',

    CANDIDATE_POOL_CREATE: 'CANDIDATE_POOL_CREATE',
    CANDIDATE_POOL_CREATE_SUCCESS: 'CANDIDATE_POOL_CREATE_SUCCESS',
    CANDIDATE_POOL_CREATE_FAIL: 'CANDIDATE_POOL_CREATE_FAIL',

    CANDIDATE_POOL_UPDATE: 'CANDIDATE_POOL_UPDATE',
    CANDIDATE_POOL_UPDATE_SUCCESS: 'CANDIDATE_POOL_UPDATE_SUCCESS',
    CANDIDATE_POOL_UPDATE_FAIL: 'CANDIDATE_POOL_UPDATE_FAIL',

    CANDIDATE_POOL_DELETE: 'CANDIDATE_POOL_DELETE',
    CANDIDATE_POOL_DELETE_SUCCESS: 'CANDIDATE_POOL_DELETE_SUCCESS',
    CANDIDATE_POOL_DELETE_FAIL: 'CANDIDATE_POOL_DELETE_FAIL',

    CANDIDATE_POOL_DISCARD_CHANGES: 'CANDIDATE_POOL_DISCARD_CHANGES',
};

const INITIAL_STATE = {
    loading: false,
    myCandidatePoolsArray: [],
    clickedCandidatePool: null,
    editedClickedCandidatePool: null,
    error: '',
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // fetch all rooms that a user is a part of
        case types.MY_CANDIDATE_POOLS_FETCH:
            return { ...state, loading: true };
        case types.MY_CANDIDATE_POOLS_RELOAD:
            return { ...state, loading: true, myCandidatePoolsArray: [] };
        case types.MY_CANDIDATE_POOLS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                myCandidatePoolsArray: action.payload,
            };
        case types.MY_CANDIDATE_POOLS_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.EDIT_CLICKED_CANDIDATE_POOL:
            return { ...state, clickedCandidatePool: action.payload };
        case types.SAVE_CLICKED_CANDIDATE_POOL:
            return { ...state, clickedCandidatePool: action.payload };

        case types.CANDIDATE_POOL_CREATE:
            return { ...state, loading: true };
        case types.CANDIDATE_POOL_CREATE_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.CANDIDATE_POOL_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.CANDIDATE_POOL_UPDATE:
            return { ...state, loading: true };
        case types.CANDIDATE_POOL_UPDATE_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.CANDIDATE_POOL_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.CANDIDATE_POOL_DELETE:
            return { ...state, loading: true };
        case types.CANDIDATE_POOL_DELETE_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.CANDIDATE_POOL_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.CANDIDATE_POOL_DISCARD_CHANGES:
            return {
                ...state,
                clickedCandidatePool: null,
                editedClickedCandidatePool: null,
            };

        default:
            return state;
    }
};

export const saveClickedCandidatePool = candidatePool => {
    return dispatch => {
        dispatch({
            type: types.SAVE_CLICKED_CANDIDATE_POOL,
            payload: candidatePool,
        });
    };
};

export const updateCandidatePoolInEdit = ({ candidatePool }) => {
    return dispatch => {
        dispatch({
            type: types.EDIT_CLICKED_CANDIDATE_POOL,
            payload: candidatePool,
        });
    };
};

export const candidatePoolDiscard = () => {
    return dispatch => {
        dispatch({ type: types.CANDIDATE_POOL_DISCARD_CHANGES });

        // dispatch(NavigationActions.back());
    };
};

export const candidatePoolCreate = ({ candidatePool }) => {
    return dispatch => {
        dispatch({
            type: types.CANDIDATE_POOL_CREATE,
        });
        WS.socket.emit('candidatePool:create', { candidatePool });
        WS.socket.once('candidatePool:create', res => {
            if (res.success) {
                candidatePoolCreateSuccess(dispatch, res.data);
            } else {
                candidatePoolCreateFail(dispatch, res.messsage);
            }
        });
    };
};

const candidatePoolCreateSuccess = (dispatch, res, user) => {
    // AmplitudeAnalytics.track('Candidate Pool Create Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(
    //         new Event(
    //             'CandidatePool',
    //             'Candidate Pool Create Success',
    //             res.owner_id
    //         )
    //     )
    //     .catch(e => console.log(e.message));

    dispatch(fetchMyCandidatePools({ user_id: res.owner_id }));

    dispatch({
        type: types.CANDIDATE_POOL_CREATE_SUCCESS,
        payload: res,
    });
};

const candidatePoolCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('CandidatePool Create Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CANDIDATE_POOL_CREATE_FAIL,
        payload: msg,
    });
};

// used for archiving, doing similar actions (toggling switches, etc) NOT for adding new data (e.g. uploading new images)
export const candidatePoolUpdate = ({ candidatePool }) => {
    return dispatch => {
        dispatch({
            type: types.CANDIDATE_POOL_UPDATE,
        });
        WS.socket.emit('candidatePool:update', { candidatePool });
        WS.socket.once('candidatePool:update', res => {
            if (res.success) {
                candidatePoolUpdateSuccess(dispatch, res.data);
            } else {
                candidatePoolUpdateFail(dispatch, res.messsage);
            }
        });
    };
};

const candidatePoolUpdateSuccess = (dispatch, res, user) => {
    // AmplitudeAnalytics.track('Candidate Pool Update Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(
    //         new Event(
    //             'CandidatePool',
    //             'Candidate Pool Update Success',
    //             res.owner_id
    //         )
    //     )
    //     .catch(e => console.log(e.message));

    if (res.company_id) {
        dispatch(saveClickedCompany({ company_id: res.company_id }));
    }
    dispatch(fetchMyCandidatePools({ user_id: res.owner_id }));

    dispatch({
        type: types.CANDIDATE_POOL_UPDATE_SUCCESS,
        payload: res,
    });
};

const candidatePoolUpdateFail = (dispatch, msg) => {
    // Sentry.captureMessage('CandidatePool Update Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CANDIDATE_POOL_UPDATE_FAIL,
        payload: msg,
    });
};

export const fetchMyCandidatePools = ({ user_id }) => {
    return dispatch => {
        dispatch({
            type: types.MY_CANDIDATE_POOLS_FETCH,
        });

        WS.socket.emit('candidatePool:fetchMyCandidatePools', { user_id });
        WS.socket.once('candidatePool:fetchMyCandidatePools', res => {
            if (res.success) {
                fetchMyCandidatePoolsSuccess(dispatch, res.data);
            } else {
                fetchMyCandidatePoolsFail(dispatch, res.message);
            }
        });
    };
};

const fetchMyCandidatePoolsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch My CandidatePools Success', {});
    dispatch({
        type: types.MY_CANDIDATE_POOLS_FETCH_SUCCESS,
        payload: res,
    });
};

const fetchMyCandidatePoolsFail = (dispatch, msg) => {
    // Sentry.captureMessage('fetchMyCandidatePoolsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.MY_CANDIDATE_POOLS_FETCH_FAIL,
        payload: msg,
    });
};
