import React from 'react';

import { Chip } from '@material-ui/core';
import AddCircle from '@material-ui/icons/AddCircle';

/*
Props:
    backgroundColor
    isDeleteable
    isAddable
    onAddPress
    onDeletePress
    skillName
    fontColor
*/

const SkillCard = props => {
    // const styles = {
    //     containerStyle: {
    //         backgroundColor: props.backgroundColor
    //             ? props.backgroundColor
    //             : Colors.pangeaBlue,
    //         borderRadius: 13,
    //         height: 26,
    //         minWidth: 112, // supports skills up to ~13 characters min128
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //         flexDirection: 'row',
    //         marginRight: props.isLastCard ? 12 : 0,
    //     },
    // };

    return props.isAddable ? (
        <Chip
            label={`#${props.skillName}`}
            variant="outlined"
            color="primary"
            onClick={props.onAddPress}
            onDelete={props.onAddPress}
            deleteIcon={<AddCircle />}
            style={{ marginRight: 20, marginTop: 5, marginBottom: 5 }}
        />
    ) : props.isDeletable ? (
        <Chip
            label={`#${props.skillName}`}
            variant="outlined"
            color="primary"
            onClick={props.onDeletePress}
            onDelete={props.onDeletePress}
            style={{ marginRight: 20, marginTop: 5, marginBottom: 5 }}
        />
    ) : (
        <Chip
            label={`#${props.skillName}`}
            variant="outlined"
            color="primary"
            style={{ marginRight: 20, marginTop: 5, marginBottom: 5 }}
        />
    );
    // <View style={{ flexDirection: 'row' }}>
    //     <View style={styles.containerStyle}>
    //         {props.isAddable ? (
    //             <TouchableOpacity
    //                 onPress={props.onAddPress}
    //                 style={{
    //                     flex: 1,
    //                     flexDirection: 'row',
    //                     alignItems: 'center',
    //                     justifyContent: 'space-between',
    //                 }}
    //             >
    //                 <Text
    //                     style={{
    //                         marginHorizontal: 12,
    //                         fontSize: 12,
    //                         fontFamily: 'InterRegular',
    //                         color: props.fontColor
    //                             ? props.fontColor === 'blue'
    //                                 ? Colors.pangeaBlue
    //                                 : props.fontColor === 'green'
    //                                 ? Colors.pangeaGreen
    //                                 : 'white'
    //                             : 'white',
    //                     }}
    //                 >
    //                     {`#${props.skillName}`}
    //                 </Text>
    //                 <Image
    //                     source={
    //                         props.fontColor
    //                             ? props.fontColor === 'blue'
    //                                 ? bluePlusButton
    //                                 : props.fontColor === 'green'
    //                                 ? greenPlusButton
    //                                 : whitePlusButton
    //                             : whitePlusButton
    //                     }
    //                     style={{
    //                         width: 12,
    //                         height: 12,
    //                         marginRight: 12,
    //                         resizeMode: 'contain',
    //                     }}
    //                 />
    //             </TouchableOpacity>
    //         ) : props.isDeletable ? (
    //             <TouchableOpacity
    //                 onPress={props.onDeletePress}
    //                 style={{
    //                     flex: 1,
    //                     flexDirection: 'row',
    //                     alignItems: 'center',
    //                     justifyContent: 'space-between',
    //                 }}
    //             >
    //                 <Text
    //                     style={{
    //                         marginLeft: 12,
    //                         fontSize: 12,
    //                         fontFamily: 'InterRegular',
    //                         color: props.fontColor
    //                             ? props.fontColor === 'blue'
    //                                 ? Colors.pangeaBlue
    //                                 : props.fontColor === 'green'
    //                                 ? Colors.pangeaGreen
    //                                 : 'white'
    //                             : 'white',
    //                     }}
    //                 >
    //                     {`#${props.skillName}`}
    //                 </Text>
    //                 <Image
    //                     source={
    //                         props.fontColor
    //                             ? props.fontColor === 'blue'
    //                                 ? blueXButton
    //                                 : props.fontColor === 'green'
    //                                 ? greenXButton
    //                                 : whiteXButton
    //                             : whiteXButton
    //                     }
    //                     style={{
    //                         width: 12,
    //                         height: 12,
    //                         marginHorizontal: 12,
    //                         resizeMode: 'contain',
    //                     }}
    //                 />
    //             </TouchableOpacity>
    //         ) : (
    //             <View
    //                 style={{
    //                     alignItems: 'center',
    //                     justifyContent: 'space-between',
    //                 }}
    //             >
    //                 <Text
    //                     style={{
    //                         marginHorizontal: 12,
    //                         fontSize: 12,
    //                         fontFamily: 'InterRegular',
    //                         color: props.fontColor
    //                             ? props.fontColor === 'blue'
    //                                 ? Colors.pangeaBlue
    //                                 : props.fontColor === 'green'
    //                                 ? Colors.pangeaGreen
    //                                 : 'white'
    //                             : 'white',
    //                     }}
    //                 >
    //                     #{props.skillName}
    //                 </Text>
    //                 <View />
    //             </View>
    //         )}
    //     </View>
    // </View>
};

export default SkillCard;
