import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    updateLogin,
    loginUser,
    sendForgotPassword,
} from '../../ducks/UserSessionDuck';

import { OnboardingHeader, CustomContainer } from '../common';

import {
    Grid,
    Container,
    TextField,
    Button,
    Dialog,
    DialogTitle,
} from '@material-ui/core';

import { push } from 'connected-react-router';
import { store } from '../..';

const styles = {
    textInput: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        marginBottom: 10,
        flex: 1,
        fontSize: 18,
    },
    resetPasswordButton: {
        borderRadius: 25,
        fontSize: 16,
        fontWeight: '400',
    },
    okButton: {
        borderRadius: 25,
        fontSize: 16,
        fontWeight: '400',
        marginBottom: 16,
        marginTop: 16,
    },
    bodyText: {
        marginLeft: 24,
        marginRight: 24,
    },
};

class ForgotPasswordScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            emailError: false,
            email: '',
        };
    }

    async componentDidMount() {}

    handleSubmit = e => {
        e.preventDefault();
        if (this.state.email === '') {
            this.setState({ emailError: true });
        } else {
            this.setState({ emailError: false });
            this.props.sendForgotPassword(this.state.email);
        }
    };

    onInputChange = event => {
        this.setState({ email: event.target.value });
    };

    render() {
        return (
            <div>
                <OnboardingHeader hasHomeLink />
                <Dialog
                    onClose={() => {
                        store.dispatch(push('/welcome'));
                    }}
                    open={this.props.resetPasswordStatus}
                >
                    <DialogTitle>Check your email</DialogTitle>
                    <p style={styles.bodyText}>
                        If there is an account connected to that email, you
                        should receive a link to reset your password.
                    </p>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            style={styles.okButton}
                            onClick={() => {
                                store.dispatch(push('/'));
                            }}
                        >
                            Okay
                        </Button>
                    </div>
                </Dialog>
                <CustomContainer>
                    <Grid
                        container
                        spacing={2}
                        style={{ height: '100vh' }}
                        alignItems="center"
                    >
                        <Grid item md={6}>
                            <p>Forgot Password?</p>
                            <h2>Please enter your email.</h2>
                        </Grid>
                        <Grid item md={4}>
                            <form
                                onSubmit={event => {
                                    this.handleSubmit(event);
                                }}
                                error={this.state.sendForgotPasswordError}
                            >
                                <TextField
                                    type="email"
                                    name="email-field"
                                    value={this.state.email}
                                    error={
                                        !!(
                                            this.props.error ||
                                            this.state.emailError
                                        )
                                    }
                                    helperText={
                                        this.props.error
                                            ? this.props.error
                                            : this.state.emailError
                                            ? 'We need this information to find your account.'
                                            : ''
                                    }
                                    placeholder="Email"
                                    autoFocus={true}
                                    onChange={this.onInputChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        style: { ...styles.textInput },
                                    }}
                                    fullWidth
                                />
                                <div style={{ height: 10 }} />
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        style={styles.resetPasswordButton}
                                    >
                                        Reset Password
                                    </Button>
                                </div>
                            </form>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const {
        email,
        password,
        error,
        loading,
        user,
        resetPasswordStatus,
    } = userSession;

    return {
        email: email ? email : '',
        password: password ? password : '',
        error,
        loading,
        user,
        resetPasswordStatus,
    };
};

export default connect(mapStateToProps, {
    loginUser,
    updateLogin,
    sendForgotPassword,
})(ForgotPasswordScreen);
