import React from 'react';

import {
    FormControl,
    Select,
    MenuItem,
    Typography,
    Checkbox,
} from '@material-ui/core';

import { CustomInputBase } from '../../common';

import { Field } from 'react-final-form';

const DurationTimeCommitmentForm = props => {
    return (
        <div>
            <div>
                <Typography variant="h5" gutterBottom>
                    What is the duration of this project?
                </Typography>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        marginBottom: 20,
                    }}
                >
                    <Field name="duration">
                        {({ input, meta }) => (
                            <FormControl
                                disabled={props.values.isDurationSkipped}
                                error={meta.error && meta.touched}
                            >
                                {/* <CustomInputLabel variant="outlined">
                                    Duration
                                </CustomInputLabel> */}
                                <Select
                                    variant="outlined"
                                    {...input}
                                    // onChange={e => {
                                    //     props._updatePost({
                                    //         duration: parseInt(e.target.value),
                                    //     });
                                    // }}
                                    labelWidth={'100%'}
                                    input={
                                        <CustomInputBase
                                            error={meta.error && meta.touched}
                                        />
                                    }
                                    style={{ minWidth: 70, marginRight: 10 }}
                                >
                                    <MenuItem value="" disabled>
                                        Weeks
                                    </MenuItem>
                                    {Array.from(Array(16), (_, x) => x).map(
                                        item => (
                                            <MenuItem
                                                key={item + 1}
                                                value={`${item + 1}`}
                                            >
                                                {`${item + 1}`}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </Field>

                    <Typography style={{ marginRight: 40 }}>weeks</Typography>
                    <Field name="isDurationSkipped" type="checkbox">
                        {({ input, meta }) => (
                            <React.Fragment>
                                <Checkbox
                                    checked={input.checked}
                                    onChange={() => {
                                        input.onChange(!input.checked);
                                    }}
                                    color="primary"
                                />
                                <Typography>skipped</Typography>
                            </React.Fragment>
                        )}
                    </Field>
                </div>
            </div>

            <div>
                <Typography variant="h5" gutterBottom>
                    What is the estimated time commitment from our Pangeans?
                </Typography>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    <Field name="timeCommitment">
                        {({ input, meta }) => (
                            <FormControl
                                disabled={props.values.isTimeCommitmentSkipped}
                                error={meta.error && meta.touched}
                            >
                                <Select
                                    variant="outlined"
                                    {...input}
                                    labelWidth={'100%'}
                                    input={
                                        <CustomInputBase
                                            error={meta.error && meta.touched}
                                        />
                                    }
                                    style={{ minWidth: 70, marginRight: 10 }}
                                >
                                    <MenuItem value="" disabled>
                                        Hours
                                    </MenuItem>
                                    {Array.from(Array(40), (_, x) => x).map(
                                        item => (
                                            <MenuItem
                                                key={item + 1}
                                                value={`${item + 1}`}
                                            >
                                                {`${item + 1}`}
                                            </MenuItem>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        )}
                    </Field>
                    <Typography>hours per week</Typography>

                    <Field name="isTimeCommitmentSkipped" type="checkbox">
                        {({ input, meta }) => (
                            <React.Fragment>
                                <Checkbox
                                    checked={input.checked}
                                    onChange={() => {
                                        input.onChange(!input.checked);
                                    }}
                                    value="time-commitment"
                                    color="primary"
                                />
                                <Typography>skipped</Typography>
                            </React.Fragment>
                        )}
                    </Field>
                </div>
            </div>
        </div>
    );
};

export default DurationTimeCommitmentForm;
