import React from 'react';

import { Typography } from '@material-ui/core';

import { SkillAutocompleteView } from '../../common';

import Colors from '../../../constants/Colors';

import { Field } from 'react-final-form';

// props
/*
skills
proposed_skills
_updatePost
*/
const SkillsForm = props => {
    return (
        <Field name="searchSkills">
            {({ input, meta }) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography variant="h5" gutterBottom>
                        What skills are relevant to this project? (Select at
                        least 2)
                    </Typography>
                    <SkillAutocompleteView
                        selectedSkills={props.selectedSkills}
                        proposedSkills={props.proposedSkills}
                        color={Colors.pangeaBlue}
                        autoFocus={false}
                        hasSuggestedSkills={true}
                        updateSelectedSkills={skills =>
                            props._updatePost({ skills })
                        }
                        updateProposedSkills={proposed_skills =>
                            props._updatePost({
                                proposed_skills,
                            })
                        }
                        error={meta.error && meta.touched}
                        helperText={
                            meta.error && meta.touched
                                ? 'Please select at least 2 skills'
                                : ''
                        }
                        fieldInput={{ ...input }}
                    />
                </div>
            )}
        </Field>
    );
};

export default SkillsForm;
