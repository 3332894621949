// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import { Analytics, Event } from 'expo-analytics';
// import Environment from '../constants/Environment';
import { types as userTypes } from './UserSessionDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    REPORT_CREATE: 'report_create',
    REPORT_CREATE_SUCCESS: 'report_create_success',
    REPORT_CREATE_FAIL: 'report_create_fail',

    BLOCK_CREATE: 'block_create',
    BLOCK_CREATE_SUCCESS: 'block_create_success',
    BLOCK_CREATE_FAIL: 'block_create_fail',

    BLOCK_DELETE: 'block_delete',
    BLOCK_DELETE_SUCCESS: 'block_delete_success',
    BLOCK_DELETE_FAIL: 'block_delete_fail',

    FRIEND_CREATE: 'friend_create',
    FRIEND_CREATE_SUCCESS: 'friend_create_success',
    FRIEND_CREATE_FAIL: 'friend_create_fail',

    FRIEND_DELETE: 'friend_delete',
    FRIEND_DELETE_SUCCESS: 'friend_delete_success',
    FRIEND_DELETE_FAIL: 'friend_delete_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: true,
    error: '',
    report: null,
    block: null,
    friend: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.REPORT_CREATE:
            return { ...state, loading: true };
        case types.REPORT_CREATE_SUCCESS:
            return { ...state, loading: false, report: action.payload };
        case types.REPORT_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.BLOCK_CREATE || types.BLOCK_DELETE:
            return { ...state, loading: true };
        case types.BLOCK_CREATE_SUCCESS || types.BLOCK_DELETE_SUCCESS:
            return { ...state, loading: false, block: action.payload };
        case types.BLOCK_CREATE_FAIL || types.BLOCK_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.FRIEND_CREATE || types.FRIEND_DELETE:
            return { ...state, loading: action.payload };
        case types.FRIEND_CREATE_SUCCESS || types.FRIEND_DELETE_SUCCESS:
            return { ...state, loading: false, friend: action.payload };
        case types.FRIEND_CREATE_FAIL || types.FRIEND_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const reportCreate = report => {
    return dispatch => {
        dispatch({ type: types.REPORT_CREATE });

        WS.socket.emit('report:create', report);
        WS.socket.once('report:create', res => {
            if (res.success) {
                reportCreateSuccess(dispatch, res.data);
            } else {
                reportCreateFail(dispatch, res.message);
            }
        });
    };
};
const reportCreateSuccess = (dispatch, res) => {
    dispatch({
        type: types.REPORT_CREATE_SUCCESS,
        payload: res,
    });

    // AmplitudeAnalytics.track('Report Create Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(new Event('Report', 'Report Create Success', res.owner_id))
    //     .catch(e => console.log(e.message));
};
const reportCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('reportCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.REPORT_CREATE_FAIL,
        payload: msg,
    });
};

export const blockCreate = ({ ownerId, reportedId }) => {
    return dispatch => {
        dispatch({ type: types.BLOCK_CREATE });

        WS.socket.emit('block:create', {
            owner_id: ownerId,
            reported_id: reportedId,
        });
        WS.socket.once('block:create', res => {
            if (res.success) {
                blockCreateSuccess(dispatch, res.data);
            } else {
                blockCreateFail(dispatch, res.message);
            }
        });
    };
};
const blockCreateSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Block Create Success', data);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: data.block.owner_id,
    //     cid: data.block.owner_id,
    // });
    // analytics
    //     .event(new Event('Block', 'Block Create Success', data.block.owner_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.BLOCK_CREATE_SUCCESS,
        payload: data.block,
    });

    dispatch({
        type: userTypes.UPDATE_USER_SUCCESS,
        payload: data.embeddedUser,
    });
};
const blockCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('blockCreateFail', {
    //     level: 'warning',
    // });

    dispatch({
        type: types.BLOCK_CREATE_FAIL,
        payload: msg,
    });
};

export const blockDelete = ({ block, filter }) => {
    return dispatch => {
        dispatch({ type: types.BLOCK_DELETE });
        WS.socket.emit('block:delete', block);
        WS.socket.once('block:delete', res => {
            if (res.success) {
                blockDeleteSuccess(dispatch, res.data);
            } else {
                blockDeleteFail(dispatch, res.message);
            }
        });
    };
};
const blockDeleteSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Block Delete Success', data);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: data.block.owner_id,
    //     cid: data.block.owner_id,
    // });
    // analytics
    //     .event(new Event('Block', 'Block Delete Success', data.block.owner_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.BLOCK_DELETE_SUCCESS,
        payload: data.block,
    });

    dispatch({
        type: userTypes.UPDATE_USER_SUCCESS,
        payload: data.embeddedUser,
    });
};
const blockDeleteFail = (dispatch, msg) => {
    // Sentry.captureMessage('blockDeleteFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.BLOCK_DELETE_FAIL,
        payload: msg,
    });
};

export const friendCreate = ({ ownerId, followedId }) => {
    return dispatch => {
        dispatch({ type: types.FRIEND_CREATE });

        WS.socket.emit('friend:create', {
            owner_id: ownerId,
            followed_id: followedId,
        });
        WS.socket.once('friend:create', res => {
            if (res.success) {
                friendCreateSuccess(dispatch, res.data);
            } else {
                friendCreateFail(dispatch, res.message);
            }
        });
    };
};
const friendCreateSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Friend Create Success', data);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: data.friend.owner_id,
    //     cid: data.friend.owner_id,
    // });
    // analytics
    //     .event(
    //         new Event('Friend', 'Friend Create Success', data.friend.owner_id)
    //     )
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.FRIEND_CREATE_SUCCESS,
        payload: data.friend,
    });

    dispatch({
        type: userTypes.UPDATE_USER_SUCCESS,
        payload: data.embeddedUser,
    });
};
const friendCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('friendCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.FRIEND_CREATE_FAIL,
        payload: msg,
    });
};

export const friendDelete = ({ friend }) => {
    return dispatch => {
        dispatch({ type: types.FRIEND_DELETE });

        WS.socket.emit('friend:delete', friend);
        WS.socket.once('friend:delete', res => {
            if (res.success) {
                friendDeleteSuccess(dispatch, res.data);
            } else {
                friendDeleteFail(dispatch, res.message);
            }
        });
    };
};
const friendDeleteSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Friend Delete Success', data);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: data.friend.owner_id,
    //     cid: data.friend.owner_id,
    // });
    // // category, action, label, value
    // analytics
    //     .event(
    //         new Event('Friend', 'Friend Delete Success', data.friend.owner_id)
    //     )
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.FRIEND_DELETE_SUCCESS,
        payload: data.friend,
    });

    dispatch({
        type: userTypes.UPDATE_USER_SUCCESS,
        payload: data.embeddedUser,
    });
};
const friendDeleteFail = (dispatch, msg) => {
    // Sentry.captureMessage('friendDeleteFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.FRIEND_DELETE_FAIL,
        payload: msg,
    });
};
