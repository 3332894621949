// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { Analytics, Event } from 'expo-analytics';
// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';
// import { NavigationActions } from 'react-navigation';

import { createProposedSkills } from './ProposedSkillDuck';
import { createGeo } from './GeoDuck';
import { saveClickedCompany } from './CompanyDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    MY_POSTS_FETCH: 'my_posts_fetch',
    MY_POSTS_RELOAD: 'inbox_reload',
    MY_POSTS_FETCH_SUCCESS: 'my_posts_fetch_success',
    MY_POSTS_FETCH_FAIL: 'my_posts_fetch_fail',

    EDIT_CLICKED_POST: 'edit_clicked_post',
    UPDATE_NEW_PHOTO_NAMES: 'update_new_photo_names',

    UPDATE_NUM_RENDERABLE_POSTS: 'update_num_renderable_posts',

    UPDATE_MY_POSTS_FILTER_SETTING: 'update_my_posts_filter_setting',

    MY_POSTS_SUBSCRIBE: 'inbox_subscribe',
    MY_POSTS_SUBSCRIBE_SUCCESS: 'inbox_subscribe_success',
    MY_POSTS_SUBSCRIBE_FAIL: 'inbox_subscribe_fail',

    POST_UPDATE: 'post_update',
    POST_UPDATE_SUCCESS: 'post_update_success',
    POST_UPDATE_FAIL: 'post_update_fail',

    POST_RESUBMIT: 'post_resubmit',
    POST_RESUBMIT_SUCCESS: 'post_resubmit_success',
    POST_RESUBMIT_FAIL: 'post_resubmit_fail',

    POST_DELETE: 'post_delete',
    POST_DELETE_SUCCESS: 'post_delete_success',
    POST_DELETE_FAIL: 'post_delete_fail',

    SAVE_MY_CLICKED_POST: 'save_my_clicked_post',

    POST_DISCARD_CHANGES: 'post_discard_changes',
};

const INITIAL_STATE = {
    loading: false,
    myPostsArray: [],
    filterSetting: 'all_posts',
    numRenderablePosts: null,
    clickedPost: null,
    editedClickedPost: null,
    error: '',
    newPhotoNames: null,
    newImages: null,
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // fetch all rooms that a user is a part of
        case types.MY_POSTS_FETCH:
            return { ...state, loading: true };
        case types.MY_POSTS_RELOAD:
            return { ...state, loading: true, myPostsArray: [] };
        case types.MY_POSTS_FETCH_SUCCESS:
            return { ...state, loading: false, myPostsArray: action.payload };
        case types.MY_POSTS_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.EDIT_CLICKED_POST:
            return { ...state, clickedPost: action.payload };
        case types.UPDATE_MY_POSTS_FILTER_SETTING:
            return { ...state, filterSetting: action.payload };
        case types.UPDATE_NUM_RENDERABLE_POSTS:
            return { ...state, numRenderablePosts: action.payload };

        case types.MY_POSTS_SUBSCRIBE:
            return { ...state, loading: true, myPostsArray: [] };
        case types.MY_POSTS_SUBSCRIBE_SUCCESS:
            return { ...state, loading: false, myPostsArray: action.payload };
        case types.MY_POSTS_SUBSCRIBE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.POST_UPDATE:
            return { ...state, loading: true };
        case types.POST_UPDATE_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.POST_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.POST_RESUBMIT:
            return { ...state, loading: true };
        case types.POST_RESUBMIT_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.POST_RESUBMIT_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.POST_DELETE:
            return { ...state, loading: true };
        case types.POST_DELETE_SUCCESS:
            return { ...state, loading: false, error: action.payload };
        case types.POST_DELETE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.SAVE_MY_CLICKED_POST:
            return { ...state, clickedPost: action.payload };

        case types.UPDATE_NEW_PHOTO_NAMES:
            return {
                ...state,
                newPhotoNames: action.payload.newPhotoNames,
                newImages: action.payload.newImages,
            };
        case types.POST_DISCARD_CHANGES:
            return { ...state, clickedPost: null, editedClickedPost: null };

        default:
            return state;
    }
};

export const saveMyClickedPost = post => {
    return dispatch => {
        dispatch({
            type: types.SAVE_MY_CLICKED_POST,
            payload: post,
        });
    };
};

export const updateProjectInEdit = ({ post }) => {
    return dispatch => {
        dispatch({
            type: types.EDIT_CLICKED_POST,
            payload: post,
        });
    };
};

export const postDiscard = () => {
    return dispatch => {
        dispatch({ type: types.POST_DISCARD_CHANGES });

        // dispatch(NavigationActions.back());
    };
};

export const updateNewPhotos = ({ newPhotoNames, newImages }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_NEW_PHOTO_NAMES,
            payload: { newPhotoNames, newImages },
        });
    };
};

// used for archiving, doing similar actions (toggling switches, etc) NOT for adding new data (e.g. uploading new images)
export const postUpdate = ({ post }) => {
    return dispatch => {
        dispatch({
            type: types.POST_UPDATE,
        });
        WS.socket.emit('post:update', { post });
        WS.socket.once('post:update', res => {
            if (res.success) {
                postUpdateSuccess(dispatch, res.data);
            } else {
                postUpdateFail(dispatch, res.messsage);
            }
        });
    };
};

const postUpdateSuccess = (dispatch, res, user) => {
    // AmplitudeAnalytics.track('Post Update Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(new Event('Post', 'Post Update Success', res.owner_id))
    //     .catch(e => console.log(e.message));

    if (res.company_id) {
        dispatch(saveClickedCompany({ company_id: res.company_id }));
    }
    dispatch(fetchMyPosts({ user_id: res.owner_id }));

    dispatch({
        type: types.POST_UPDATE_SUCCESS,
        payload: res,
    });
};

const postUpdateFail = (dispatch, msg) => {
    // Sentry.captureMessage('Post Update Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.POST_UPDATE_FAIL,
        payload: msg,
    });
};

const uploadImages = (res, imageUriArray, photos) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;

    imageUriArray.forEach((current, index) => {
        const uri = current;
        const uriParts = uri.split('.');
        const fileType = uriParts[uriParts.length - 1];
        const formData = new FormData();
        formData.append('image', {
            uri,
            type: `image/${fileType}`,
            name: photos[index],
            originalname: photos[index],
        });

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'file-path': 'Posts/' + res.id + '/',
            },
            body: formData,
        })
            .then(res => res.json())
            .then(results => {})
            .catch(error => {
                console.log(error);
            });
    });
};

export const postResubmit = ({
    post,
    combinedPhotoNames,
    newImages,
    newPhotoNames,
}) => {
    return dispatch => {
        dispatch({
            type: types.POST_RESUBMIT,
        });

        post.status = 'in_review';
        post.photos = combinedPhotoNames;
        post.duration = parseInt(post.duration);
        post.time_commitment = parseInt(post.time_commitment);

        if (post.skills) {
            post.skill_ids = post.skills.map(function(skill) {
                return skill['id'];
            });
        }

        let address;

        if (post.address) {
            address = post.address;
            delete post.address;
        }

        if (post.geoId) {
            post.geo_id = post.geoId;
            delete post.geoId;
        }

        if (post.loc) {
            delete post.loc;
        }

        let proposed_skills = post.proposed_skills;

        WS.socket.emit('post:update', { post });
        WS.socket.once('post:update', res => {
            if (res.success) {
                postResubmitSuccess(
                    dispatch,
                    res.data,
                    newImages,
                    newPhotoNames,
                    proposed_skills,
                    address
                );
            } else {
                postResubmitFail(dispatch, res.message);
            }
        });
    };
};
const postResubmitSuccess = (
    dispatch,
    res,
    newImages,
    newPhotoNames,
    proposed_skills,
    address
) => {
    if (newImages && newPhotoNames) {
        uploadImages(res, newImages, newPhotoNames);
    }

    // AmplitudeAnalytics.track('Post Resubmit Success', res);

    // const analytics = new Analytics(Environment.ANALYTICS, {
    //     uid: res.owner_id,
    //     cid: res.owner_id,
    // });
    // analytics
    //     .event(new Event('Post', 'Post Resubmit Success', res.owner_id))
    //     .catch(e => console.log(e.message));

    dispatch({
        type: types.POST_RESUBMIT_SUCCESS,
        payload: res,
    });

    // create any proposed skills tied to newly created post if they exist
    if (proposed_skills) {
        createProposedSkills(
            dispatch,
            proposed_skills,
            res.owner_id,
            res.id,
            'post'
        );
    }
    // create geo object for new address if one exists
    if (address) {
        dispatch(
            createGeo({
                address,
                relatedTo: { id: res.id, type: 'post' },
            })
        );
    }

    // const backAction = NavigationActions.back({
    //     key: 'EditYourProject',
    // });

    // dispatch(backAction);

    if (res && res.owner_id) {
        dispatch(fetchMyPosts({ user: { id: res.owner_id } }));
    }
};
const postResubmitFail = (dispatch, msg) => {
    // Sentry.captureMessage('Post Resubmit Fail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.POST_RESUBMIT_FAIL,
        payload: msg,
    });
};

export const fetchMyPosts = ({ user_id }) => {
    return dispatch => {
        dispatch({
            type: types.MY_POSTS_FETCH,
        });

        WS.socket.emit('post:fetchMyPosts', { user_id });
        WS.socket.once('post:fetchMyPosts', res => {
            if (res.success) {
                fetchMyPostsSuccess(dispatch, res.data);
            } else {
                fetchMyPostsFail(dispatch, res.message);
            }
        });
    };
};

const fetchMyPostsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch My Posts Success', {});
    dispatch({
        type: types.MY_POSTS_FETCH_SUCCESS,
        payload: res,
    });
};

const fetchMyPostsFail = (dispatch, msg) => {
    // Sentry.captureMessage('fetchMyPostsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.MY_POSTS_FETCH_FAIL,
        payload: msg,
    });
};

export const updateMyPostsFilterSetting = ({ filterSetting }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_MY_POSTS_FILTER_SETTING,
            payload: filterSetting,
        });
    };
};

export const updateNumRenderablePosts = ({ numRenderablePosts }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_NUM_RENDERABLE_POSTS,
            payload: numRenderablePosts,
        });
    };
};
