import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    TextField,
    Button,
    Typography,
    List,
    ListItem,
    Divider,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import OnboardingContainerView from './OnboardingContainerView';

import Colors from '../../../constants/Colors';

import {
    addBusinessCompanyLookupInfo,
    lookupCompany,
} from '../../../ducks/OnboardingDuck';

import {
    companiesTypeahead,
    resetTypeaheadCompanies,
} from '../../../ducks/CompanyDuck';

import GlobalStyles from '../../../constants/GlobalStyles';
import OnboardingStyles from '../../../constants/OnboardingStyles';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

const globalStyles = GlobalStyles;

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/

const styles = theme => ({
    rootContainer: {
        // height: 500,
        width: 'calc(100% - 40px)',
        minWidth: 'calc(100% - 40px)',
        // display: 'flex',
        // flexDirection: 'column',
        // paddingTop: 10,
        border: 'solid 1px #979797',
        backgroundColor: 'white',
        overflowY: 'scroll',
        padding: 0,
    },

    listItem: {
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },

    button: {
        marginTop: 20,
    },
});

class BusinessSelectCompanyScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.titleText}>
                    Please enter your company's name
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        const classes = this.props.classes;

        return (
            <form
                onSubmit={event => {
                    event.preventDefault();
                    this.props.lookupCompany({
                        companyName: this.props.companyName,
                    });
                }}
                error={!!this.props.error}
                loading={this.props.loading}
            >
                <TextField
                    variant="outlined"
                    label="Company Name"
                    name="company-name"
                    id="company-name"
                    autoFocus
                    autoComplete="off"
                    value={this.props.companyName}
                    onChange={event => {
                        this.props.addBusinessCompanyLookupInfo({
                            businessCompanyLookupInfo: {
                                companyName: event.target.value,
                                yourTitle: this.props.yourTitle,
                            },
                        });
                        this.props.companiesTypeahead({
                            text: event.target.value,
                            size: 5,
                        });
                    }}
                    fullWidth
                />
                {this.props.typeaheadCompanies.length > 0 && (
                    <List className={classes.rootContainer}>
                        {this.props.typeaheadCompanies.map(item => {
                            return (
                                <React.Fragment key={item.id}>
                                    <ListItem
                                        className={classes.listItem}
                                        disableGutters
                                        onClick={() => {
                                            this.props.addBusinessCompanyLookupInfo(
                                                {
                                                    businessCompanyLookupInfo: {
                                                        companyName: item.name,
                                                        yourTitle: this.props
                                                            .yourTitle,
                                                    },
                                                }
                                            );
                                            this.props.resetTypeaheadCompanies();
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                height: 44,
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginLeft: 20,
                                                marginRight: 20,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 38,
                                                    width: 38,
                                                    marginRight: 12,
                                                }}
                                            />
                                            <Typography style={styles.itemText}>
                                                {item.name}
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            );
                        })}
                        {this.props.companyName &&
                            this.props.companyName.length > 2 && (
                                <React.Fragment>
                                    <ListItem
                                        className={classes.listItem}
                                        disableGutters
                                        onClick={() => {
                                            this.props.addBusinessCompanyLookupInfo(
                                                {
                                                    businessCompanyLookupInfo: {
                                                        companyName: this.props
                                                            .companyName,
                                                        yourTitle: this.props
                                                            .yourTitle,
                                                    },
                                                }
                                            );
                                            this.props.resetTypeaheadCompanies();
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'flex',
                                                flex: 1,
                                                height: 44,
                                                alignItems: 'center',
                                                flexDirection: 'row',
                                                marginLeft: 20,
                                                marginRight: 20,
                                            }}
                                        >
                                            <div
                                                style={{
                                                    height: 38,
                                                    width: 38,
                                                    marginRight: 12,
                                                }}
                                            />
                                            <Typography
                                                style={{
                                                    color: Colors.pangeaBlue,
                                                }}
                                            >
                                                {`+ New Company: ${this.props.companyName}`}
                                            </Typography>
                                        </div>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            )}
                    </List>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ ...globalStyles.button }}
                    className={classes.button}
                >
                    Continue
                </Button>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding, company }) => {
    const { loading, error, businessCompanyLookupInfo } = onboarding;

    const { typeaheadCompanies } = company;

    return {
        loading,
        error,
        companyName: businessCompanyLookupInfo
            ? businessCompanyLookupInfo.companyName
            : '',
        yourTitle: businessCompanyLookupInfo
            ? businessCompanyLookupInfo.yourTitle
            : '',
        typeaheadCompanies: typeaheadCompanies ? typeaheadCompanies : [],
    };
};

export default withStyles(styles)(
    connect(mapStateToProps, {
        addBusinessCompanyLookupInfo,
        lookupCompany,
        companiesTypeahead,
        resetTypeaheadCompanies,
    })(BusinessSelectCompanyScreen)
);
