import React from 'react';

import PropTypes from 'prop-types';
import Environment from '../../../constants/Environment';
import Colors from '../../../constants/Colors';

import { Typography, List, ListItem } from '@material-ui/core';

import { EmployeeItem } from '../../common';

const styles = {
    rootContainer: {
        paddingTop: 20,
        paddingBottom: 20,
        width: '100%',
    },
    rule: {
        border: 'solid 1px #979797',
        marginTop: 20,
        marginBottom: 15,
    },
};

const ProfileTeamMembersCard = props => {
    console.log('props');

    console.log(props);

    return (
        <div style={styles.rootContainer}>
            <Typography variant="h4" gutterBottom>
                Team Members
            </Typography>

            <hr style={styles.rule}></hr>

            <List style={styles.listContainer}>
                {props.company.members.map((pangean, index) => (
                    <ListItem key={index} style={{ padding: 0 }}>
                        <EmployeeItem
                            pangean={pangean}
                            onPress={() => props.onEmployeeItemPress(pangean)}
                        />
                    </ListItem>
                ))}
            </List>

            {/* <EmployeeItem/> */}

            {/* <div style={schoolSectionStyle.bioContainer}>
                <img
                    src={
                        props.type === 'company'
                            ? `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${props.company.id}/logoPic/${props.company.logo_pic}`
                            : `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Schools/${props.school.id}`
                    }
                    style={schoolSectionStyle.profileImage}
                    alt={'School'}
                />

                <div style={schoolSectionStyle.bioTextContainer}>
                    <Typography variant="body2">
                        {props.type === 'company'
                            ? `${props.company.about_us}`
                            : `${props.school.about}`}
                    </Typography>
                </div>
            </div> */}
        </div>
    );
};

ProfileTeamMembersCard.propTypes = {
    company: PropTypes.object,
    user: PropTypes.object,
};

export default ProfileTeamMembersCard;
