import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';

import { push } from 'connected-react-router';

import { store } from '../../..';

import Colors from '../../../constants/Colors';
import OnboardingStyles from '../../../constants/OnboardingStyles';

import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class StudentCompleteSignUpScreen extends Component {
    renderLeftColumn = () => {
        return (
            <div>
                <Typography style={OnboardingStyles.subTitleText} gutterBottom>
                    Onboarding Complete
                </Typography>
                <Typography style={OnboardingStyles.titleText} gutterBottom>{`${
                    this.props.user ? this.props.user.first_name : 'JOHN'
                }, you're a Pangean!`}</Typography>
                <Typography style={OnboardingStyles.bodyText} gutterBottom>
                    As a Pangean, you’ll always be matched with amazing
                    projects, curated just for you!
                </Typography>

                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            store.dispatch(push('/myProfile'));
                        }}
                        style={{ marginRight: 20 }}
                    >
                        View Profile
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => {
                            store.dispatch(push('/projects'));
                        }}
                    >
                        View Projects
                    </Button>
                </div>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <div
                style={{
                    height: '100vh',
                    display: 'flex',
                    backgroundImage:
                        "url('images/pangea/pangea-middle-panel.png')",
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'right top',
                }}
            />
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(StudentCompleteSignUpScreen);
