import React from 'react';

import pangeaWordmarkBlue from '../../assets/images/pangea/pangea-wordmark-blue.png';

import { CustomContainer } from '.';

import { push } from 'connected-react-router';
import { store } from '../..';

const OnboardingHeader = props => (
    <div style={{ position: 'fixed', top: 0, width: '100vw' }}>
        <CustomContainer>
            {props.hasHomeLink ? (
                <img
                    src={pangeaWordmarkBlue}
                    alt={'pangea-logo'}
                    style={{ height: 90 }}
                    onClick={() => {
                        store.dispatch(push('/welcome'));
                    }}
                />
            ) : (
                <img
                    src={pangeaWordmarkBlue}
                    alt={'pangea-logo'}
                    style={{ height: 90 }}
                />
            )}
        </CustomContainer>
    </div>
);

export default OnboardingHeader;
