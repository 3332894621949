// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    INVOICES_FETCH: 'invoices_fetch',
    INVOICES_FETCH_SUCCESS: 'invoices_fetch_success',
    INVOICES_FETCH_FAIL: 'invoices_fetch_fail',
    SAVE_CLICKED_INVOICE: 'save_clicked_invoice',
    SAVE_CLICKED_INVOICE_SUCCESS: 'save_clicked_invoice_success',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: true,
    error: '',
    clickedInvoice: null,
    invoicesArray: [],
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.INVOICES_FETCH:
            return { ...state, loading: true };
        case types.INVOICES_FETCH_SUCCESS:
            return { ...state, loading: false, invoicesArray: action.payload };
        case types.INVOICES_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.SAVE_CLICKED_INVOICE:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_INVOICE_SUCCESS:
            return { ...state, loading: false, clickedInvoice: action.payload };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------
export const invoicesFetch = () => {
    return dispatch => {
        dispatch({ type: types.INVOICES_FETCH });
        WS.socket.emit('invoice:fetchAll', {});
        WS.socket.once('invoice:fetchAll', res => {
            if (res.success) {
                invoicesFetchSuccess(dispatch, res.data);
            } else {
                invoicesFetchFail(dispatch, res.msg);
            }
        });
    };
};
const invoicesFetchSuccess = (dispatch, invoice) => {
    dispatch({
        type: types.INVOICES_FETCH_SUCCESS,
        payload: invoice,
    });
};
const invoicesFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('invoiceFetchFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.INVOICES_FETCH_FAIL,
        payload: msg,
    });
};

export const saveClickedInvoice = ({ invoice }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_INVOICE });
        dispatch({
            type: types.SAVE_CLICKED_INVOICE_SUCCESS,
            payload: invoice,
        });
    };
};
