// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';

// import { NavigationActions } from 'react-navigation';

import { createGeo } from './GeoDuck';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    // Reports
    UNFILED_REPORTS_FETCH: 'unfiled_reports_fetch',
    UNFILED_REPORTS_FETCH_SUCCESS: 'unfiled_reports_fetch_success',
    UNFILED_REPORTS_FETCH_FAIL: 'unfiled_reports_fetch_fail',

    FILED_REPORTS_FETCH: 'filed_reports_fetch',
    FILED_REPORTS_FETCH_SUCCESS: 'filed_reports_fetch_success',
    FILED_REPORTS_FETCH_FAIL: 'filed_reports_fetch_fail',

    UPDATE_REPORT: 'update_report',
    UPDATE_REPORT_SUCCESS: 'update_report_success',
    UPDATE_REPORT_FAIL: 'update_report_fail',

    // Posts In Review
    POSTS_IN_REVIEW_FETCH: 'posts_in_review_fetch',
    POSTS_IN_REVIEW_FETCH_SUCCESS: 'posts_in_review_fetch_success',
    POSTS_IN_REVIEW_FETCH_FAIL: 'posts_in_review_fetch_fail',

    SAVE_CLICKED_ADMIN_POST: 'save_clicked_admin_post',
    UPDATE_CLICKED_POST: 'update_clicked_post',

    APPROVE_POST: 'approve_post',
    APPROVE_POST_SUCCESS: 'approve_post_success',
    APPROVE_POST_FAIL: 'approve_post_fail',

    REJECT_POST: 'reject_post',
    REJECT_POST_SUCCESS: 'reject_post_success',
    REJECT_POST_FAIL: 'reject_post_fail',

    UPDATE_REJECT_STATUS: 'update_reject_status',
    UPDATE_APPROVED_STATUS: 'update_approved_status',

    // Proposed Schools
    PROPOSED_SCHOOLS_FETCH: 'proposed_schools_fetch',
    PROPOSED_SCHOOLS_FETCH_SUCCESS: 'proposed_schools_fetch_success',
    PROPOSED_SCHOOLS_FETCH_FAIL: 'proposed_schools_fetch_fail',

    APPROVE_PROPOSED_SCHOOL: 'approve_proposed_school',
    APPROVE_PROPOSED_SCHOOL_SUCCESS: 'approve_proposed_school_success',
    APPROVE_PROPOSED_SCHOOL_FAIL: 'approve_proposed_school_fail',

    REJECT_PROPOSED_SCHOOL: 'reject_proposed_school',
    REJECT_PROPOSED_SCHOOL_SUCCESS: 'reject_proposed_school_success',
    REJECT_PROPOSED_SCHOOL_FAIL: 'reject_proposed_school_fail',

    UPLOAD_PROPOSED_SCHOOL_CREST: 'upload_proposed_school_crest',
    UPLOAD_PROPOSED_SCHOOL_CREST_SUCCESS:
        'upload_proposed_school_crest_success',
    UPLOAD_PROPOSED_SCHOOL_CREST_FAIL: 'upload_proposed_school_crest_fail',

    SAVE_CLICKED_ADMIN_PROPOSED_SCHOOL: 'save_clicked_admin_proposed_school',
    UPDATE_CLICKED_PROPOSED_SCHOOL: 'update_clicked_proposed_school',
    UPDATE_PROPOSED_SCHOOL_CREST_INFO: 'update_proposed_school_crest_info',
    CANCEL_ADMIN_PROPOSED_SCHOOL: 'cancel_admin_proposed_school',
    // Events
    CREATE_EVENT: 'create_event',
    CREATE_EVENT_SUCCESS: 'create_event_success',
    CREATE_EVENT_FAIL: 'create_event_fail',

    UPDATE_EVENT: 'update_event',
    UPDATE_EVENT_SUCCES: 'update_event_success',
    UPDATE_EVENT_FAIL: 'update_event_fail',

    DELETE_EVENT: 'delete_event',
    DELETE_EVENT_SUCCESS: 'delete_event_success',
    DELETE_EVENT_FAIL: 'delete_event_fail',

    FETCH_PROPOSED_SKILLS: 'fetch_proposed_skills',
    FETCH_PROPOSED_SKILLS_SUCCESS: 'fetch_proposed_skills_success',
    FETCH_PROPOSED_SKILLS_FAIL: 'fetch_proposed_skills_fail',

    SAVE_CLICKED_ADMIN_PROPOSED_SKILL: 'save_clicked_admin_proposed_skill',
    UPDATE_CLICKED_PROPOSED_SKILL: 'update_clicked_proposed_skill',
    CANCEL_ADMIN_PROPOSED_SKILL: 'cancel_admin_proposed_skill',

    APPROVE_PROPOSED_SKILL: 'approve_proposed_skill',
    APPROVE_PROPOSED_SKILL_SUCCESS: 'approve_proposed_skill_success',
    APPROVE_PROPOSED_SKILL_FAIL: 'approve_proposed_skill_fail',

    REJECT_PROPOSED_SKILL: 'reject_proposed_skill',
    REJECT_PROPOSED_SKILL_SUCCESS: 'reject_proposed_skill_success',
    REJECT_PROPOSED_SKILL_FAIL: 'reject_proposed_skill_fail',

    UPDATE_PROPOSED_SKILL_RELATED_SKILLS:
        'update_proposed_skill_related_skills',

    BAN_USER: 'ban_user',
    BAN_USER_SUCCESS: 'ban_user_success',
    BAN_USER_FAIL: 'ban_user_fail',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    reportsLoading: false,
    postsLoading: false,
    schoolsLoading: false,
    error: '',
    report: null,
    unfiledReportsArray: [],
    filedReportsArray: [],
    proposedSchoolsArray: [],
    postsInReviewArray: [],
    event: null,
    clickedPost: null,
    updatedPost: null,
    pressedReject: null,
    rejectReason: null,
    pressedApprove: null,
    suggestions: null,
    clickedProposedSchool: null,
    proposedSchoolCrestURI: null,
    proposedSchoolApproved: false,
    proposedSchoolRejected: false,
    proposedSkillsArray: null,
    clickedProposedSkill: null,
    proposedSkillApproved: false,
    proposedSkillRejected: false,
    proposedSkillRelatedSkills: null,
    bannedUser: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        // Reports
        case types.UNFILED_REPORTS_FETCH:
            return { ...state, reportsLoading: true };
        case types.UNFILED_REPORTS_FETCH_SUCCESS:
            return {
                ...state,
                reportsLoading: false,
                unfiledReportsArray: action.payload,
            };
        case types.UNFILED_REPORTS_FETCH_FAIL:
            return { ...state, reportsLoading: false, error: action.payload };

        case types.FILED_REPORTS_FETCH:
            return { ...state, reportsLoading: true };
        case types.FILED_REPORTS_FETCH_SUCCESS:
            return {
                ...state,
                reportsLoading: false,
                filedReportsArray: action.payload,
            };
        case types.FILED_REPORTS_FETCH_FAIL:
            return { ...state, reportsLoading: false, error: action.payload };

        case types.UPDATE_REPORT:
            return { ...state, loading: true };
        case types.UPDATE_REPORT_SUCCESS:
            return { ...state, loading: false, report: action.payload };
        case types.UPDATE_REPORT_FAIL:
            return { ...state, loading: false, error: action.payload };

        // Posts In Review
        case types.POSTS_IN_REVIEW_FETCH:
            return { ...state, postsLoading: true };
        case types.POSTS_IN_REVIEW_FETCH_SUCCESS:
            return {
                ...state,
                postsLoading: false,
                postsInReviewArray: action.payload,
            };
        case types.POSTS_IN_REVIEW_FETCH_FAIL:
            return { ...state, postsLoading: false, error: action.payload };

        case types.SAVE_CLICKED_ADMIN_POST:
            return { ...state, clickedPost: action.payload };
        case types.UPDATE_CLICKED_POST:
            return { ...state, clickedPost: action.payload };

        case types.APPROVE_POST:
            return { ...state, loading: true };
        case types.APPROVE_POST_SUCCESS:
            return { ...state, loading: false, updatedPost: action.payload };
        case types.APPROVE_POST_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.REJECT_POST:
            return { ...state, loading: true };
        case types.REJECT_POST_SUCCESS:
            return { ...state, loading: false, updatedPost: action.payload };
        case types.REJECT_POST_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPDATE_REJECT_STATUS:
            return {
                ...state,
                pressedReject: action.payload.status,
                rejectReason: action.payload.rejectReason,
            };

        case types.UPDATE_APPROVED_STATUS:
            return {
                ...state,
                pressedApprove: action.payload.status,
                suggestions: action.payload.suggestions,
            };

        // Proposed Schools
        case types.PROPOSED_SCHOOLS_FETCH:
            return { ...state, schoolsLoading: true };
        case types.PROPOSED_SCHOOLS_FETCH_SUCCESS:
            return {
                ...state,
                schoolsLoading: false,
                proposedSchoolsArray: action.payload,
            };
        case types.PROPOSED_SCHOOLS_FETCH_FAIL:
            return { ...state, schoolsLoading: false, error: action.payload };

        case types.SAVE_CLICKED_ADMIN_PROPOSED_SCHOOL:
            return { ...state, clickedProposedSchool: action.payload };
        case types.UPDATE_CLICKED_PROPOSED_SCHOOL:
            return { ...state, clickedProposedSchool: action.payload };
        case types.UPDATE_PROPOSED_SCHOOL_CREST_INFO:
            return { ...state, proposedSchoolCrestURI: action.payload };
        case types.CANCEL_ADMIN_PROPOSED_SCHOOL:
            return {
                ...state,
                clickedProposedSchool: null,
                proposedSchoolCrestURI: null,
                proposedSchoolApproved: false,
                proposedSchoolRejected: false,
            };

        case types.APPROVE_PROPOSED_SCHOOL:
            return { ...state, loading: true };
        case types.APPROVE_PROPOSED_SCHOOL_SUCCESS:
            return { ...state, proposedSchoolApproved: true, loading: false };
        case types.APPROVE_PROPOSED_SCHOOL_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.REJECT_PROPOSED_SCHOOL:
            return { ...state, loading: true };
        case types.REJECT_PROPOSED_SCHOOL_SUCCESS:
            return { ...state, loading: false, proposedSchoolRejected: true };
        case types.REJECT_PROPOSED_SCHOOL_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPLOAD_PROPOSED_SCHOOL_CREST:
            return { ...state, loading: true };
        case types.UPLOAD_PROPOSED_SCHOOL_CREST_SUCCESS:
            return { ...state, loading: false };
        case types.UPLOAD_PROPOSED_SCHOOL_CREST_FAIL:
            return { ...state, loading: false, error: action.payload };

        // Events
        case types.CREATE_EVENT || types.DELETE_EVENT || types.UPDATE_EVENT:
            return { ...state, loading: true };
        case types.CREATE_EVENT_SUCCESS ||
            types.DELETE_EVENT_SUCCESS ||
            types.UPDATE_EVENT_SUCCESS:
            return { ...state, loading: false, event: action.payload };
        case types.CREATE_EVENT_FAIL ||
            types.DELETE_EVENT_FAIL ||
            types.UPDATE_EVENT_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.FETCH_PROPOSED_SKILLS:
            return { ...state, loading: true };
        case types.FETCH_PROPOSED_SKILLS_SUCCESS:
            return {
                ...state,
                loading: false,
                proposedSkillsArray: action.payload,
            };
        case types.FETCH_PROPOSED_SKILLS_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.SAVE_CLICKED_ADMIN_PROPOSED_SKILL:
            return { ...state, clickedProposedSkill: action.payload };
        case types.UPDATE_CLICKED_PROPOSED_SKILL:
            return { ...state, clickedProposedSkill: action.payload };
        case types.CANCEL_ADMIN_PROPOSED_SKILL:
            return {
                ...state,
                clickedProposedSkill: null,
                proposedSkillApproved: false,
                proposedSkillRejected: false,
                proposedSkillRelatedSkills: [],
            };

        case types.APPROVE_PROPOSED_SKILL:
            return { ...state, loading: true };
        case types.APPROVE_PROPOSED_SKILL_SUCCESS:
            return { ...state, loading: false, proposedSkillApproved: true };
        case types.APPROVE_PROPOSED_SKILL_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.REJECT_PROPOSED_SKILLL:
            return { ...state, loading: true };
        case types.REJECT_PROPOSED_SKILL_SUCCESS:
            return { ...state, loading: false, proposedSkillRejected: true };
        case types.REJECT_PROPOSED_SKILL_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPDATE_PROPOSED_SKILL_RELATED_SKILLS:
            return { ...state, proposedSkillRelatedSkills: action.payload };

        case types.BAN_USER:
            return { ...state, loading: true };
        case types.BAN_USER_SUCCESS:
            return { ...state, loading: false, bannedUser: action.payload };
        case types.BAN_USER_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export const updateProjectInReview = ({ updatedPost }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_CLICKED_POST,
            payload: updatedPost,
        });
    };
};

export const approvePost = ({ updatedPost, suggestions, moderator_name }) => {
    return dispatch => {
        dispatch({ type: types.APPROVE_POST, payload: updatedPost });

        // let skill_ids = [];
        // let skill_names = [];

        // for (let i = 0; i < updatedPost.skill_ids.length; i++) {
        //     skill_ids.push(updatedPost.skill_ids[i].id);
        //     skill_names.push(updatedPost.skill_names[i].name);
        // }

        let approvedPost = {};
        approvedPost.id = updatedPost.id;
        approvedPost.owner_id = updatedPost.owner_id;
        if (updatedPost.company_id) {
            approvedPost.company_id = updatedPost.company_id;
        }
        approvedPost.owner_id = updatedPost.owner_id;
        approvedPost.post_type = updatedPost.post_type;
        approvedPost.geo_id = updatedPost.geo_id;
        approvedPost.project_type = updatedPost.project_type;
        approvedPost.title = updatedPost.title.trim();
        approvedPost.description = updatedPost.description.trim();
        approvedPost.skills = updatedPost.skills;
        approvedPost.skill_ids = updatedPost.skills.map(function(skill) {
            return skill['id'];
        });
        approvedPost.compensation = updatedPost.compensation;
        approvedPost.payment_frequency = updatedPost.payment_frequency;
        approvedPost.is_paid = updatedPost.is_paid;
        approvedPost.duration =
            updatedPost.duration === null || updatedPost.duration === ''
                ? null
                : updatedPost.duration;
        approvedPost.time_commitment =
            updatedPost.time_commitment === null ||
            updatedPost.time_commitment === ''
                ? null
                : updatedPost.timeCommitment;
        approvedPost.start_date = updatedPost.start_date;
        approvedPost.photos = updatedPost.photos;
        approvedPost.prefer_local = updatedPost.prefer_local;
        approvedPost.status = 'active';
        approvedPost.moderator_name = moderator_name.trim();

        let address;
        if (updatedPost.address) {
            address = updatedPost.address;
        }
        if (updatedPost.geoId) {
            approvedPost.geo_id = updatedPost.geoId;
        }

        WS.socket.emit('post:approve', {
            post: approvedPost,
            owner: updatedPost.owner,
            suggestions,
        });
        WS.socket.once('post:approve', res => {
            if (res.success) {
                approvePostSuccess(dispatch, res.data, address);
            } else {
                approvePostFail(dispatch, res.message);
            }
        });
    };
};
const approvePostSuccess = (dispatch, res, address) => {
    // AmplitudeAnalytics.track('Approve Post Success', res);
    dispatch({
        type: types.APPROVE_POST_SUCCESS,
        payload: res,
    });

    // create geo object for new address if one exists
    if (address) {
        dispatch(
            createGeo({
                address,
                relatedTo: { id: res.id, type: 'post' },
            })
        );
    }

    dispatch(postsInReviewFetch());
    // dispatch(
    //     NavigationActions.navigate({
    //         key: 'AdminPanel',
    //         routeName: 'AdminPanel',
    //     })
    // );
};
const approvePostFail = (dispatch, msg) => {
    dispatch({
        type: types.APPROVE_POST_FAIL,
        payload: msg,
    });
};

export const updateApprovedStatus = ({ status, suggestions }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_APPROVED_STATUS,
            payload: { status, suggestions },
        });
    };
};

export const updateRejectStatus = ({ status, rejectReason }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_REJECT_STATUS,
            payload: { status, rejectReason },
        });
    };
};

export const rejectPost = ({ updatedPost, rejectReason, moderator_name }) => {
    return dispatch => {
        dispatch({ type: types.REJECT_POST });

        let rejectedPost = {};
        rejectedPost.id = updatedPost.id;
        rejectedPost.owner_id = updatedPost.owner_id;
        rejectedPost.post_type = updatedPost.post_type;
        rejectedPost.geo_id = updatedPost.geo_id;
        rejectedPost.title = updatedPost.title.trim();
        rejectedPost.description = updatedPost.description.trim();
        rejectedPost.skills = updatedPost.skills;
        rejectedPost.payment_type = updatedPost.payment_type;
        rejectedPost.cost = updatedPost.cost;
        rejectedPost.num_hours = updatedPost.num_hours;
        rejectedPost.is_recurring = updatedPost.is_recurring;
        rejectedPost.expiration = updatedPost.expiration;
        rejectedPost.photos = updatedPost.photo_names;
        rejectedPost.prefer_local = updatedPost.prefer_local;
        rejectedPost.status = 'rejected';
        rejectedPost.moderator_name = moderator_name.trim();
        WS.socket.emit('post:reject', {
            post: rejectedPost,
            owner: updatedPost.owner,
            reject_reason: rejectReason,
        });
        WS.socket.once('post:reject', res => {
            if (res.success) {
                rejectPostSuccess(dispatch, res.data);
            } else {
                rejectPostFail(dispatch, res.message);
            }
        });
    };
};
const rejectPostSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Reject Post Success', res);
    dispatch({
        type: types.REJECT_POST_SUCCESS,
        payload: res,
    });

    dispatch(postsInReviewFetch());
    // dispatch(
    //     NavigationActions.navigate({
    //         key: 'AdminPanel',
    //         routeName: 'AdminPanel',
    //     })
    // );
};
const rejectPostFail = (dispatch, msg) => {
    dispatch({
        type: types.REJECT_POST_FAIL,
        payload: msg,
    });
};

// Reports
export const fetchUnfiledReports = () => {
    return dispatch => {
        dispatch({ type: types.UNFILED_REPORTS_FETCH });
        WS.socket.emit('report:fetchUnfiled', {});
        WS.socket.once('report:fetchUnfiled', res => {
            if (res.success) {
                fetchUnfiledReportsSuccess(dispatch, res.data);
            } else {
                fetchUnfiledReportsFail(dispatch, res.message);
            }
        });
    };
};
const fetchUnfiledReportsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch Unfiled Reports Success', res);
    dispatch({
        type: types.UNFILED_REPORTS_FETCH_SUCCESS,
        payload: res,
    });
};
const fetchUnfiledReportsFail = (dispatch, msg) => {
    // Sentry.captureMessage('fetchUnfiledReportsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UNFILED_REPORTS_FETCH_FAIL,
        payload: msg,
    });
};

export const fetchFiledReports = () => {
    return dispatch => {
        dispatch({ type: types.FILED_REPORTS_FETCH });
        WS.socket.emit('report:fetchUnfiled', {});
        WS.socket.once('report:fetchUnfiled', res => {
            if (res.success) {
                fetchFiledReportsSuccess(dispatch, res.data);
            } else {
                fetchFiledReportsFail(dispatch, res.message);
            }
        });
    };
};
const fetchFiledReportsSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch Filed Reports Success', res);
    dispatch({
        type: types.FILED_REPORTS_FETCH_SUCCESS,
        payload: res,
    });
};
const fetchFiledReportsFail = (dispatch, msg) => {
    // Sentry.captureMessage('fetchUnfiledReportsFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.FILED_REPORTS_FETCH_FAIL,
        payload: msg,
    });
};

export const reportUpdate = ({ report }) => {
    return dispatch => {
        dispatch({ type: types.UPDATE_REPORT });
        WS.socket.emit('report:update', { report });
        WS.socket.once('report:update', res => {
            if (res.success) {
                reportUpdateSuccess(dispatch, res.data);
            } else {
                reportUpdateFail(dispatch, res.message);
            }
        });
    };
};
const reportUpdateSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Report Update Success', res);
    dispatch({
        type: types.UPDATE_REPORT_SUCCESS,
        payload: res,
    });
    dispatch(fetchFiledReports());
    dispatch(fetchUnfiledReports());
};
const reportUpdateFail = (dispatch, msg) => {
    // Sentry.captureMessage('reportUpdateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_REPORT_FAIL,
        payload: msg,
    });
};

// Posts In Review
export const postsInReviewFetch = () => {
    return dispatch => {
        dispatch({ type: types.POSTS_IN_REVIEW_FETCH });
        WS.socket.emit('post:fetchPostsInReview', {});
        WS.socket.once('post:fetchPostsInReview', res => {
            if (res.success) {
                postsInReviewFetchSuccess(dispatch, res.data);
            } else {
                postsInReviewFetchFail(dispatch, res.message);
            }
        });
    };
};
const postsInReviewFetchSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch Posts In Review Success', res);
    dispatch({
        type: types.POSTS_IN_REVIEW_FETCH_SUCCESS,
        payload: res,
    });
};
const postsInReviewFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('postsInReviewFetchFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.POSTS_IN_REVIEW_FETCH_FAIL,
        payload: msg,
    });
};

export const saveClickedPost = ({ post }) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_CLICKED_ADMIN_POST,
            payload: post,
        });
    };
};

// Proposed Schools
export const proposedSchoolsFetch = () => {
    return dispatch => {
        dispatch({ type: types.PROPOSED_SCHOOLS_FETCH });
        WS.socket.emit('proposedSchool:fetchPending', {});
        WS.socket.once('proposedSchool:fetchPending', res => {
            if (res.success) {
                proposedSchoolsFetchSuccess(dispatch, res.data);
            } else {
                proposedSchoolsFetchFail(dispatch, res.message);
            }
        });
    };
};
const proposedSchoolsFetchSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Fetch Proposed Schools Success', res);
    dispatch({
        type: types.PROPOSED_SCHOOLS_FETCH_SUCCESS,
        payload: res,
    });
};
const proposedSchoolsFetchFail = (dispatch, msg) => {
    // Sentry.captureMessage('proposedSchoolsFetchFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.PROPOSED_SCHOOLS_FETCH_FAIL,
        payload: msg,
    });
};

export const saveClickedProposedSchool = ({ proposedSchool }) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_CLICKED_ADMIN_PROPOSED_SCHOOL,
            payload: proposedSchool,
        });
    };
};

export const updateProposedSchoolInReview = ({ proposedSchool }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_CLICKED_PROPOSED_SCHOOL,
            payload: proposedSchool,
        });
    };
};

export const updateProposedSchoolCrestInfo = ({ proposedSchoolCrestURI }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROPOSED_SCHOOL_CREST_INFO,
            payload: proposedSchoolCrestURI,
        });
    };
};

export const cancelAdminProposedSchool = () => {
    return dispatch => {
        dispatch({
            type: types.CANCEL_ADMIN_PROPOSED_SCHOOL,
        });
    };
};

export const approveProposedSchool = ({
    proposedSchool,
    proposedSchoolCrestURI,
}) => {
    return dispatch => {
        dispatch({ type: types.APPROVE_PROPOSED_SCHOOL });
        WS.socket.emit('admin:approveProposedSchool', {
            proposedSchool,
        });
        WS.socket.once('admin:approveProposedSchool', res => {
            if (res.success) {
                approveProposedSchoolSuccess(
                    dispatch,
                    res.data,
                    proposedSchoolCrestURI
                );
            } else {
                approveProposedSchoolFail(dispatch, res.message);
            }
        });
    };
};
const approveProposedSchoolSuccess = (
    dispatch,
    data,
    proposedSchoolCrestURI
) => {
    // AmplitudeAnalytics.track('Approve Proposed School Success', data);
    dispatch({
        type: types.APPROVE_PROPOSED_SCHOOL_SUCCESS,
        payload: data,
    });
    uploadProposedSchoolCrest(dispatch, data.school, proposedSchoolCrestURI);
    // dispatch(proposedSchoolsFetch());
};
const approveProposedSchoolFail = (dispatch, msg) => {
    // Sentry.captureMessage('approveProposedSchoolFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.APPROVE_PROPOSED_SCHOOL_FAIL,
        payload: msg.message,
    });
};

const uploadProposedSchoolCrest = (dispatch, school, imageUri) => {
    dispatch({
        type: types.UPLOAD_PROPOSED_SCHOOL_CREST,
        payload: imageUri,
    });

    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;
    const uri = imageUri;
    const uriParts = uri.split('.');
    const fileType = uriParts[uriParts.length - 1];
    const formData = new FormData();
    formData.append('image', {
        uri,
        type: `image/${fileType}`,
        name: school.id,
        originalname: school.id,
    });

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'file-path': 'Schools/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            // AmplitudeAnalytics.track('Proposed School Upload Crest Success', {
            //     'image:url': results.imageUrl,
            // });

            dispatch({
                type: types.UPLOAD_PROPOSED_SCHOOL_CREST_SUCCESS,
                payload: results.imageUrl,
            });
        })
        .catch(error => {
            dispatch({
                type: types.UPLOAD_PROPOSED_SCHOOL_CREST_FAIL,
                payload: error,
            });
        });
};

export const rejectProposedSchool = ({ proposedSchool }) => {
    return dispatch => {
        dispatch({ type: types.REJECT_PROPOSED_SCHOOL });
        WS.socket.emit('admin:rejectProposedSchool', { proposedSchool });
        WS.socket.once('admin:rejectProposedSchool', res => {
            if (res.success) {
                rejectProposedSchoolSuccess(dispatch, res.data);
            } else {
                rejectProposedSchoolFail(dispatch, res.message);
            }
        });
    };
};

const rejectProposedSchoolSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Reject Proposed School Success', res);
    dispatch({
        type: types.REJECT_PROPOSED_SCHOOL_SUCCESS,
        payload: res,
    });
    dispatch(proposedSchoolsFetch());
};

const rejectProposedSchoolFail = (dispatch, msg) => {
    // Sentry.captureMessage('rejectProposedSchoolFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.REJECT_PROPOSED_SCHOOL_FAIL,
        payload: msg.message,
    });
};

// Events
export const createEvent = ({ title, description, date, ownerId }) => {
    return dispatch => {
        dispatch({ type: types.CREATE_EVENT });
        WS.socket.emit('event:create', {
            owner_id: ownerId,
            title: title.trim(),
            description: description.trim(),
            date,
        });
        WS.socket.once('event:create', res => {
            if (res.success) {
                createEventSuccess(dispatch, res.data);
            } else {
                createEventFail(dispatch, res.message);
            }
        });
    };
};
const createEventSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Create Event Success', res);
    dispatch({
        type: types.CREATE_EVENT_SUCCESS,
        payload: res,
    });
};
const createEventFail = (dispatch, msg) => {
    // Sentry.captureMessage('createEventFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.CREATE_EVENT_FAIL,
        payload: msg,
    });
};

export const updateEvent = event => {
    return dispatch => {
        dispatch({ type: types.UPDATE_EVENT });
        WS.socket.emit('event:update', event);
        WS.socket.once('event:update', res => {
            if (res.success) {
                updateEventSuccess(dispatch, res.data);
            } else {
                updateEventFail(dispatch, res.message);
            }
        });
    };
};
const updateEventSuccess = (dispatch, res, filter) => {
    // AmplitudeAnalytics.track('Update Event Success', res);
    dispatch({
        type: types.UPDATE_EVENT_SUCCESS,
        payload: res,
    });
};
const updateEventFail = (dispatch, msg) => {
    // Sentry.captureMessage('updateEventFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.UPDATE_EVENT_FAIL,
        payload: msg,
    });
};

export const deleteEvent = event => {
    return dispatch => {
        dispatch({ type: types.DELETE_EVENT });

        WS.socket.emit('event:delete', event);
        WS.socket.once('event:delete', res => {
            if (res.success) {
                deleteEventSuccess(dispatch, res.data);
            } else {
                deleteEventFail(dispatch, res.message);
            }
        });
    };
};
const deleteEventSuccess = (dispatch, res, filter) => {
    // AmplitudeAnalytics.track('Delete Event Success', res);
    dispatch({
        type: types.DELETE_EVENT_SUCCESS,
        payload: res,
    });
};
const deleteEventFail = (dispatch, msg) => {
    // Sentry.captureMessage('deleteEventFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.DELETE_EVENT_FAIL,
        payload: msg,
    });
};

export const fetchProposedSkills = () => {
    return dispatch => {
        dispatch({ type: types.FETCH_PROPOSED_SKILLS });

        WS.socket.emit('admin:fetchProposedSkills', {});
        WS.socket.once('admin:fetchProposedSkills', res => {
            if (res.success) {
                dispatch({
                    type: types.FETCH_PROPOSED_SKILLS_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.FETCH_PROPOSED_SKILLS_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};

export const saveClickedProposedSkill = ({ proposedSkill }) => {
    return dispatch => {
        dispatch({
            type: types.SAVE_CLICKED_ADMIN_PROPOSED_SKILL,
            payload: proposedSkill,
        });
    };
};

export const updateProposedSkillInReview = ({ proposedSkill }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_CLICKED_PROPOSED_SKILL,
            payload: proposedSkill,
        });
    };
};

export const cancelAdminProposedSkill = () => {
    return dispatch => {
        dispatch({
            type: types.CANCEL_ADMIN_PROPOSED_SKILL,
        });
    };
};

export const approveProposedSkill = ({ proposedSkill, relatedSkills }) => {
    return dispatch => {
        dispatch({ type: types.APPROVE_PROPOSED_SKILL });
        WS.socket.emit('admin:approveProposedSkill', {
            proposedSkill,
            relatedSkills,
        });
        WS.socket.once('admin:approveProposedSkill', res => {
            if (res.success) {
                approveProposedSkillSuccess(dispatch, res.data);
            } else {
                approveProposedSkillFail(dispatch, res.message);
            }
        });
    };
};
const approveProposedSkillSuccess = (dispatch, data) => {
    // AmplitudeAnalytics.track('Approve Proposed Skill Success', data);
    dispatch({
        type: types.APPROVE_PROPOSED_SKILL_SUCCESS,
        payload: data,
    });

    // don't navigate here - in onPressOk in popover on adminProposedSkillScreen

    dispatch(fetchProposedSkills());
};
const approveProposedSkillFail = (dispatch, msg) => {
    // Sentry.captureMessage('approveProposedSkillFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.APPROVE_PROPOSED_SKILL_FAIL,
        payload: msg.message,
    });
};

export const rejectProposedSkill = ({ proposedSkill }) => {
    return dispatch => {
        dispatch({ type: types.REJECT_PROPOSED_SKILL });
        WS.socket.emit('admin:rejectProposedSkill', { proposedSkill });
        WS.socket.once('admin:rejectProposedSkill', res => {
            if (res.success) {
                rejectProposedSkillSuccess(dispatch, res.data);
            } else {
                rejectProposedSkillFail(dispatch, res.message);
            }
        });
    };
};

const rejectProposedSkillSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Reject Proposed Skill Success', res);
    dispatch({
        type: types.REJECT_PROPOSED_SKILL_SUCCESS,
    });

    dispatch(fetchProposedSkills());
};

const rejectProposedSkillFail = (dispatch, msg) => {
    // Sentry.captureMessage('rejectProposedSkillFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.REJECT_PROPOSED_SKILL_FAIL,
        payload: msg.message,
    });
};

export const adminUpdateRelatedSkills = ({ proposedSkillRelatedSkills }) => {
    return dispatch => {
        dispatch({
            type: types.UPDATE_PROPOSED_SKILL_RELATED_SKILLS,
            payload: proposedSkillRelatedSkills,
        });
    };
};

export const banUser = ({ bannedUser }) => {
    return dispatch => {
        dispatch({ type: types.BAN_USER });
        WS.socket.emit('admin:banUser', { bannedUser });
        WS.socket.once('admin:banUser', res => {
            if (res.success) {
                dispatch({
                    type: types.BAN_USER_SUCCESS,
                    payload: res.data,
                });
            } else {
                dispatch({
                    type: types.BAN_USER_FAIL,
                    payload: res.message,
                });
            }
        });
    };
};
