import React from 'react';
import Colors from '../../constants/Colors';
import { Grid } from '@material-ui/core';

/*
 * This file contains a common component for header which allows text, a left icon,
 * a right icon, or any combination of the three to be passed in such that they
 * will all be correctly aligned. Left and right icons must have width less than
 * 65px and must already have a onPress method attached to them. See end of document
 * and ProfileScreen.js for implementation guidelines.
 */

const styles = {
    container: {
        paddingTop: 40,
        paddingBottom: 60,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: '#1d252d',
    },
    link: {
        paddingBottom: 10,
        color: '#d4d6e4',
    },
    footerHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    headerText: { color: '#fff', marginRight: 15 },
    contactButton: {
        borderRadius: 20,
        border: 'solid 1px #1d252d',
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: '#fff',
        color: '#000',
    },
    footerSection: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
    },
    footerSectionRightAlign: {
        display: 'flex',
        flexDirection: 'column',
        color: '#fff',
        textAlign: 'right',
    },
    sectionTitle: {
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 600,
    },
    divider: {
        borderTop: '1px solid rgba(155, 158, 184, 0.26)',
    },
    dividerLight: {
        borderBottom: '1px solid #C7C7CC',
    },
    containerLight: {
        paddingTop: 40,
        paddingBottom: 60,
        paddingRight: '5%',
        paddingLeft: '5%',
        backgroundColor: Colors.lightGray,
    },
    linkLight: {
        paddingBottom: 10,
        color: Colors.fontBlack,
    },
    footerHeaderLight: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    hr: {
        color: Colors.fontBlack,
    },
    headerTextLight: { color: Colors.fontBlack, marginRight: 15 },
    contactButtonLight: {
        borderRadius: 20,
        border: 'solid 1px #1d252d',
        paddingRight: 20,
        paddingLeft: 20,
        paddingTop: 10,
        paddingBottom: 10,
        backgroundColor: Colors.lightGray,
        // color: '#000',
    },
    footerSectionLight: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.fontBlack,
    },
    footerSectionRightAlignLight: {
        display: 'flex',
        flexDirection: 'column',
        color: Colors.fontBlack,
        textAlign: 'right',
    },
    sectionTitleLight: {
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 600,
        color: Colors.fontBlack,
    },
};

const Footer = props => (
    <div>
        <div style={props.light ? styles.dividerLight : styles.divider} />
        <div style={props.light ? styles.containerLight : styles.container}>
            <div
                style={
                    props.light ? styles.footerHeaderLight : styles.footerHeader
                }
            >
                <a href="/">
                    {props.light ? (
                        <img
                            src={require('../../assets/images/pangea/pangea-wordmark-gray.png')}
                            height={48}
                            width={180}
                            alt="Pangea Logo"
                            resizeMode="contain"
                            objectFit="cover"
                            style={{ marginBottom: 20 }}
                        />
                    ) : (
                        <img
                            src={require('../../assets/images/pangea/full-logo.png')}
                            height={69.7}
                            width={204}
                            alt="Pangea Logo"
                            resizeMode="contain"
                            objectFit="cover"
                            // style={{ resizeMode: 'cover' }}
                        />
                    )}
                </a>

                <div>
                    <span
                        style={
                            props.light
                                ? styles.headerTextLight
                                : styles.headerText
                        }
                    >
                        Want to get in touch?
                    </span>
                    <input
                        type="button"
                        style={
                            props.light
                                ? styles.contactButtonLight
                                : styles.contactButton
                        }
                        value="Contact Us"
                    ></input>
                </div>
            </div>

            <hr style={styles.hr} />
            <Grid container spacing={2}>
                <Grid item md={2}>
                    <div
                        style={
                            props.light
                                ? styles.footerSectionLight
                                : styles.footerSection
                        }
                    >
                        <span
                            style={
                                props.light
                                    ? styles.sectionTitleLight
                                    : styles.sectionTitle
                            }
                        >
                            Pangea.app
                        </span>

                        <a
                            href="/"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Home
                        </a>
                        <a
                            href="/download"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Download
                        </a>
                        <a
                            href="/about"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            About
                        </a>
                    </div>
                </Grid>

                <Grid item md={2}>
                    <div
                        style={
                            props.light
                                ? styles.footerSectionLight
                                : styles.footerSection
                        }
                    >
                        <span
                            style={
                                props.light
                                    ? styles.sectionTitleLight
                                    : styles.sectionTitle
                            }
                        >
                            Social Media
                        </span>

                        <a
                            href="https://www.instagram.com/pangea.app/?hl=en"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Instagram
                        </a>
                        <a
                            href="https://www.linkedin.com/company/app-pangea/"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            LinkedIn
                        </a>
                        <a
                            href="https://medium.com/pangea-app"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Medium
                        </a>
                    </div>
                </Grid>

                <Grid item md={2}>
                    <div
                        style={
                            props.light
                                ? styles.footerSectionLight
                                : styles.footerSection
                        }
                    >
                        <span
                            style={
                                props.light
                                    ? styles.sectionTitleLight
                                    : styles.sectionTitle
                            }
                        >
                            Legal
                        </span>

                        <a
                            href="/termsOfService"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Terms of Service
                        </a>
                        <a
                            href="/privacyPolicy"
                            style={props.light ? styles.linkLight : styles.link}
                        >
                            Privacy Policy
                        </a>
                    </div>
                </Grid>

                <Grid item md={6} direction="row-reverse">
                    <div
                        style={
                            props.light
                                ? styles.footerSectionRightAlignLight
                                : styles.footerSectionRightAlign
                        }
                    >
                        <span
                            style={
                                props.light
                                    ? styles.sectionTitleLight
                                    : styles.sectionTitle
                            }
                        >
                            Address
                        </span>

                        <a
                            style={props.light ? styles.linkLight : styles.link}
                            href="https://goo.gl/maps/Z7Yegsv361un6UVF6"
                        >
                            225 Dyer St, 2nd Floor
                            <br></br>
                            Providence, RI 02903
                        </a>
                    </div>
                </Grid>
            </Grid>
        </div>
    </div>
);

export default Footer;
