// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import { NavigationActions } from 'react-navigation';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
// import { Analytics, Event } from 'expo-analytics';
// import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    // Messenger
    INITIALIZE_ROOM: 'initialize_room',
    INITIALIZE_ROOM_SUCCESS: 'initialize_room_success',

    ROOM_CREATE: 'room_create',
    ROOM_CREATE_SUCCESS: 'room_create_success',
    ROOM_CREATE_FAIL: 'room_create_fail',

    ROOM_UPDATE: 'room_update',
    ROOM_UPDATE_SUCCESS: 'room_update_success',
    ROOM_UPDATE_FAIL: 'room_update_fail',

    ROOM_SET_TRANSACTION_ID: 'room_set_transaction_id',
    ROOM_SET_TRANSACTION_ID_SUCCESS: 'room_set_transaction_id_success',
    ROOM_SET_TRANSACTION_ID_FAIL: 'room_set_transaction_id_fail',

    ROOM_EMBED: 'room_embed',
    ROOM_EMBED_SUCCESS: 'room_embed_success',
    ROOM_EMBED_FAIL: 'room_embed_fail',

    SAVE_CLICKED_ROOM: 'save_room',
    SAVE_CLICKED_ROOM_SUCCESS: 'save_room_success',

    CLEAR_CLICKED_ROOM: 'clear_clicked_room',

    MESSAGE_CREATE: 'message_create',
    MESSAGE_CREATE_SUCCESS: 'message_create_success',
    MESSAGE_CREATE_FAIL: 'message_create_fail',
    MESSAGE_RECEIVE: 'message_receive',

    ROOM_SUBSCRIBE: 'room_subscribe',
    ROOM_SUBSCRIBE_SUCCESS: 'room_subscribe_success',
    MESSAGE_RECEIVED_UPDATE: 'message_received_update',

    // Transaction
    TRANSACTIONS_FETCH: 'transactions_fetch',
    TRANSACTIONS_FETCH_SUCCESS: 'transactions_fetch_success',
    TRANSACTIONS_FETCH_FAIL: 'transactions_fetch_fail',

    CHARGE: 'charge',
    CHARGE_SUCCESS: 'charge_success',
    CHARGE_FAIL: 'charge_fail',

    TRANSACTION_CREATE: 'transaction_create',
    TRANSACTION_CREATE_SUCCESS: 'transaction_create_success',
    TRANSACTION_CREATE_FAIL: 'transaction_create_fail',

    UPDATE_TRANSACTION: 'update_transaction',
    UPDATE_TRANSACTION_SUCCESS: 'update_transaction_success',
    UPDATE_TRANSACTION_FAIL: 'update_transaction_fail',

    TRANSACTION_SET_INVOICE_ID: 'transaction_set_invoice_id',
    TRANSACTION_SET_INVOICE_ID_SUCCESS: 'transaction_set_invoice_id_success',
    TRANSACTION_SET_INVOICE_ID_FAIL: 'transaction_set_invoice_id_fail',

    TRANSACTION_SUBSCRIBE: 'transaction_subscribe',
    TRANSACTION_SUBSCRIBE_SUCCESS: 'transaction_subscribe_success',

    CREATE_INVOICE: 'create_invoice',
    CREATE_INVOICE_SUCCESS: 'create_invoice_success',
    CREATE_INVOICE_FAIL: 'create_invoice_fail',

    UPDATE_INVOICE: 'update_invoice',
    UPDATE_INVOICE_SUCCESS: 'update_invoice_success',
    UPDATE_INVOICE_FAIL: 'update_invoice_fail',

    GET_MESSENGER_SCREEN_DATA: 'get_messenger_screen_data',
    GET_MESSENGER_SCREEN_DATA_FAIL: 'get_messenger_screen_data_fail',

    SAVE_RELATED_ROOM: 'save_related_room',
    SAVE_RELATED_ROOM_SUCCESS: 'save_related_room_success',
    SAVE_RELATED_ROOM_FAIL: 'save_related_room_success_fail',

    SAVE_ASSOCIATED_POST: 'SAVE_ASSOCIATED_POST',

    SEND_PAYMENT_REQUEST: 'SEND_PAYMENT_REQUEST',
    SEND_PAYMENT_REQUEST_SUCCESS: 'SEND_PAYMENT_REQUEST_SUCCESS',
    SEND_PAYMENT_REQUEST_FAIL: 'SEND_PAYMENT_REQUEST_FAIL',

    RESET_PAYMENT_REQUEST_STATUS: 'RESET_PAYMENT_REQUEST_STATUS',
};

const INITIAL_STATE = {
    loading: false,
    messageCreateLoading: false,
    requestPaymentLoading: false,
    paymentRequestSuccess: false,
    paymentRequestError: '',
    clickedRoom: null,
    error: '',
    unreadMessagesCount: 0,
    paymentLoading: false,
    transactionsArray: [],
    paidTransaction: null,
    associated_post: null,
};

// ------------------------------------ REDUCER ------------------------------------
export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.INITIALIZE_ROOM:
            return { ...state, loading: true };
        case types.INITIALIZE_ROOM_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };

        case types.ROOM_CREATE:
            return { ...state, loading: true };
        case types.ROOM_CREATE_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };
        case types.ROOM_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.ROOM_SUBSCRIBE:
            return { ...state };
        case types.ROOM_SUBSCRIBE_SUCCESS:
            if (
                state.clickedRoom &&
                state.clickedRoom.id === action.payload[0].room_id
            ) {
                return {
                    ...state,
                    loading: false,
                    clickedRoom: {
                        ...state.clickedRoom,
                        messages: [
                            ...action.payload,
                            ...state.clickedRoom.messages,
                        ],
                    },
                };
            } else {
                return { ...state, loading: false };
            }

        case types.SAVE_CLICKED_ROOM:
            return { ...state, loading: true };
        case types.SAVE_CLICKED_ROOM_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };

        case types.CLEAR_CLICKED_ROOM:
            return { ...state, loading: false, clickedRoom: null };

        case types.ROOM_UPDATE:
            return { ...state, loading: false };
        case types.ROOM_UPDATE_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };
        case types.ROOM_UPDATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.ROOM_SET_TRANSACTION_ID:
            return { ...state, loading: true };
        case types.ROOM_SET_TRANSACTION_ID_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload[0] };
        case types.ROOM_SET_TRANSACTION_ID_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.MESSAGE_CREATE:
            return { ...state, messageCreateLoading: true };
        case types.MESSAGE_CREATE_SUCCESS:
            return {
                ...state,
                messageCreateLoading: false,
            }; /* messagesArray: action.payload*/
        case types.MESSAGE_CREATE_FAIL:
            return {
                ...state,
                messageCreateLoading: false,
                error: action.payload,
            };

        case types.MESSAGE_RECEIVE:
            return { ...state, msg: action.payload };

        // Transaction
        case types.TRANSACTIONS_FETCH:
            return { ...state, loading: true };
        case types.TRANSACTIONS_FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                transactionsArray: action.payload,
            };
        case types.TRANSACTIONS_FETCH_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.CHARGE:
            return { ...state, paymentLoading: true, error: '' };
        case types.CHARGE_SUCCESS:
            return {
                ...state,
                paymentLoading: false,
                paidTransaction: action.payload,
            };
        case types.CHARGE_FAIL:
            return {
                ...state,
                paymentLoading: false,
                error: action.payload
                    ? action.payload
                    : 'unable to complete transaction',
            };

        case types.TRANSACTION_CREATE:
            return { ...state, loading: true };
        case types.TRANSACTION_CREATE_SUCCESS:
            return { ...state, loading: false, transaction: action.payload[0] };
        case types.TRANSACTION_CREATE_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPDATE_TRANSACTION:
            return { ...state, loading: true };
        case types.UPDATE_TRANSACTION_SUCCESS:
            return { ...state, loading: false, transaction: action.payload };
        case types.UPDATE_TRANSACTION_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.TRANSACTION_SET_INVOICE_ID:
            return { ...state, loading: true };
        case types.TRANSACTION_SET_INVOICE_ID_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload[0] };
        case types.TRANSACTION_SET_INVOICE_ID_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.TRANSACTION_SUBSCRIBE:
            return { ...state, loading: true };
        case types.TRANSACTION_SUBSCRIBE_SUCCESS:
            return {
                ...state,
                loading: false,
                clickedRoom: {
                    ...state.clickedRoom,
                    associated_transaction: {
                        ...state.clickedRoom.associated_transaction,
                        ...action.payload[0],
                    },
                },
            };

        // ----------------------- UNUSED CODE -----------------------
        case types.ROOM_EMBED:
            return { ...state, loading: true };
        case types.ROOM_EMBED_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };
        case types.ROOM_EMBED_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.GET_MESSENGER_SCREEN_DATA:
            return { ...state, loading: true };
        case types.GET_MESSENGER_SCREEN_DATA_Fail:
            return { ...state, loading: false };

        case types.RESET_MESSAGES_ARRAY:
            return {
                ...state,
                loading: false,
                clickedRoom: { ...state.clickedRoom, messages: [] },
            };

        case types.SAVE_RELATED_ROOM:
            return { ...state, loading: true };
        case types.SAVE_RELATED_ROOM_SUCCESS:
            return { ...state, loading: false, clickedRoom: action.payload };
        case types.SAVE_RELATED_ROOM_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.SAVE_ASSOCIATED_POST:
            return { ...state, associated_post: action.payload };
        case types.SAVE_OTHER_USER:
            return { ...state, otherUser: action.payload };

        case types.SEND_PAYMENT_REQUEST:
            return { ...state, requestPaymentLoading: true };
        case types.SEND_PAYMENT_REQUEST_SUCCESS:
            return {
                ...state,
                requestPaymentLoading: false,
                paymentRequestSuccess: true,
            };
        case types.SEND_PAYMENT_REQUEST_FAIL:
            return {
                ...state,
                requestPaymentLoading: false,
                paymentRequestError: action.payload,
            };

        case types.RESET_PAYMENT_REQUEST_STATUS:
            return {
                ...state,
                paymentRequestSuccess: false,
                paymentRequestError: '',
            };
        default:
            return state;
    }
};

export const initializeRoom = ({ room }) => {
    return dispatch => {
        dispatch({ type: types.INITIALIZE_ROOM });
        dispatch({ type: types.INITIALIZE_ROOM_SUCCESS, payload: room });
    };
};

// ------------------------------------ ACTIONS ------------------------------------
export const saveClickedRoom = ({ room }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_ROOM });
        dispatch({ type: types.SAVE_CLICKED_ROOM_SUCCESS, payload: room });
    };
};

export const clearClickedRoom = () => {
    return dispatch => {
        dispatch({ type: types.CLEAR_CLICKED_ROOM });
    };
};

// -------------------------------------- CHAINING GET MESSENGER DATA --------------------------------
export const getMessengerScreenData = ({
    room_id,
    transaction_id,
    user_id,
}) => {
    return dispatch => {
        dispatch({
            type: types.GET_MESSENGER_SCREEN_DATA,
            payload: { room_id, transaction_id, user_id },
        });
        if (
            room_id === undefined ||
            /* transaction_id === undefined ||*/ user_id === undefined
        ) {
            dispatch({ type: types.GET_MESSENGER_SCREEN_DATA_FAIL });
        } else {
            dispatch({ type: types.ROOM_EMBED });

            WS.socket.emit('room:embed', {
                room: { id: room_id },
                user_id: user_id,
            });
            WS.socket.once('room:embed', res => {
                if (res.success) {
                    roomEmbedMessengerSuccess(
                        dispatch,
                        res.data,
                        room_id,
                        transaction_id,
                        user_id
                    );
                } else {
                    roomEmbedMessengerFail(dispatch, res.msg);
                }
            });
        }
    };
};
const roomEmbedMessengerSuccess = (
    dispatch,
    room,
    room_id,
    transaction_id,
    user_id
) => {
    dispatch({ type: types.ROOM_EMBED_SUCCESS, payload: room });

    if (room.related_to.type === 'project') {
        WS.socket.emit('post:embed', {
            post_id: room.related_to.id,
        });
        WS.socket.once('post:embed', res => {
            if (res.success) {
                dispatch({
                    type: types.SAVE_ASSOCIATED_POST,
                    payload: res.data,
                });
                dispatch(roomSubscribeMessenger({ room_id, user_id }));
            } else {
                console.log('post embed FAIL');
            }
        });
    } else {
        dispatch(roomSubscribeMessenger({ room_id, user_id }));
    }
};
const roomEmbedMessengerFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomEmbedMessengerFail', {
    //     level: 'warning',
    // });
    dispatch({ type: types.ROOM_EMBED_FAIL, payload: msg });
};
const roomSubscribeMessenger = ({ room_id, user_id }) => {
    return dispatch => {
        dispatch({ type: types.ROOM_SUBSCRIBE, payload: { room_id, user_id } });
        WS.socket.emit('room:enter', { room_id, user_id });
        let hasListener = WS.socket.hasListeners(`message:in:${room_id}`);
        if (hasListener) {
        } else {
            WS.socket.on(`message:in:${room_id}`, res => {
                messageInSuccess(dispatch, res, user_id);
            });
        }
    };
};
const messageInSuccess = (dispatch, messageArr, user_id) => {
    dispatch({
        type: types.ROOM_SUBSCRIBE_SUCCESS,
        payload: messageArr,
    });
    for (let message of messageArr) {
        if (user_id === message.to) {
            dispatch(changeMessageToReceived(message));
        }
    }
};
// const transactionSubscribeMessenger = ({ transaction_id, user_id }) => {
//     return dispatch => {
//         let hasListener = WS.socket.hasListeners(
//             `transaction:change:${transaction_id}`
//         );
//         if (hasListener) {
//         } else {
//             dispatch({ type: types.TRANSACTION_SUBSCRIBE });
//             WS.socket.emit('transaction:subscribe', {
//                 transaction_id,
//                 user_id,
//             });
//             WS.socket.on(`transaction:change:${transaction_id}`, res => {
//                 transactionSubscribeSuccess(dispatch, res);
//             });
//         }
//     };
// };
// const transactionSubscribeSuccess = (dispatch, transaction) => {
//     dispatch({
//         type: types.TRANSACTION_SUBSCRIBE_SUCCESS,
//         payload: transaction,
//     });
// };
// -------------------------------------- CHAINING GET MESSENGER DATA --------------------------------

// -------------------------------------- CHAINING ADD MESSENGER DATA --------------------------------
export const addMessengerScreenData = ({
    room,
    firstMessage,
    transaction,
    user,
}) => {
    return dispatch => {
        dispatch({
            type: types.ROOM_CREATE,
            payload: { room, firstMessage, transaction, user },
        });
        WS.socket.emit('room:create', {
            owner_id: room.owner_id,
            responder_id: room.responder_id,
            seller_id: room.seller_id,
            buyer_id: room.buyer_id,
            related_to: room.related_to,
            message_ids: [],
            title: room.title,
            most_recent_message: firstMessage.text,
            owner_name: room.owner_name,
            owner_profile_pic: room.owner_profile_pic,
            responder_name: room.responder_name,
            responder_profile_pic: room.responder_profile_pic,
        });
        WS.socket.once('room:create', res => {
            if (res.success) {
                roomCreateSuccess(
                    dispatch,
                    res.data,
                    firstMessage,
                    transaction,
                    user
                );
            } else {
                roomCreateFail(dispatch, res.msg);
            }
        });
    };
};
const roomCreateSuccess = (dispatch, room, firstMessage, transaction, user) => {
    // AmplitudeAnalytics.track('Room Create Success', room);

    dispatch({
        type: types.ROOM_CREATE_SUCCESS,
        payload: room,
    });
    dispatch(roomSubscribeMessenger({ room_id: room.id, user_id: user.id }));
    let newMessage = firstMessage;
    newMessage.room_id = room.id;
    dispatch(messageCreate(newMessage, user));
};
const roomCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.ROOM_CREATE_FAIL,
        payload: msg,
    });
};
export const messageCreate = data => {
    return dispatch => {
        dispatch({ type: types.MESSAGE_CREATE, payload: data });
        WS.socket.emit('message:create', data);
        WS.socket.once('message:create', res => {
            if (res.success) {
                messageCreateSuccess(dispatch, res.data);
            } else {
                messageCreateFail(dispatch, res.message);
            }
        });
    };
};
const messageCreateSuccess = (dispatch, res) => {
    // AmplitudeAnalytics.track('Message Create Success', res);
    dispatch({
        type: types.MESSAGE_CREATE_SUCCESS,
        payload: res,
    });
};
const messageCreateFail = (dispatch, msg) => {
    // Sentry.captureMessage('messageCreateFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.MESSAGE_CREATE_FAIL,
        payload: msg,
    });
};

export const messageReceive = () => {
    return dispatch => {
        WS.socket.once('message:receive', res => {
            // AmplitudeAnalytics.track('Message Receive', res);
            dispatch({ type: types.MESSAGE_RECEIVE, payload: res.data });
        });
    };
};

export const roomEmbed = ({ room }) => {
    return dispatch => {
        dispatch({ type: types.ROOM_EMBED, payload: { room } });
        WS.socket.emit('room:embed', { room });
        WS.socket.once('room:embed', res => {
            if (res.success) {
                roomEmbedSuccess(dispatch, res.data);
            } else {
                roomEmbedFail(dispatch, res.msg);
            }
        });
    };
};
const roomEmbedSuccess = (dispatch, room) => {
    dispatch({ type: types.ROOM_EMBED_SUCCESS, payload: room });
};
const roomEmbedFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomEmbedFail', {
    //     level: 'warning',
    // });
    dispatch({ type: types.ROOM_EMBED_FAIL, payload: msg });
};
// -------------------------------------- CHAINING ADD MESSENGER DATA --------------------------------

// -------------------------------------- CHAINING GET INVOICE DATA --------------------------------
export const getInvoiceData = ({ room }) => {
    return dispatch => {
        dispatch({ type: types.ROOM_EMBED });

        WS.socket.emit('room:embed', { room: { id: room.id } });
        WS.socket.once('room:embed', res => {
            if (res.success) {
                roomEmbedInvoiceSuccess(dispatch, res.data);
            } else {
                roomEmbedInvoiceFail(dispatch, res.msg);
            }
        });
    };
};
const roomEmbedInvoiceSuccess = (dispatch, room) => {
    dispatch({ type: types.ROOM_EMBED_SUCCESS, payload: room });
    // We now have the room with the associated transaction and associated invoice
    dispatch(
        saveClickedInvoice({
            invoice: room[0].associated_transaction.associated_invoice,
        })
    );
};
const roomEmbedInvoiceFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomEmbedInvoiceFail', {
    //     level: 'warning',
    // });
    dispatch({ type: types.ROOM_EMBED_FAIL, payload: msg });
};
const saveClickedInvoice = ({ invoice }) => {
    return dispatch => {
        dispatch({ type: types.SAVE_CLICKED_INVOICE });
        dispatch({
            type: types.SAVE_CLICKED_INVOICE_SUCCESS,
            payload: invoice,
        });

        // dispatch(
        //     NavigationActions.navigate({
        //         key: 'Checkout',
        //         routeName: 'Checkout',
        //     })
        // );
    };
};
// -------------------------------------- CHAINING GET INVOICE DATA --------------------------------

export const changeMessageToReceived = message => {
    return dispatch => {
        dispatch({ type: types.MESSAGE_RECEIVED_UPDATE, payload: message });
        let newMessage = {};
        newMessage.id = message.id;
        WS.socket.emit('pushNotification:update', newMessage);
    };
};

export const resetMessagesArray = () => {
    return dispatch => {
        dispatch({ type: types.RESET_MESSAGES_ARRAY });
    };
};

export const sendPaymentRequest = room_id => {
    return dispatch => {
        dispatch({ type: types.SEND_PAYMENT_REQUEST, payload: { room_id } });

        WS.socket.emit('room:sendPaymentRequest', { room_id });
        WS.socket.once('room:sendPaymentRequest', res => {
            if (res.success) {
                sendPaymentRequestSuccess(dispatch, res.data);
            } else {
                sendPaymentRequestFail(dispatch, res.status);
            }
        });
    };
};

const sendPaymentRequestSuccess = (dispatch, room) => {
    dispatch({ type: types.SEND_PAYMENT_REQUEST_SUCCESS, payload: room });
};
const sendPaymentRequestFail = (dispatch, msg) => {
    // Sentry.captureMessage('roomEmbedInvoiceFail', {
    //     level: 'warning',
    // });
    dispatch({ type: types.SEND_PAYMENT_REQUEST_FAIL, payload: msg });
};

export const resetPaymentRequestStatus = () => {
    return dispatch => {
        dispatch({ type: types.RESET_PAYMENT_REQUEST_STATUS });
    };
};
