import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TextField, Button, Typography } from '@material-ui/core';

import OnboardingContainerView from './OnboardingContainerView';
import {
    createAccountAndProposedSchool,
    addNewSchoolInfo,
} from '../../../ducks/OnboardingDuck';
import OnboardingStyles from '../../../constants/OnboardingStyles';
import { OnboardingHeader, FooterShort, CustomContainer } from '../../common';

import { push } from 'connected-react-router';
import { store } from '../../..';
/*
    Props (methods):
    renderLeftColumn()
    renderRightColumn()
*/
class AddNewSchoolScreen extends Component {
    _onNext = () => {
        if (!this._areInputsValid()) {
            return;
        }

        const {
            schoolName,
            phoneNumber,
            firstName,
            lastName,
            email,
            password,
            userType,
        } = this.props;

        let domain = email.substring(email.indexOf('@'));

        this.props.addNewSchoolInfo({
            newSchoolInfo: {
                schoolName,
                domain,
            },
        });

        this.props.createAccountAndProposedSchool({
            schoolInfo: {
                name: schoolName,
                domain: domain,
            },
            userInfo: {
                phoneNumber,
                firstName,
                lastName,
                email,
                password,
                userType,
            },
        });
    };

    _areInputsValid = () => {
        return (
            this.props.schoolName &&
            this.props.schoolName !== undefined &&
            this.props.schoolName.length >= 3
        );
    };

    renderLeftColumn = () => {
        return (
            <div>
                <Typography gutterBottom>Based on your email</Typography>
                <Typography variant="h5" gutterBottom>
                    {this.props.email}
                </Typography>
                <Typography gutterBottom>
                    It looks like you are the first Pangean from your school!
                </Typography>
                <Typography>
                    If this email is correct, enter the your school's name and
                    we will add it to our database! If not, please go back and
                    enter your .edu email.
                </Typography>
            </div>
        );
    };

    renderRightColumn = () => {
        return (
            <form
                onSubmit={event => {
                    event.preventDefault();
                    this._onNext();
                }}
                loading={this.props.loading}
            >
                <TextField
                    autoFocus
                    label="School Name"
                    variant="outlined"
                    name="school-name"
                    value={this.props.schoolName}
                    onChange={event => {
                        this.props.addNewSchoolInfo({
                            newSchoolInfo: {
                                schoolName: event.target.value,
                            },
                        });
                    }}
                    fullWidth
                />

                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    style={{ marginTop: 20, marginRight: 20 }}
                >
                    Create Account
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        store.dispatch(push('/addAccountInfo'));
                    }}
                    style={{ marginTop: 20, marginRight: 20 }}
                >
                    Go Back
                </Button>
            </form>
        );
    };

    render() {
        return (
            <div>
                <OnboardingHeader />
                <CustomContainer>
                    <OnboardingContainerView
                        renderLeftColumn={() => this.renderLeftColumn()}
                        renderRightColumn={() => this.renderRightColumn()}
                    />
                </CustomContainer>
                <FooterShort light />
            </div>
        );
    }
}

const mapStateToProps = ({ onboarding }) => {
    const {
        newSchoolInfo,
        phoneNumber,
        personalInfo,
        accountInfo,
        loading,
        userType,
    } = onboarding;

    return {
        schoolName: newSchoolInfo ? newSchoolInfo.schoolName : '',
        domain: newSchoolInfo ? newSchoolInfo.domain : '',
        phoneNumber,
        firstName: personalInfo ? personalInfo.firstName : '',
        lastName: personalInfo ? personalInfo.lastName : '',
        email: accountInfo ? accountInfo.email : '',
        password: accountInfo ? accountInfo.password : '',
        loading,
        userType,
    };
};

export default connect(mapStateToProps, {
    createAccountAndProposedSchool,
    addNewSchoolInfo,
})(AddNewSchoolScreen);
