import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { Grid, Typography, List, ListItem } from '@material-ui/core';

import Colors from '../../../constants/Colors';

import { ProjectItem } from '../../common';

const styles = {
    rootContainer: {
        marginTop: 20,
        marginBottom: 20,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
    },
    listContainer: {
        padding: 0,
    },
};

class ProfilePostsCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visibleModal: null,
        };
    }

    render() {
        // boolean logic, if it's not a profile, it's a company.
        const isProfile =
            this.props.type === 'detailed_profile' ||
            this.props.type === 'my_profile';
        const isOwner =
            this.props.type === 'my_profile' ||
            this.props.type === 'my_company';

        const isStudentProfile =
            isProfile && this.props.user && this.props.user_type === 'student';

        return (
            <div style={styles.rootContainer}>
                <Typography variant="h4" gutterBottom>
                    Projects
                </Typography>
                {!this.props.loading && (
                    <List style={styles.listContainer}>
                        {this.props.posts.map((project, index) => (
                            <ListItem key={index} style={{ padding: 0 }}>
                                <ProjectItem
                                    project={project}
                                    onPress={() =>
                                        this.props.onPostItemPress(project)
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
        );
    }
}

ProfilePostsCard.propTypes = {
    type: PropTypes.oneOf([
        'detailed_profile',
        'detailed_company',
        'my_profile',
        'my_company',
    ]),
    user: PropTypes.object,
    company: PropTypes.object,
    posts: PropTypes.array,
    onPostItemPress: PropTypes.func,
};

export default ProfilePostsCard;
