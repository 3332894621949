import React, { Component } from 'react';

import { Footer, WelcomeHeader } from '../common';
import Colors from '../../constants/Colors';

class AboutScreen extends Component {
    render() {
        return (
            <div style={{backgroundColor: Colors.pangeaBlue}}>
                <WelcomeHeader/>
                <div className="section-4">
                    <h1 className="heading-3">We are the Pangeans.</h1>
                    <div className="text-block-19">Our mission at Pangea.app is to create more equal access to economic opportunity. We
                    believe that by giving people more opportunity to create value in their own communities, we can strengthen the
                    health and vitality of neighborhoods, inspire innovation and entrepreneurship, and foster new local connections
                    which are predicated on a valuable exchange of knowledge, experience, and skill.</div>
                </div>
                <div className="container-2 w-container">
                    <div className="div-block-11-copy">
                    <div style={{paddingTop: '56.17021276595745%'}} id="w-node-f00c1fdce68e-f8ab2350"
                        className="w-embed-youtubevideo youtube-2"><iframe
                        src="https://www.youtube.com/embed/3DmEIAf5JdM?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                        frameborder="0" style={{position:'absolute',left:0,top:0,width:'100%',height:'100%',pointerEvents:'auto'}}
                        allow="autoplay; encrypted-media" allowfullscreen=""></iframe></div>
                    </div>
                </div>
                <div className="section-5">
                    <h1 className="heading-3-copy">Team</h1>
                </div>
                <div className="w-layout-grid grid-2">
                    <div className="div-block-15 adam"></div>
                    <div className="div-block-15 john"></div>
                    <div className="text-block-20">Adam Alpert<br></br></div>
                    <div className="text-block-20">John Tambunting<br></br></div>
                    <div className="div-block-15 andrew"></div>
                    <div className="div-block-15 scott"></div>
                    <div className="text-block-20">Andrew Thompson<br></br></div>
                    <div className="text-block-20">Scott Bagley<br></br></div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default AboutScreen;
