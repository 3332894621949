// import Sentry from 'sentry-expo';
import WS from '../api/WS';

// import AmplitudeAnalytics from '../services/amplitudeAnalytics';
import Environment from '../constants/Environment';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    MODERATOR_UPDATE_BUSINESS_ACCOUNT_INFO:
        'moderator_update_business_account_info',
    MODERATOR_UPDATE_BUSINESS_ACCOUNT_PROFILE_PIC:
        'moderator_update_business_account_profile_pic',
    MODERATOR_UPDATE_BUSINESS_ACCOUNT_COMPANY_LOGO:
        'moderator_update_business_account_company_logo',
    MODERATOR_CREATE_BUSINESS_ACCOUNT: 'moderator_create_business_account',
    MODERATOR_CREATE_BUSINESS_ACCOUNT_SUCCESS:
        'moderator_create_business_account_success',
    MODERATOR_CREATE_BUSINESS_ACCOUNT_FAIL:
        'moderator_create_business_account_fail',
    MODERATOR_CANCEL_BUSINESS_ACCOUNT: 'moderator_cancel_business_account',
    UPDATE_PROFILE_PIC: 'update_profile_pic',
    UPDATE_PROFILE_PIC_SUCCESS: 'update_profile_pic_success',
    UPDATE_PROFILE_PIC_FAIL: 'update_profile_pic_fail',
    UPDATE_COMPANY_LOGO_PIC: 'update_company_logo_pic',
    UPDATE_COMPANY_LOGO_PIC_SUCCESS: 'update_company_logo_pic_success',
    UPDATE_COMPANY_LOGO_PIC_FAIL: 'update_company_logo_pic_fail',
    MODERATOR_UPDATE_USER_PROFILE_PIC: 'moderator_update_user_profile_pic',
    MODERATOR_UPDATE_USER_PROFILE_PIC_SUCCESS:
        'moderator_update_user_profile_pic_success',
    MODERATOR_UPDATE_USER_PROFILE_PIC_FAIL:
        'moderator_update_user_profile_pic_fail',
    MODERATOR_UPDATE_COMPANY_LOGO_PIC: 'moderator_update_company_logo_pic',
    MODERATOR_UPDATE_COMPANY_LOGO_PIC_SUCCESS:
        'moderator_update_company_logo_pic_success',
    MODERATOR_UPDATE_COMPANY_LOGO_PIC_FAIL:
        'moderator_update_company_logo_pic_fail',
};

// ----------------------------------- REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    accountOwner: {},
    accountCompany: {},
    companyLogoInfo: {},
    profilePicInfo: {},
    error: '',
    businessAccountCreateSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_INFO:
            return {
                ...state,
                accountOwner: {
                    ...state.accountOwner,
                    ...action.payload.accountOwner,
                },
                accountCompany: {
                    ...state.accountCompany,
                    ...action.payload.accountCompany,
                },
            };
        case types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_PROFILE_PIC:
            return { ...state, profilePicInfo: action.payload };
        case types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_COMPANY_LOGO:
            return { ...state, companyLogoInfo: action.payload };
        case types.MODERATOR_CREATE_BUSINESS_ACCOUNT:
            return { ...state, loading: true };
        case types.MODERATOR_CREATE_BUSINESS_ACCOUNT_SUCCESS:
            return {
                ...state,
                error: '',
                businessAccountCreateSuccess: true,
                loading: false,
            };
        case types.MODERATOR_CREATE_BUSINESS_ACCOUNT_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.MODERATOR_CANCEL_BUSINESS_ACCOUNT:
            return { ...INITIAL_STATE };

        case types.UPDATE_PROFILE_PIC:
            return { ...state, loading: true };
        case types.UPDATE_PROFILE_PIC_SUCCESS:
            return { ...state, loading: false };
        case types.UPDATE_PROFILE_PIC_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.UPDATE_COMPANY_LOGO_PIC:
            return { ...state, loading: true };
        case types.UPDATE_COMPANY_LOGO_PIC_SUCCESS:
            return { ...state, loading: false };
        case types.UPDATE_COMPANY_LOGO_PIC_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.MODERATOR_UPDATE_USER_PROFILE_PIC:
            return { ...state, loading: true };
        case types.MODERATOR_UPDATE_USER_PROFILE_PIC_SUCCESS:
            return { ...state, loading: false };
        case types.MODERATOR_UPDATE_USER_PROFILE_PIC_FAIL:
            return { ...state, loading: false, error: action.payload };

        case types.MODERATOR_UPDATE_COMPANY_LOGO_PIC:
            return { ...state, loading: true };
        case types.MODERATOR_UPDATE_COMPANY_LOGO_PIC_SUCCESS:
            return { ...state, loading: false };
        case types.MODERATOR_UPDATE_COMPANY_LOGO_PIC_FAIL:
            return { ...state, loading: false, error: action.payload };

        default:
            return state;
    }
};

export const updateBusinessAccountInfo = ({ accountOwner, accountCompany }) => {
    return dispatch => {
        dispatch({
            type: types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_INFO,
            payload: { accountOwner, accountCompany },
        });
    };
};
export const updateBusinessAccountProfilePicInfo = ({ profilePicInfo }) => {
    return dispatch => {
        dispatch({
            type: types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_PROFILE_PIC,
            payload: profilePicInfo,
        });
    };
};

export const updateBusinessAccountCompanyLogoInfo = ({ companyLogoInfo }) => {
    return dispatch => {
        dispatch({
            type: types.MODERATOR_UPDATE_BUSINESS_ACCOUNT_COMPANY_LOGO,
            payload: companyLogoInfo,
        });
    };
};

export const createBusinessAccount = ({
    accountOwner,
    accountCompany,
    profilePicInfo,
    companyLogoInfo,
    moderator,
}) => {
    return dispatch => {
        dispatch({
            type: types.MODERATOR_CREATE_BUSINESS_ACCOUNT,
            payload: { accountOwner, accountCompany },
        });
        WS.socket.emit('moderator:createBusinessAccount', {
            accountOwner,
            accountCompany,
            moderator,
        });
        WS.socket.once('moderator:createBusinessAccount', res => {
            if (res.success) {
                createBusinessAccountSuccess(
                    dispatch,
                    res.data,
                    profilePicInfo,
                    companyLogoInfo
                );
            } else {
                createBusinessAccountFail(dispatch, res.message);
            }
        });
    };
};
const createBusinessAccountSuccess = (
    dispatch,
    data,
    profilePicInfo,
    companyLogoInfo
) => {
    // AmplitudeAnalytics.track('Create Business Account Success', data);
    dispatch({
        type: types.MODERATOR_CREATE_BUSINESS_ACCOUNT_SUCCESS,
        payload: data,
    });
    if (
        profilePicInfo &&
        profilePicInfo.profile_pic &&
        profilePicInfo.profile_name
    ) {
        dispatch({
            type: types.UPDATE_PROFILE_PIC,
            payload: {
                user_id: data.accountCompany.owner_id,
                imageUri: profilePicInfo.profile_pic,
                photoName: profilePicInfo.profile_name,
            },
        });
        uploadProfilePic(
            dispatch,
            data.accountCompany.owner_id,
            profilePicInfo.profile_pic,
            profilePicInfo.profile_name
        );
    }
    if (
        companyLogoInfo &&
        companyLogoInfo.logo_pic &&
        companyLogoInfo.logo_name
    ) {
        dispatch({
            type: types.UPDATE_COMPANY_LOGO_PIC,
            payload: {
                company_id: data.accountCompany.id,
                imageUri: profilePicInfo.profile_pic,
                photoName: profilePicInfo.profile_name,
            },
        });
        uploadCompanyLogo(
            dispatch,
            data.accountCompany.id,
            companyLogoInfo.logo_pic,
            companyLogoInfo.logo_name
        );
    }
};
const createBusinessAccountFail = (dispatch, msg) => {
    // Sentry.captureMessage('createBusinessAccountFail', {
    //     level: 'warning',
    // });
    dispatch({
        type: types.MODERATOR_CREATE_BUSINESS_ACCOUNT_FAIL,
        payload: msg.message,
    });
};

export const cancelModeratorBusinessAccount = () => {
    return dispatch => {
        dispatch({
            type: types.MODERATOR_CANCEL_BUSINESS_ACCOUNT,
            payload: true,
        });
    };
};

const uploadProfilePic = (dispatch, user_id, imageUri, photoName) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;
    const uri = imageUri;
    const uriParts = uri.split('.');
    const fileType = uriParts[uriParts.length - 1];
    const formData = new FormData();
    formData.append('image', {
        uri,
        type: `image/${fileType}`,
        name: photoName,
        originalname: photoName,
    });

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'file-path': 'Users/' + user_id + '/profilePic/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            // AmplitudeAnalytics.track('User Update Profile Success', {
            //     'image:url': results.imageUrl,
            // });
            dispatch({
                type: types.UPDATE_PROFILE_PIC_SUCCESS,
                payload: results.imageUrl,
            });
            updateUserProfilePic(dispatch, user_id, photoName);
        })
        .catch(error => {
            dispatch({
                type: types.UPDATE_PROFILE_PIC_FAIL,
                payload: error,
            });
        });
};

const uploadCompanyLogo = (dispatch, company_id, imageUri, photoName) => {
    const apiUrl = `https://${Environment.RESTAPI}/post-pic/image-upload`;
    const uri = imageUri;
    const uriParts = uri.split('.');
    const fileType = uriParts[uriParts.length - 1];
    const formData = new FormData();
    formData.append('image', {
        uri,
        type: `image/${fileType}`,
        name: photoName,
        originalname: photoName,
    });

    fetch(apiUrl, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'file-path': 'Companies/' + company_id + '/logoPic/',
        },
        body: formData,
    })
        .then(res => res.json())
        .then(results => {
            // AmplitudeAnalytics.track('Company Update Logo Success', {
            //     'image:url': results.imageUrl,
            // });
            dispatch({
                type: types.UPDATE_COMPANY_LOGO_PIC_SUCCESS,
            });
            updateCompanyLogoPic(dispatch, company_id, photoName);
        })
        .catch(error => {
            dispatch({
                type: types.UPDATE_COMPANY_LOGO_PIC_FAIL,
                payload: error,
            });
        });
};

const updateUserProfilePic = (dispatch, user_id, photoName) => {
    dispatch({
        type: types.MODERATOR_UPDATE_USER_PROFILE_PIC,
        payload: { user_id, photoName },
    });
    WS.socket.emit('user:update', {
        user: {
            id: user_id,
            profile_pic: photoName,
        },
    });
    WS.socket.once('user:update', res => {
        if (res.success) {
            updateUserProfilePicSuccess(dispatch, res.data);
        } else {
            updateUserProfilePicFail(dispatch, res.message);
        }
    });
};

const updateUserProfilePicSuccess = (dispatch, data) => {
    dispatch({
        type: types.MODERATOR_UPDATE_USER_PROFILE_PIC_SUCCESS,
        payload: { data },
    });
};

const updateUserProfilePicFail = (dispatch, message) => {
    dispatch({
        type: types.MODERATOR_UPDATE_USER_PROFILE_PIC_FAIL,
        payload: { message },
    });
};

const updateCompanyLogoPic = (dispatch, company_id, photoName) => {
    dispatch({
        type: types.MODERATOR_UPDATE_COMPANY_LOGO_PIC,
        payload: { company_id, photoName },
    });
    WS.socket.emit('company:update', {
        company: {
            id: company_id,
            logo_pic: photoName,
        },
    });
    WS.socket.once('company:update', res => {
        if (res.success) {
            updateCompanyLogoPicSuccess(dispatch, res.data);
        } else {
            updateCompanyLogoPicFail(dispatch, res.message);
        }
    });
};

const updateCompanyLogoPicSuccess = (dispatch, data) => {
    dispatch({
        type: types.MODERATOR_UPDATE_COMPANY_LOGO_PIC_SUCCESS,
        // payload: {data}
    });
};

const updateCompanyLogoPicFail = (dispatch, message) => {
    dispatch({
        type: types.MODERATOR_UPDATE_COMPANY_LOGO_PIC_FAIL,
        payload: { message },
    });
};
