import React from 'react';
import InboxItem from './InboxItem';
import { TextField, Typography } from '@material-ui/core';

import globalStyles from '../../../constants/GlobalStyles';

import Environment from '../../../constants/Environment';

const styles = {
    searchMessages: {
        marginBottom: 0,
    },
    profileImage: {
        width: 60,
        height: 60,
        backgroundColor: '#d8d8d8',
        borderRadius: 30,
        margin: 20,
    },
    inbox: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        position: 'relative',
    },
};

const InboxHeader = props => {
    let pic_uri = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${props.user.id}/profilePic/${props.user.profile_pic}`;
    return (
        <div
            style={{
                height: 140,
                position: 'sticky',
                top: 0,
                background: 'white',
                border: 'solid 1px #c7c7cc',
                zIndex: 2,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <img
                    style={styles.profileImage}
                    alt="User profile"
                    src={pic_uri}
                ></img>
                <Typography className="toolbar-title">{'Messenger'}</Typography>
            </div>
            <TextField
                placeholder={'Search Messages'}
                InputProps={{
                    disableUnderline: true,
                    style: {
                        ...globalStyles.textInput,
                        ...styles.searchMessages,
                    },
                }}
                // fullWidth
                // value={this.props.description}
                onChange={event => {
                    console.log(event.target.value);
                }}
                maxLength={5000}
                multiline
                style={{
                    justifyContent: 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginLeft: 20,
                    marginRight: 20,
                    // position: 'sticky',
                    // top: 0,
                }}
            />
        </div>
    );
};

export default function Inbox(props) {
    return (
        <div
            style={{
                // overflowY: 'scroll',
                overflowY: 'scroll',
                position: 'relative',
                background: 'white',
                height: '100vh',
            }}
        >
            <InboxHeader user={props.user} />
            <div style={styles.inbox}>
                {props.rooms && props.rooms.length > 0 ? (
                    props.rooms.map(room => (
                        <InboxItem
                            key={room.id}
                            data={room}
                            onPress={props.onPress}
                            onProfilePress={props.onProfilePress}
                        />
                    ))
                ) : (
                    <p>{'You have no messages yet!'}</p>
                )}
            </div>
        </div>
    );
}
