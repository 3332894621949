import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header as HeaderItem, CustomContainer } from '../common';

import { Grid } from '@material-ui/core';

import {
    inboxFetch,
    roomUpdate,
    inboxSubscribe,
} from '../../ducks/MyRoomsDuck';

import { saveClickedPangean } from '../../ducks/MainFeedDuck';

import {
    saveClickedRoom,
    getMessengerScreenData,
} from '../../ducks/TransactionMessengerDuck';

import Inbox from './Inbox';
import MessageRoom from './MessageRoom';

import Environment from '../../constants/Environment';

// import { push } from 'connected-react-router';
// import { store } from '../..';

const avatarPic = require('../../assets/images/common/default-profile-pic.png');

// const styles = {
//     rootContainer: {
//         display: 'flex',
//         flex: 1,
//         paddingLeft: 42,
//         paddingRight: 42,
//     },

//     messengerHeader: {
//         paddingTop: 20,
//         paddingRight: 20,
//         paddingLeft: 20,
//         paddingBottom: 10,
//         border: 'solid 1px #979797',
//     },

//     messengerHeaderContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//     },

//     messengerHeaderTitle: {
//         marginLeft: 20,
//     },

//     messengerHeaderImage: {
//         backgroundColor: '#d8d8d8',
//         width: 60,
//         height: 60,
//         borderRadius: 30,
//     },

//     messengerHeaderSearchInput: {
//         borderRadius: 15,
//         border: 'solid 1px #979797',
//         marginTop: 20,
//         paddingRight: 20,
//         paddingLeft: 20,
//         width: '90%',
//         height: 30,
//     },

//     contactList: {
//         overflow: 'scroll',
//         display: 'flex',
//         flexDirection: 'column',
//         height: '100%',
//     },

//     contactCard: {
//         border: 'solid 1px #979797',
//         height: 80,
//         paddingTop: 10,
//         paddingLeft: 20,
//         paddingBottom: 10,
//         paddingRight: 20,
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         position: 'relative',
//     },

//     contactTime: {
//         position: 'absolute',
//         top: 5,
//         right: 5,
//     },

//     contactCardHighlighted: {
//         border: 'solid 1px #979797',
//         height: 80,
//         backgroundColor: '#d8d8d8',
//         paddingTop: 10,
//         paddingLeft: 20,
//         paddingBottom: 10,
//         paddingRight: 20,
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//     },

//     contactCardProfileImage: {
//         width: 60,
//         height: 60,
//         borderRadius: 30,
//         border: 'solid 1px #979797',
//         backgroundColor: '#d8d8d8',
//         marginRight: 20,
//     },

//     contactCardMessageContainer: {
//         display: 'flex',
//         flexDirection: 'column',
//     },

//     contactCardMessageSender: {
//         marginBottom: 5,
//     },

//     contactInfoContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         border: 'solid 1px #979797',
//         paddingTop: 10,
//         paddingLeft: 20,
//         paddingBottom: 10,
//         paddingRight: 20,
//     },

//     contactInfoNameContainer: {
//         flex: 1,
//         display: 'flex',
//         flexDirection: 'column',
//     },

//     contactInfoIcon: {
//         height: 40,
//         width: 40,
//         marginLeft: 20,
//         backgroundColor: 'grey',
//     },

//     messageContainer: {
//         height: '100%',
//     },

//     messageInputContainer: {
//         display: 'flex',
//         flexDirection: 'row',
//         alignItems: 'center',
//         padding: 15,
//     },

//     messageInputIcon: {
//         width: 25,
//         height: 20,
//         backgroundColor: 'grey',
//         marginRight: 10,
//     },

//     messageTextInput: {
//         borderRadius: 15,
//         border: 'solid 1px #979797',
//         paddingRight: 20,
//         paddingLeft: 20,
//         height: 30,
//         flex: 1,
//         marginRight: 10,
//     },
// };

class MessengerScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            messagesArray: [],
        };
    }

    componentDidMount() {
        // console.log(this.props.user);
        // console.log('this.props.user');
        this.props.inboxFetch({
            user: this.props.user,
            isPullDownReload: false,
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let clickedRoom = nextProps.clickedRoom;
        if (
            nextProps.clickedRoom !== null &&
            nextProps.clickedRoom !== undefined
        ) {
            let isOwner = clickedRoom.owner_id === nextProps.user.id;
            let isBuyer = clickedRoom.buyer_id === nextProps.user.id;

            let buyerId = clickedRoom.buyer_id;
            let sellerId = clickedRoom.seller_id;

            let otherUser = null;
            let avatarPic = null;

            if (isOwner) {
                otherUser = clickedRoom.responder;
                avatarPic = clickedRoom.responder_profile_pic;
            } else {
                otherUser = clickedRoom.owner;
                avatarPic = clickedRoom.owner_profile_pic;
            }

            return {
                clickedRoom,
                isOwner,
                isBuyer,
                buyerId,
                sellerId,
                otherUser,
                avatarPic,
                messagesArray: clickedRoom.messages,
                user: nextProps.user,
            };
        } else {
            return {};
        }
    }

    _getRenderableRooms() {
        const { roomsArray } = this.props;

        // null pointer at: this.props.user.id
        let newArr = [];
        for (let i = 0; i < roomsArray.length; i++) {
            if (roomsArray[i].owner_id === this.props.user.id) {
                if (!roomsArray[i].owner_has_archived) {
                    newArr.push(roomsArray[i]);
                }
            } else {
                if (!roomsArray[i].responder_has_archived) {
                    newArr.push(roomsArray[i]);
                }
            }
        }

        return newArr;
    }

    _getFirstNonSystemMessage = messages => {
        for (let msg of messages) {
            if (!msg.system) {
                return msg.text;
            }
        }
        return 'message unavailable';
    };

    _formatRenderableRooms(rooms) {
        let formattedRooms = [];

        for (let room of rooms) {
            const { responder, owner, messages } = room;
            if (
                responder !== null &&
                responder !== undefined &&
                owner !== null &&
                owner !== undefined
            ) {
                let name = ``;

                if (this.props.user.id === room.owner_id) {
                    name = `${
                        responder.first_name
                    } ${responder.last_name.charAt(0)}.`;
                } else {
                    name = `${owner.first_name} ${owner.last_name.charAt(0)}.`;
                }

                let avatarID = '';
                let avatar = '';
                let roomTitle = '';
                let badgeCount = 0;

                if (this.props.user) {
                    if (this.props.user.id === room.owner_id) {
                        avatarID = responder.id;
                        avatar = {
                            uri: `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${responder.id}/profilePic/${responder.profile_pic}`,
                        };
                        roomTitle = responder.full_name;
                        badgeCount = room.owner_badge_count
                            ? room.owner_badge_count
                            : 0;
                    } else {
                        avatarID = owner.id;
                        avatar = {
                            uri: `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${owner.id}/profilePic/${owner.profile_pic}`,
                        };
                        roomTitle = owner.full_name;
                        badgeCount = room.responder_badge_count
                            ? room.responder_badge_count
                            : 0;
                    }
                }

                if (this.state.profilePicFailed) {
                    avatar = avatarPic;
                }

                let message = '';

                if (messages.length > 0) {
                    message = this._getFirstNonSystemMessage(messages);
                } else {
                    message = 'message unavailable';
                }

                if (room.title !== null && room.title !== undefined) {
                    roomTitle = room.title;
                }

                room.otherUserName = name;
                room.avatar = avatar;
                room.avatarID = avatarID;
                room.roomTitle = roomTitle;
                room.badgeCount = badgeCount;
                room.message = message;

                formattedRooms.push(room);
            }
        }

        return formattedRooms;
    }

    _onProfilePress(item) {
        let isOwner = item.owner_id === this.props.user.id;

        let otherUser = null;

        if (isOwner) {
            otherUser = item.responder;
        } else {
            otherUser = item.owner;
        }

        this.props.saveClickedPangean({
            owner_id: item.owner_id,
            responder_id: item.responder_id,
            pangean: otherUser,
        });

        // store.dispatch(push('/detailedProfile'));
    }

    _onRoomItemPress(item) {
        this.props.getMessengerScreenData({
            room_id: item.id,
            transaction_id: item.transaction_id,
            user_id: this.props.user.id,
        });
    }

    render() {
        let arrRooms = this._getRenderableRooms();

        let formattedRooms = this._formatRenderableRooms(arrRooms);

        return (
            <div style={{ backgroundColor: '#f2f2f7' }}>
                <HeaderItem user={this.props.user} />
                <CustomContainer>
                    <Grid
                        container
                        style={{
                            width: '100%',
                            background: '#eeeef1',
                        }}
                    >
                        <Grid item md={4}>
                            {/* <div
                                style={{
                                    // overflowY: 'scroll',
                                    overflowY: 'hidden',
                                    position: 'relative',
                                    background: 'white',
                                    height: '100vh',
                                }}
                            > */}
                            <Inbox
                                rooms={formattedRooms}
                                onPress={room => this._onRoomItemPress(room)}
                                onProfilePress={room =>
                                    this._onProfilePress(room)
                                }
                                user={this.props.user}
                                clickeRoom={this.props.clickeRoom}
                            />
                            {/* </div> */}
                        </Grid>
                        <Grid item md={8}>
                            <div
                                style={{
                                    overflowY: 'scroll',
                                    // display: 'flex',
                                    position: 'relative',
                                    background: 'white',
                                    height: '100vh',
                                }}
                            >
                                <MessageRoom
                                    messages={this.state.messagesArray}
                                    user={this.props.user}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </CustomContainer>
            </div>
        );
    }
}

const mapStateToProps = ({ myRooms, userSession, transactionMessenger }) => {
    const { roomsArray, loading } = myRooms;
    const { user } = userSession;
    const {
        clickedRoom,
        // loading,
        requestPaymentLoading,
    } = transactionMessenger;

    return {
        roomsArray,
        loading,
        user,
        clickedRoom,
        requestPaymentLoading,
    };
};

export default connect(mapStateToProps, {
    inboxFetch,
    roomUpdate,
    inboxSubscribe,
    saveClickedRoom,
    getMessengerScreenData,
    saveClickedPangean,
})(MessengerScreen);

// return (
//     <div>
//         <HeaderItem />
//         <div style={styles.rootContainer}>
//             <Grid container>
//                 <Grid item md={'4'}>
//                     <div style={styles.messengerHeader}>
//                         <div style={styles.messengerHeaderContainer}>
//                             <div style={styles.messengerHeaderImage} />
//                             <span style={styles.messengerHeaderTitle}>
//                                 Messenger
//                             </span>
//                         </div>

//                         <input
//                             type="text"
//                             style={styles.messengerHeaderSearchInput}
//                             placeholder="Search Messages"
//                         />
//                     </div>

//                     <div style={styles.contactList}>
//                         <MessageContact />
//                         <MessageContact />
//                         <MessageContact />
//                         <MessageContact />

//                         {/* TODO: Scroll Behavior Fixes */}
//                     </div>
//                 </Grid>

//                 <Grid
//                     item
//                     md={'8'}
//                     flexDirection="column"
//                     alignItems="stretch"
//                 >
//                     <MessageContactInfo />

//                     <div style={styles.messageContainer}></div>

//                     <MessageInput />
//                 </Grid>
//             </Grid>
//         </div>
//     </div>
// );

// const MessageContact = () => {
//     return (
//         <div style={styles.contactCard}>
//             <span style={styles.contactTime}>Aug 15</span>
//             <div style={styles.contactCardProfileImage}></div>

//             <div style={styles.contactCardMessageContainer}>
//                 <span style={styles.contactCardMessageSender}>First Last</span>
//                 <span>You: Thank You</span>
//             </div>
//         </div>
//     );
// };

// const MessageContactInfo = () => {
//     return (
//         <div style={styles.contactInfoContainer}>
//             <div style={styles.contactCardProfileImage}></div>

//             <div style={styles.contactInfoNameContainer}>
//                 <span style={styles.contactCardMessageSender}>First Last</span>
//                 <span>Company/School</span>
//             </div>

//             <div style={styles.contactInfoIcon}>Phone</div>
//             <div style={styles.contactInfoIcon}>Menu</div>
//         </div>
//     );
// };

// const MessageInput = () => {
//     return (
//         <div style={styles.messageInputContainer}>
//             <div style={styles.messageInputIcon} />
//             <div style={styles.messageInputIcon} />
//             <div style={styles.messageInputIcon} />
//             <input
//                 type="text"
//                 style={styles.messageTextInput}
//                 placeholder="Type Message"
//             />
//             <div style={styles.messageInputIcon} />
//         </div>
//     );
// };

// _renderCardContent(mostRecentMessage) {
//     if (!this.props.user) return null;

//     const { responder, owner } = this.props.room;

//     let fullName = '';
//     let badgeCount = 0;
//     if (this.props.user.id === this.props.room.owner_id) {
//         fullName = responder.full_name;
//         badgeCount = this.props.room.owner_badge_count
//             ? this.props.room.owner_badge_count
//             : 0;
//     } else {
//         fullName = owner.full_name;
//         badgeCount = this.props.room.responder_badge_count
//             ? this.props.room.responder_badge_count
//             : 0;
//     }

//     return (
//         <View style={{ flex: 1, flexDirection: 'row' }}>
//             <View style={styles.cardLeftContainer}>
//                 <Text
//                     style={styles.title}
//                     adjustsFontSizeToFit
//                     numberOfLines={1}
//                     minimumFontScale={0.8}
//                 >
//                     {this.props.room.title === null ||
//                     this.props.room.title === undefined
//                         ? fullName
//                         : this.props.room.title}
//                     {/* {associated_post.title} */}
//                 </Text>
//                 <View style={styles.mostRecentMessageContainer}>
//                     {badgeCount > 0 ? (
//                         <Text
//                             style={[
//                                 styles.mostRecentMessage,
//                                 {
//                                     color: Colors.fontGray,
//                                     fontFamily: 'InterMedium',
//                                 },
//                             ]}
//                             numberOfLines={2}
//                         >
//                             {mostRecentMessage}
//                         </Text>
//                     ) : (
//                         <Text
//                             style={styles.mostRecentMessage}
//                             numberOfLines={2}
//                         >
//                             {mostRecentMessage}
//                         </Text>
//                     )}
//                 </View>
//             </View>
//             <View style={styles.cardRightContainer}>
//                 <Text style={styles.timeStampText}>
//                     <Timestamp
//                         time={this.props.room.last_modified}
//                         format="ago"
//                         component={Text}
//                     />
//                 </Text>
//             </View>
//             {badgeCount > 0 ? (
//                 <View
//                     style={{
//                         position: 'absolute',
//                         right: 18,
//                         top: 32,
//                         // top: -4,
//                         // right: -4,
//                         margin: 0,
//                         width: 16,
//                         height: 16,
//                         borderRadius: 8,
//                         alignItems: 'center',
//                         justifyContent: 'center',
//                         backgroundColor: Colors.fontGray,
//                     }}
//                 >
//                     <Text
//                         style={{
//                             color: 'white',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             textAlign: 'center',
//                             fontSize: 9,
//                         }}
//                     >
//                         {`${badgeCount}`}
//                     </Text>
//                 </View>
//             ) : null}
//         </View>
//     );
// }
