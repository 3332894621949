import React from 'react';

import {
    TextField,
    FormControl,
    Select,
    MenuItem,
    Typography,
} from '@material-ui/core';

import { CustomInputLabel, CustomInputBase } from '../../common';

import { Field } from 'react-final-form';
import { getPickerHourlyWageValues } from '../../../constants/HourlyWages';

const required = value => (value ? undefined : 'Required');

const SalaryContent = props => {
    let salaryArr = [];
    for (let i = 15; i <= 200; i++) {
        salaryArr.push(
            <MenuItem value={i * 1000} key={i}>
                {`$${i},000`}
            </MenuItem>
        );
    }
    return (
        <div>
            <Field name="compensation">
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <FormControl style={{ minWidth: '50%' }}>
                            <CustomInputLabel variant="outlined">
                                Salary
                            </CustomInputLabel>
                            <Select
                                variant="outlined"
                                {...input}
                                onChange={e => {
                                    input.onChange(e.target.value);
                                }}
                                labelWidth={'100%'}
                                disableUnderline
                                input={
                                    <CustomInputBase
                                        error={meta.error && meta.touched}
                                    />
                                }
                            >
                                {salaryArr}
                            </Select>
                        </FormControl>

                        <Typography
                            style={{
                                paddingLeft: 20,
                            }}
                        >
                            {'/ Year'}
                        </Typography>
                    </div>
                )}
            </Field>
        </div>
    );
};

const HourlyContent = props => {
    let hourlyPickerValues = getPickerHourlyWageValues();
    let hourlyPickerItems = [];

    for (let i = 0; i < hourlyPickerValues.length; i++) {
        hourlyPickerItems.push(
            <MenuItem value={hourlyPickerValues[i]} key={hourlyPickerValues[i]}>
                {`$${hourlyPickerValues[i]}`}
            </MenuItem>
        );
    }

    return (
        <div>
            <Field name="compensation">
                {({ input, meta }) => (
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <FormControl style={{ minWidth: '30%' }}>
                            <CustomInputLabel variant="outlined">
                                Compensation
                            </CustomInputLabel>
                            <Select
                                variant="outlined"
                                {...input}
                                onChange={e => {
                                    input.onChange(e.target.value);
                                }}
                                labelWidth={'100%'}
                                disableUnderline
                                input={
                                    <CustomInputBase
                                        error={meta.error && meta.touched}
                                    />
                                }
                            >
                                {hourlyPickerItems}
                            </Select>
                        </FormControl>

                        <Typography
                            style={{
                                paddingLeft: 20,
                            }}
                        >
                            {'/ Hour'}
                        </Typography>
                    </div>
                )}
            </Field>
        </div>
    );
};

const EmptyPriceContent = () => {
    return (
        <div
            style={{
                height: 18,
            }}
        />
    );
};

const PaidContent = props => {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 20,
                }}
            >
                <Field name="compensation">
                    {({ input, meta }) => (
                        <React.Fragment>
                            <Typography style={{ marginRight: 5 }}>
                                {'$'}
                            </Typography>
                            <TextField
                                variant="outlined"
                                label="Price"
                                {...input}
                                style={{
                                    color: '#565656',
                                    width: '30%',
                                    marginRight: 20,
                                }}
                                placeholder="250"
                                onChange={e => {
                                    let text = e.target.value;
                                    let compensationText = text.replace(
                                        /[^0-9]/g,
                                        ''
                                    );
                                    let compensation = parseInt(
                                        compensationText
                                    );
                                    input.onChange(compensation);
                                }}
                                // value={
                                //     props.formattedCompensation
                                //         ? props.formattedCompensation
                                //         : null
                                // }
                                maxLength={7}
                            />
                        </React.Fragment>
                    )}
                </Field>
                <Field name="paymentFrequency">
                    {({ input, meta }) => (
                        <FormControl style={{ minWidth: '30%' }}>
                            <CustomInputLabel variant="outlined">
                                Frequency
                            </CustomInputLabel>
                            <Select
                                variant="outlined"
                                {...input}
                                // value={props.payment_frequency}
                                onChange={e => {
                                    input.onChange(e.target.value);
                                }}
                                labelWidth={'100%'}
                                // style={{
                                //     ...globalStyles.textInput,
                                //     ...styles.inputNoBottomMargin,
                                // }}
                                disableUnderline
                                input={
                                    <CustomInputBase
                                        error={meta.error && meta.touched}
                                    />
                                }
                            >
                                <MenuItem value="hour">{'/ Hour'}</MenuItem>
                                <MenuItem value="day">{'/ Day'}</MenuItem>
                                <MenuItem value="week">{'/ Week'}</MenuItem>
                                <MenuItem value="month">{'/ Month'}</MenuItem>
                                <MenuItem value="project">
                                    {`${
                                        props.project_type === 'internship'
                                            ? '/ Internship'
                                            : '/ Project'
                                    }`}
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                </Field>
            </div>
        </div>
    );
};

const CompensationForm = props => {
    let compensationLabels =
        props.values.projectType === 'full_time'
            ? ['Salaried', 'Hourly', 'Negotiable']
            : ['Paid', 'Negotiable', 'Unpaid'];

    let paymentContent;

    console.log('project_type');
    console.log(props.values.projectType);
    console.log('props');
    console.log(props);

    if (props.values.projectType === 'full_time') {
        if (props.values.compensationType === 'Salaried') {
            paymentContent = <SalaryContent {...props} />;
        } else if (props.values.compensationType === 'Hourly') {
            paymentContent = <HourlyContent {...props} />;
        } else if (props.values.compensationType === 'Negotiable') {
            paymentContent = <EmptyPriceContent {...props} />;
        }
    } else if (props.values.projectType === 'one_time') {
        if (props.values.compensationType === 'Paid') {
            paymentContent = <PaidContent {...props} />;
        } else {
            paymentContent = <EmptyPriceContent {...props} />;
        }
    } else if (props.values.projectType === 'internship') {
        if (props.values.compensationType === 'Paid') {
            paymentContent = <PaidContent {...props} />;
        } else {
            paymentContent = <EmptyPriceContent {...props} />;
        }
    }

    return (
        <div>
            <Typography variant="h5" gutterBottom>
                What is the compensation?
            </Typography>
            <Field name="compensationType" validate={required}>
                {({ input, meta }) => (
                    <FormControl
                        style={{ minWidth: '50%' }}
                        error={meta.error && meta.touched}
                    >
                        <CustomInputLabel variant="outlined">
                            Compensation Type
                        </CustomInputLabel>
                        <Select
                            {...input}
                            onChange={event => {
                                input.onChange(event.target.value);
                            }}
                            labelWidth={'100%'}
                            disableUnderline
                            input={
                                <CustomInputBase
                                    error={meta.error && meta.touched}
                                />
                            }
                        >
                            <MenuItem value="" disabled>
                                Compensation Type
                            </MenuItem>
                            {compensationLabels.map(compensation_type => {
                                return (
                                    <MenuItem value={compensation_type}>
                                        {compensation_type}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                )}
            </Field>
            {paymentContent}
        </div>
    );
};

export default CompensationForm;
