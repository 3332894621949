import React from 'react';

const adSectionStyle = {
    rootContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 22,
    },

    sectionTitle: {
        color: 'gray',
        marginBottom: 20,
    },

    adContainer: {
        height: 142,
        borderRadius: 5,
        border: 'solid 1px #8e8e93',
        backgroundColor: 'gray',
    },
};

const ProfileAdCard = () => {
    return (
        <div style={adSectionStyle.rootContainer}>
            <span style={adSectionStyle.sectionTitle}>Sponsored</span>

            <div style={adSectionStyle.adContainer}></div>
        </div>
    );
};

export default ProfileAdCard;
