import React, { useEffect } from 'react';
import shave from 'shave';
import { makeStyles } from '@material-ui/core/styles';

import './InboxItem.css';

const useStyles = makeStyles({
    conversationListItem: {
        display: 'flex',
        alignItems: 'center',
        padding: 10,
        border: 'solid 1px #c7c7cc',
        '&:hover': {
            background: '#eeeef1',
            cursor: 'pointer',
        },
    },
});

export default function InboxItem(props) {
    useEffect(() => {
        shave('.conversation-snippet', 20);
    });

    const classes = useStyles();

    const { avatar, roomTitle, message } = props.data;

    return (
        <div
            className={classes.conversationListItem}
            // style={styles.conversationListItem}
            onClick={() => {
                props.onPress(props.data);
            }}
        >
            <img
                className="conversation-photo"
                src={avatar.uri}
                alt=""
                onClick={() => {
                    props.onProfilePress(props.data);
                }}
            />
            <div className="conversation-info">
                <h1 className="conversation-title">{roomTitle}</h1>
                <p className="conversation-snippet">{message}</p>
            </div>
        </div>
    );
}
