import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Header as HeaderItem, Footer, CustomContainer } from '../common';
import Colors from '../../constants/Colors';
import { push } from 'connected-react-router';
import { store } from '../..';

import {
    Grid,
    Card,
    CardActionArea,
    CardContent,
    Typography,
    Button,
} from '@material-ui/core';

const styles = {
    rootContainer: {
        backgroundColor: Colors.lightGray,
        height: '100vh',
        // paddingLeft: 42,
        // paddingRight: 42,
        // paddingTop: 51,
        // paddingBottom: 51,
    },
    scratchButton: {
        borderRadius: 25,
        fontSize: 16,
        fontWeight: '400',
        borderColor: Colors.fontBlack,
    },

    titleText: {
        marginBottom: 29,
    },

    headerText: {
        display: 'block',
        marginBottom: 9,
    },

    flex: {
        display: 'flex',
        flex: 1,
    },

    projectTypeCard: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
        backgroundColor: '#ffffff',
        // marginTop: 20,
        // marginRight: 20,
        // marginLeft: 20,
        // marginBottom: 20,
    },

    projectTypeCardText: {
        marginBottom: 20,
    },

    projectTypeContainer: {
        marginTop: 35,
        marginBottom: 35,
    },

    customProjectActionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },

    customProjectText: {
        marginLeft: 5,
        marginRight: 5,
    },

    newProjectBtn: {
        borderRadius: 15,
        border: 'solid 1px #000000',
        display: 'inline',
        marginLeft: 5,
        marginRight: 5,
        padding: 8,
    },

    newProjectButton: {
        borderRadius: 20,
        // fontSize: 16,
        // fontWeight: '400',
    },
};

class PostChooseProjectTypeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div style={styles.rootContainer}>
                    <HeaderItem user={this.props.user} />
                    <CustomContainer>
                        {/* <div style={styles.rootContainer}> */}
                        <Typography
                            variant="h6"
                            gutterBottom
                            style={{ marginTop: 50, paddingBottom: 10 }}
                        >
                            Post a New Project
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Awesome! Let's get you started.
                        </Typography>
                        <Typography variant="h4" gutterBottom>
                            What type of project would you like to post?
                        </Typography>
                        <Grid
                            container
                            spacing={2}
                            style={styles.projectTypeContainer}
                        >
                            <Grid item md={4} style={styles.flex}>
                                <Card style={styles.projectTypeCard}>
                                    <CardActionArea>
                                        <CardContent>
                                            <Typography
                                                variant="h3"
                                                gutterBottom
                                            >
                                                One-Time
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                For gigs that have no long term
                                                commitment.
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                Require only a few hours or more
                                                to complete.
                                            </Typography>
                                            <Typography variant="caption">
                                                Example: Designing a logo or
                                                writing a blog post.
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid item md={4} style={styles.flex}>
                                <Card style={styles.projectTypeCard}>
                                    <CardActionArea
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h3"
                                                gutterBottom
                                            >
                                                Internship
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                For work that requires a little
                                                more commitment.
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                Usually 3-6 months of
                                                employment.
                                            </Typography>
                                            <Typography variant="caption">
                                                Example: Summer Analysts or UX
                                                Design Internship
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>

                            <Grid item md={4} style={styles.flex}>
                                <Card style={styles.projectTypeCard}>
                                    <CardActionArea
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            flex: 1,
                                            justifyContent: 'flex-start',
                                        }}
                                    >
                                        <CardContent>
                                            <Typography
                                                variant="h3"
                                                gutterBottom
                                            >
                                                Full-Time Job
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                For students that are
                                                graduating. Testing the length
                                                of this typography
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                gutterBottom
                                            >
                                                Years of employment.
                                            </Typography>
                                            <Typography variant="caption">
                                                Example: Summer Analysts or UX
                                                Design Internship
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        </Grid>

                        <div style={styles.customProjectActionsContainer}>
                            <Typography
                                variant="body1"
                                style={{ marginRight: 20 }}
                            >
                                Not what you're looking for?
                            </Typography>
                            {/* <span style={styles.customProjectText}>
                        </span> */}
                            <Button
                                variant="contained"
                                color={'primary'}
                                style={styles.newProjectButton}
                                onClick={() => {
                                    store.dispatch(push('/postNewProject'));
                                }}
                            >
                                Post a new project from scratch
                            </Button>
                            {/* <input
                                type="button"
                                style={styles.newProjectBtn}
                                value="Post a new project from scratch"
                            /> */}
                        </div>
                        {/* </div> */}
                    </CustomContainer>
                </div>
                <Footer light />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;

    return {
        user,
    };
};

export default connect(mapStateToProps, {})(PostChooseProjectTypeScreen);
