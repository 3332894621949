import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    updateLogin,
    loginUser,
    authenticate,
} from '../../ducks/UserSessionDuck';

import {
    WelcomeHeader,
    Footer,
    SnackbarContentWrapper,
    CustomContainer,
} from '../common';

import { fetchSchools } from '../../ducks/OnboardingDuck';
import { push } from 'connected-react-router';

import {
    Grid,
    TextField,
    Button,
    Snackbar,
    Link,
    CircularProgress,
    Typography,
} from '@material-ui/core';

import './WelcomeScreen.css';
import { store } from '../..';

import Colors from '../../constants/Colors';
import GlobalStyles from '../../constants/GlobalStyles';
import OnbordingStyles from '../../constants/OnboardingStyles';

const globalStyles = GlobalStyles;

const styles = {
    textInput: {
        display: 'flex',
        borderRadius: 20,
        border: 'solid 1px #8e8e93',
        backgroundColor: '#ffffff',
        paddingRight: 12,
        paddingLeft: 12,
        paddingTop: 3,
        paddingBottom: 3,
        marginBottom: 20,
        flex: 1,
        fontSize: 18,
        // fontFamily: 'Inter',
    },
    buttonContainer: {},
    forgotPasswordContainer: {
        marginTop: 10,
    },
};

class WelcomeScreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isSuccessSnackbarOpen: false,
            isErrorSnackbarOpen: false,
        };

        this.handleSuccessSnackbarClose = this.handleSuccessSnackbarClose.bind(
            this
        );
        this.handleErrorSnackbarClose = this.handleErrorSnackbarClose.bind(
            this
        );
    }

    async componentDidMount() {
        // this.checkLoggedIn();
    }

    checkLoggedIn() {
        const token = localStorage.getItem('auth_token');

        if (token) {
            this.props.authenticate({ token: token });
        }
    }

    handleSuccessSnackbarClose() {
        this.setState({
            isSuccessSnackbarOpen: false,
        });
    }

    handleErrorSnackbarClose() {
        this.setState({
            isErrorSnackbarOpen: false,
        });
    }

    render() {
        return (
            <div
                style={
                    {
                        // backgroundImage: "url('images/pangea-background.jpg')",
                        // backgroundRepeat: 'no-repeat',
                        // backgroundSize: 'contain',
                        // opacity: 0.5,
                        // // backgroundSize: 'auto auto',
                        // // backgroundSize: 'auto 100%',
                        // backgroundPosition: 'left top',
                    }
                }
            >
                {/* <img
                    src={'images/pangea-background.jpg'}
                    alt="bg"
                    style={{
                        opacity: 0.5,
                        display: 'block',
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        zIndex: -2,
                    }}
                /> */}
                <WelcomeHeader activeLink="login" />
                <div>
                    <CustomContainer>
                        <Grid
                            container
                            spacing={2}
                            style={{ height: '100vh' }}
                            // justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item md={6}>
                                <p style={OnbordingStyles.subTitleText}>
                                    Welcome to Pangea.app
                                </p>
                                <div style={{ height: 20 }} />
                                <p style={OnbordingStyles.titleText}>
                                    Please Sign In!
                                </p>
                                <div style={{ height: 20 }} />
                            </Grid>
                            <Grid item md={4}>
                                <form
                                    // loading={this.props.loading}
                                    onSubmit={e => {
                                        e.preventDefault();
                                        this.props.loginUser({
                                            email: this.props.email,
                                            password: this.props.password,
                                        });
                                    }}
                                >
                                    <TextField
                                        type="email"
                                        name="email-field"
                                        // placeholder="Email"
                                        label="Email"
                                        variant="outlined"
                                        // fluid
                                        // icon="envelope"
                                        value={this.props.email}
                                        autoFocus={true}
                                        onChange={event => {
                                            this.props.updateLogin({
                                                email: event.target.value,
                                                password: this.props.password,
                                            });
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                ...globalStyles.marginBottom20,
                                            },
                                        }}
                                    />
                                    <TextField
                                        type="password"
                                        name="password-field"
                                        label="Password"
                                        variant="outlined"
                                        // icon="lock"
                                        value={this.props.password}
                                        onChange={event => {
                                            this.props.updateLogin({
                                                email: this.props.email,
                                                password: event.target.value,
                                            });
                                        }}
                                        fullWidth
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                ...globalStyles.marginBottom20,
                                            },
                                        }}
                                    />
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            flex: 1,
                                        }}
                                    >
                                        <div style={{ position: 'relative' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                type="submit"
                                                disabled={this.props.loading}
                                            >
                                                Sign In
                                            </Button>
                                            {this.props.loading && (
                                                <CircularProgress
                                                    size={24}
                                                    style={
                                                        globalStyles.buttonProgress
                                                    }
                                                />
                                            )}
                                        </div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => {
                                                this.props.fetchSchools();
                                                store.dispatch(
                                                    push('/addName')
                                                );
                                            }}
                                        >
                                            Create Account
                                        </Button>
                                    </div>
                                    <div style={styles.forgotPasswordContainer}>
                                        <Typography>
                                            <Link
                                                color="inherit"
                                                href="/forgotPassword"
                                                style={{
                                                    fontSize: 14,
                                                    color: Colors.fontBlack,
                                                }}
                                            >
                                                Forgot Password?
                                            </Link>
                                        </Typography>
                                    </div>
                                    {/* <p
                                    onClick={() => {
                                        store.dispatch(push('/forgotPassword'));
                                    }}
                                >
                                    Forgot Password
                                </p> */}
                                </form>
                                {/* {this.props.user ? (
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        open={
                                            this.isSuccessSnackbarOpen ||
                                            !!this.props.user
                                        }
                                        key={!!this.props.user}
                                        autoHideDuration={6000}
                                        onClose={
                                            this.handleSuccessSnackbarClose
                                        }
                                    >
                                        <SnackbarContentWrapper
                                            onClose={
                                                this.handleSuccessSnackbarClose
                                            }
                                            variant="success"
                                            message={`Welcome, ${this.props.user.full_name}!`}
                                        />
                                    </Snackbar>
                                ) : null} */}

                                {this.props.error ? (
                                    <Snackbar
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'left',
                                        }}
                                        open={
                                            this.isErrorSnackbarOpen ||
                                            !!this.props.error
                                        }
                                        key={!!this.props.error}
                                        autoHideDuration={6000}
                                        onClose={this.handleErrorSnackbarClose}
                                    >
                                        <SnackbarContentWrapper
                                            onClose={
                                                this.handleErrorSnackbarClose
                                            }
                                            variant="error"
                                            message={`${this.props.error}`}
                                        />
                                    </Snackbar>
                                ) : null}
                            </Grid>
                        </Grid>
                    </CustomContainer>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = ({ userSession }) => {
    const { email, password, error, loading, user } = userSession;

    return {
        email: email ? email : '',
        password: password ? password : '',
        error,
        loading,
        user,
    };
};

export default connect(mapStateToProps, {
    loginUser,
    updateLogin,
    authenticate,
    fetchSchools,
})(WelcomeScreen);
