import React, { Component } from 'react';

import { Footer, WelcomeHeader } from '../common';

class DownloadScreen extends Component {
    render() {
        return (
            <div>
                <div className="section-2">
                    <WelcomeHeader />
                    <div className="container-download-copy">
                        <div className="div-block-13">
                            <h6 className="h1-copy heading-2-copy-copy">
                                Join the Pangeans.
                            </h6>
                            <a
                                href="https://itunes.apple.com/us/app/pangea-app/id1278002444?mt=8"
                                className="button-10 w-button"
                            >
                                Download for iOS
                            </a>
                            <a
                                href="https://play.google.com/store/apps/details?id=com.Pangeamart.Pangea"
                                className="button-10 w-button"
                            >
                                Download for Android
                            </a>
                        </div>
                        <div className="div-block-12"></div>
                    </div>
                </div>
                <div className="section-3">
                    <h1 className="h1-blue-downlod">Watch a demo</h1>
                    <div className="div-block-11-copy-copy-copy">
                        <div
                            style={{ paddingTop: '56.17021276595745%' }}
                            id="w-node-af0f77fa0ba4-815e7649"
                            className="w-embed-youtubevideo youtube"
                        >
                            <iframe
                                src="https://www.youtube.com/embed/EMPFBrpQs7c?rel=0&amp;controls=1&amp;autoplay=0&amp;mute=0&amp;start=0"
                                frameborder="0"
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    width: '100%',
                                    height: '100%',
                                    pointerEvents: 'auto',
                                }}
                                allow="autoplay; encrypted-media"
                                allowfullscreen=""
                            ></iframe>
                        </div>
                    </div>
                    <h1 className="h1-blue-downlod-copy">
                        See what we&#x27;re up to
                    </h1>
                    <div className="columns-6 w-row">
                        <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <a
                                href="https://medium.com/pangea-app"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-block-4 w-inline-block"
                            >
                                <img
                                    src="images/Medium.png"
                                    alt=""
                                    className="image-20"
                                />
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <a
                                href="https://www.instagram.com/pangea.app/?hl=en"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-block-4 w-inline-block"
                            >
                                <img
                                    src="images/Instagram.png"
                                    alt=""
                                    className="image-19"
                                />
                            </a>
                        </div>
                        <div className="w-col w-col-4 w-col-small-4 w-col-tiny-4">
                            <a
                                href="https://www.linkedin.com/company/app-pangea/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="link-block-4 w-inline-block"
                            >
                                <img
                                    src="images/Linkedin.png"
                                    alt=""
                                    className="image-18"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default DownloadScreen;
