import React, { Component } from 'react';
import { connect } from 'react-redux';

import Colors from '../../../constants/Colors';
import Environment from '../../../constants/Environment';

import PropTypes from 'prop-types';

import { Card, CardActionArea, Typography, GridList } from '@material-ui/core';

import { SkillCard } from '..';

const styles = {
    rootContainer: {
        borderRadius: 10,
        boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.15)',
        border: 'solid 1px #c7c7cc',
        backgroundColor: '#ffffff',
        marginBottom: 20,
        width: '100%',
        // padding: 10,
    },

    bioContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
    },

    profileImage: {
        borderRadius: 40,
        width: 80,
        height: 80,
        border: 'solid 1px #c7c7cc',
        marginRight: 20,
    },

    schoolImage: {
        borderRadius: 30,
        width: 60,
        height: 60,
        border: 'solid 1px #c7c7cc',
        marginLeft: 20,
    },

    bioTextContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    boldBioText: {
        fontWeight: 'bold',
        display: 'block',
        fontSize: 16,
        marginBottom: 1,
    },

    bioText: {
        fontSize: 14,
        display: 'block',
    },

    // skillsContainer: {
    //     marginTop: 10,
    //     display: 'flex',
    //     justifyContent: 'space-between',
    // },

    skillChip: {
        borderRadius: 15.5,
        border: 'solid 1px #006c9e',
        backgroundColor: '#ffffff',
        color: '#006c9e',
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 10,
    },
    gridList: {
        flexWrap: 'nowrap',
        transform: 'translateZ(0)',
        paddingBottom: 10,
    },
};

class ProjectItem extends Component {
    _renderSelectedSkillItem = (item, index) => {
        return (
            <SkillCard
                backgroundColor={Colors.white}
                skillName={item.name}
                isDeletable={false}
                isAddable={false}
                fontColor={'blue'}
            />
        );
    };

    render() {
        let project = this.props.project;

        let crest = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Schools/84c9ffd3-107b-43f0-8f7a-8d99515d0892`;

        if (
            project.owner_user_type !== null &&
            project.owner_user_type !== undefined
        ) {
            if (project.owner_user_type === 'business') {
                crest = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${project.owner_company_id}/logoPic/${project.owner_company_logo}`;
            } else {
                crest = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${project.owner_id}/profilePic/${project.owner_profile_pic}`;
            }
        } else {
            if (project.owner && project.company) {
                if (project.owner.user_type === 'business') {
                    crest = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Companies/${project.company.id}/logoPic/${project.company.logo_pic}`;
                } else {
                    crest = `https://storage.googleapis.com/${Environment.GCLOUDBUCKET}/Pangea/Users/${project.owner_id}/profilePic/${project.owner.profile_pic}`;
                }
            }
        }

        let compensationString = '';
        let comp = project.compensation ? project.compensation.toString() : '';

        if (comp.length === 4) {
            comp = comp.substring(0, 1) + ',' + comp.substring(1);
        } else if (comp.length === 5) {
            comp = comp.substring(0, 2) + ',' + comp.substring(2);
        } else if (comp.length === 6) {
            comp = comp.substring(0, 3) + ',' + comp.substring(3);
        }

        switch (project.payment_frequency) {
            case 'project':
                compensationString = `$${comp}`;
                break;
            case 'hour':
            case 'day':
            case 'week':
            case 'month':
            case 'year':
                compensationString = `$${comp} / ${project.payment_frequency}`;
                break;
            case 'negotiable':
                compensationString = `Negotiable`;
                break;
            case 'never':
                compensationString = `Unpaid`;
                break;
            default:
                break;
        }

        let logoText = '';

        if (
            project.owner_user_type !== null &&
            project.owner_user_type !== undefined
        ) {
            if (project.owner_user_type === 'business') {
                logoText = project.owner_company_name;
            } else {
                logoText =
                    project.owner_first_name +
                    ' ' +
                    project.owner_last_name.substring(0, 1) +
                    '.';
            }
        } else {
            if (project.owner && project.company) {
                if (project.owner.user_type === 'business') {
                    logoText = project.company.company_name;
                } else {
                    logoText =
                        project.owner.first_name +
                        ' ' +
                        project.owner.last_name.substring(0, 1) +
                        '.';
                }
            }
        }

        console.log('PangeanItem props');
        console.log(this.props);
        return (
            <Card style={styles.rootContainer}>
                <CardActionArea
                    style={{ padding: 10, paddingBottom: 0 }}
                    onClick={this.props.onPress}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div style={styles.bioContainer}>
                            <img
                                src={crest}
                                style={styles.profileImage}
                                alt={''}
                            />
                            <div style={styles.bioTextContainer}>
                                <Typography variant="h5">
                                    {this.props.project.title}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {logoText}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {compensationString}
                                </Typography>
                                <Typography variant="subtitle2">
                                    {
                                        this.props.project.loc.address
                                            .formatted_name
                                    }
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <GridList style={styles.gridList}>
                        {this.props.project.skills &&
                        this.props.project.skills.length > 0
                            ? this.props.project.skills.map((skill, index) =>
                                  this._renderSelectedSkillItem(skill, index)
                              )
                            : null}
                    </GridList>
                </CardActionArea>
            </Card>
        );
    }
}

ProjectItem.propTypes = {
    project: PropTypes.object.isRequired,
};

const mapStateToProps = ({ userSession }) => {
    const { user } = userSession;
    return {
        user,
    };
};

export default connect(mapStateToProps, {})(ProjectItem);
