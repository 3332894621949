import React from 'react';

import { FormControlLabel, Radio, Typography } from '@material-ui/core';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

import { Field } from 'react-final-form';

import moment from 'moment';

let aYearFromNow = new Date();
aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

const ProjectTypeForm = props => {
    return (
        <div>
            <Typography variant="h5" gutterBottom>
                When is the start date?
            </Typography>
            <Field name="availability" type="radio" value="asap">
                {({ input, meta }) => (
                    <React.Fragment>
                        <FormControlLabel
                            value="ASAP"
                            control={
                                <Radio
                                    color="primary"
                                    checked={input.checked}
                                    onChange={() => {
                                        input.onChange('asap');
                                    }}
                                />
                            }
                            label="ASAP"
                        />
                    </React.Fragment>
                )}
            </Field>
            <Field name="availability" type="radio" value="specific_date">
                {({ input, meta }) => (
                    <React.Fragment>
                        <FormControlLabel
                            value="Specific Date"
                            control={
                                <Radio
                                    color="primary"
                                    checked={input.checked}
                                    onChange={() => {
                                        input.onChange('specific_date');
                                    }}
                                />
                            }
                            label="Specific Date"
                        />
                        {meta.error && meta.touched && (
                            <Typography
                                variant="caption"
                                color="error"
                                display="block"
                            >
                                {' '}
                                {`${meta.error}`}
                            </Typography>
                        )}
                    </React.Fragment>
                )}
            </Field>
            {props.values.availability &&
                props.values.availability === 'specific_date' && (
                    <div>
                        <Field name="startDate">
                            {({ input, meta }) => (
                                <React.Fragment>
                                    <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                    >
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM-dd-yyyy"
                                            margin="normal"
                                            id="date-picker-inline"
                                            label="Date"
                                            value={moment(input.value).format(
                                                'MM-DD-YYYY'
                                            )}
                                            minDate={Date.now()}
                                            maxDate={aYearFromNow}
                                            onChange={date => {
                                                let start_date = moment
                                                    .utc(date, 'MM-DD-YYYY')
                                                    // .add(1, 'day')
                                                    .toISOString();

                                                input.onChange(start_date);

                                                // props._updatePost({
                                                //     start_date,
                                                // });
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </React.Fragment>
                            )}
                        </Field>
                    </div>
                )}
        </div>
    );
};

export default ProjectTypeForm;
