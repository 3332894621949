import WS from '../api/WS';

// ------------------------------------ TYPES ------------------------------------
export const types = {
    SEARCHTAG_TYPEAHEAD: 'searchtags_typeahead',
    SEARCHTAG_TYPEAHEAD_SUCCESS: 'searchtags_typeahead_success',
    SEARCHTAG_TYPEAHEAD_FAIL: 'searchtags_typeahead_fail',
    SEARCHTAG_RESET_TYPEAHEAD: 'searchtags_reset_typeahead',
};

// ------------------------------------ REDUCER ------------------------------------
const INITIAL_STATE = {
    loading: false,
    error: '',
    typeaheadSearchTags: null,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case types.SEARCHTAG_TYPEAHEAD:
            return { ...state, loading: true };
        case types.SEARCHTAG_TYPEAHEAD_SUCCESS:
            return {
                ...state,
                loading: false,
                typeaheadSearchTags: action.payload,
            };
        case types.SEARCHTAG_TYPEAHEAD_FAIL:
            return { ...state, loading: false, error: action.payload };
        case types.SEARCHTAG_RESET_TYPEAHEAD:
            return { ...state, typeaheadSearchTags: [] };
        default:
            return state;
    }
};

// ------------------------------------ ACTIONS ------------------------------------

export const searchTagsTypeahead = ({ text, size }) => {
    return dispatch => {
        dispatch({ type: types.SEARCHTAG_TYPEAHEAD });

        WS.socket.emit('search:searchTag:typeahead', {
            text,
            size,
        });
        WS.socket.once('search:searchTag:typeahead', res => {
            if (res.success) {
                searchtagsTypeaheadSuccess(dispatch, res.data);
            } else {
                searchtagsTypeaheadFail(dispatch, res.message);
            }
        });
    };
};

const searchtagsTypeaheadSuccess = (dispatch, res) => {
    dispatch({ type: types.SEARCHTAG_TYPEAHEAD_SUCCESS, payload: res });
};
const searchtagsTypeaheadFail = (dispatch, msg) => {
    dispatch({ type: types.SEARCHTAG_TYPEAHEAD_FAIL, payload: msg });
};

export const resetTypeaheadSearchTags = () => {
    return dispatch => {
        dispatch({ type: types.SEARCHTAG_RESET_TYPEAHEAD, payload: '' });
    };
};
